import React, { useState, useEffect } from "react";
import { makeGETRequest } from "../../Api/api";
import { addSerialNumber } from "../../Helpers/SecurityMasterHelpers";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { issueTimeDataGridCells, getIPOAPIBaseURL } from "./helpers";

const toastr = require("toastr");

export default function IssueTime() {
  let baseUrl = getIPOAPIBaseURL();
  const [issueTimes, setIssueTimes] = useState<any>([]);

  useEffect(() => {
    makeGETRequest(baseUrl + "/ipo/issuetimes_ipo/")
      .then((resp) => {
        if (resp.status === 200) {
          setIssueTimes(addSerialNumber(resp.data));
        } else {
          toastr.error("Something went wrong.");
        }
      })
      .catch((err) => toastr.error(err));
  }, []);

  return (
    <Grid className="securityTypeDataGird">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>Issue Time</div>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ height: "100%", width: "100%" }}>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            rows={issueTimes}
            columns={issueTimeDataGridCells}
            getRowId={(row) => row.serial}
            autoHeight
          />
        </div>
      </Paper>
    </Grid>
  );
}
