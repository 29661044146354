import React from "react";
import {
  makeDELETERequestOnUserServiceWithQueryParameter,
  makeGETRequestOnUserService,
  makePUTRequestOnUserService,
} from "../Api/api";
import GenericDialog from "../components/Dialog/GenericDialog";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { Grid } from "@material-ui/core";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EditCashFlowDateDialog from "../components/Dialog/EditCashFlowDateDialog";
import useForceUpdate from "use-force-update";
import IdleTimoutConfig from "../Helpers/IdleTimeoutConfig";

interface CashFlowRecord {
  amount: string;
  date: string;
  insertedAt: string;
  isin: string;
  updatedAt: string;
  user: string;
  uuid: string;
  serial?: number;
  userName: string;
  mobileNumber: string;
  createdAt: string;
}

const dataGridCells = [
  { field: "serial", headerName: "ID", width: 100, editable: false },
  { field: "amount", headerName: "Amount", width: 200, editable: false },
  { field: "isin", headerName: "ISIN", width: 200, editable: false },
  { field: "date", headerName: "Date", width: 250, editable: false },
  { field: "userName", headerName: "User Name", width: 250, editable: false },
  {
    field: "mobileNumber",
    headerName: "Mobile Number",
    width: 250,
    editable: false,
  },

  {
    field: "insertedAt",
    headerName: "Created At",
    width: 250,
    editable: false,
  },
  {
    field: "Edit",
    headerName: "Edit",
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,

    renderCell: () => {
      return (
        <EditIcon
          color="primary"
          fontSize="medium"
          style={{
            float: "left",
            marginLeft: "10px",
            marginTop: "14px",
            cursor: "pointer",
          }}
        />
      );
    },
  },
  {
    field: "Delete",
    headerName: "Delete",
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,

    renderCell: () => {
      return (
        <DeleteIcon
          color="primary"
          fontSize="medium"
          style={{
            float: "left",
            marginLeft: "10px",
            marginTop: "14px",
            cursor: "pointer",
          }}
        />
      );
    },
  },
];

function formattifyRecords(records: CashFlowRecord[]): CashFlowRecord[] {
  let formatted: CashFlowRecord[] = [];

  records.map((record: CashFlowRecord, index: number) => {
    record.date = moment(record.date).format("LL"); //format("MMMM Do YYYY");
    record.createdAt = record.insertedAt;
    record.insertedAt = moment(record.insertedAt).format(
      "MMMM Do YYYY, h:mm:ss a"
    );
    record.serial = index + 1;
    formatted.push(record);
  });

  return formatted;
}

function getUpdatedCashFlowRecords(
  updated: CashFlowRecord,
  cashFlowRecords: CashFlowRecord[]
): CashFlowRecord[] {
  let formatted: CashFlowRecord[] = [];
  cashFlowRecords.map((cashFlowRecord: CashFlowRecord) => {
    if (cashFlowRecord.uuid === updated.uuid) {
      updated.date = moment(updated.date).format("MMMM Do YYYY");
      formatted.push(updated);
    } else formatted.push(cashFlowRecord);
  });
  return formatted;
}

function getFilteredCashFlowRecords(
  uuid: string,
  cashFlowRecords: CashFlowRecord[]
): CashFlowRecord[] {
  return cashFlowRecords.filter(
    (cashFlowRecord: CashFlowRecord) => cashFlowRecord.uuid !== uuid
  );
}

export default function CashFlowRecords(props: any) {
  const toastr = require("toastr");
  const forceUpdate = useForceUpdate();
  const initialState = {
    amount: "",
    date: "",
    insertedAt: "",
    isin: "",
    updatedAt: "",
    user: "",
    uuid: "",
    serial: 0,
    userName: "",
    mobileNumber: "",
    createdAt: "",
  };
  const [cashFlowRecords, setCashFlowRecords] = React.useState<
    CashFlowRecord[]
  >([]);
  const [toBeDeleted, setToBeDeleted] =
    React.useState<CashFlowRecord>(initialState);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [toBeEdited, setToBeEdited] =
    React.useState<CashFlowRecord>(initialState);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    makeGETRequestOnUserService("/cashflow_records/list/")
      .then((response: any) => {
        if (response && response.status === 200) {
          setCashFlowRecords(formattifyRecords(response.data));
        }
      })
      .catch((error: any) => toastr.error(error));
  }, []);

  function handleDeleteOnClick(row: CashFlowRecord) {
    setToBeDeleted(row);
    setShowDeleteModal(!showDeleteModal);
  }

  function handleEditIconOnClick(row: CashFlowRecord) {
    setToBeEdited(row);
    setShowEditModal(!showEditModal);
  }

  function currentlySelected(params: any) {
    if (params.field === "Delete") {
      handleDeleteOnClick(params.row);
    } else if (params.field === "Edit") {
      handleEditIconOnClick(params.row);
    }
  }

  function handleEdit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    makePUTRequestOnUserService("/cashflow_date/update/", {
      uuid: toBeEdited.uuid,
      date: moment(toBeEdited.date).format("LL"),
      amount: toBeEdited.amount,
    })
      .then((response: any) => {
        if (response?.status === 200) {
          setCashFlowRecords(
            getUpdatedCashFlowRecords(toBeEdited, cashFlowRecords)
          );
          setShowEditModal(!showEditModal);
          setToBeEdited(initialState);
          toastr.success("Updated Cash Flow record successfully!");
        }
      })
      .catch((error: any) => toastr.error(error));
  }

  function handleDateChange(date: string) {
    let currentState = toBeEdited;
    currentState.date = date;
    currentState.createdAt = date;
    setToBeEdited(currentState);
    forceUpdate();
  }

  function handleAmountChange(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    enteredValue: string
  ) {
    event.preventDefault();
    let currentState = toBeEdited;
    currentState.amount = enteredValue;
    setToBeEdited(currentState);
  }

  if (cashFlowRecords.length === 0) {
    return (
      <Box
        sx={{ width: 900 }}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <h1> Nothing to show here :(</h1>
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Box>
    );
  } else {
    return (
      <Grid className="subscriptionsDataGrid">
        <Paper>
          <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs>
                <div style={{ fontSize: "36px", float: "left" }}>
                  Cash Flow Records
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              height: "100vh",
              width: "100%",
            }}
          >
            <DataGrid
              className="subscripionDataGrid abc"
              components={{ Toolbar: GridToolbar }}
              rows={cashFlowRecords}
              columns={dataGridCells}
              onCellClick={currentlySelected}
              getRowId={(row: any) => row.uuid}
              pagination
            />
          </div>
        </Paper>
        {showDeleteModal ? (
          <GenericDialog
            show={showDeleteModal}
            heading="Delete Cash Flow Date"
            inputProps="Are you sure you want to delete this CashFlow Date?"
            onClose={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.preventDefault();
              setShowDeleteModal(!showDeleteModal);
              setToBeDeleted(initialState);
            }}
            handleSubmit={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.preventDefault();
              makeDELETERequestOnUserServiceWithQueryParameter(
                `cashflow_date/delete/${toBeDeleted.uuid}/`
              )
                .then((response: any) => {
                  if (response?.status === 200 || response?.status === 204) {
                    toastr.success("Deleted Cash Flow Record successfully !");
                    setCashFlowRecords(
                      getFilteredCashFlowRecords(
                        toBeDeleted.uuid,
                        cashFlowRecords
                      )
                    );
                    setToBeDeleted(initialState);
                    setShowDeleteModal(!showDeleteModal);
                  }
                })
                .catch((error: any) => {
                  toastr.error(error);
                });
            }}
          />
        ) : (
          ""
        )}
        {showEditModal ? (
          <EditCashFlowDateDialog
            show={showEditModal}
            cashFlowRecord={toBeEdited}
            onClose={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.preventDefault();
              setShowEditModal(!showEditModal);
              setToBeEdited(initialState);
            }}
            handleAmountChange={handleAmountChange}
            handleDateChange={handleDateChange}
            handleSubmit={handleEdit}
          />
        ) : (
          ""
        )}
        // <IdleTimoutConfig />
      </Grid>
    );
  }
}
