import React, { useState, useEffect, useContext } from "react";
import toastr from "toastr";
import { TextField, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ValidateSecurityDialog = (props) => {
  const [isin, setIsin] = React.useState("");

  const resetStateAndClose = (event) => {
    event.preventDefault();
    setIsin("");
    props.handleClose(event);
  };

  const handleChange = (event) => {
    event.preventDefault();
    setIsin(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    let enteredISIN = isin;
    setIsin("");
    props.validateISIN(enteredISIN);
  };

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={(event) => resetStateAndClose(event)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Validate ISIN</DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            <Grid item xs={12}>
              <TextField
                type="text"
                name="isin"
                variant="outlined"
                fullWidth
                label="ISIN"
                defaultValue={isin}
                onChange={(event) => handleChange(event)}
              />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(event) => resetStateAndClose(event)}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={(event) => submitHandler(event)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ValidateSecurityDialog;
