import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Values } from "./Calculations";
import { shallow } from "zustand/shallow";
import moment from "moment";
import toastr from "toastr";
import useCalc from "../../store/calc";
import styles from "../../Helpers/styles";
import { sortDate } from "../../Helpers/functions";
import downloadCashflow from "../../Helpers/downloadCashflowFunction";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const AdditionalInfo = () => {
  const [bondInfo, calculatedValues] = useCalc(
    (state) => [state.bondInfo, state.calculatedValues],
    shallow
  );

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "16px",
          color: "#9397A4",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
        }}
      >
        Additional Information
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "380px",
          position: "relative",
          overflow: "visible",
          mt: 2,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: -11,
            display: "flex",
            flexWrap: "wrap",
            height: "100%",
            overflow: "hidden",
            pr: 1,
            ":hover": {
              overflowY: "overlay",
            },
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              marginTop: "130px",
              marginBottom: "130px",
              backgroundColor: `${alpha("#2A324C", 0.1)}`,
              borderRadius: 50,
              border: `2px solid white`,
              boxShadow: `inset 0 0 6px ${alpha("#2A324C", 0.1)}`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: `#afafaf`,
              borderRadius: 50,
            },
          }}
        >
          <Values
            value={`${bondInfo?.securityType.type}`}
            label="Security Type"
          />
          <Values
            value={`${bondInfo?.frequencyOfInterest}`}
            label="Interest Payment Frequency"
          />
          <Values
            value={
              <>
                {bondInfo?.ratingOrganizationMasters
                  ?.map(
                    ({ rating: { rating }, organization: { name } }: any) => ({
                      rating,
                      ratingOrg: name,
                    })
                  )
                  .map((value, index) => (
                    <React.Fragment key={index}>
                      <Box component={"span"}>{value.rating}</Box>
                      <Box component={"span"} sx={styles.label}>
                        {" "}
                        by{" "}
                      </Box>
                      <Box component={"span"} sx={{ color: "info.main" }}>
                        {value.ratingOrg}
                      </Box>
                      <br />
                    </React.Fragment>
                  ))}
              </>
            }
            label="Issue Rating"
          />
          <Values value={`${bondInfo?.stepUpDown}`} label="Step Up/Down" />
          <Values
            value={`${bondInfo?.recordDate}`}
            label="Shut Period(No. of days)"
          />
          <Values
            value={`${calculatedValues?.ipDates.map((date) => date)}`}
            label="Interest Payment Date"
          />
          <Values
            value={`${bondInfo?.putOptions?.at(-1)?.date || "Not Applicable"}`}
            label="Put Date"
          />
          <Values
            value={`${bondInfo?.callOptions?.at(-1)?.date || "Not Applicable"}`}
            label="Call Date"
          />
          <Values
            value={`${moment(bondInfo?.issueDate, "YYYY-MM-DD").format(
              "DD/MM/YYYY"
            )}`}
            label="Allotment Date"
          />
          <Values
            value={
              <>
                {bondInfo?.maturities
                  .sort((a, b) => sortDate(a, b))
                  .map((item, index) => (
                    <React.Fragment key={index}>
                      {item.date} - {item.amount}% <br />
                    </React.Fragment>
                  ))}
              </>
            }
            label="Redemption Dates"
          />
        </Box>
      </Box>
      <Box sx={{ mt: 2, ...styles.flexColumnCenterCenter }}>
        <Button
          variant="outlined"
          sx={{
            ...styles.flexCenterCenter,
            fontSize: 13,
            p: 1.5,
            mb: 2,
            width: "80%",
            color: "#F33A43",
            bgcolor: `${alpha("#EC4F56", 0.08)}`,
            border: `1px solid ${alpha("#EC4F56", 0.3)}`,
            ":hover": {
              bgcolor: `${alpha("#EC4F56", 0.08)}`,
              border: `1px solid ${alpha("#EC4F56", 0.3)}`,
            },
          }}
          onClick={() =>
            bondInfo && calculatedValues
              ? downloadCashflow(bondInfo, calculatedValues)
              : toastr.error(`Error Generating Cashflow`)
          }
        >
          <Box component="span" sx={{ mr: 1 }}>
            Download Cashflow in PDF
          </Box>
          <PictureAsPdfIcon sx={{ color: "red" }} />
        </Button>
      </Box>
    </Box>
  );
};

export default AdditionalInfo;
