import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBoxIcon from "@mui/icons-material/AddBox";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment-timezone";

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const userTableCells = [
  { field: "serial", headerName: "Serial", width: 190, editable: false },
  {
    field: "insertedAt",
    headerName: "Created At",
    valueFormatter: (params) => {
      return moment(params.value)
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD HH:mm:ss");
    },
    width: 190,
    editable: false,
  },
  { field: "source", headerName: "Source", width: 190, editable: false },
  {
    field: "isESignComplete",
    headerName: "KYC Status",
    width: 190,
    editable: false,
  },
  { field: "name", headerName: "Name", width: 190, editable: false },
  { field: "emailId", headerName: "Email", width: 190, editable: false },
  {
    field: "mobileNumber",
    headerName: "Mobile Number",
    width: 190,
    editable: false,
  },
  {
    field: "leadowner",
    headerName: "Lead Owner",
    width: 190,
    editable: false,
  },
  {
    field: "leadsstatus",
    headerName: "Lead Status",
    width: 190,
    editable: false,
  },
  {
    field: "isDeleted",
    headerName: "Is Deleted",
    width: 190,
    editable: false,
  },
  { field: "smestID", headerName: "SMEST ID", width: 190, editable: false },
  {
    field: "view",
    headerName: "View Details",
    sortable: false,
    width: 130,
    disableClickEventBubbling: true,
    renderCell: () => {
      return (
        <VisibilityIcon
          color="primary"
          style={{
            float: "left",
            "margin-top": "10px",
            "margin-left": "14px",
            cursor: "pointer",
          }}
        />
      );
    },
  },
  {
    field: "edit",
    headerName: "Edit",
    sortable: false,
    width: 130,
    disableClickEventBubbling: true,
    renderCell: () => {
      return (
        <EditIcon
          color="primary"
          fontSize="medium"
          style={{
            float: "left",
            "margin-top": "10px",
            "margin-left": "14px",
            cursor: "pointer",
          }}
        />
      );
    },
  },
  {
    field: "download",
    headerName: "Download KYC Docs",
    sortable: false,
    width: 130,
    disableClickEventBubbling: true,
    renderCell: () => {
      return (
        <DownloadIcon
          color="primary"
          style={{
            float: "left",
            "margin-top": "10px",
            "margin-left": "14px",
            cursor: "pointer",
          }}
        />
      );
    },
  },

  {
    field: "delete",
    headerName: "Delete",
    sortable: false,
    width: 130,
    disableClickEventBubbling: true,
    renderCell: () => {
      return (
        <DeleteIcon
          color="primary"
          style={{
            cursor: "pointer",
          }}
        />
      );
    },
  },

  {
    field: "addUCC",
    headerName: "Add UCC",
    sortable: false,
    width: 130,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      const rowData = params.row;
      let isKYCCompleteCondition =
        rowData.isPanCardSubmitted &&
        rowData.isAddressProofSubmitted &&
        rowData.isChequeSubmitted &&
        rowData.isPennyDropComplete &&
        rowData.isDematProofSubmitted &&
        rowData.isESignComplete;
      if (isKYCCompleteCondition && !rowData.isRFQRegistrationComplete) {
        return (
          <AddBoxIcon
            color="primary"
            style={{
              cursor: "pointer",
            }}
          />
        );
      } else {
        return "";
      }
    },
  },

  {
    field: "updateUCC",
    headerName: "Edit UCC",
    sortable: false,
    width: 130,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      const rowData = params.row;
      if (rowData.isRFQRegistrationComplete) {
        return (
          <BorderColorIcon
            color="primary"
            style={{
              cursor: "pointer",
            }}
          />
        );
      } else {
        return "";
      }
    },
  },
];

export const childAccountHeadCells = [
  { id: "id", numeric: true, disablePadding: false, label: "Serial No" },
  {
    id: "Account Type",
    numeric: false,
    disablePadding: true,
    label: "accountType",
  },
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: "First Name",
  },
  {
    id: "lastName",
    numeric: false,
    disablePadding: true,
    label: "Last Name",
  },
  {
    id: "mobileNumber",
    numeric: false,
    disablePadding: true,
    label: "Mobile Number",
  },

  {
    id: "gender",
    numeric: false,
    disablePadding: true,
    label: "Gender",
  },

  {
    id: "editDetails",
    numeric: false,
    disablePadding: true,
    label: "Edit Details",
  },
  { id: "delete", numeric: false, disablePadding: true, label: "Delete" },
];

export const ChildAccountEnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {childAccountHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

ChildAccountEnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
