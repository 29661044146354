import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Container } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    textAlign: "center",
  },
  link: {
    textDecoration: "none",
  },
}));

const NotFoundPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth="md">
      <Typography variant="h1" gutterBottom>
        404 - Not Found
      </Typography>
      <Typography variant="h4" gutterBottom>
        Sorry, the page you are looking for does not exist.
      </Typography>
      <Typography variant="body1" gutterBottom>
        You can go back to the{" "}
        <Link to="/dashboard" className={classes.link}>
          homepage
        </Link>
        .
      </Typography>
      <Button
        variant="contained"
        component={Link}
        to="/dashboard"
        color="primary"
      >
        Dashboard
      </Button>
    </Container>
  );
};

export default NotFoundPage;
