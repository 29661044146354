import React, { useEffect, useState } from "react";
import { makePOSTRequest } from "../../Api/api";
import { IPOBid } from "./Interfaces";
import { addSerialNumber } from "../../Helpers/SecurityMasterHelpers";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { ipoBidDataCells, getIPOAPIBaseURL } from "./helpers";

const toastr = require("toastr");

export default function IPOBids() {
  let baseUrl = getIPOAPIBaseURL();

  const [ipoBids, setIpoBids] = useState<IPOBid[]>([]);

  useEffect(() => {
    makePOSTRequest(baseUrl + "/ipo/biddataipoapiview_ipo/", {
      symbol: "MMFSLNCD1",
      flag: "B",
      issuetype: "DBT",
    }).then((resp) => {
      if (resp.status === 200) {
        setIpoBids(addSerialNumber(resp.data));
      } else {
        toastr.error("Something went wrong!");
      }
    });
  }, []);

  return (
    <Grid className="securityTypeDataGird">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>
                IPO Bid Data
              </div>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ height: "100%", width: "100%" }}>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            rows={ipoBids}
            columns={ipoBidDataCells}
            getRowId={(row) => row.serial}
            autoHeight
          />
        </div>
      </Paper>
    </Grid>
  );
}
