import React from "react";
import { TextField } from "@material-ui/core";
import FullScreenDialog from "../Dialog/FullScreenDialog";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import AboutTheIssuer from "./AboutTheIssuerComponent";
import ManagementComponent from "./ManagementComponent";
import GenericSlider from "../Slider/GenericSlider";
import { makeStyles } from "@material-ui/core/styles";
import {
  Management,
  Pro,
  Con,
  QuarterlyRevenue,
  ShareholdingPattern,
  CategoryWiseSHP,
} from "./Interfaces";
import ProComponent from "./ProComponent";
import ConComponent from "./ConComponent";
import QuarterlyRevenueComponent from "./QuarterlyRevenue";
import ShareHoldingPatternComponent from "./ShareHoldingPatternComponent";
import RichTextEditorComponent from "../TextEditor/RichTextEditorComponent";
import { makePOSTRequest } from "../../Api/api";
import AnalysisReportPreview from "./AnalysisReportPreview";
import CategoryWiseSHPTable from "./CategoryWiseSHP/CategoryWiseSHPTable";
import {
  changeIdForIndividualRecords,
  removeCategorySHPRecord,
} from "./Helpers";
import GenericDialog from "../Dialog/GenericDialog";
const toastr = require("toastr");

const marks = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  aboutTheIssuer: {
    width: "650px",
    height: "300px",
  },

  useOfTheIssue: {
    width: "350px",
    height: "300px",
  },
  proTextField: {
    width: "450px",
    height: "300px",
  },
}));

const Heading = (props: any) => {
  const [showChangeHeadingDialog, setShowChangeHeadingDialog] =
    React.useState<boolean>(false);
  const [updatedHeading, setUpdatedHeading] = React.useState<string>("");
  return (
    <>
      <h3
        style={{ padding: "10px" }}
        onClick={(event) => {
          event.preventDefault();
          setShowChangeHeadingDialog(true);
        }}
      >
        {props.heading}
      </h3>
      {showChangeHeadingDialog ? (
        <GenericDialog
          show={showChangeHeadingDialog}
          heading="Update Heading"
          inputProps={
            <TextField
              type="text"
              name="con"
              variant="outlined"
              fullWidth
              label="Update Heading"
              defaultValue={updatedHeading}
              onChange={(event: any) => {
                event.persist();
                setUpdatedHeading(event.target.value);
              }}
            />
          }
          onClose={(event: any) => {
            event.preventDefault();
            setShowChangeHeadingDialog(false);
          }}
          handleSubmit={(event: any) => {
            event.preventDefault();
            props.updateHeading(updatedHeading);
            setShowChangeHeadingDialog(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default function SMESTAnalysisReportDialog(
  props: any
): React.ReactElement {
  const classes = useStyles();
  const categoryWiseSHPinitialState = {
    id: 1,
    categoryName: "",
    particular: "",
    shareholding: "",
  };
  const [categoryWiseSHPRecords, setCategoryWiseSHPRecords] = React.useState<
    CategoryWiseSHP[]
  >(
    props?.report?.categoryWiseSHPRecords
      ? props.report.categoryWiseSHPRecords
      : [categoryWiseSHPinitialState]
  );
  const [aboutTheIssuer, setAboutTheIssuer] = React.useState<string>(
    props?.report?.aboutTheIssuer ? props.report.aboutTheIssuer : ""
  );
  const [useOfTheIssueHeading, setUseOfTheIssueHeading] = React.useState(
    props?.report?.useOfTheIssueHeading
      ? props.report.useOfTheIssueHeading
      : "Use of the Issue"
  );
  const [aboutTheIssuerHeading, setAboutTheIssuerHeading] = React.useState(
    props?.report?.aboutTheIssuerHeading
      ? props.report.aboutTheIssuerHeading
      : "About the Issuer"
  );
  const [interestPaymentHistoryHeading, setInterestPaymentHistoryHeading] =
    React.useState(
      props?.report?.interestPaymentHistoryHeading
        ? props.report.interestPaymentHistoryHeading
        : "Interest Payment History"
    );
  const [managementHeading, setManagementHeading] = React.useState(
    props?.report?.managementHeading
      ? props.report.managementHeading
      : "Management"
  );
  const [ratingDriversHeading, setRatingDriversHeading] = React.useState(
    props?.report?.ratingDriversHeading
      ? props.report.ratingDriversHeading
      : "Rating Drivers"
  );
  const [quarterlyRevenueHeading, setQuarterlyRevenueHeading] = React.useState(
    props?.report?.quarterlyRevenueHeading
      ? props.report.quarterlyRevenueHeading
      : "Quarterly Revenue"
  );
  const [categoryWiseSHPHeading, setCategoryWiseSHPHeading] = React.useState(
    props?.report?.categoryWiseSHPHeading
      ? props.report.categoryWiseSHPHeading
      : "Category-wise SHP"
  );
  const [shareholdingPatternHeading, setShareholdingPatternHeading] =
    React.useState(
      props?.report?.shareholdingPatternHeading
        ? props.report.shareholdingPatternHeading
        : "Shareholding Pattern"
    );
  const [outlookonFinancialsHeading, setOutlookonFinancialsHeading] =
    React.useState(
      props?.report?.outlookonFinancialsHeading
        ? props.report.outlookonFinancialsHeading
        : "Outlook on Financials"
    );
  const [interestPaymentHistory, setInterestPaymentHistory] =
    React.useState<string>(
      props?.report?.interestPaymentHistory
        ? props.report.interestPaymentHistory
        : "1"
    );
  const [interestPaymentHistoryText, setInterestPaymentHistoryText] =
    React.useState<string>(
      props?.report?.interestPaymentHistoryText
        ? props.report.interestPaymentHistoryText
        : ""
    );
  const [managementRows, setManagementRows] = React.useState<Management[]>(
    props?.report?.managementRows ? props.report.managementRows : []
  );
  const [useOfTheIssue, setUseOfTheIssue] = React.useState<string>(
    props?.report?.useOfTheIssue ? props.report.useOfTheIssue : ""
  );
  const [pros, setPros] = React.useState<Pro[]>(
    props?.report?.pros ? props.report.pros : []
  );
  const [cons, setCons] = React.useState<Con[]>(
    props?.report?.cons ? props.report.cons : []
  );
  const [quarterlyRevenues, setQuarterlyRevenues] = React.useState<
    QuarterlyRevenue[]
  >(props?.report?.quarterlyRevenues ? props.report.quarterlyRevenues : []);
  const [shareholdingPatterns, setShareholdingPatterns] = React.useState<
    ShareholdingPattern[]
  >(
    props?.report?.shareholdingPatterns ? props.report.shareholdingPatterns : []
  );
  const [lookoutOnFinancials, setLookoutOnFinancials] = React.useState<string>(
    props?.report?.lookoutOnFinancials ? props.report.lookoutOnFinancials : ""
  );
  const [showAnalysisReportPreviewDialog, setShowAnalysisReportPreviewDialog] =
    React.useState<boolean>(false);

  function addNewShareholdingPattern(
    shareholdingPattern: ShareholdingPattern
  ): void {
    let currentPatterns: ShareholdingPattern[] = shareholdingPatterns;
    currentPatterns.push({
      ...shareholdingPattern,
      serial: shareholdingPatterns.length + 1,
    });
    setShareholdingPatterns(currentPatterns);
  }

  function deleteShareholdingPattern(index: number): void {
    setShareholdingPatterns(
      shareholdingPatterns.filter(
        (shareholdingPattern: ShareholdingPattern) =>
          shareholdingPattern.serial !== index
      )
    );
  }

  function removeManagementRow(toBeRemoved: number): void {
    setManagementRows(
      managementRows.filter(
        (management: Management) => management.index !== toBeRemoved
      )
    );
  }

  function addQuarterlyRevenue(quarterlyRevenue: QuarterlyRevenue): void {
    let currentQuarterlyRevenues: QuarterlyRevenue[] = quarterlyRevenues;
    currentQuarterlyRevenues.push({
      ...quarterlyRevenue,
      serial: quarterlyRevenues.length + 1,
    });
    setQuarterlyRevenues(currentQuarterlyRevenues);
  }

  function removeQuarterlyRevenue(index: number): void {
    setQuarterlyRevenues(
      quarterlyRevenues.filter(
        (quarterlyRevenue: QuarterlyRevenue) =>
          quarterlyRevenue.serial !== index
      )
    );
  }

  function addNewCon(con: Con): void {
    let currentCons = cons;
    currentCons.push({
      serial: cons.length + 1,
      heading: con.heading,
      text: con.text,
    });
    setCons(currentCons);
  }

  function deleteCon(index: number): void {
    setCons(cons.filter((con: Con) => con.serial !== index));
  }

  function addNewPro(pro: Pro): void {
    let currentPros = pros;
    currentPros.push({
      serial: pros.length + 1,
      heading: pro.heading,
      text: pro.text,
    });
    setPros(currentPros);
  }

  function deletePro(index: number) {
    setPros(pros.filter((pro: Pro) => pro.serial !== index));
  }

  function addNewManagementRow(newRow: Management): void {
    if (
      typeof newRow.information === "string" &&
      /\r|\n/.exec(newRow.information)
    ) {
      newRow = {
        ...newRow,
        information: newRow.information.split("\n").filter((val) => val),
      };
    }
    let currentRecords = managementRows;
    newRow.index = managementRows.length + 1;
    currentRecords.push(newRow);
    setManagementRows(currentRecords);
  }

  function getFormattedPayloadForAnalysisReport(): any {
    return {
      aboutTheIssuer: aboutTheIssuer,
      interestPaymentHistory: interestPaymentHistory,
      interestPaymentHistoryText: interestPaymentHistoryText,
      useOfTheIssue: useOfTheIssue,
      managementRows: managementRows,
      useOfTheIssueHeading: useOfTheIssueHeading,
      aboutTheIssuerHeading: aboutTheIssuerHeading, // NEW Field
      interestPaymentHistoryHeading: interestPaymentHistoryHeading, // NEW Field
      managementHeading: managementHeading, // NEW Field
      ratingDriversHeading: ratingDriversHeading, // NEW Field
      quarterlyRevenueHeading: quarterlyRevenueHeading, // NEW Field
      categoryWiseSHPHeading: categoryWiseSHPHeading, // NEW Field
      shareholdingPatternHeading: shareholdingPatternHeading, // NEW Field
      outlookonFinancialsHeading: outlookonFinancialsHeading, // NEW Field
      cons: cons,
      pros: pros,
      quarterlyRevenues: quarterlyRevenues,
      shareholdingPatterns: shareholdingPatterns,
      lookoutOnFinancials: lookoutOnFinancials,
      issuer_master_uuid: props.selectedIssuerMaster.uuid,
      categoryWiseSHPRecords: categoryWiseSHPRecords,
    };
  }

  function resetState(): void {
    setAboutTheIssuer("");
    setInterestPaymentHistory("1");
    setInterestPaymentHistoryText("");
    setManagementRows([]);
    setUseOfTheIssue("");
    setPros([]);
    setCons([]);
    setQuarterlyRevenues([]);
    setShareholdingPatterns([]);
    setLookoutOnFinancials("");
    setCategoryWiseSHPRecords([]);
  }

  function saveAnalysisReport(event: any): void {
    makePOSTRequest(
      "/admin/issuer_master/create/analysisReport/",
      getFormattedPayloadForAnalysisReport()
    )
      .then((response: any) => {
        props.updateReportInParentComponent(response.data.data);
        toastr.success("Added Analysis report successfully!");
      })
      .catch((error) => toastr.error(error));
  }

  function addSHPRecord(event: any, newlyAdded: CategoryWiseSHP) {
    event.preventDefault();
    categoryWiseSHPRecords.push(newlyAdded);
    setCategoryWiseSHPRecords(
      changeIdForIndividualRecords(categoryWiseSHPRecords)
    );
  }

  function deleteCategorySHPRecord(event: any, currentID: number) {
    event.preventDefault();
    let filteredRecords = removeCategorySHPRecord(
      categoryWiseSHPRecords,
      currentID
    );
    setCategoryWiseSHPRecords(changeIdForIndividualRecords(filteredRecords));
  }

  function updateSHPRecord(event: any, newlyAdded: CategoryWiseSHP) {
    event.preventDefault();
    let formatted: CategoryWiseSHP[] = [];
    categoryWiseSHPRecords.map((record: CategoryWiseSHP) => {
      if (record.id === newlyAdded.id) {
        let newState = {
          categoryName:
            newlyAdded.categoryName !== ""
              ? newlyAdded.categoryName
              : record.categoryName,
          shareholding:
            newlyAdded.shareholding !== ""
              ? newlyAdded.shareholding
              : record.shareholding,
          particular:
            newlyAdded.particular !== ""
              ? newlyAdded.particular
              : record.particular,
          id: newlyAdded.id || record.id,
        };
        formatted.push(newState);
      } else {
        formatted.push(record);
      }
    });
    setCategoryWiseSHPRecords(formatted);
  }

  return (
    <>
      <FullScreenDialog
        secondarySaveButtonText="Preview"
        onSecondarySaveButtonClick={(event: any) => {
          if (event) event.preventDefault();
          if (props.report) {
            setShowAnalysisReportPreviewDialog(
              !showAnalysisReportPreviewDialog
            );
          } else {
            toastr.warning("No Report Created for this user.");
          }
        }}
        showSecondarySaveButton={true}
        show={props.show}
        handleClose={(event: any) => {
          resetState();
          props.onCloseDialog(event);
        }}
        showSaveButton={true}
        saveButtonText="Update All"
        onSumitSaveButton={saveAnalysisReport}
        inputProps={
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <AboutTheIssuer
                  aboutTheIssuer={aboutTheIssuer}
                  setAboutTheIssuer={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    event.persist();
                    setAboutTheIssuer(event.target.value);
                  }}
                  heading={aboutTheIssuerHeading}
                  updateUseOfIssueHeading={(updatedValue: string) => {
                    setAboutTheIssuerHeading(updatedValue);
                  }}
                  class={classes.aboutTheIssuer}
                />
              </Grid>
              <Grid item xs={4}>
                <Paper
                  className={classes.paper}
                  style={{ justifyContent: "center" }}
                >
                  <Heading
                    heading={interestPaymentHistoryHeading}
                    updateHeading={(updatedValue: string) => {
                      setInterestPaymentHistoryHeading(updatedValue);
                    }}
                  />
                  <GenericSlider
                    defaultValue={interestPaymentHistory}
                    marks={marks}
                    onChange={(selectedValue: number) => {
                      setInterestPaymentHistory(selectedValue.toString());
                    }}
                  />

                  <TextField
                    type="text"
                    name="interestPaymentHistoryText"
                    variant="outlined"
                    fullWidth
                    label="Interest Payment History Text"
                    defaultValue={interestPaymentHistoryText}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      event.persist();
                      setInterestPaymentHistoryText(event.target.value);
                    }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={8}>
                <ManagementComponent
                  managementRows={managementRows}
                  addNewRow={addNewManagementRow}
                  removeFromRows={removeManagementRow}
                  heading={
                    <Heading
                      heading={managementHeading}
                      updateHeading={(updatedValue: string) => {
                        setManagementHeading(updatedValue);
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <AboutTheIssuer
                  aboutTheIssuer={useOfTheIssue}
                  setAboutTheIssuer={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    event.persist();
                    setUseOfTheIssue(event.target.value);
                  }}
                  heading={useOfTheIssueHeading}
                  updateUseOfIssueHeading={(updatedValue: string) => {
                    setUseOfTheIssueHeading(updatedValue);
                  }}
                  class={classes.useOfTheIssue}
                />
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Heading
                    heading={ratingDriversHeading}
                    updateHeading={(updatedValue: string) => {
                      setRatingDriversHeading(updatedValue);
                    }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <ProComponent
                        pros={pros}
                        addNewPro={addNewPro}
                        deletePro={deletePro}
                        class={classes.proTextField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ConComponent
                        cons={cons}
                        addNewCon={addNewCon}
                        deleteCon={deleteCon}
                        class={classes.proTextField}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <QuarterlyRevenueComponent
                  quarterlyRevenues={quarterlyRevenues}
                  removeQuarterlyRevenue={removeQuarterlyRevenue}
                  addQuarterlyRevenue={addQuarterlyRevenue}
                  heading={
                    <Heading
                      heading={quarterlyRevenueHeading}
                      updateHeading={(updatedValue: string) => {
                        setQuarterlyRevenueHeading(updatedValue);
                      }}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Heading
                  heading={categoryWiseSHPHeading}
                  updateHeading={(updatedValue: string) => {
                    setCategoryWiseSHPHeading(updatedValue);
                  }}
                />
                <CategoryWiseSHPTable
                  categoryWiseSHPRecords={categoryWiseSHPRecords}
                  addSHPRecord={addSHPRecord}
                  removeCategorySHPRecord={deleteCategorySHPRecord}
                  updateSHPRecord={updateSHPRecord}
                />
              </Grid>

              <Grid item xs={12}>
                <ShareHoldingPatternComponent
                  shareholdingPatterns={shareholdingPatterns}
                  addNewRow={addNewShareholdingPattern}
                  removeRow={deleteShareholdingPattern}
                  heading={
                    <Heading
                      heading={shareholdingPatternHeading}
                      updateHeading={(updatedValue: string) => {
                        setShareholdingPatternHeading(updatedValue);
                      }}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <RichTextEditorComponent
                  setLookoutOnFinancials={setLookoutOnFinancials}
                  lookoutOnFinancials={lookoutOnFinancials}
                  heading={
                    <Heading
                      heading={outlookonFinancialsHeading}
                      updateHeading={(updatedValue: string) => {
                        setOutlookonFinancialsHeading(updatedValue);
                      }}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Box>
        }
        heading="Analysis Report"
      />
      {showAnalysisReportPreviewDialog ? (
        <FullScreenDialog
          show={showAnalysisReportPreviewDialog}
          handleClose={(event: any) =>
            setShowAnalysisReportPreviewDialog(!showAnalysisReportPreviewDialog)
          }
          showSaveButton={false}
          onSumitSaveButton={(event: any) => {
            setShowAnalysisReportPreviewDialog(
              !showAnalysisReportPreviewDialog
            );
          }}
          inputProps={<AnalysisReportPreview report={props.report} />}
          heading="Analysis Report Preview"
        />
      ) : (
        ""
      )}
    </>
  );
}
