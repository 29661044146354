import React from "react";
import { Grid, Button, TextField } from "@material-ui/core";
import GenericTable from "../GenericTable/GenericTable";
import DeleteIcon from "@material-ui/icons/Delete";
import { ShareholdingPattern } from "./Interfaces";
import GenericDialog from "../Dialog/GenericDialog";

export default function ShareHoldingPatternComponent(
  props: any
): React.ReactElement {
  const [showAddShareHoldingPatternModal, setShowAddShareholdingPattern] =
    React.useState<boolean>(false);
  const shareholdingPatternInitialState = {
    tag: "",
    value: "",
  };
  const [currentShareholdingPattern, setCurrentShareholdingPattern] =
    React.useState<ShareholdingPattern>(shareholdingPatternInitialState);

  function getFormattedRowsWithJSX(
    shareholdingPatterns: ShareholdingPattern[]
  ): any[] {
    let formatted: any[] = [];
    shareholdingPatterns.map(
      (shareholdingPattern: ShareholdingPattern, index: number) => {
        formatted.push({
          tag: shareholdingPattern.tag,
          value: shareholdingPattern.value,
          serial: index + 1,
          xdelete: (
            <DeleteIcon
              onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                event.preventDefault();
                props.removeRow(shareholdingPattern.serial);
              }}
              color="primary"
              style={{
                cursor: "pointer",
              }}
            />
          ),
        });
      }
    );
    return formatted;
  }

  function handleChange(event: any): void {
    setCurrentShareholdingPattern((previousValues: ShareholdingPattern) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  }

  function generateInputProps(): React.ReactElement {
    return (
      <Grid container item xs={12} spacing={1}>
        <React.Fragment>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="tag"
              variant="outlined"
              fullWidth
              label="Tag"
              defaultValue={currentShareholdingPattern.tag}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="value"
              variant="outlined"
              fullWidth
              label="Value"
              defaultValue={currentShareholdingPattern.value}
              onChange={handleChange}
            />
          </Grid>
        </React.Fragment>
      </Grid>
    );
  }

  return (
    <>
      <Grid className="holidayMastersDataGrid">
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              {props.heading}
            </Grid>
            <Grid item xs="auto">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  event.preventDefault();
                  setShowAddShareholdingPattern(
                    !showAddShareHoldingPatternModal
                  );
                }}
              >
                Add Shareholding Pattern
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <GenericTable
        columnNames={["Tag", "Value", "XDelete"]} // Make sure to arrange the column names in alphabetical order.
        rows={
          props?.shareholdingPatterns
            ? getFormattedRowsWithJSX(props?.shareholdingPatterns)
            : []
        }
      />
      {showAddShareHoldingPatternModal ? (
        <GenericDialog
          show={showAddShareHoldingPatternModal}
          heading="Add Shareholding Pattern"
          inputProps={generateInputProps()}
          onClose={(event: any) => {
            setShowAddShareholdingPattern(!showAddShareHoldingPatternModal);
          }}
          handleSubmit={() => {
            props.addNewRow(currentShareholdingPattern);
            setCurrentShareholdingPattern(shareholdingPatternInitialState);
            setShowAddShareholdingPattern(!showAddShareHoldingPatternModal);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
