import React from "react";
import {
  makeGETRequest,
  makeGETRequestOnUserService,
  makeSuperuserAdminNamesRequest,
} from "../../Api/api";
import { LoginUser, NameAndUUID, SuperuserAndAdminUser } from "./Interfaces";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { withRouter } from "react-router-dom";

function AdminPanelUserDropdownComponent(props: any) {
  const toastr = require("toastr");
  const initialValues: NameAndUUID = {
    name: "",
    uuid: "",
  };
  const [users, setUsers] = React.useState<any>({
    adminUsers: [initialValues],
    superusers: [initialValues],
  });

  React.useEffect(() => {
    let loginUser: LoginUser = JSON.parse(
      localStorage.getItem("loginUser") || ""
    );
    let accessToken: string | null = localStorage.getItem("accessToken");

    makeGETRequest("/admin/superuser/admin/names/list/")
      .then((response: any) => {
        if (response && response.status === 200) {
          setUsers(response.data);
        }
      })
      .catch((error: any) => toastr.error(error));
  }, []);

  function handleOnSelection(event: any): void {
    props.history.push(`whatsapp_campaign/categories/${event.target.value}`);
  }

  if (Object.keys(users).length === 0) {
    return (
      <Box
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        sx={{ display: "flex" }}
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <div>
        <FormControl sx={{ m: 1, minWidth: 420 }}>
          <InputLabel htmlFor="grouped-native-select">Names</InputLabel>
          <Select
            native
            onChange={handleOnSelection}
            defaultValue=""
            id="grouped-native-select"
            label="Grouping"
          >
            <option aria-label="None" value="" />
            <optgroup label="Admin Users">
              {users.adminUsers.map((adminUser: NameAndUUID) => (
                <option value={adminUser.uuid}>{adminUser.name}</option>
              ))}
            </optgroup>
            <optgroup label="Superusers">
              {users.superusers.map((superuser: NameAndUUID) => (
                <option value={superuser.uuid}>{superuser.name}</option>
              ))}
            </optgroup>
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default withRouter(AdminPanelUserDropdownComponent);
