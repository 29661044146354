import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 100,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarComponent = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Snackbar
        open={true}
        autoHideDuration={10000}
        onClose={props.handleClose}
      >
        <Alert onClose={props.handleClose} severity="error">
          {props.errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackbarComponent;
