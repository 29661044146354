import React, { useContext } from "react";
import toastr from "toastr";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import { makePOSTRequest } from "../../Api/api";
import OtpInput from "react-otp-input";
import InputLabel from "@material-ui/core/InputLabel";
import { appContext } from "../../App";
import MuiLink from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Link = withStyles({
  root: {
    "&[disabled]": {
      color: "grey",
      cursor: "default",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
})(MuiLink);

function MultiFactorAuth(props) {
  const classes = useStyles();
  const { authUser, setAuthUser } = useContext(appContext);
  const [showOTPInput, setShowOTPInput] = React.useState(true);
  const [otp, setOtp] = React.useState("");
  const [enableResendOTPTimer, setEnableResendOTPTimer] = React.useState(false);
  const [seconds, setSeconds] = React.useState(30);

  React.useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        setEnableResendOTPTimer(true);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  function validateOTP(event) {
    event.preventDefault();
    let session = localStorage.getItem("session");
    if (!session) {
      toastr.error("Something went wrong. Please try again later.");
    }
    makePOSTRequest("/admin/superuser/respond_to_auth/", {
      session: session,
      mobile_number: props.superuserDetails.mobileNumber,
      otp: otp,
      user_type: props?.userType || "superuser",
    })
      .then((resp) => {
        if (resp.status === 200) {
          localStorage.setItem("AccessToken", resp.data.access_token);
          localStorage.setItem("RefreshToken", resp.data.refresh_token);
          localStorage.setItem("IdToken", resp.data.id_token);
          localStorage.setItem("showHeader", true);
          localStorage.setItem(
            "permissions",
            JSON.stringify(resp.data.permissions)
          );
          localStorage.setItem(
            "loginUser",
            JSON.stringify(resp.data.superuser_details)
          );
          setAuthUser(resp.data.superuser_details);
          props.history.push("/dashboard");
        }
      })
      .catch((err) => {
        console.error(err);
        toastr.error("Invalid Credentials");
      });
  }

  const renderOTPInput = () => {
    return (
      <Grid item xs={12}>
        <InputLabel id="demo-simple-select-outlined-label">OTP</InputLabel>
        <OtpInput
          value={otp}
          isInputNum={true}
          onChange={(enteredValue) => setOtp(enteredValue)}
          inputStyle={{ padding: "20px", width: "100%" }}
          numInputs={6}
          separator={<span>-</span>}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={validateOTP}
        >
          Validate OTP
        </Button>
        {/* <Link
          component="button"
          disabled={!enableResendOTPTimer}
          onClick={resendOTP}
        >
          {!enableResendOTPTimer
            ? `Resend OTP in ${seconds} seconds`
            : "Resend OTP"}
        </Link> */}
      </Grid>
    );
  };

  return (
    <Container
      style={{ backgroundColor: "#FFFFF" }}
      className="mainLogin"
      maxWidth="xs"
    >
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Validate OTP
        </Typography>
        <form className={classes.form} noValidate>
          {showOTPInput ? renderOTPInput() : ""}
          <FormControl
            className="full-width border-radius"
            style={{ width: "100%" }}
          ></FormControl>
          <Grid container>
            <Grid item xs></Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

export default withRouter(MultiFactorAuth);
