import React, { useEffect } from "react";
import {
  makeDELETERequestOnUserServiceWithQueryParameter,
  makeGETRequestOnUserService,
  makePOSTRequestOnUserService,
  makePUTRequestOnUserService,
} from "../../Api/api";
import toastr from "toastr";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddPortfolioHoldingModal from "./AddPortfolioHoldingModal";
import Grid from "@material-ui/core/Grid";
import {
  descendingComparator,
  getComparator,
  stableSort,
  headCells,
  EnhancedTableHead,
} from "./TableUtils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteModal from "../DeleteModal";
import EditPortfolio from "./EditPortfolio";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "96%",
    margin: "35px auto",
    height: " 0px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function removeDeletedPortfolioHolding(portfolioHoldings, uuid) {
  return portfolioHoldings.filter(
    (portfolioHolding) => portfolioHolding.uuid != uuid
  );
}

const PortfolioHolding = (props) => {
  const { currentUser } = props;
  const classes = useStyles();
  const [portfolioHoldings, setPortfolioHoldings] = React.useState([]);
  const [showAddPortfolioModal, setShowAddPortfolioModal] =
    React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [toBeEdited, setToBeEdited] = React.useState(null);
  const [toBeDeleted, setToBeDeleted] = React.useState(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showEditModal, setShowEditModal] = React.useState(false);

  useEffect(() => {
    let requestURL = `/portfolio/list/${currentUser.uuid}/`;
    let response = makeGETRequestOnUserService(requestURL)
      .then((response) => {
        if (response && response.status === 200) {
          setPortfolioHoldings(response.data);
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  const submitHandler = async (event, details) => {
    event.preventDefault();
    try {
      details.userId = currentUser.uuid;
      let requestURL = "/portfolio/create/";
      let response = await makePOSTRequestOnUserService(requestURL, details);
      if (response && response.status === 201) {
        toastr.success("Created Portfolio Holding successfully !");
        portfolioHoldings.push(response.data);
        setPortfolioHoldings(portfolioHoldings);
        setShowAddPortfolioModal(false);
      }
    } catch (error) {
      let errorResponse = error.response;
      toastr.error(errorResponse.data[0]);
    }
  };

  const handleAddIconOnClick = (event) => {
    event.preventDefault();
    setShowAddPortfolioModal(true);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setToBeEdited(null);
    setShowAddPortfolioModal(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleEditIconOnClick = (event, row) => {
    event.preventDefault();
    setToBeEdited(row);
    setShowEditModal(true);
  };

  const renderEditIcon = (row, labelId) => {
    return (
      <EditIcon
        onClick={(event) => handleEditIconOnClick(event, row)}
        color="primary"
        fontSize="medium"
        style={{
          cursor: "pointer",
        }}
      />
    );
  };

  const handleDeleteIconOnClick = (event, row) => {
    event.preventDefault();
    setToBeDeleted(row);
    setShowDeleteModal(true);
  };

  const renderDeleteIcon = (row, labelId) => {
    return (
      <DeleteIcon
        onClick={(event) => handleDeleteIconOnClick(event, row)}
        color="primary"
        style={{
          cursor: "pointer",
        }}
      />
    );
  };

  const updateCurrentValue = (event, updated) => {
    event.preventDefault();
    let requestURL = "/portfolio/current_value/update/";
    let requestBody = {
      portfolioUUID: "orderID" in toBeEdited ? "" : toBeEdited.uuid,
      orderID: "orderID" in toBeEdited ? toBeEdited.orderID : "",
      currentValue: updated,
    };
    makePUTRequestOnUserService(requestURL, requestBody)
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Updated Current Value successfully !");
          setShowEditModal(false);
          setToBeEdited(null);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const closeEditModal = (event) => {
    event.preventDefault();
    setShowEditModal(false);
    setToBeEdited(null);
  };

  const closeDeleteModal = (event) => {
    event.preventDefault();
    setShowDeleteModal(false);
  };

  const deletePortfolioHolding = async (event) => {
    event.preventDefault();
    try {
      let requestURL = `/portfolio/delete/${toBeDeleted.uuid}/`;
      let response = await makeDELETERequestOnUserServiceWithQueryParameter(
        requestURL
      );
      if (response && response.status === 200) {
        toastr.success("Deleted Portfolio Holding successfully !");
        setPortfolioHoldings(
          removeDeletedPortfolioHolding(portfolioHoldings, toBeDeleted.uuid)
        );
        setShowDeleteModal(false);
        setToBeDeleted(null);
      }
    } catch (error) {
      toastr.error(error);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div style={{ fontSize: "40px", float: "left" }}>
          Portfolio Holdings
        </div>
        <AddCircleIcon
          color="primary"
          fontSize="large"
          onClick={handleAddIconOnClick}
          style={{
            float: "right",
            "margin-top": "29px",
            "margin-right": "30px",
            cursor: "pointer",
          }}
        />
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={portfolioHoldings.length}
            />
            <TableBody>
              {stableSort(portfolioHoldings, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell align="left">{index + 1}</TableCell>

                      <TableCell
                        id={labelId}
                        align="center"
                        scope="row"
                        padding="none"
                      >
                        {row.isin}
                      </TableCell>

                      <TableCell id={labelId} scope="row" padding="none">
                        {row.gain}
                      </TableCell>

                      <TableCell
                        id={labelId}
                        align="center"
                        scope="row"
                        padding="none"
                      >
                        {row.currentValue}
                      </TableCell>

                      <TableCell
                        id={labelId}
                        align="center"
                        scope="row"
                        padding="none"
                      >
                        {row.dateOfPurchase || row.paymentDate}
                      </TableCell>

                      <TableCell
                        id={labelId}
                        align="center"
                        scope="row"
                        padding="none"
                      >
                        {row.purchasePrice}
                      </TableCell>

                      <TableCell
                        id={labelId}
                        align="center"
                        scope="row"
                        padding="none"
                      >
                        {row.quantity || row.noOfUnits}
                      </TableCell>

                      <TableCell id={labelId} scope="row" padding="none">
                        {renderEditIcon(row, labelId)}
                      </TableCell>

                      <TableCell id={labelId} scope="row" padding="none">
                        {renderDeleteIcon(row, labelId)}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={portfolioHoldings.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <AddPortfolioHoldingModal
        closeModal={closeModal}
        show={showAddPortfolioModal}
        submitHandler={submitHandler}
        currentHolding={toBeEdited}
      />

      <DeleteModal
        primaryText="Delete Portfolio holding"
        secondaryText="Are you sure you want to delete this Portfolio holding ?"
        isOpen={showDeleteModal}
        handleClose={closeDeleteModal}
        confirmDelete={deletePortfolioHolding}
      />

      {showEditModal ? (
        <EditPortfolio
          openModal={showEditModal}
          onCloseModal={closeEditModal}
          currentValue={toBeEdited.currentValue || "0"}
          updateCurrentValue={updateCurrentValue}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default PortfolioHolding;
