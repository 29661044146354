import React from "react";
import {
  Grid,
  Tooltip,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { withStyles } from "@material-ui/core/styles";
import WhatsappIcon from "../../images/whatsappIcon.png";
import BoxTableHeader from "./BoxTableHeader";
import List from "@mui/material/List";
import UserCard from "./UserCard";
import {
  CampaignCategory,
  CheckBoxSelectionCategory,
  LoginUser,
} from "./Interfaces";
import GenericDialog from "../../components/Dialog/GenericDialog";
import { makePUTRequestOnUserService } from "../../Api/api";

const DateTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginLeft: 5,
  },
}))(Tooltip);

export default function GenericCategoryBox(props: any) {
  const toastr = require("toastr");
  const { categories, categoryName, areAllChecked } = props;
  const [showCategoryNameDialog, setShowCategoryNameDialog] =
    React.useState(false);
  const [updatedCategoryName, setUpdatedCategoryName] =
    React.useState(categoryName);

  function handleCheckBoxSelection(
    event: any,
    row: CheckBoxSelectionCategory
  ): void {
    props.handleCheckBoxSelection(event, row, categoryName);
  }

  function onSelectAllWithCurrentCategory(event: any): void {
    props.onSelectAllWithCurrentCategory(event, categoryName);
  }

  function selectIndividualUser(
    event: any,
    row: CheckBoxSelectionCategory
  ): void {
    props.selectIndividualUser(event, row, categoryName);
  }

  function renameCategory(event: any): void {
    setShowCategoryNameDialog(!showCategoryNameDialog);
  }

  function generateInputProps() {
    return (
      <TextField
        style={{ marginTop: "10px" }}
        type="text"
        name="spread"
        variant="outlined"
        fullWidth
        label="Category Name"
        defaultValue={updatedCategoryName}
        onChange={(event) => setUpdatedCategoryName(event.target.value)}
      />
    );
  }

  function updateCategoryName(event: any): void {
    event.preventDefault();
    if (categoryName !== updatedCategoryName) {
      const loginUser: LoginUser = JSON.parse(
        localStorage.getItem("loginUser") || ""
      );
      makePUTRequestOnUserService("/whatsapp_campaign/category_name/update/", {
        oldCategoryName: categoryName,
        newCategoryName: updatedCategoryName,
        userId: loginUser.uuid,
      }).then((response) => {
        if (response && response.status === 200) {
          toastr.success("Updated Category Name successfully");
          let currentCategoryName: string = updatedCategoryName;
          setUpdatedCategoryName("");
          setShowCategoryNameDialog(!showCategoryNameDialog);
          props.updatedCategoryName(event, currentCategoryName, categoryName);
        }
      });
    }
  }

  if (categoryName) {
    return (
      <Grid className="dashCard">
        <DateTip
          placement="right"
          interactive
          title={categoryName}
          enterTouchDelay={10}
        >
          <Grid className="cardValue">
            <Grid container className="">
              <div onClick={renameCategory}>
                <Typography
                  style={{
                    cursor: "pointer",
                  }}
                  variant="h6"
                  noWrap
                >
                  {categoryName}
                </Typography>
              </div>
              <DateTip
                placement="right"
                interactive
                title="Send whatsapp message to selected users."
                enterTouchDelay={10}
              >
                <Button
                  onClick={(event) =>
                    props.sendMessageToSelectedUsers(event, categoryName)
                  }
                >
                  <img src={WhatsappIcon} width="28px" height="28px" />
                </Button>
              </DateTip>
            </Grid>
            <Grid className="recentTransactions">
              <Grid className="gridComTabel tableHade">
                <BoxTableHeader
                  checked={areAllChecked}
                  onSelectAll={onSelectAllWithCurrentCategory}
                />
                <List
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                    margin: "5px 0",
                    overflow: "auto",
                    maxHeight: 400,
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  <Grid className="corpCompMainContainer">
                    <Grid container style={{ height: "100%" }}>
                      <Grid item xs>
                        <div className="tradeTableContainer">
                          <Grid className="recentTransactions">
                            <Grid className="gridComTabel tableHade"></Grid>
                            <Grid
                              className="recentTransactionsScroll"
                              id="scrollableDiv"
                            >
                              {categories.map(
                                (category: CampaignCategory, index: number) => {
                                  return (
                                    <UserCard
                                      row={{
                                        mobileNumber:
                                          category.whatsappMessage.mobileNumber,
                                        firstName: category.fullName,
                                        lastName: "",
                                        isChecked: category.isChecked,
                                        categoryId: category.uuid,
                                      }}
                                      index={index}
                                      handleCheckBoxSelection={
                                        handleCheckBoxSelection
                                      }
                                      selectIndividualUser={
                                        selectIndividualUser
                                      }
                                    />
                                  );
                                }
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </DateTip>
        {showCategoryNameDialog ? (
          <GenericDialog
            show={showCategoryNameDialog}
            heading="Edit Category Name "
            inputProps={generateInputProps()}
            onClose={(event: any) => {
              event.preventDefault();
              setShowCategoryNameDialog(!showCategoryNameDialog);
            }}
            handleSubmit={updateCategoryName}
          />
        ) : (
          ""
        )}
      </Grid>
    );
  } else {
    return (
      <Box sx={{ width: "560px" }}>
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Box>
    );
  }
}
