import React from "react";
import FullScreenDialog from "../Dialog/FullScreenDialog";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { makeGETRequest } from "../../Api/api";
import { addSerialNumber } from "../../Helpers/SecurityMasterHelpers";

const toastr = require("toastr");

const dataGridCells = [
  { field: "serial", headerName: "Serial", width: 190, editable: false },
  { field: "coupon", headerName: "Coupon", width: 190, editable: false },
  { field: "isin", headerName: "ISIN", width: 190, editable: false },
  {
    field: "securityName",
    headerName: "Security Name",
    width: 190,
    editable: false,
  },
  {
    field: "securedOrUnsecured",
    headerName: "Secured / Unsecured",
    width: 190,
    editable: false,
  },
  { field: "rating", headerName: "Rating", width: 190, editable: false },
  { field: "category", headerName: "Category", width: 190, editable: false },
  {
    field: "typeOfGuarantee",
    headerName: "Type of Guarantee",
    width: 190,
    editable: false,
  },
  {
    field: "nameOfInstrument",
    headerName: "Perp / Non Perp",
    width: 190,
    editable: false,
  },
  {
    field: "issuerType",
    headerName: "Issuer Type",
    width: 190,
    editable: false,
  },
  {
    field: "callDate",
    headerName: "Call Date",
    width: 190,
    editable: false,
  },
  {
    field: "maturityDate",
    headerName: "Maturity Date",
    width: 190,
    editable: false,
  },
  { field: "ipDates", headerName: "IP Dates", width: 190, editable: false },
  {
    field: "sellPrice",
    headerName: "Price Per 100",
    width: 190,
    editable: false,
  },
  { field: "ytc", headerName: "YTC", width: 190, editable: false },
  { field: "ytm", headerName: "YTM", width: 190, editable: false },
  { field: "faceValue", headerName: "Face Value", width: 190, editable: false },
  { field: "quantum", headerName: "Quantum", width: 190, editable: false },
  {
    field: "nriEligibility",
    headerName: "NRI Eligibility",
    width: 190,
    editable: false,
  },
  { field: "listed", headerName: "Listed", width: 190, editable: false },
];

export default function DownloadQuotesModal(props: any): React.ReactElement {
  const [quotesForDownload, setQuotesForDownload] = React.useState([]);

  React.useEffect(() => {
    makeGETRequest("/admin/quotes_for_download/")
      .then((response: any) => {
        setQuotesForDownload(response.data);
      })
      .catch((error: any) => toastr.error(error));
  }, []);

  return (
    <FullScreenDialog
      heading="Download Quotes"
      showSecondarySaveButton={false}
      show={props.open}
      handleClose={props.handleClose}
      showSaveButton={false}
      saveButtonText="Download All"
      inputProps={
        <div
          style={{
            height: "100vh",
            width: "100%",
          }}
        >
          <DataGrid
            pagination
            className="QuotesDataGrid"
            components={{ Toolbar: GridToolbar }}
            rows={addSerialNumber(quotesForDownload)}
            columns={dataGridCells}
            getRowId={(row) => row.serial}
          />
        </div>
      }
    />
  );
}
