import React from "react";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function RadioButtonComponent(props: any): React.ReactElement {
  return (
    <>
      {props.options.map((option: string) => {
        return (
          <FormControlLabel
            value={option}
            label={option}
            control={
              <Radio
                checked={props.params.defaultValue === option}
                onChange={(event: React.ChangeEvent) =>
                  props.handleRadioButtonChange(event, props.params.name)
                }
                value={option}
                color="primary"
                name="radio-button-demo"
              />
            }
          ></FormControlLabel>
        );
      })}
    </>
  );
}
