import React, { useContext } from "react";
import MoneyIcon from "@mui/icons-material/Money";
import CalculateIcon from "@mui/icons-material/Calculate";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { withRouter } from "react-router";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BusinessIcon from "@material-ui/icons/Business";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import RedeemIcon from "@material-ui/icons/Redeem";
import { appContext } from "../App";
import BlockIcon from "@mui/icons-material/Block";
import FaceIcon from "@mui/icons-material/Face";
import GenericDialog from "./Dialog/GenericDialog";
import KYCModule from "./KYCModule/KYCModule";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const SidePanel = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { authUser, setAuthUser } = useContext(appContext);
  const currentPermissions = localStorage.getItem("permissions");

  const handleSideMenuNavigation = (event, navigateTo) => {
    localStorage.removeItem("selectedNotify");
    props.history.push(navigateTo);
    props.onClose(event);
  };

  const renderHolidayMasterOrEmpty = () => {
    if (authUser.isSuperuser || currentPermissions.includes("Holiday Master")) {
      return (
        <ListItem
          button
          onClick={(event) =>
            handleSideMenuNavigation(event, "/holiday_master")
          }
        >
          <ListItemIcon>
            <CalendarTodayIcon />
          </ListItemIcon>
          <ListItemText primary="Holiday Master" />
        </ListItem>
      );
    } else return "";
  };

  const renderIssuerMasterOrEmpty = () => {
    if (authUser.isSuperuser || currentPermissions.includes("Issuer Master")) {
      return (
        <ListItem
          button
          onClick={(e) => handleSideMenuNavigation(e, "/issuer_master")}
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Issuer Master" />
        </ListItem>
      );
    } else return "";
  };

  const renderRatingOrganizationMasterOrEmpty = () => {
    if (
      authUser.isSuperuser ||
      currentPermissions.includes("Rating Organization Master")
    ) {
      return (
        <ListItem
          button
          onClick={(e) =>
            handleSideMenuNavigation(e, "/rating_organization_master")
          }
        >
          <ListItemIcon>
            <SpellcheckIcon />
          </ListItemIcon>
          <ListItemText primary="Rating Organization Master" />
        </ListItem>
      );
    } else return "";
  };

  const renderSecurityTypeOrEmpty = () => {
    if (authUser.isSuperuser || currentPermissions.includes("Security Type")) {
      return (
        <ListItem
          button
          onClick={(e) => handleSideMenuNavigation(e, "/security_type")}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Security Type" />
        </ListItem>
      );
    } else return "";
  };

  const renderSecurityMasterOrEmpty = () => {
    if (
      authUser.isSuperuser ||
      currentPermissions.includes("Security Master")
    ) {
      return (
        <ListItem
          button
          onClick={(e) => handleSideMenuNavigation(e, "/security_master")}
        >
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Security Master" />
        </ListItem>
      );
    } else return "";
  };

  const renderSecurityManagementOrEmpty = () => {
    if (authUser.isSuperuser) {
      return (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Security Management
            </ListSubheader>
          }
          className={classes.root}
        >
          {renderHolidayMasterOrEmpty()}

          {renderIssuerMasterOrEmpty()}

          {renderRatingOrganizationMasterOrEmpty()}

          {renderSecurityTypeOrEmpty()}

          {renderSecurityMasterOrEmpty()}
        </List>
      );
    } else {
      if (currentPermissions?.includes("Security Management")) {
        return (
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Security Management
              </ListSubheader>
            }
            className={classes.root}
          >
            {renderHolidayMasterOrEmpty()}

            {renderIssuerMasterOrEmpty()}

            {renderRatingOrganizationMasterOrEmpty()}

            {renderSecurityTypeOrEmpty()}

            {renderSecurityMasterOrEmpty()}
          </List>
        );
      }
    }
  };

  const renderPriceManagementOrEmpty = () => {
    if (authUser.isSuperuser || currentPermissions.includes("Quotes")) {
      return (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Price Management
            </ListSubheader>
          }
          className={classes.root}
        >
          <ListItem
            button
            onClick={(event) => handleSideMenuNavigation(event, "/quotes")}
          >
            <ListItemIcon>
              <FormatQuoteIcon />
            </ListItemIcon>
            <ListItemText primary="Quotes" />
          </ListItem>
        </List>
      );
    }
  };

  function renderIPOOrEmpty() {
    if (authUser.isSuperuser || currentPermissions.includes("IPO")) {
      return (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              IPO Management
            </ListSubheader>
          }
          className={classes.root}
        >
          <ListItem
            button
            onClick={(event) =>
              handleSideMenuNavigation(event, "/open_debt_issues")
            }
          >
            <ListItemIcon>
              <RedeemIcon />
            </ListItemIcon>
            <ListItemText primary="Open Debt Issues" />
          </ListItem>

          <ListItem
            button
            onClick={(event) =>
              handleSideMenuNavigation(event, "/get_categories_demand")
            }
          >
            <ListItemIcon>
              <RedeemIcon />
            </ListItemIcon>
            <ListItemText primary="Get Categories Demand" />
          </ListItem>

          <ListItem
            button
            onClick={(event) => handleSideMenuNavigation(event, "/ipo_orders")}
          >
            <ListItemIcon>
              <RedeemIcon />
            </ListItemIcon>
            <ListItemText primary="IPO Orders" />
          </ListItem>

          <ListItem
            button
            onClick={(event) => handleSideMenuNavigation(event, "/ipo_bids")}
          >
            <ListItemIcon>
              <RedeemIcon />
            </ListItemIcon>
            <ListItemText primary="IPO Bid Data" />
          </ListItem>

          <ListItem
            button
            onClick={(event) => handleSideMenuNavigation(event, "/issue_time")}
          >
            <ListItemIcon>
              <RedeemIcon />
            </ListItemIcon>
            <ListItemText primary="Issue Time" />
          </ListItem>

          <ListItem
            button
            onClick={(event) => handleSideMenuNavigation(event, "/bse_ipos")}
          >
            <ListItemIcon>
              <RedeemIcon />
            </ListItemIcon>
            <ListItemText primary="BSE IPOs" />
          </ListItem>

          <ListItem
            button
            onClick={(event) =>
              handleSideMenuNavigation(event, "/ipo_security_master")
            }
          >
            <ListItemIcon>
              <RedeemIcon />
            </ListItemIcon>
            <ListItemText primary="IPO Security Master" />
          </ListItem>

          <ListItem
            button
            onClick={(event) => handleSideMenuNavigation(event, "/ipo_quotes")}
          >
            <ListItemIcon>
              <RedeemIcon />
            </ListItemIcon>
            <ListItemText primary="IPO Quote" />
          </ListItem>
        </List>
      );
    }
  }

  function renderWhatsappCampaignOrEmpty() {
    if (
      authUser.isSuperuser ||
      currentPermissions.includes("Whatsapp Campaign")
    ) {
      return (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Whatsapp Campaign
            </ListSubheader>
          }
          className={classes.root}
        >
          <ListItem
            button
            onClick={(event) =>
              handleSideMenuNavigation(event, "/whatsapp_campaign")
            }
          >
            <ListItemIcon>
              <RedeemIcon />
            </ListItemIcon>
            <ListItemText primary="Whatsapp Campaign" />
          </ListItem>

          <ListItem
            button
            onClick={(event) =>
              handleSideMenuNavigation(event, "/whatsapp_templates")
            }
          >
            <ListItemIcon>
              <RedeemIcon />
            </ListItemIcon>
            <ListItemText primary="Whatsapp Templates" />
          </ListItem>
        </List>
      );
    }
  }

  function renderKYCModuleOrEmpty() {
    if (authUser.isSuperuser || currentPermissions.includes("KYC Module")) {
      return (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              KYC Module
            </ListSubheader>
          }
          className={classes.root}
        >
          <ListItem
            button
            onClick={(event) => {
              event.preventDefault();
              setOpen(!open);
            }}
          >
            <ListItemIcon>
              <FaceIcon />
            </ListItemIcon>
            <ListItemText primary="KYC Module" />
          </ListItem>
        </List>
      );
    }
  }

  function renderEmailVerificationOrEmpty() {
    if (authUser.isSuperuser) {
      return (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Metadata
            </ListSubheader>
          }
          className={classes.root}
        >
          <ListItem
            button
            onClick={(event) =>
              handleSideMenuNavigation(event, "/email_verifications")
            }
          >
            <ListItemIcon>
              <RedeemIcon />
            </ListItemIcon>
            <ListItemText primary="Email Verifications" />
          </ListItem>
          <ListItem
            button
            onClick={(event) =>
              handleSideMenuNavigation(event, "/cashflow_records")
            }
          >
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Cash Flow Records" />
          </ListItem>
          <ListItem
            button
            onClick={(event) =>
              handleSideMenuNavigation(event, "/notifications")
            }
          >
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItem>
        </List>
      );
    }
  }

  const renderOrderManagementOrEmpty = () => {
    if (authUser.isSuperuser || currentPermissions.includes("Orders")) {
      return (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Order Management
            </ListSubheader>
          }
          className={classes.root}
        >
          <ListItem
            button
            onClick={(event) => handleSideMenuNavigation(event, "/rfq_orders")}
          >
            <ListItemIcon>
              <RedeemIcon />
            </ListItemIcon>
            <ListItemText primary="Orders V2" />
          </ListItem>
        </List>
      );
    }
  };

  const renderCreateOrder = () => {
    if (authUser.isSuperuser || currentPermissions.includes("Create Orders")) {
      return (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Create Orders
            </ListSubheader>
          }
          className={classes.root}
        >
          <ListItem
            button
            onClick={(event) =>
              handleSideMenuNavigation(event, "/create_rfq_order")
            }
          >
            <ListItemIcon>
              <RedeemIcon />
            </ListItemIcon>
            <ListItemText primary="Create RFQ Order" />
          </ListItem>
        </List>
      );
    }
  };

  const renderSubscriberManagementOrEmpty = () => {
    if (authUser.isSuperuser || currentPermissions.includes("Subscriptions")) {
      return (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Subscription Management
            </ListSubheader>
          }
          className={classes.root}
        >
          <ListItem
            button
            onClick={(event) =>
              handleSideMenuNavigation(event, "/subscriptions")
            }
          >
            <ListItemIcon>
              <SubscriptionsIcon />
            </ListItemIcon>
            <ListItemText primary="Subscriptions" />
          </ListItem>
          <ListItem
            button
            onClick={(event) => handleSideMenuNavigation(event, "/newsletter")}
          >
            <ListItemIcon>
              <SubscriptionsIcon />
            </ListItemIcon>
            <ListItemText primary="Newsletter" />
          </ListItem>
        </List>
      );
    }
  };

  const renderUserManagementOrEmpty = () => {
    if (authUser.isSuperuser || currentPermissions.includes("Users")) {
      return (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              User Management
            </ListSubheader>
          }
          className={classes.root}
        >
          <ListItem
            button
            onClick={(event) => handleSideMenuNavigation(event, "/users")}
          >
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>

          {props.isSuperuser ? (
            <ListItem
              button
              onClick={(event) =>
                handleSideMenuNavigation(event, "/admin_users")
              }
            >
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText primary="Admin users" />
            </ListItem>
          ) : (
            ""
          )}

          {props.isSuperuser ? (
            <ListItem
              button
              onClick={(event) =>
                handleSideMenuNavigation(event, "/blacklisted_users")
              }
            >
              <ListItemIcon>
                <BlockIcon />
              </ListItemIcon>
              <ListItemText primary="Blacklisted Users" />
            </ListItem>
          ) : (
            ""
          )}
        </List>
      );
    }
  };

  const renderYieldCalcManagementOrEmpty = () => {
    if (
      authUser.isSuperuser ||
      currentPermissions.includes("Yield Calculator")
    ) {
      return (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Yield Calculator Management
            </ListSubheader>
          }
          className={classes.root}
        >
          <ListItem
            button
            onClick={(event) =>
              handleSideMenuNavigation(event, "/yield-calculator")
            }
          >
            <ListItemIcon>
              <CalculateIcon />
            </ListItemIcon>
            <ListItemText primary="User Calculations" />
          </ListItem>
        </List>
      );
    }
  };

  return (
    <>
      {renderSecurityManagementOrEmpty()}

      {renderPriceManagementOrEmpty()}

      {renderUserManagementOrEmpty()}

      {renderSubscriberManagementOrEmpty()}

      {renderOrderManagementOrEmpty()}

      {renderCreateOrder()}

      {renderEmailVerificationOrEmpty()}

      {renderKYCModuleOrEmpty()}

      {renderWhatsappCampaignOrEmpty()}

      {renderIPOOrEmpty()}

      {renderYieldCalcManagementOrEmpty()}

      {open ? (
        <GenericDialog
          show={open}
          showDialogAction={false}
          heading="KYC Module"
          inputProps={
            <KYCModule
              handleClose={(event) => {
                event.preventDefault();
                setOpen(false);
              }}
            />
          }
          fullWidth={true}
          maxWidth="sm"
          onClose={(event) => {
            event.preventDefault();
            setOpen(!open);
          }}
          handleSubmit={(event) => {
            event.preventDefault();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default withRouter(SidePanel);
