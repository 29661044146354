import React, { useState, useContext, useEffect } from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "400px",
  },
}));

function getOrderStatusOptions(orderStatus) {
  switch (orderStatus) {
    case "Pending": {
      return ["Confirmed", "On Hold", "Cancelled", "Pending"];
      break;
    }

    case "Confirmed": {
      return ["On Hold", "Settled", "Cancelled", "Confirmed"];
      break;
    }

    case "On Hold": {
      return ["Confirmed", "Cancelled", "On Hold"];
      break;
    }

    case "Settled": {
      return ["Settled"];
      break;
    }

    case "Cancelled": {
      return ["Cancelled"];
      break;
    }
    default:
      return [];
      break;
  }
}

export default function ChangeOrderStatusModal(props) {
  const classes = useStyles();
  const { orderStatus, reason } = props;
  const orderStatusOptions = getOrderStatusOptions(orderStatus);
  const [status, setStatus] = React.useState(orderStatus);
  const [reasonForHold, setReasonForHold] = React.useState(reason);

  const resetStateAndClose = (event) => {
    event.preventDefault();
    setReasonForHold("");
    props.closeModal(event);
  };

  const handleChange = (event) => {
    event.preventDefault();
    setStatus(event.target.value);
  };

  const handleReasonForHoldChange = (event) => {
    event.preventDefault();
    setReasonForHold(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    let state = {
      reasonForHold: reasonForHold,
      status: status,
    };
    setReasonForHold("");
    setStatus(orderStatus);
    props.changeOrderStatus(event, state);
  };

  return (
    <Dialog
      open={props.show}
      onClose={resetStateAndClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Change Order Status</DialogTitle>
      <DialogContent style={{ width: "500px" }}>
        <DialogContentText id="alert-dialog-description">
          <Grid item xs={12} style={{ "margin-top": "10px" }}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "100%" }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="settlementType"
                value={status}
                onChange={handleChange}
                label="T+0/T+1"
              >
                {orderStatusOptions &&
                  orderStatusOptions.length > 0 &&
                  orderStatusOptions.map((orderStatusOption, index) => {
                    return (
                      <MenuItem value={orderStatusOption} key={index}>
                        {orderStatusOption}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>

          {status === "On Hold" ? (
            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <TextField
                type="text"
                name="reasonForhold"
                variant="outlined"
                fullWidth
                label="Reason for hold"
                defaultValue={reasonForHold}
                onChange={handleReasonForHoldChange}
              />
            </Grid>
          ) : (
            ""
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetStateAndClose}>Cancel</Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
