import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Grid } from "@material-ui/core";

const convertToDate = (date: Date) => {
  if (date === null) {
    return "";
  } else {
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = date.getFullYear();
    return dd + "/" + mm + "/" + yyyy;
  }
};

export default function DatePickerComponent(props: any): React.ReactElement {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container>
        <KeyboardDatePicker
          id="date-picker-dialog"
          label=""
          format="dd/MM/yyyy"
          onChange={(date: any) => {
            props.handleChange(convertToDate(date));
          }}
          value={props.params?.defaultValue}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
