import React from "react";
import toastr from "toastr";
import { TextField, Button, Grid, Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import UserHelpers from "./UserHelpers";
import { makeDELETERequestOnUserServiceWithQueryParameter } from "../../Api/api";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  paper: {
    width: "500px",
    margin: "35px auto",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    height: 355,
    maxWidth: 600,
    overflow: "hidden",
    display: "block",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const DematDetailsInformationGrid = (props) => {
  const classes = useStyles();
  const initialState = {
    dpType: UserHelpers.getValueOrEmptyForDemat(props.dematDetails, "dp_type"),
    dpName: UserHelpers.getValueOrEmptyForDemat(props.dematDetails, "dp_name"),
    clientId: UserHelpers.getValueOrEmptyForDemat(
      props.dematDetails,
      "client_id"
    ),
    dpID: UserHelpers.getValueOrEmptyForDemat(props.dematDetails, "dp_id"),
    oldClientId: UserHelpers.getValueOrEmptyForDemat(
      props.dematDetails,
      "client_id"
    ),
  };
  const [currentState, setCurrentState] = React.useState(initialState);
  const dpTypeOptions = ["NSDL", "CDSL"];
  const [oldClientId, setOldClientId] = React.useState(
    UserHelpers.getValueOrEmptyForDemat(props.dematDetails, "client_id")
  );
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [createCond, setCreateCond] = React.useState(
    props.dematDetails?.client_id === "" || props.dematDetails?.clientId === ""
  );

  const cond =
    !selectedFile &&
    props.dematDetails?.image &&
    props.dematDetails?.image !== "";

  function dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  function downloadFile(e, b64) {
    e.preventDefault();
    // data should be your response data in base64 format
    const blob = dataURItoBlob(b64);
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  }

  const handleChange = (event) => {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const setFile = (event) => {
    event.preventDefault();
    setSelectedFile(event.target.files[0]);
  };

  const addDematDetails = (event) => {
    event.preventDefault();
    currentState.file = selectedFile;
    props.addDematDetails(event, currentState);
  };

  const saveDematDetails = (event) => {
    event.preventDefault();
    let state = currentState;
    state.oldClientId = oldClientId;
    setOldClientId(currentState.clientId);
    props.saveDematDetails(event, state);
  };

  const renderInformationGridContent = () => {
    if (currentState.dpType === "NSDL") {
      return (
        <Grid item xs={10}>
          <TextField
            type="text"
            name="dpID"
            variant="outlined"
            fullWidth
            helperText="DP ID"
            onChange={handleChange}
            value={currentState.dpID}
          />
        </Grid>
      );
    } else return "";
  };

  function deleteDematDetails(event) {
    event.preventDefault();
    makeDELETERequestOnUserServiceWithQueryParameter(
      `kyc/demat_detail/delete/${props.currentUser.uuid}/`
    )
      .then((response) => {
        if (response?.status === 204) {
          toastr.success("Deleted Demat Details successfully !");
          setCurrentState({
            dpType: "",
            dpName: "",
            clientId: "",
            dpID: "",
            oldClientId: "",
          });
          setShowDeleteModal(!showDeleteModal);
          setSelectedFile(null);
          setCreateCond(true);
          props.nullifyDematDetails(event);
        }
      })
      .catch((error) => toastr.error(error));
  }

  const handleFileDownload = () => {
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      const a = document.createElement("a");
      a.href = url;
      a.download = selectedFile.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleBase64Download = () => {
    if (props.dematDetails?.image) {
      const a = document.createElement("a");
      a.href = `data:image/png;base64,${props.dematDetails.image}`;
      a.download = "base64_image.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        style={{ width: "100%" }}
      >
        <InputLabel id="demo-simple-select-outlined-label">DP Type</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          name="dpType"
          value={currentState.dpType}
          onChange={(event) => handleChange(event)}
          label="DP Type"
        >
          {dpTypeOptions &&
            dpTypeOptions.length > 0 &&
            dpTypeOptions.map((dpTypeOption, index) => {
              return (
                <MenuItem value={dpTypeOption} key={index}>
                  {dpTypeOption}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>

      <TextField
        type="text"
        name="dpName"
        variant="outlined"
        fullWidth
        style={{ marginTop: "20px" }}
        helperText="DP Name"
        onChange={handleChange}
        value={currentState.dpName}
      />

      <TextField
        type="text"
        name="clientId"
        variant="outlined"
        fullWidth
        helperText="Client ID"
        onChange={handleChange}
        value={currentState.clientId}
      />

      {currentState.dpType === "NSDL" && (
        <TextField
          type="text"
          name="dpID"
          variant="outlined"
          fullWidth
          helperText="DP ID"
          onChange={handleChange}
          value={currentState.dpID}
        />
      )}

      <FormControl fullWidth style={{ marginTop: "20px" }}>
        <InputLabel>Upload File</InputLabel>
        <Input
          type="file"
          name="file"
          onChange={(e) => {
            setSelectedFile(e.target.files[0]);
          }}
          inputProps={{ accept: "image/*,.pdf" }}
        />
      </FormControl>

      {selectedFile && (
        <Button variant="outlined" onClick={handleFileDownload}>
          Download File
        </Button>
      )}

      {cond && (
        <Button variant="outlined" onClick={handleBase64Download}>
          Download Base64 Image
        </Button>
      )}

      {selectedFile && selectedFile.type.startsWith("image/") && (
        <img
          src={URL.createObjectURL(selectedFile)}
          alt="File Preview"
          style={{ maxWidth: "100%", maxHeight: "200px" }}
        />
      )}

      <Button
        variant="contained"
        color="primary"
        style={{ width: 300 }}
        onClick={createCond ? addDematDetails : saveDematDetails}
      >
        {createCond ? "Create" : "Update"}
      </Button>
    </>
  );
};

export default DematDetailsInformationGrid;
