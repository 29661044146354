import * as React from "react";
import PropTypes from "prop-types";
import toastr from "toastr";
import {
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbar,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/styles";
import { dataGridCells } from "./TableUtils";
import { appContext } from "../../App";
import {
  makeGETRequestOnUserService,
  makeGETRequest,
  makeGETRequestOnPaymentService,
  makePOSTRequest,
  makeDELETERequestOnUserServiceWithQueryParameter,
} from "../../Api/api";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CreateOrderModal from "./CreateOrderModal";
import { Grid } from "@material-ui/core";
import GenericDialog from "../Dialog/GenericDialog";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: "space-between",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    textField: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      margin: theme.spacing(1, 0.5, 1.5),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(1.5),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  {}
);

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const OrderDataGrid = (props) => {
  const classes = useStyles();

  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const { authUser, setAuthUser } = React.useContext(appContext);
  const [showAddOrderModal, setShowAddOrderModal] = React.useState(false);
  const [quoteISINMappings, setQuoteISINMappings] = React.useState([]);
  const [userNameAndUUIDMappings, setUserNameAndUUIDMappings] = React.useState(
    []
  );
  const [
    showDeleteOrderConfirmationDialog,
    setShowDeleteOrderConfirmationDialog,
  ] = React.useState(false);
  const [currentlySelectedForDelete, setCurrentlySelectedForDelete] =
    React.useState(null);

  const onSave = (event, details) => {
    let requestURL = "";
    if (details.orderType === "Sell") {
      requestURL = "/users/sell/order/create/";
    } else {
      requestURL = "/users/order/create/";
    }

    makePOSTRequest(requestURL, details)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          setRows([response.data, ...rows]);
          toastr.success("Created Order Successfully !");
          props.history.push("/orders");
          setShowAddOrderModal(false);
        }
      })
      .catch((error) => toastr.error(error));
  };

  React.useEffect(() => {
    if (authUser && authUser.uuid) {
      let requestURL = "/user/name/uuid/mapping/";
      makeGETRequestOnUserService(requestURL)
        .then((response) => {
          if (response && response.status === 200) {
            setUserNameAndUUIDMappings(response.data);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  React.useEffect(() => {
    if (authUser && authUser.uuid) {
      let requestURL = "/admin/quote/uuid/isin/mapping/";
      makeGETRequest(requestURL)
        .then((response) => {
          if (response && response.status === 200) {
            setQuoteISINMappings(response.data);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  React.useEffect(() => {
    if (authUser && authUser.uuid) {
      let requestURL = "/order_management/get_all/";
      makeGETRequestOnUserService(requestURL)
        .then((response) => {
          if (response && response.status === 200) {
            setRows(response.data);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  function deleteOrder() {
    makeDELETERequestOnUserServiceWithQueryParameter(
      `/order_management/purge/${currentlySelectedForDelete}`
    )
      .then((response) => {
        setRows(
          rows.filter((order) => order.orderID !== currentlySelectedForDelete)
        );
        setCurrentlySelectedForDelete(null);
        setShowDeleteOrderConfirmationDialog(
          !showDeleteOrderConfirmationDialog
        );

        toastr.success("Deleted Order Successfully!");
      })
      .catch((error) => toastr.error(error));
  }

  function handleDeleteOrderOnClick(orderId) {
    setShowDeleteOrderConfirmationDialog(!showDeleteOrderConfirmationDialog);
    setCurrentlySelectedForDelete(orderId);
  }

  const currentlySelected = (params) => {
    params.field === "edit" && props.history.push(`/orders/${params.row.uuid}`);
    params.field === "delete" && handleDeleteOrderOnClick(params.row.orderID);
  };

  const closeCreateOrderModal = (event) => {
    event.preventDefault();
    setShowAddOrderModal(false);
  };

  const handleAddOrderOnClick = (event) => {
    event.preventDefault();
    setShowAddOrderModal(true);
  };

  return (
    <Grid className="ordersDataGird">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px" }}>Orders</div>
            </Grid>
            <Grid item xs="auto">
              <AddCircleIcon
                color="primary"
                fontSize="large"
                onClick={handleAddOrderOnClick}
              />
            </Grid>
          </Grid>
        </Grid>

        <div
          style={{
            height: "100vh",
            width: "100%",
          }}
        >
          <DataGrid
            className="ordersDataGird Rehan"
            getRowId={(row) => row.uuid}
            components={{ Toolbar: GridToolbar }}
            rows={rows}
            columns={dataGridCells}
            onCellClick={currentlySelected}
            pagination
          />
        </div>
        {showAddOrderModal ? (
          <CreateOrderModal
            show={showAddOrderModal}
            closeCreateOrderModal={closeCreateOrderModal}
            quoteISINMappings={quoteISINMappings}
            userNameAndUUIDMappings={userNameAndUUIDMappings}
            onSave={onSave}
          />
        ) : (
          ""
        )}
        {showDeleteOrderConfirmationDialog ? (
          <GenericDialog
            show={showDeleteOrderConfirmationDialog}
            heading="Delete Order"
            inputProps="Are you sure you want to delete this order?"
            onClose={(event) => {
              event.preventDefault();
              setCurrentlySelectedForDelete(null);
              setShowDeleteOrderConfirmationDialog(
                !showDeleteOrderConfirmationDialog
              );
            }}
            handleSubmit={deleteOrder}
          />
        ) : (
          ""
        )}
      </Paper>
    </Grid>
  );
};

export default withRouter(OrderDataGrid);
