import React from "react";
import { Grid } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@material-ui/core/Typography";
import SendIcon from "@mui/icons-material/Send";
import { withStyles } from "@material-ui/core/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function UserCard(props) {
  const { row, index } = props;

  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <em>
            <b>Mobile Number </b> : {row.mobileNumber}
          </em>{" "}
          <br />
          <em>
            {" "}
            <b> Name </b> : {`${row.firstName} ${row.lastName}`}
          </em>
        </React.Fragment>
      }
    >
      <Grid tabIndex={-1} key={index} className="gridComTabel tableData">
        <Grid container spacing={2} alignItems="center">
          <Grid
            item
            xs={2}
            id={index}
            padding="none"
            className="tableDataImgMain"
          >
            <span>
              <Checkbox
                checked={row.isChecked}
                onChange={(event) => props.handleCheckBoxSelection(event, row)}
                inputProps={{
                  "aria-label": "controlled",
                }}
              />
            </span>
          </Grid>
          <Grid item xs={4}>
            <Typography className="issuerName" noWrap>
              {row.mobileNumber}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography noWrap>{`${row.firstName} ${row.lastName}`}</Typography>
          </Grid>

          <Grid item xs={2}>
            <SendIcon
              style={{
                cursor: "pointer",
              }}
              onClick={(event) => props.selectIndividualUser(event, row)}
              color="primary"
            />
          </Grid>
        </Grid>
      </Grid>
    </HtmlTooltip>
  );
}
