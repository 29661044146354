import React from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { Select } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

const BlueCheckbox = withStyles({
  root: {
    color: blue[800],
    "&$checked": {
      color: blue[800],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const AddUserDialog = (props) => {
  const classes = useStyles();
  const { userDetails } = props;
  const initialState = {
    maidenName: !userDetails ? "" : userDetails.maidenName,
    firstName: userDetails === null ? "" : userDetails.firstName,
    lastName: userDetails === null ? "" : userDetails.lastName,
    mobileNumber: userDetails === null ? "" : userDetails.mobileNumber,
    emailId: userDetails === null ? "" : userDetails.emailId,
    fathersName: userDetails === null ? "" : userDetails.fathersName,
    mothersName: userDetails === null ? "" : userDetails.mothersName,
    gender: userDetails === null ? "" : userDetails.gender,
    investedBefore: userDetails === null ? "" : userDetails.investedBefore,
    maritalStatus: userDetails === null ? "" : userDetails.maritalStatus,
    occupation: userDetails === null ? "" : userDetails.occupation,
    annualIncome: userDetails === null ? "" : userDetails.annualIncome,
    accountType: userDetails === null ? "" : userDetails.accountType,
    address: userDetails === null ? "" : userDetails.address,
    city: userDetails === null ? "" : userDetails.city,
    pincode: userDetails === null ? "" : userDetails.pincode,
    state: userDetails === null ? "" : userDetails.state,
    dateOfBirth: userDetails === null ? "" : userDetails.dateOfBirth,
    gstin: userDetails === null ? "" : userDetails.gstin,
    password: "",
    birthCountry: userDetails === null ? "" : userDetails.birthCountry,
    nationality: userDetails === null ? "" : userDetails.nationality,
    hasPoliticalExposure:
      userDetails === null ? "" : userDetails.hasPoliticalExposure,
    canSendWhatsappUpdates: userDetails?.canSendWhatsappUpdates || false,
  };
  const [currentState, setCurrentState] = React.useState(initialState);
  let accountTypeOptions = [
    "Individual Resident",
    "Individual Non-Resident",
    "Hindu Undivided Family",
    "Sole Proprietor",
    "Limited Liability Partnership",
    "Partnership",
    "Private Limited",
    "Trust",
    "Public Limited",
  ];

  const updateUserDetails = (event) => {
    event.preventDefault();
    let state = currentState;
    setCurrentState(initialState);
    props.editUserDetails(event, state);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    let state = currentState;
    if (state.hasPoliticalExposure === "") {
      state.hasPoliticalExposure = false;
    }
    if (state.investedBefore === "") {
      state.investedBefore = false;
    }
    if (state.canSendWhatsappUpdates === "") {
      state.canSendWhatsappUpdates = false;
    }
    setCurrentState(initialState);
    props.addNewUser(event, state);
  };

  const resetStateAndClose = (event) => {
    event.preventDefault();
    setCurrentState(initialState);
    props.closeAddUserModal(event);
  };

  const handleChange = (event) => {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const renderAccountTypeDropdown = () => {
    return (
      <Grid item xs={4} style={{ "margin-top": "10px" }}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Account Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="accountType"
            value={currentState.accountType}
            onChange={handleChange}
            label="Account Type"
          >
            {accountTypeOptions &&
              accountTypeOptions.length > 0 &&
              accountTypeOptions.map((accountTypeOption, index) => {
                return (
                  <MenuItem value={accountTypeOption} key={index}>
                    {accountTypeOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const handleCheckBox = (event) => {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={props.show}
        onClose={resetStateAndClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create User</DialogTitle>
        <DialogContent style={{ width: "900px" }}>
          <DialogContentText id="alert-dialog-description">
            <Grid container item xs={12} spacing={1}>
              <React.Fragment>
                <Grid item xs={4} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    label="First Name"
                    defaultValue={currentState.firstName}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={4} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="maidenName"
                    variant="outlined"
                    fullWidth
                    label="Maiden Name"
                    defaultValue={currentState.maidenName}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={4} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="lastName"
                    variant="outlined"
                    fullWidth
                    label="Last Name"
                    defaultValue={currentState.lastName}
                    onChange={handleChange}
                  />
                </Grid>
              </React.Fragment>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <React.Fragment>
                <Grid item xs={6} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="mobileNumber"
                    variant="outlined"
                    fullWidth
                    label="Mobile Number"
                    defaultValue={currentState.mobileNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="emailId"
                    variant="outlined"
                    fullWidth
                    label="Email ID"
                    defaultValue={currentState.emailId}
                    onChange={handleChange}
                  />
                </Grid>
              </React.Fragment>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <React.Fragment>
                <Grid item xs={6} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="fathersName"
                    variant="outlined"
                    fullWidth
                    label="Fathers Name"
                    defaultValue={currentState.fathersName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="mothersName"
                    variant="outlined"
                    fullWidth
                    label="Mothers Name"
                    defaultValue={currentState.mothersName}
                    onChange={handleChange}
                  />
                </Grid>
              </React.Fragment>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <React.Fragment>
                <Grid item xs={4} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="gender"
                    variant="outlined"
                    fullWidth
                    label="Gender"
                    defaultValue={currentState.gender}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={4} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="maritalStatus"
                    variant="outlined"
                    fullWidth
                    label="Marital Status"
                    defaultValue={currentState.maritalStatus}
                    onChange={handleChange}
                  />
                </Grid>
              </React.Fragment>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <React.Fragment>
                {renderAccountTypeDropdown()}
                <Grid item xs={4} style={{ "margin-top": "25px" }}>
                  <TextField
                    type="text"
                    name="address"
                    variant="outlined"
                    fullWidth
                    label="Address"
                    defaultValue={currentState.address}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} style={{ "margin-top": "25px" }}>
                  <TextField
                    type="text"
                    name="city"
                    variant="outlined"
                    fullWidth
                    label="City"
                    defaultValue={currentState.city}
                    onChange={handleChange}
                  />
                </Grid>
              </React.Fragment>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <React.Fragment>
                <Grid item xs={4} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="pincode"
                    variant="outlined"
                    fullWidth
                    label="Pincode"
                    defaultValue={currentState.pincode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="state"
                    variant="outlined"
                    fullWidth
                    label="State"
                    defaultValue={currentState.state}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="dateOfBirth"
                    variant="outlined"
                    fullWidth
                    label="Date Of Birth"
                    defaultValue={currentState.dateOfBirth}
                    onChange={handleChange}
                  />
                </Grid>
              </React.Fragment>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <React.Fragment>
                <Grid item xs={4} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="annualIncome"
                    variant="outlined"
                    fullWidth
                    label="Annual Income"
                    defaultValue={currentState.annualIncome}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="birthCountry"
                    variant="outlined"
                    fullWidth
                    label="Birth Country"
                    defaultValue={currentState.birthCountry}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="occupation"
                    variant="outlined"
                    fullWidth
                    label="Occupation"
                    defaultValue={currentState.occupation}
                    onChange={handleChange}
                  />
                </Grid>
              </React.Fragment>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <React.Fragment>
                <Grid item xs={4} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="gstin"
                    variant="outlined"
                    fullWidth
                    label="GSTIN"
                    defaultValue={currentState.gstin}
                    onChange={handleChange}
                  />
                </Grid>
                {userDetails === null ? (
                  <Grid item xs={4} style={{ "margin-top": "10px" }}>
                    <TextField
                      type="text"
                      name="password"
                      variant="outlined"
                      fullWidth
                      label="Password"
                      defaultValue={currentState.password}
                      onChange={handleChange}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={4} style={{ "margin-top": "10px" }}>
                  <TextField
                    type="text"
                    name="nationality"
                    variant="outlined"
                    fullWidth
                    label="Nationality"
                    defaultValue={currentState.dateOfBirth}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} style={{ "margin-top": "10px" }}>
                  <FormControlLabel
                    control={
                      <BlueCheckbox
                        checked={currentState.hasPoliticalExposure}
                        onChange={handleCheckBox}
                        name="hasPoliticalExposure"
                      />
                    }
                    label="Has Political Exposure ?"
                  />
                </Grid>
                <Grid item xs={4} style={{ "margin-top": "10px" }}>
                  <FormControlLabel
                    control={
                      <BlueCheckbox
                        checked={currentState.investedBefore}
                        onChange={handleCheckBox}
                        name="investedBefore"
                      />
                    }
                    label="Invested Before ?"
                  />
                </Grid>
                <Grid item xs={4} style={{ "margin-top": "10px" }}>
                  <FormControlLabel
                    control={
                      <BlueCheckbox
                        checked={currentState.canSendWhatsappUpdates}
                        onChange={handleCheckBox}
                        name="canSendWhatsappUpdates"
                      />
                    }
                    label="Enable Whatsapp Updates ?"
                  />
                </Grid>
              </React.Fragment>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetStateAndClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={userDetails === null ? submitHandler : updateUserDetails}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddUserDialog;
