import { saveAs } from "file-saver";

export const getAppropriateErrorMessage = (error) => {
  let response = error.response;

  if (response.status === 400) {
    let missingFields = Object.keys(response.data);
    let errorMessage = `We found the following errors :- \n`;
    missingFields.map((missingField) => {
      let missingFieldValue = response.data[missingField][0];
      errorMessage += `${missingField} : ${missingFieldValue} \n`;
    });
    return errorMessage;
  } else if (response.status === 500) {
    return response.data;
  } else if (response.status === 304) {
    return response.data;
  }
};

export const sxText = (theme) => ({
  "& .MuiInputBase-input": {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
    },
  },
  "& .MuiInput-root": {
    fontWeight: 700,
    border: "2px solid #c1c2c4",
    borderRadius: "12px",
    padding: "8px 12px 8px 12px !important",
    marginTop: "20px !important",
    backgroundColor: "#F2F3F4",
    ":focus-within": {
      border: "2px solid #7569ee",
    },
  },
  "& .MuiInput-underline:before, & .MuiInput-underline:after": {
    borderBottom: "none !important",
  },
  "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
    borderBottom: "none !important",
  },
  "& .MuiInputLabel-root": {
    marginLeft: theme.spacing(1),
    fontWeight: 550,
    fontSize: 16,
  },
  "& label.Mui-focused": {
    fontWeight: 800,
  },
  "& .MuiFormHelperText-root": {
    marginLeft: theme.spacing(1),
    fontWeight: 800,
  },
});

export const sxTypo = (selected) => ({
  textAlign: "left",
  fontSize: 14,
  fontWeight: selected ? 600 : 500,
  py: 1,
  px: "12px !important",
  mr: 1,
  borderRadius: "12px !important",
  ":hover": {
    bgcolor: "#F2F3F4",
  },
});

export const sxBox = {
  bgcolor: "#FFFFFF",
  my: 1,
  p: 1.5,
  borderRadius: "12px !important",
};

export const sxRoot = {
  "& .MuiAutocomplete-endAdornment": {
    right: 10,
    top: "50%",
    transform: "translateY(-50%)",
  },
};

export const downloadBase64 = (base64String, name) => {
  let contentType = "application/unknown";
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blobData = new Blob([byteArray], { type: contentType });
  saveAs(blobData, name);
};
