import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import PropTypes from "prop-types";
import toastr from "toastr";
import Table from "@material-ui/core/Table";
import { Grid, TextField } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { appContext } from "../App";
import {
  getAllIssuerNames,
  addIssuerName,
  deleteIssuerName,
  makePUTRequest,
} from "../Api/api";
import DeleteModal from "./DeleteModal";
import AddIssuerNameModal from "./AddIssuerNameModal";
import { getAppropriateErrorMessage } from "../Helpers/helpers";
import EditIcon from "@material-ui/icons/Edit";
import GenericDialog from "./Dialog/GenericDialog";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "id", numeric: true, disablePadding: false, label: "Serial No" },
  {
    id: "issuerName",
    numeric: false,
    disablePadding: true,
    label: "Issuer Name",
  },
  {
    id: "abbreviation",
    numeric: false,
    disablePadding: false,
    label: "Abbreviation",
  },
  {
    id: "isReferenced",
    numeric: true,
    disablePadding: false,
    label: "Is Referenced",
  },
  { id: "edit", numeric: false, disablePadding: true, label: "Edit" },
  { id: "delete", numeric: false, disablePadding: true, label: "Delete" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "96%",
    margin: "35px auto",
    height: " 0px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddIssuerNameDialog = (props) => {
  const classes = useStyles();
  const { show, onCloseModal } = props;
  const [issuerNames, setIssuerNames] = React.useState([]);
  const { authUser, setAuthUser } = useContext(appContext);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [showIssuerNameModal, setShowIssuerNameModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [toBeDeleted, setToBeDeleted] = React.useState(null);
  const [toBeEdited, setToBeEdited] = React.useState(null);
  const [showEditIssuerNameModal, setShowEditIssuerNameModal] =
    React.useState(false);
  const [enteredName, setEnteredName] = React.useState("");
  const [enteredAbbreviation, setEnteredAbbreviation] = React.useState("");

  useEffect(() => {
    if (authUser && authUser.uuid) {
      getAllIssuerNames()
        .then((response) => {
          if (response && response.status === 200) {
            setIssuerNames(response.data);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleAddIssuerNameOnClick = (event) => {
    event.preventDefault();
    setShowIssuerNameModal(true);
  };

  function handleEditIconClick(event, row) {
    event.preventDefault();
    setToBeEdited(row);
    setShowEditIssuerNameModal(true);
    setEnteredName(row.name);
    setEnteredAbbreviation(row.abbreviation);
  }

  const handleDeleteIconOnClick = (event, issuerName) => {
    event.preventDefault();
    setToBeDeleted(issuerName.uuid);
    setShowDeleteModal(true);
  };

  function renderEditIcon(row, labelId) {
    return (
      <TableCell id={labelId} scope="row" padding="none">
        <EditIcon
          onClick={(event) => handleEditIconClick(event, row)}
          color="primary"
          style={{
            cursor: "pointer",
          }}
        />
      </TableCell>
    );
  }

  const renderDeleteIcon = (row, labelId) => {
    return (
      <TableCell id={labelId} scope="row" padding="none">
        <DeleteIcon
          onClick={(event) => handleDeleteIconOnClick(event, row)}
          color="primary"
          style={{
            cursor: "pointer",
          }}
        />
      </TableCell>
    );
  };

  const renderIssuerNameTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={issuerNames.length}
          />
          <TableBody>
            {stableSort(issuerNames, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                  >
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell id={labelId} scope="row" padding="none">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.abbreviation}</TableCell>
                    <TableCell align="left">
                      {row.isReferenced ? "True" : "False"}
                    </TableCell>
                    {renderEditIcon(row, labelId)}
                    {renderDeleteIcon(row, labelId)}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const closeAddIssuerNameModal = (event) => {
    event.preventDefault();
    setShowIssuerNameModal(false);
  };

  const handleOnAddIssuerName = (event, issuerName) => {
    event.preventDefault();
    if (authUser && authUser.uuid) {
      addIssuerName({ name: issuerName })
        .then((response) => {
          if (response && response.status === 201) {
            props.onCloseModal(event);
            toastr.success("Created Issuer Name successfully !");
            setIssuerNames(response.data);
            setShowIssuerNameModal(false);
          }
        })
        .catch((error) => toastr.error(error));
    }
  };

  const renderAddIssuerNameModal = () => {
    return (
      <AddIssuerNameModal
        openModal={showIssuerNameModal}
        onCloseModal={closeAddIssuerNameModal}
        onAddIssuerName={handleOnAddIssuerName}
      />
    );
  };

  const renderFullScreenDialogBody = () => {
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div style={{ fontSize: "40px", float: "left" }}>Issuer Names</div>
          <AddCircleIcon
            onClick={(event) => handleAddIssuerNameOnClick(event)}
            color="primary"
            fontSize="large"
            style={{
              float: "right",
              "margin-top": "29px",
              "margin-right": "30px",
              cursor: "pointer",
            }}
          />
          {renderIssuerNameTable()}
          {renderAddIssuerNameModal()}
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={issuerNames.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  };

  const closeDeleteModal = (event) => {
    event.preventDefault();
    setShowDeleteModal(false);
  };

  const handleOnDeleteIssuerName = (event) => {
    event.preventDefault();
    deleteIssuerName(toBeDeleted)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 204)) {
          toastr.success("Deleted Issuer Name successfully");
          let filtered = issuerNames.filter(
            (issuerName) => issuerName.uuid !== toBeDeleted
          );
          setShowDeleteModal(false);
          setToBeDeleted(null);
          setIssuerNames(filtered);
        }
      })
      .catch((error) => {
        toastr.error(
          "Cant Delete Issuer Name as it is being referenced in an Issuer Master"
        );
      });
  };

  const renderDeleteModal = () => {
    return (
      <DeleteModal
        primaryText="Delete Issuer Name ?"
        secondaryText="Are you sure you want to delete this Issuer Name ?"
        isOpen={showDeleteModal}
        handleClose={closeDeleteModal}
        confirmDelete={handleOnDeleteIssuerName}
      />
    );
  };

  function generateInputProps() {
    return (
      <>
        <Grid item xs={12}>
          <TextField
            id="outlined-read-only-input"
            style={{
              width: "400px",
              margin: "20px",
              justifyContent: "center",
            }}
            type="text"
            label={"Update Issuer Name"}
            value={enteredName}
            onChange={(event) => setEnteredName(event.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-read-only-input"
            style={{
              width: "400px",
              margin: "20px",
              justifyContent: "center",
            }}
            type="text"
            label={"Update Issuer Name"}
            value={enteredAbbreviation}
            onChange={(event) => setEnteredAbbreviation(event.target.value)}
            variant="outlined"
          />
        </Grid>
      </>
    );
  }

  function closeEditModal(event) {
    event.preventDefault();
    setToBeEdited(null);
    setEnteredName("");
    setShowEditIssuerNameModal(false);
  }

  function updateIssuerName(event) {
    event.preventDefault();
    let requestURL = `/admin/issuer_name/update/`;
    makePUTRequest(requestURL, {
      uuid: toBeEdited.uuid,
      name: enteredName,
      abbreviation: enteredAbbreviation,
    })
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Updated Issuer Name successfully !");
          setShowEditIssuerNameModal(false);
          setToBeEdited(null);
          setEnteredName("");
          setIssuerNames(response.data);
          setEnteredAbbreviation("");
        }
      })
      .catch((error) => toastr.error(error));
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={show}
        onClose={onCloseModal}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onCloseModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              className={classes.title}
              onClick={(event) => onCloseModal(event)}
            >
              Close
            </Typography>
          </Toolbar>
        </AppBar>
        {renderFullScreenDialogBody()}
        {renderDeleteModal()}
      </Dialog>
      {showEditIssuerNameModal ? (
        <GenericDialog
          show={showEditIssuerNameModal}
          heading="Update Issuer Name"
          inputProps={generateInputProps()}
          onClose={closeEditModal}
          handleSubmit={updateIssuerName}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default AddIssuerNameDialog;
