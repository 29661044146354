import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";
import Searchbar from "./Searchbar";
import SearchResult from "./SearchResult";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";
import Inputs from "./Inputs";
import Calculations from "./Calculations";
import BasicBondInfo from "./BasicBondInfo";
import Grid from "@mui/material/Grid";
import AdditionalInfo from "./AdditionalInfo";
import { shallow } from "zustand/shallow";
import CalculateIcon from "@mui/icons-material/Calculate";
import useCalc from "../../store/calc";
import styles from "../../Helpers/styles";
import MinimizeIcon from "@mui/icons-material/Minimize";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const Heading = () => (
  <Box sx={styles.flexStartCenter}>
    <CalculateIcon />
    <Typography
      variant="h6"
      sx={{
        fontWeight: "bolder",
        fontSize: 16,
        color: "#2A324C",
        ml: 1,
      }}
    >
      Yield Calculator
    </Typography>
  </Box>
);

const Actions = (props: any) => {
  const [
    zoom,
    getBondInfo,
    getCalculatedValues,
    getBreakup,
    getYield,
    getPrice,
    getDate,
    getUnits,
    getZoom,
  ] = useCalc(
    (state: any) => [
      state.zoom,
      state.getBondInfo,
      state.getCalculatedValues,
      state.getBreakup,
      state.getYield,
      state.getPrice,
      state.getDate,
      state.getUnits,
      state.getZoom,
    ],
    shallow
  );

  return (
    <Box sx={styles.flexEndCenter}>
      <IconButton onClick={() => props.setOpen(false)}>
        <MinimizeIcon />
      </IconButton>
      {!props.viewMatch && (
        <IconButton onClick={() => getZoom(!zoom)}>
          {zoom ? <ZoomInMapIcon /> : <ZoomOutMapIcon />}
        </IconButton>
      )}
      <IconButton
        onClick={() => {
          props.setOpen(false);
          getZoom(false);
          getBondInfo(null);
          getCalculatedValues(null);
          getBreakup(false);
          getYield(null);
          getPrice(null);
          getDate(null);
          getUnits(1);
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

const SearchSection = () => {
  const [
    bondInfo,
    getBondInfo,
    getCalculatedValues,
    getBreakup,
    getYield,
    getPrice,
    getDate,
    getUnits,
    getMMY,
  ] = useCalc(
    (state: any) => [
      state.bondInfo,
      state.getBondInfo,
      state.getCalculatedValues,
      state.getBreakup,
      state.getYield,
      state.getPrice,
      state.getDate,
      state.getUnits,
      state.getMMY,
    ],
    shallow
  );

  return (
    <Box sx={{ mt: 2.5, ...styles.flexBetweenCenter }}>
      {bondInfo && (
        <IconButton
          sx={{
            borderRadius: 50,
            border: `1px solid ${alpha("#2A324C", 0.3)}`,
            mr: 1,
            p: 1.4,
          }}
          onClick={() => {
            getBondInfo(null);
            getBreakup(false);
            getCalculatedValues(null);
            getYield(null);
            getPrice(null);
            getDate(null);
            getUnits(1);
            getMMY(false);
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      <Searchbar />
    </Box>
  );
};

const Calculator = () => {
  const [zoom, bondInfo, loading, calculatedValues] = useCalc(
    (state: any) => [
      state.zoom,
      state.bondInfo,
      state.loading,
      state.calculatedValues,
    ],
    shallow
  );
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);

  return (
    <Box
      sx={{
        zIndex: (theme) => theme.zIndex.appBar + 3,
        ...(open && !smDown && !zoom
          ? {
              position: "fixed",
              bottom: 10,
              right: 10,
            }
          : open && smDown && !zoom
          ? {
              position: "fixed",
              top: 0,
              left: 0,
            }
          : open && zoom && !smDown
          ? {
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }
          : {
              position: "fixed",
              bottom: 85,
              right: [10, 20],
            }),
        ...styles.flexEndEnd,
      }}
    >
      {open ? (
        <>
          {!zoom ? (
            <Box
              sx={{
                ...(smDown
                  ? {
                      width: "100vw",
                      height: "100vh",
                      borderRadius: 0,
                    }
                  : {
                      width: "400px",
                      borderRadius: "32px",
                    }),
                boxShadow: "10px 10px 40px 0px #2A324C29",
                bgcolor: "#FFF",
              }}
            >
              <Box
                sx={{
                  ...(!smDown && {
                    borderTopLeftRadius: "32px",
                    borderTopRightRadius: "32px",
                  }),
                  px: 2.5,
                  py: 2,
                  bgcolor: `${alpha("#7569EE", 0.1)}`,
                }}
              >
                <Box sx={styles.flexBetweenCenter}>
                  <Heading />
                  <Actions open={open} setOpen={setOpen} viewMatch={smDown} />
                </Box>
                <SearchSection />
              </Box>
              <Box
                sx={{
                  px: 2.5,
                  py: 2,
                  overflowY: "scroll",
                  height: "calc(100% - 130px)",
                  "&::-webkit-scrollbar": { display: "none", width: 0 },
                }}
              >
                {loading ? (
                  <Box sx={{ height: "100%", ...styles.flexCenterCenter }}>
                    <CircularProgress color="secondary" />
                  </Box>
                ) : (
                  <>
                    {bondInfo ? (
                      <>
                        <BasicBondInfo />
                        <Inputs />
                        {calculatedValues && <Calculations />}
                      </>
                    ) : (
                      <SearchResult viewMatch={smDown} />
                    )}
                  </>
                )}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                width: "1200px",
                borderRadius: "32px",
                boxShadow: "10px 10px 40px 0px #2A324C29",
                bgcolor: "#FFF",
                p: 2,
              }}
            >
              <Grid container columnSpacing={3}>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      borderRadius: "32px",
                      px: 2.5,
                      py: 2,
                      bgcolor: `${alpha("#7569EE", 0.1)}`,
                    }}
                  >
                    <Heading />
                    <SearchSection />
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <SearchResult viewMatch={smDown} />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  {bondInfo && (
                    <>
                      <BasicBondInfo />
                      <Inputs />
                      {calculatedValues && <Calculations />}
                    </>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Actions open={open} setOpen={setOpen} viewMatch={smDown} />
                  {calculatedValues && <AdditionalInfo />}
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      ) : (
        <Box
          sx={{
            position: "relative",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <IconButton
            sx={{
              bgcolor: "#7569EE1A",
              p: 2,
              backdropFilter: "blur(2.5px)",
              boxShadow: `${alpha("#7469eeff", 0.2)} 0 0 0 0.2rem`,
              ":hover": {
                bgcolor: "#ec4f561a",
                boxShadow: `${alpha("#ec4f56", 0.2)} 0 0 0 0.2rem`,
              },
            }}
          >
            <CalculateIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default Calculator;
