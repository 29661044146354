import React from "react";
import { Grid, Typography } from "@material-ui/core";

function calculateRevenue(userProfileData) {
  return (userProfileData.spread * userProfileData.faceValue) / 100;
}

export default function OrderDetails(props) {
  const { userProfileData } = props;
  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Order Type</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.orderType || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Order ID</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.orderID || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">SMEST ID</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.smestID || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Security Name</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.securityName || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">ISIN</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.isin || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Settlement Type</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.settlementType || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">No. of Units</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.noOfUnits || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Purchase Price</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.purchasePrice || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Spread</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.spread || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Sell Price</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.sellPrice || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={12} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Sell Yield</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.sellYield || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={12} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Settlement Date</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.settlementDate || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Order Status</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.orderStatus || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Principal</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.principal || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Accrued</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.accrued || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Stamp Duty</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.stampDuty || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">TCS</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.tcs || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Transaction Cost</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.transactionCost || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Payment Date</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.paymentDate || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Total Inv Amount</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.totalInvAmount || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Customer Name</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.customerName || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">PAN</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.panNumber || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Client ID</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.clientId || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Dp Name</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.dpName || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Virtual Account Number</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.virtualAccountNumber || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Account Number</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.accountNumber || "--"}
            </Typography>
          </Grid>
        </Grid>

        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">IFSC Code</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.ifscCode || "--"}
            </Typography>
          </Grid>
        </Grid>

        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Beneficiary Name</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.beneficiaryName || "--"}
            </Typography>
          </Grid>
        </Grid>

        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Bank Name</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData.bankName || "--"}
            </Typography>
          </Grid>
        </Grid>

        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Mode of Payment</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData?.transactionDetails?.paymentMode}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Transaction Number</Grid>
            <Typography variant="h6" noWrap>
              {userProfileData?.transactionDetails?.uuid}
            </Typography>
          </Grid>
        </Grid>

        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">Revenue</Grid>
            <Typography variant="h6" noWrap>
              {calculateRevenue(userProfileData)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
