export function changeIdForQuarterlyRevenues(quarterlyRevenues) {
  let formatted = [];
  for (let iterator = 0; iterator < quarterlyRevenues.length; iterator++) {
    formatted.push({
      id: iterator + 1,
      year: quarterlyRevenues[iterator].year,
      revenue: quarterlyRevenues[iterator].revenue,
      profit: quarterlyRevenues[iterator].profit,
    });
  }
  return formatted;
}

export function changeIdForShareholdingPatterns(shareholdingPatterns) {
  let formatted = [];
  for (let iterator = 0; iterator < shareholdingPatterns.length; iterator++) {
    formatted.push({
      id: iterator + 1,
      tag: shareholdingPatterns[iterator].tag,
      value: shareholdingPatterns[iterator].value,
    });
  }
  return formatted;
}

export function getFilteredQuarterlyRevenue(quarterlyRevenues, currentID) {
  return quarterlyRevenues.filter(
    (quarterlyRevenue) => quarterlyRevenue.id !== currentID
  );
}

export function getFilteredShareholdingPatterns(
  shareholdingPatterns,
  currentID
) {
  return shareholdingPatterns.filter((record) => record.id !== currentID);
}

export function removeCategorySHPRecord(categoryWiseSHP, id) {
  return categoryWiseSHP.filter((record) => record.id !== id);
}

export function changeIdForIndividualRecords(updatedRecords) {
  let formatted = [];
  for (let iterator = 0; iterator < updatedRecords.length; iterator++) {
    formatted.push({
      id: iterator + 1,
      categoryName: updatedRecords[iterator].categoryName,
      particular: updatedRecords[iterator].particular,
      shareholding: updatedRecords[iterator].shareholding,
    });
  }
  return formatted;
}

export function getFormattedRatingDrivers(ratingDrivers) {
  let formatted = [];
  ratingDrivers.map((ratingDriver) => {
    if (ratingDriver.text !== "") formatted.push(ratingDriver);
  });
  return formatted;
}

export function getFormattedPros(pros) {
  return pros.filter((pro) => pro.text !== "");
}

export function getFormattedCons(cons) {
  return cons.filter((con) => con.text !== "");
}

export function getFormattedCategoryWiseSHP(records) {
  return records.filter(
    (record) =>
      record.categoryName !== "" &&
      record.particular !== "" &&
      record.shareholding !== ""
  );
}

export function getFormattedShareholdingPatterns(shareholdingPatterns) {
  return shareholdingPatterns.filter(
    (pattern) => pattern.tag !== "" && pattern.value !== ""
  );
}

export function getFormattedQuarterlyRevenues(quartelyRevenues) {
  return quartelyRevenues.filter(
    (quarterlyRevenue) =>
      quarterlyRevenue.year !== "" &&
      quarterlyRevenue.revenue !== "" &&
      quarterlyRevenue.profit !== ""
  );
}
export function extractOnlyRatingDriverText(ratingDrivers) {
  let onlyText = [];
  ratingDrivers.map((ratingDriver) => {
    onlyText.push(ratingDriver.text);
  });
  return onlyText;
}

export function getOnlyPros(pros) {
  return pros.map((pro) => pro.text);
}

export function getOnlyCons(cons) {
  return cons.map((con) => con.text);
}

export function formatShareholdingPatternsAsPerAPI(shareholdingpatterns) {
  let formatted = [];
  shareholdingpatterns.map((shareholdingpattern) => {
    let emptyObject = {};
    emptyObject[shareholdingpattern.tag] = shareholdingpattern.value;
    formatted.push(emptyObject);
  });
  return formatted;
}

export function getProfitAndRevenueByYear(quarterlyRevenues, currentYear) {
  let filtered = [];
  quarterlyRevenues.map((quarterlyRevenue) => {
    if (quarterlyRevenue.year === currentYear) {
      filtered.push({
        profit: quarterlyRevenue.profit,
        revenue: quarterlyRevenue.revenue,
      });
    }
  });
  return filtered;
}

export function formatQuarterlyRevenuesAsPerAPI(quarterlyRevenues) {
  let formatted = [];
  quarterlyRevenues.map((quarterlyRevenue) => {
    if (
      formatted.filter((record) => record.hasOwnProperty(quarterlyRevenue.year))
        .length <= 0
    ) {
      let emptyObject = {};
      emptyObject[quarterlyRevenue.year] = getProfitAndRevenueByYear(
        quarterlyRevenues,
        quarterlyRevenue.year
      );
      formatted.push(emptyObject);
    }
  });
  return formatted;
}

export function formatIDTextObjectsForTable(records) {
  let formatted = [];
  for (let iterator = 0; iterator < records.length; iterator++) {
    formatted.push({
      id: iterator + 1,
      text: records[iterator],
    });
  }
  formatted.push({
    id: formatted.length + 1,
    text: "",
  });
  return formatted;
}

export function formatCategoryWiseSHPForTable(categoryWiseSHP) {
  let formatted = [];
  for (let iterator = 0; iterator < categoryWiseSHP.length; iterator++) {
    formatted.push({
      id: iterator + 1,
      categoryName: categoryWiseSHP[iterator].categoryName,
      particular: categoryWiseSHP[iterator].particular,
      shareholding: categoryWiseSHP[iterator].shareholding,
    });
  }
  formatted.unshift({
    id: formatted.length + 1,
    categoryName: "",
    particular: "",
    shareholding: "",
  });
  return formatted;
}

export function formatShareholdingPatternsForTable(shareholdingPatterns) {
  let formatted = [];
  for (let iterator = 0; iterator < shareholdingPatterns.length; iterator++) {
    for (const property in shareholdingPatterns[iterator]) {
      formatted.push({
        id: iterator + 1,
        tag: property,
        value: shareholdingPatterns[iterator][property],
      });
    }
  }
  formatted.unshift({
    id: formatted.length + 1,
    tag: "",
    value: "",
  });
  return formatted;
}

export function formatQuarterlyRevenuesForTable(quarterlyRevenues) {
  let formatted = [];
  for (let iterator = 0; iterator < quarterlyRevenues.length; iterator++) {
    for (const property in quarterlyRevenues[iterator]) {
      quarterlyRevenues[iterator][property].map((value) => [
        formatted.push({
          id: iterator + 1,
          year: property,
          revenue: value.revenue,
          profit: value.profit,
        }),
      ]);
    }
  }
  formatted.unshift({
    id: formatted.length + 1,
    year: "",
    profit: "",
    revenue: "",
  });
  return formatted;
}
