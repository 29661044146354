import React, { useEffect, useContext } from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Select } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "184px",
  },
}));

const AddChildAccountDialog = (props) => {
  const classes = useStyles();
  const { toBeEdited } = props;
  const initialState = {
    accountType: toBeEdited === null ? "" : toBeEdited.accountType,
    firstName: toBeEdited === null ? "" : toBeEdited.firstName,
    lastName: toBeEdited === null ? "" : toBeEdited.lastName,
    mobileNumber: toBeEdited === null ? "" : toBeEdited.mobileNumber,
    gender: toBeEdited === null ? "" : toBeEdited.gender,
  };
  const [currentState, setCurrentState] = React.useState(initialState);
  const accountTypeOptions = [
    "Mother",
    "Father",
    "Wife",
    "Husband",
    "Daughter",
    "Son",
    "HUF",
    "Company",
    "Trust",
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    let state = currentState;
    setCurrentState(initialState);
    if (toBeEdited === null) {
      props.createChildAccount(event, state);
    } else props.updateExistingChildAccount(event, state);
  };

  const resetStateAndClose = (event) => {
    event.preventDefault();
    setCurrentState(initialState);
    props.closeModal(event);
  };

  const handleChange = (event) => {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCheckBox = (event) => {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={resetStateAndClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Child Account</DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "100%" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Account Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="accountType"
                  value={currentState.accountType}
                  onChange={(event) => handleChange(event)}
                  label="Account Type"
                >
                  {accountTypeOptions &&
                    accountTypeOptions.length > 0 &&
                    accountTypeOptions.map((accountTypeOption, index) => {
                      return (
                        <MenuItem value={accountTypeOption} key={index}>
                          {accountTypeOption}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <TextField
                style={{ "margin-top": "10px" }}
                type="text"
                name="firstName"
                variant="outlined"
                fullWidth
                label="First Name"
                defaultValue={currentState.firstName}
                onChange={(event) => handleChange(event)}
              />
            </Grid>
            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <TextField
                style={{ "margin-top": "10px" }}
                type="text"
                name="lastName"
                variant="outlined"
                fullWidth
                label="Last Name"
                defaultValue={currentState.lastName}
                onChange={(event) => handleChange(event)}
              />
            </Grid>

            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <TextField
                style={{ "margin-top": "10px" }}
                type="text"
                name="gender"
                variant="outlined"
                fullWidth
                label="Gender"
                defaultValue={currentState.gender}
                onChange={(event) => handleChange(event)}
              />
            </Grid>

            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <TextField
                style={{ "margin-top": "10px" }}
                type="text"
                name="mobileNumber"
                variant="outlined"
                fullWidth
                label="Mobile Number"
                defaultValue={currentState.mobileNumber}
                onChange={(event) => handleChange(event)}
              />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetStateAndClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddChildAccountDialog;
