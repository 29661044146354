import React, { useEffect } from "react";
import toastr from "toastr";
import { TextField, Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import ImageUpload from "../ImageUpload";
import {
  makeDELETERequestOnUserServiceWithQueryParameter,
  makePOSTRequest,
  makePOSTRequestOnUserService,
} from "../../Api/api";
import GenericDialog from "../Dialog/GenericDialog";
import Chip from "@mui/material/Chip";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  paper: {
    width: "500px",
    margin: "35px auto",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    height: 355,
    maxWidth: 600,
    overflow: "hidden",
    display: "block",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function PanCardDetailsModalContent({ panCardDetails }) {
  return (
    <>
      <TextField
        label="PAN Number"
        value={panCardDetails.pan_number}
        fullWidth
        variant="outlined"
        margin="normal"
        InputProps={{ readOnly: true }}
      />
      <TextField
        label="KYC Holder Name"
        value={panCardDetails.kyc_holder_name}
        fullWidth
        variant="outlined"
        margin="normal"
        InputProps={{ readOnly: true }}
      />
      <div style={{ marginBottom: "8px" }}>
        <Typography variant="subtitle1">KYC Status in Codes:</Typography>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "4px",
          }}
        >
          {panCardDetails?.kyc_status_in_codes &&
            panCardDetails.kyc_status_in_codes.map((status, index) => (
              <Chip
                key={index}
                label={status}
                variant="outlined"
                color="primary"
                style={{ margin: "4px" }}
              />
            ))}
        </div>
      </div>
      <TextField
        label="KYC Status Last Updated Date"
        value={panCardDetails.kyc_status_last_updated_date}
        fullWidth
        variant="outlined"
        margin="normal"
        InputProps={{ readOnly: true }}
      />
      <TextField
        label="KYC Creation Date"
        value={panCardDetails.kyc_creation_date}
        fullWidth
        variant="outlined"
        margin="normal"
        InputProps={{ readOnly: true }}
      />
    </>
  );
}

const PanCardInformationGrid = (props) => {
  const classes = useStyles();

  const initialState = {
    panCardNumber: props?.panCard?.uuid || "",
    name: props?.panCard?.name || "",
    fathersName: props?.panCard?.fathers_name || "",
    dateOfBirth: props?.panCard?.dob || "",
    image: props?.panCard?.image || "",
  };
  const [currentState, setCurrentState] = React.useState(initialState);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [createCond, setCreateCond] = React.useState(
    props.panCard
      ? Object.keys(props.panCard).length === 0 &&
          props.panCard.constructor === Object
      : true
  );
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showPanDetailsDialog, setShowPanDetailsDialog] = React.useState(false);
  const [panCardDetails, setPanCardDetails] = React.useState(null);

  const handleChange = (event) => {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  function deletePanCard(event) {
    event.preventDefault();
    makeDELETERequestOnUserServiceWithQueryParameter(
      `/kyc/pan_card/delete/${props.currentUser.uuid}/`
    )
      .then((response) => {
        if (response?.status === 204) {
          toastr.success("Deleted Pan card successfully !");
          setCurrentState({
            panCardNumber: "",
            name: "",
            fathersName: "",
            dateOfBirth: "",
            image: "",
          });
          setShowDeleteModal(!showDeleteModal);
          setSelectedFile(null);
          setCreateCond(true);
          props.nullifyPanCard(event);
        }
      })
      .catch((error) => toastr.error(error));
  }

  const setFile = (event) => {
    event.preventDefault();
    setSelectedFile(event.target.files[0]);
  };

  const savePanCardDetails = (event) => {
    event.preventDefault();
    props.savePanCardDetails(event, currentState);
  };

  function handlePanStatusClick(event) {
    if (!createCond || !currentState.panCardNumber) {
      makePOSTRequestOnUserService("/kyc/cvl_pancard/get/", {
        admin_name: JSON.parse(localStorage.getItem("loginUser"))?.name,
        user_id: props.currentUser.uuid,
      })
        .then((resp) => {
          if (resp.status === 200) {
            setPanCardDetails(resp.data);
            setShowPanDetailsDialog(true);
          }
        })
        .catch((error) => {
          console.log(error);
          toastr.error(error);
        });
    } else {
      toastr.warning("Pan Card Record needs to be created first.");
    }
  }

  const addPanCardDetails = (event) => {
    event.preventDefault();
    let state = { ...currentState };
    state.image = selectedFile;
    props.addPanCardDetails(event, state);
  };

  function dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  function downloadFile(e, b64) {
    e.preventDefault();
    // data should be your response data in base64 format
    const blob = dataURItoBlob(b64);
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  }

  function renderImageOrPDFButton() {
    if (props.panCard?.extension === "pdf") {
      return (
        <Button
          className="btnClass"
          variant="outlined"
          onClick={(event) => {
            downloadFile(event, props.panCard.image);
          }}
          color="primary"
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          View PDF
        </Button>
      );
    } else {
      return (
        <img
          className={classes.img}
          src={`data:image/png;base64,${props?.panCard.image}`}
          alt={`data:image/png;base64,${props?.panCard.image}`}
        />
      );
    }
  }

  return (
    <div
      style={{
        position: "absolute",
        left: "70%",
        top: "70%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Box
        sx={{ width: 410 }}
        style={{
          position: "absolute",
          left: "-43%",
          top: "45%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {createCond ? (
          <>
            <Skeleton variant="rectangular" width={410} height={418} />
            <ImageUpload setFile={setFile} />
          </>
        ) : (
          renderImageOrPDFButton()
        )}
      </Box>
      <Paper className={classes.paper} style={{ padding: "2%" }}>
        <div style={{ fontSize: "36px", float: "center" }}>Pan Card</div>
        <Grid item xs={10}>
          <TextField
            type="text"
            name="name"
            variant="outlined"
            fullWidth
            helperText="Name on PAN"
            onChange={handleChange}
            value={currentState.name}
          />
        </Grid>
        <Grid item xs={10} style={{ "margin-top": "10px" }}>
          <TextField
            type="text"
            name="panCardNumber"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            helperText="Pan Card Number"
            value={currentState.panCardNumber}
          />
        </Grid>
        <Grid item xs={10} style={{ "margin-top": "10px" }}>
          <TextField
            type="text"
            name="fathersName"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            helperText="Fathers Name"
            value={currentState.fathersName}
          />
        </Grid>
        <Grid item xs={10} style={{ "margin-top": "10px" }}>
          <TextField
            type="text"
            name="dateOfBirth"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            helperText="Date Of Birth"
            value={currentState.dateOfBirth}
          />
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={5} style={{ marginTop: "20px", marginBottom: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ width: 200 }}
              onClick={createCond ? addPanCardDetails : savePanCardDetails}
            >
              {createCond ? "Create" : "Update"}
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              style={{ width: 150, top: "7px" }}
              onClick={handlePanStatusClick}
            >
              Pan Status
            </Button>
          </Grid>
        </Grid>

        {!createCond ? (
          <Grid item xs={5}>
            <Button
              className="btnClass"
              variant="outlined"
              onClick={(event) => {
                event.preventDefault();
                setShowDeleteModal(!showDeleteModal);
              }}
              color="primary"
              style={{
                float: "right",
                margin: "25px 10px 40px",
                cursor: "pointer",
              }}
            >
              Delete
            </Button>
          </Grid>
        ) : (
          ""
        )}
        {showDeleteModal ? (
          <GenericDialog
            show={showDeleteModal}
            heading="Delete Pan Card"
            inputProps={"Are you sure you want to delete this pan card ?"}
            onClose={(event) => {
              event.preventDefault();
              setShowDeleteModal(!showDeleteModal);
            }}
            handleSubmit={(event) => {
              event.preventDefault();
              deletePanCard(event);
            }}
          />
        ) : (
          ""
        )}
        {showPanDetailsDialog && (
          <GenericDialog
            show={showPanDetailsDialog}
            heading="PAN Status"
            inputProps={
              <PanCardDetailsModalContent panCardDetails={panCardDetails} />
            }
            submitButtonText="Close"
            onClose={(event) => {
              event.preventDefault();
              setShowPanDetailsDialog(false);
              setPanCardDetails(null);
            }}
            handleSubmit={(event) => {
              event.preventDefault();
              setShowPanDetailsDialog(false);
              setPanCardDetails(null);
            }}
          />
        )}
      </Paper>
    </div>
  );
};

export default PanCardInformationGrid;
