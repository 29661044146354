import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import toastr from "toastr";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import Tooltip from "@material-ui/core/Tooltip";
import { appContext } from "../App";
import clsx from "clsx";
import SidePanel from "./SidePanel";
import BulkUploadModal from "./BulkUploadModal";
import { makeGETRequest, makePOSTRequest } from "../Api/api";
import WeeklySmest from "./WeeklySmest";
import GenericDialog from "../components/Dialog/GenericDialog";
import InputEmoji from "react-input-emoji";
import NotificationCloseIcon from "../images/notificationCloseIcon.svg";
import ChangePasswordDialog from "./ChangePassword/ChangePasswordDialog";

const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  menusBackground: {
    position: "fixed",
    background: "transparent",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    zIndex: "8",
  },
  link: {
    color: "#FFF",
  },
}));

const Header = (props) => {
  const { authUser, setAuthUser } = useContext(appContext);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [accountMenuState, setAccountMenuState] = useState(false);
  const accountAnchorRef = React.useRef(null);
  const [ntsMenuOpen, ntsMenuSetOpen] = useState(false);
  const [showUploadModalGSECModal, setShowUploadModalGSECModal] =
    React.useState(false);
  const [showWeeklyGyanModal, setShowWeeklyGyanModal] = React.useState(false);
  const currentPermissions = localStorage.getItem("permissions");
  const [showInformationTickerDialog, setShowInformationTickerDialog] =
    React.useState(false);
  const [informationTickerMessage, setInformationTickerMessage] =
    React.useState("");
  const [informationTickerHref, setInformationTickerHref] = React.useState("");
  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const sessionHeaderClosed =
    localStorage.getItem("showHeader") == "false" ? false : true;

  const [showInformationTicker, setShowInformationTicker] =
    React.useState(true);

  React.useEffect(() => {
    makeGETRequest("/admin/information_ticker/message/get/")
      .then((response) => {
        if (response && response.status === 200) {
          setInformationTickerMessage(response.data.informationTickerMessage);
          setInformationTickerHref(response.data.hrefLink);
        }
      })
      .catch((error) => toastr.error(error));
  }, [showInformationTickerDialog]);

  const renderDrawerButton = () => {
    return (
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        className={clsx(classes.menuButton, open && classes.hide)}
      >
        <MenuIcon />
      </IconButton>
    );
  };

  const accountHandleClose = (event) => {
    if (
      accountAnchorRef.current &&
      accountAnchorRef.current.contains(event.target)
    ) {
      return;
    }
    setAccountMenuState(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      ntsMenuSetOpen(false);
      setAccountMenuState(false);
    }
  }

  const handleGSECUpload = (event) => {
    event.preventDefault();
    setShowUploadModalGSECModal(true);
  };

  function handleOnEnter(text) {}

  function generateInputProps() {
    return (
      <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <InputEmoji
          value={informationTickerMessage}
          onChange={setInformationTickerMessage}
          cleanOnEnter
          onEnter={handleOnEnter}
          height={100}
          maxLength={500}
          placeholder="Type here..."
        />
        <br />

        <TextField
          className="inputRounded"
          type="text"
          fullWidth
          name="hrefLinl"
          variant="outlined"
          label="Href Link"
          size="small"
          defaultValue={informationTickerHref}
          onChange={(event) => setInformationTickerHref(event.target.value)}
        />
      </>
    );
  }

  function checkIfValidURL(informationTickerHref) {
    if (informationTickerHref === "") {
      return true;
    } else {
      let url;
      try {
        url = new URL(informationTickerHref);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    }
  }

  function handleSubmitForInformationTicker(event) {
    event.preventDefault();
    if (checkIfValidURL(informationTickerHref)) {
      makePOSTRequest("/admin/information_ticker/message/update/", {
        informationTickerMessage: informationTickerMessage,
        hrefLink: informationTickerHref,
      })
        .then((response) => {
          if (response && response.status === 200) {
            setShowInformationTickerDialog(false);
          }
        })
        .then((error) => console.error(error));
    } else {
      toastr.error("Please enter a valid url !");
    }
  }

  function handleInformationTickerOnClick(event) {
    event.preventDefault();
    setShowInformationTickerDialog(!showInformationTickerDialog);
  }
  const handleWeeklyGyanUpload = (event) => {
    event.preventDefault();
    setShowWeeklyGyanModal(true);
  };

  const accountMenuNavigation = (href) => {
    props.history.push(href);
    setAccountMenuState(false);
  };

  const renderAppBarHeading = () => {
    return (
      <Grid item>
        <Link to={"/dashboard"} className={classes.link}>
          <Typography variant="h6" noWrap style={{ cursor: "pointer" }}>
            SMEST Admin
          </Typography>
        </Link>
      </Grid>
    );
  };

  const handleAccountMenuState = () => {
    setAccountMenuState((accountMenuState) => !accountMenuState);
  };

  const logoutUser = () => {
    localStorage.clear();
    window.location = "/";
  };

  const renderProfileIcon = () => {
    return (
      <Tooltip title="Account">
        <IconButton
          color="inherit"
          aria-label="Account"
          onClick={handleAccountMenuState}
          ref={accountAnchorRef}
          aria-controls={
            accountMenuState ? "account-menu-list-grow" : undefined
          }
          aria-haspopup="true"
        >
          <AccountCircleIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const renderProfileMenuOptions = () => {
    return (
      <Popper
        open={accountMenuState}
        anchorEl={accountAnchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper style={{ maxWidth: "300px" }}>
              <ClickAwayListener onClickAway={accountHandleClose}>
                <MenuList
                  autoFocusItem={accountMenuState}
                  id="account-menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  style={{ paddingBottom: "0" }}
                >
                  <MenuItem
                    onClick={(e) =>
                      accountMenuNavigation(`/profile/${authUser.uuid}`)
                    }
                  >
                    Edit Profile
                  </MenuItem>
                  <MenuItem
                    onClick={(e) =>
                      setShowChangePasswordDialog(!showChangePasswordDialog)
                    }
                  >
                    Change Password
                  </MenuItem>
                  {authUser.isSuperuser ||
                  currentPermissions.includes("GSEC Upload") ? (
                    <MenuItem onClick={handleGSECUpload}>Upload GSEC</MenuItem>
                  ) : (
                    ""
                  )}
                  <MenuItem onClick={handleWeeklyGyanUpload}>
                    Weekly SMEST Gyan
                  </MenuItem>
                  <MenuItem onClick={handleInformationTickerOnClick}>
                    Information Ticker
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={logoutUser}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  const renderAccountSection = () => {
    if (authUser && authUser.uuid) {
      return (
        <>
          {renderProfileIcon()}
          {renderProfileMenuOptions()}
        </>
      );
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSideMenuNavigation = (navigateTo) => {
    localStorage.removeItem("selectedNotify");
    props.history.push(navigateTo);
    handleDrawerClose();
  };

  const handleOnClosePanel = (event) => {
    event.preventDefault();
    setOpen(false);
  };

  const renderSidePanel = () => {
    return (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        {authUser && authUser.uuid ? (
          <SidePanel
            onClose={handleOnClosePanel}
            isSuperuser={authUser.isSuperuser}
          />
        ) : (
          ""
        )}
      </Drawer>
    );
  };

  const handleBulkUploadOnClose = (event) => {
    event.preventDefault();
    setShowUploadModalGSECModal(false);
  };

  const handleWeeklyGyanOnClose = (event) => {
    event.preventDefault();
    setShowWeeklyGyanModal(false);
  };
  const handleWeeklyGyanOnSave = (event) => {
    event.preventDefault();
    setShowWeeklyGyanModal(false);
  };

  const handleSubmitForBulkUpload = (event, selectedFile) => {
    event.preventDefault();
    let requestURL = "/gsec/upload/";
    let formData = new FormData();
    formData.append("file", selectedFile);
    makePOSTRequest(requestURL, formData)
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Uploaded file successfully !");
          setShowUploadModalGSECModal(false);
        }
      })
      .catch((error) => toastr.error(error));
  };

  function renderSlider() {
    return (
      <span style={{ paddingRight: "5px" }}>{informationTickerMessage}</span>
    );
  }

  function closeMarquee() {
    localStorage.setItem("showHeader", false);
    setShowInformationTicker(false);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        {sessionHeaderClosed &&
        showInformationTicker &&
        informationTickerMessage !== "" ? (
          <>
            <Toolbar>
              <marquee width="100%" direction="left" height="auto">
                {renderSlider()}
              </marquee>
              <Grid style={{ padding: "0px 0px 0px 20px" }}>
                <IconButton
                  className="headerFloatingBtn"
                  aria-label="notification"
                  style={{
                    backgroundImage: "url(" + NotificationCloseIcon + ")",
                  }}
                  onClick={closeMarquee}
                ></IconButton>
              </Grid>{" "}
            </Toolbar>
          </>
        ) : (
          ""
        )}
        <Toolbar>
          {authUser && authUser.uuid ? renderDrawerButton() : ""}
          <Grid
            container
            style={{ width: "100%" }}
            alignItems="center"
            justify="space-between"
          >
            {renderAppBarHeading()}
            {renderAccountSection()}
          </Grid>
        </Toolbar>
      </AppBar>
      {renderSidePanel()}
      <BulkUploadModal
        show={showUploadModalGSECModal}
        onCloseModal={handleBulkUploadOnClose}
        submitHandler={handleSubmitForBulkUpload}
        heading="Upload GSEC"
      />
      <WeeklySmest
        show={showWeeklyGyanModal}
        onCloseModal={handleWeeklyGyanOnClose}
        submitHandler={handleWeeklyGyanOnSave}
        heading="Weekly SMEST Gyan"
      />
      <GenericDialog
        show={showInformationTickerDialog}
        heading="Information Ticker"
        inputProps={generateInputProps()}
        onClose={handleInformationTickerOnClick}
        handleSubmit={handleSubmitForInformationTicker}
      />
      {showChangePasswordDialog ? (
        <ChangePasswordDialog
          show={showChangePasswordDialog}
          onClose={() => {
            setShowChangePasswordDialog(!showChangePasswordDialog);
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default withRouter(Header);
