import React, { useEffect } from "react";
import { alpha, styled } from "@mui/material/styles";
import { shallow } from "zustand/shallow";
import useCalc from "../../store/calc";
import { makePOSTRequest } from "../../Api/api";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { CSSObject } from "@emotion/react";
import styles from "../../Helpers/styles";
import toastr from "toastr";

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 50,
  border: "2px solid",
  borderColor: alpha("#7569EE", 0.2),
  "&:hover": {
    borderColor: alpha("#7569EE", 0.35),
  },
  width: "100%",
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0, 0, 1.5),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  ...(styles.flexCenterCenter as CSSObject),
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const Searchbar = () => {
  const [
    searchInput,
    getBondInfo,
    getSearchResult,
    getSearchInput,
    loader,
    getCalculatedValues,
    getBreakup,
    getYield,
    getPrice,
    getDate,
    getUnits,
    getMMY,
  ] = useCalc(
    (state) => [
      state.searchInput,
      state.getBondInfo,
      state.getSearchResult,
      state.getSearchInput,
      state.loader,
      state.getCalculatedValues,
      state.getBreakup,
      state.getYield,
      state.getPrice,
      state.getDate,
      state.getUnits,
      state.getMMY,
    ],
    shallow
  );

  useEffect(() => {
    const timer = setTimeout(async () => {
      try {
        if (searchInput) {
          loader(true);
          getBondInfo(null);
          getCalculatedValues(null);
          getBreakup(false);
          getYield(null);
          getPrice(null);
          getDate(null);
          getUnits(1);
          getMMY(false);
          const response = await makePOSTRequest(
            "/admin/security_master/trade_floor/search/",
            {
              query_string: searchInput,
            }
          );
          if (response && response.status === 200) {
            getSearchResult(response.data);
            loader(false);
          }
        }
      } catch (error: any) {
        toastr.error(error.message);
        loader(false);
      }
    }, 500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  const handleSearchBar: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    getSearchInput(event.currentTarget.value);
  };

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
        {!searchInput && "Search by ISIN"}
      </SearchIconWrapper>
      <StyledInputBase value={searchInput} onChange={handleSearchBar} />
    </Search>
  );
};

export default Searchbar;
