import React from "react";
import { makeGETRequest } from "../../Api/api";
import { Grid } from "@material-ui/core";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Paper from "@material-ui/core/Paper";
import FullScreenDialog from "../../components/Dialog/FullScreenDialog";
import IPODetails from "./IPODetails";
const toastr = require("toastr");

const dataGridCells = [
  { field: "serial", headerName: "Serial", width: 350, editable: false },
  { field: "category", headerName: "Category", width: 350, editable: false },
  { field: "name", headerName: "Name", width: 350, editable: false },
  { field: "symbol", headerName: "Symbol", width: 350, editable: false },
  {
    field: "opendatetime",
    headerName: "Open Date Time",
    width: 350,
    editable: false,
  },
];

function addSerialNumber(records: any): any {
  let withSerialNumber = [];
  for (let iterator = 0; iterator < records.length; iterator++) {
    let updatedRecord = records[iterator];
    updatedRecord.serial = iterator + 1;
    withSerialNumber.push(updatedRecord);
  }
  return withSerialNumber;
}

export default function BSEIPOs(props: any) {
  const [bseIpos, setBseIpos] = React.useState([]);
  const [currentlySelected, setCurrentlySelected] = React.useState(null);
  const [showIPODetailsDialog, setShowIPODetailsDialog] = React.useState(false);

  React.useEffect(() => {
    makeGETRequest("/ipo/list/")
      .then((response: any) => {
        setBseIpos(addSerialNumber(response.data));
      })
      .catch((error: any) => toastr.error(error));
  }, []);

  function handleCellClick(params: any) {
    let filtered = bseIpos.filter((ipo: any) => ipo.serial === params.id)[0];
    setCurrentlySelected(filtered);
    setShowIPODetailsDialog(!showIPODetailsDialog);
  }

  function handleCloseForFullScreenDialog(event: any) {
    setShowIPODetailsDialog(!showIPODetailsDialog);
    setCurrentlySelected(null);
  }

  return (
    <Grid className="securityTypeDataGird">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>BSE IPOs</div>
            </Grid>
            <Grid item xs="auto"></Grid>
          </Grid>
        </Grid>
        <div style={{ height: "100%", width: "100%" }}>
          <DataGrid
            pagination
            className="securityMasterDataGrid hgn"
            components={{ Toolbar: GridToolbar }}
            rows={bseIpos}
            columns={dataGridCells}
            onCellClick={handleCellClick}
            getRowId={(row) => row.serial}
            autoHeight
          />
        </div>
      </Paper>
      {showIPODetailsDialog ? (
        <FullScreenDialog
          show={showIPODetailsDialog}
          handleClose={handleCloseForFullScreenDialog}
          showSaveButton={true}
          saveButtonText="Update All"
          onSumitSaveButton={handleCloseForFullScreenDialog}
          inputProps={
            <Grid className="profileshowConntan">
              {" "}
              <IPODetails ipo={currentlySelected} />{" "}
            </Grid>
          }
          heading="IPO Details"
        />
      ) : (
        ""
      )}
    </Grid>
  );
}
