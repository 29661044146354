import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import { TextField, Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteModal from "../../DeleteModal";
import {
  getComparator,
  stableSort,
  EnhancedTableHead,
} from "./CategoryTableUtils";
import CancelIcon from "@mui/icons-material/Cancel";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
const toastr = require("toastr");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  table: {
    minWidth: 450,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  textFieldClass: {
    width: "20%",
    height: "20%",
  },
}));

const CategoryWiseSHPTable = (props: any) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState<string[]>([]);
  const initialState = {
    categoryName: "",
    shareholding: "",
    particular: "",
    id: -1,
  };
  const [currentState, setCurrentState] = React.useState(initialState);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [deletableCategoryId, setDeletableCategoryId] = React.useState<
    null | number
  >(null);
  const [showEditModal, setShowUpdateModal] = React.useState(false);
  const [toBeEdited, setToBeEdited] = React.useState<any>(null);

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleAddNewRow = (event: any) => {
    event.preventDefault();
    if (
      currentState.categoryName !== "" &&
      currentState.shareholding !== "" &&
      currentState.particular !== ""
    ) {
      let state = currentState;
      setCurrentState(initialState);
      props.addSHPRecord(event, state);
    }
  };

  const handleChange = (event: any) => {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  function handleOnCancelClick(event: any) {
    event.preventDefault();
    setShowUpdateModal(false);
    setToBeEdited(null);
  }

  function renderDeleteOrCancelIcon(row: any, index: number) {
    if (showEditModal && index === toBeEdited.id) {
      return (
        <CancelIcon
          onClick={handleOnCancelClick}
          color="primary"
          fontSize="medium"
          style={{
            float: "right",
            cursor: "pointer",
          }}
        />
      );
    } else {
      return (
        <DeleteIcon
          onClick={(event) => handleDeleteIconOnClick(event, row)}
          color="primary"
          fontSize="medium"
          style={{
            float: "right",
            cursor: "pointer",
          }}
        />
      );
    }
  }

  const renderInputOrReadOnly = (
    index: number,
    row: any,
    key: string,
    placeholder: string
  ) => {
    if (!toBeEdited && index !== 0) {
      return <TableCell align="left">{row[key]}</TableCell>;
    } else {
      return (
        <Grid item xs={12}>
          <TableCell align="left">
            <TextField
              id="standard-basic"
              name={key}
              placeholder={placeholder}
              label={key}
              defaultValue={row[key]}
              onChange={(event) => handleChange(event)}
            />
          </TableCell>
        </Grid>
      );
    }
  };

  const handleDeleteIconOnClick = (event: any, row: any) => {
    event.preventDefault();
    setDeletableCategoryId(row.id);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = (event: any) => {
    event.preventDefault();
    setShowDeleteModal(false);
  };

  const deleteCategorySHPRecord = (event: any) => {
    event.preventDefault();
    let currentID = deletableCategoryId;
    setDeletableCategoryId(0);
    setShowDeleteModal(false);
    props.removeCategorySHPRecord(event, currentID);
  };

  function handleEditIconOnClick(event: any, row: any) {
    if (
      row.categoryName === "" &&
      row.shareholding === "" &&
      row.particular === ""
    ) {
      toastr.warning("Nothing to edit here !");
    } else {
      setShowUpdateModal(true);
      setToBeEdited(row);
    }
  }

  function updateCategoryRecord(event: any) {
    event.preventDefault();
    let state = currentState;
    state.id = toBeEdited.id;
    setShowUpdateModal(false);
    setToBeEdited(null);
    setCurrentState(initialState);
    props.updateSHPRecord(event, state);
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"small"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={props.categoryWiseSHPRecords.length}
            onSelectAllClick={() => {}}
          />
          <TableBody>
            {stableSort(
              props.categoryWiseSHPRecords,
              getComparator(order, orderBy)
            ).map((row: any, index: number) => {
              const isItemSelected = isSelected(row.name);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.name}
                  selected={isItemSelected}
                >
                  <TableCell id={labelId} scope="row" padding="none">
                    {index + 1}
                  </TableCell>
                  <TableCell id={labelId} scope="row" padding="none">
                    {renderInputOrReadOnly(
                      index,
                      row,
                      "categoryName",
                      "Category Name"
                    )}
                  </TableCell>
                  <TableCell id={labelId} scope="row" padding="none">
                    {renderInputOrReadOnly(
                      index,
                      row,
                      "shareholding",
                      "Shareholding"
                    )}
                  </TableCell>
                  <TableCell id={labelId} scope="row" padding="none">
                    {renderInputOrReadOnly(
                      index,
                      row,
                      "particular",
                      "Particular"
                    )}
                  </TableCell>

                  <TableCell
                    id={labelId}
                    scope="row"
                    padding="none"
                    style={{ textAlign: "center" }}
                  >
                    {row.categoryName === "" &&
                    row.shareholding === "" &&
                    row.particular === "" ? (
                      <AddCircleIcon
                        onClick={handleAddNewRow}
                        color="primary"
                        fontSize="large"
                        style={{
                          float: "right",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      renderDeleteOrCancelIcon(row, index + 1)
                    )}
                  </TableCell>

                  <TableCell id={labelId} scope="row" padding="none">
                    {showEditModal && index + 1 === toBeEdited.id ? (
                      <DoneOutlineIcon
                        color="primary"
                        fontSize="medium"
                        onClick={updateCategoryRecord}
                        style={{
                          float: "left",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <EditIcon
                        color="primary"
                        fontSize="medium"
                        onClick={(e: any) => handleEditIconOnClick(e, row)}
                        style={{
                          float: "left",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteModal
        primaryText="Delete Category SHP Record?"
        secondaryText="Are you sure you want to delete this Category SHP Record?"
        isOpen={showDeleteModal}
        handleClose={closeDeleteModal}
        confirmDelete={deleteCategorySHPRecord}
      />
    </>
  );
};

export default CategoryWiseSHPTable;
