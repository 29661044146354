import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import toastr from "toastr";
import Paper from "@material-ui/core/Paper";
import DeleteModal from "../../components/DeleteModal";
import {
  makeDELETERequestWithQueryParameter,
  makeGETRequest,
  makePOSTRequest,
  downloadSubscribers,
} from "../../Api/api";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import ConfirmationModal from "../../components/ConfirmationModal";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { Grid, TextField } from "@material-ui/core";
import GenericDialog from "../Dialog/GenericDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "96%",
    margin: "35px auto",
    height: " 0px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function getFilteredSubscribersByEmail(subscribers, emailId) {
  return subscribers.filter((subscriber) => subscriber.emailId != emailId);
}

export default function Subscriptions(props) {
  const classes = useStyles();
  const [subscribers, setSubscribers] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [order, setOrder] = React.useState("asc");
  const [toBeDeleted, setToBeDeleted] = React.useState(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false);
  const [showNewsletterDataModal, setShowNewsletterDataModal] =
    React.useState(false);
  const [emailSubject, setEmailSubject] = React.useState("");

  function addSerialNumberToSubscriptions(users) {
    let formatted = [];
    for (let iterator = 0; iterator < users.length; iterator++) {
      let userWithSerial = users[iterator];
      userWithSerial.serial = iterator + 1;
      formatted.push(userWithSerial);
    }
    return formatted;
  }

  useEffect(() => {
    let requestURL = "/admin/news_letter_user/list/";
    makeGETRequest(requestURL)
      .then((response) => {
        if (response && response.status === 200) {
          response.data
            ? setSubscribers(addSerialNumberToSubscriptions(response.data))
            : setSubscribers([]);
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleDeleteIconOnClick = (row) => {
    setToBeDeleted(row);
    setShowDeleteModal(true);
  };

  const dataGridCells = [
    { field: "serial", headerName: "Serial", width: 350, editable: false },
    { field: "emailId", headerName: "Emails", width: 450, editable: false },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 350,
      disableClickEventBubbling: true,

      renderCell: () => {
        return (
          <DeleteIcon
            color="primary"
            fontSize="medium"
            style={{
              float: "left",
              "margin-top": "10px",
              "margin-left": "14px",
              cursor: "pointer",
            }}
          />
        );
      },
    },
  ];

  const closePublishModal = (event) => {
    event.preventDefault();
    setShowConfirmationModal(false);
  };

  const closeDeleteModal = (event) => {
    event.preventDefault();
    setShowDeleteModal(false);
    setToBeDeleted(null);
  };

  const deleteSubscriber = (event) => {
    event.preventDefault();
    let requestURL = `/admin/news_letter_user/delete/email/${toBeDeleted.emailId}`;
    makeDELETERequestWithQueryParameter(requestURL)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          setSubscribers(
            getFilteredSubscribersByEmail(subscribers, toBeDeleted.emailId)
          );
          setShowDeleteModal(false);
          setToBeDeleted(null);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const handlePublishConfirmationModalState = (event) => {
    event.preventDefault();
    setShowNewsletterDataModal(!showNewsletterDataModal);
  };

  const publishNewsletters = (event) => {
    event.preventDefault();
    let requestURL = "/admin/news_letter_user/send_mail/";
    makePOSTRequest(requestURL, { subject: emailSubject })
      .then((response) => {
        setShowConfirmationModal(false);
        toastr.success("Published Newsletters successfully !");
      })
      .catch((error) => toastr.error(error));
  };

  const currentlySelected = (params) => {
    params.field === "delete" && handleDeleteIconOnClick(params.row);
  };

  function getContentForEmailSubject() {
    return (
      <TextField
        type="text"
        name="subject"
        variant="outlined"
        fullWidth
        label="Subject"
        defaultValue={emailSubject}
        onChange={(event) => setEmailSubject(event.target.value)}
      />
    );
  }

  return (
    <Grid className="subscriptionsDataGrid">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>
                Subscriptions
              </div>
            </Grid>
            <Button
              className="btnClass"
              variant="outlined"
              onClick={handlePublishConfirmationModalState}
              color="primary"
              style={{
                float: "right",
                margin: "25px 10px 15px",
                cursor: "pointer",
              }}
            >
              Publish NewsLetter
            </Button>

            <DeleteModal
              primaryText="Delete User"
              secondaryText="Are you sure you want to delete this User ?"
              isOpen={showDeleteModal}
              handleClose={closeDeleteModal}
              confirmDelete={deleteSubscriber}
            />
            <ConfirmationModal
              primaryText="Publish Newsletter"
              secondaryText="Are you sure you want to Publish newsletters to all users ? "
              isOpen={showConfirmationModal}
              handleClose={closePublishModal}
              onConfirmation={publishNewsletters}
            />
          </Grid>
        </Grid>
        <div
          style={{
            height: "100vh",
            width: "100%",
          }}
        >
          <DataGrid
            className="subscripionDataGrid abc"
            components={{ Toolbar: GridToolbar }}
            rows={subscribers}
            columns={dataGridCells}
            onCellClick={currentlySelected}
            getRowId={(row) => row.uuid}
            pagination
          />
        </div>
        {showNewsletterDataModal ? (
          <GenericDialog
            show={showNewsletterDataModal}
            heading="Enter Email Subject"
            inputProps={getContentForEmailSubject()}
            onClose={(event) => {
              event.preventDefault();
              setShowNewsletterDataModal(!showNewsletterDataModal);
            }}
            handleSubmit={(event) => {
              event.preventDefault();
              setShowConfirmationModal(true);
              setShowNewsletterDataModal(false);
            }}
          />
        ) : (
          ""
        )}
      </Paper>
    </Grid>
  );
}
