import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import toastr from "toastr";
import "toastr/build/toastr.css";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
// import reportWebVitals from "./reportWebVitals";

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  positionClass: "toast-bottom-full-width",
  preventDuplicates: false,
  onclick: null,
  showDuration: "1000",
  hideDuration: "1000",
  timeOut: "15000",
  extendedTimeOut: "7000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_PROJECT_DSN,
//   integrations: [new BrowserTracing()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
