import React from "react";
import { Grid, Button, Typography, TextField } from "@material-ui/core";
import {
  makeGETRequestOnUserService,
  makePOSTRequestOnUserService,
  makePUTRequestOnUserService,
} from "../../../Api/api";
import toastr from "toastr";
import AddNewNomineeModal from "./AddNewNomineeModal";
import UpdateNomineeDetailsModal from "./UpdateNomineeDetailsModal";

function pad(s) {
  return s < 10 ? "0" + s : s;
}

function getFormattedNomineeDetails(response) {
  return {
    dateOfBirth: response.date_of_birth,
    mobileNumber: response.mobileNumber,
    relationship: response.relationship,
    nomineeName: response.name,
    uuid: response.uuid,
  };
}

export default function NomineeDetailsTab(props) {
  const [nomineeDetails, setNomineeDetails] = React.useState({});
  const [showAddNomineeModal, setShowAddNomineeModal] = React.useState(false);
  const [showEditNomineeDetailsModal, setShowEditNomineeDetailsModal] =
    React.useState(false);

  React.useEffect(() => {
    let requestURL = `/user/get_nominee/${props.currentUser.uuid}`;
    makeGETRequestOnUserService(requestURL)
      .then((response) => {
        if (response && response.status === 200) {
          typeof response.data !== "string" &&
            setNomineeDetails(getFormattedNomineeDetails(response.data));
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  const handleAddNomineeDetails = (event) => {
    event.preventDefault();
    setShowAddNomineeModal(true);
  };

  const handleUpdateNomineeDetails = (event) => {
    event.preventDefault();
    setShowEditNomineeDetailsModal(true);
  };

  const closeAddNomineeModal = (event) => {
    event.preventDefault();
    setShowAddNomineeModal(false);
  };

  const closeUpdateNomineeDetailsModal = (event) => {
    event.preventDefault();
    setShowEditNomineeDetailsModal(false);
  };

  const updateNomineeDetails = (event, updatedDetails) => {
    event.preventDefault();
    let d =
      updatedDetails.dateOfBirth === undefined
        ? new Date(nomineeDetails.dateOfBirth)
        : updatedDetails.dateOfBirth;
    let requestURL = `/user/update_nominee/${nomineeDetails.uuid}/`;
    let requestBody = {
      userId: props.currentUser.uuid,
      name: updatedDetails.nomineeName,
      date_of_birth: [
        pad(d.getDate()),
        pad(d.getMonth() + 1),
        d.getFullYear(),
      ].join("/"),
      relationship: updatedDetails.relationship,
      mobileNumber: updatedDetails.mobileNumber,
    };

    makePUTRequestOnUserService(requestURL, requestBody)
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Updated Nominee Details successfully");
          setNomineeDetails(getFormattedNomineeDetails(response.data));
          setShowEditNomineeDetailsModal(false);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const addNewNominee = (event, enteredDetails) => {
    event.preventDefault();
    let d = enteredDetails.dateOfBirth;
    let requestURL = "/user/create_nominee/";
    let requestBody = {
      userId: props.currentUser.uuid,
      name: enteredDetails.nomineeName,
      date_of_birth: [
        pad(d.getDate()),
        pad(d.getMonth() + 1),
        d.getFullYear(),
      ].join("/"),
      relationship: enteredDetails.relationship,
      mobileNumber: enteredDetails.mobileNumber,
    };
    makePOSTRequestOnUserService(requestURL, requestBody)
      .then((response) => {
        if (response && (response.status === 201 || response.status === 200)) {
          toastr.success("Added Nominee Details successfully");
          setNomineeDetails(getFormattedNomineeDetails(response.data));
          setShowAddNomineeModal(false);
        }
      })
      .catch((error) => toastr.error(error));
  };

  return (
    <>
      <Grid className="nomineeDetailsContainer">
        <Grid container spacing={3}>
          <Grid item lg={6} sm={12}>
            <Grid className="profileInfoDate">
              <Grid className="profileInfoDateTitle">Nominee Name</Grid>
              <Typography variant="h6" noWrap>
                {nomineeDetails.nomineeName || "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={6} sm={12}>
            <Grid className="profileInfoDate">
              <Grid className="profileInfoDateTitle">
                Nominee Date of Birth
              </Grid>
              <Typography variant="h6" noWrap>
                {nomineeDetails.dateOfBirth || "--"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item lg={6} sm={12}>
            <Grid className="profileInfoDate">
              <Grid className="profileInfoDateTitle">Nominee Relationship</Grid>
              <Typography variant="h6" noWrap>
                {nomineeDetails.relationship || "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={6} sm={12}>
            <Grid className="profileInfoDate">
              <Grid className="profileInfoDateTitle">Nominee Phone Number</Grid>
              <Typography variant="h6" noWrap>
                {nomineeDetails.mobileNumber || "--"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item lg={6} sm={12}>
            {Object.keys(nomineeDetails).length === 0 ? (
              <Grid className="profileInfoDate">
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    "margin-right": "15px",
                  }}
                  onClick={handleAddNomineeDetails}
                >
                  Add Nominee
                </Button>
              </Grid>
            ) : (
              <Grid className="profileInfoDate">
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    "margin-right": "15px",
                  }}
                  onClick={handleUpdateNomineeDetails}
                >
                  Update Nominee
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <AddNewNomineeModal
        onClose={closeAddNomineeModal}
        onSave={addNewNominee}
        show={showAddNomineeModal}
      />
      <UpdateNomineeDetailsModal
        onClose={closeUpdateNomineeDetailsModal}
        onSave={updateNomineeDetails}
        show={showEditNomineeDetailsModal}
        nomineeDetails={nomineeDetails}
      />
    </>
  );
}
