import React, { useState, useEffect } from "react";
import Close from "@material-ui/icons/Close";
import { TextField, Button, Grid, Fade, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ChangePasswordStepper from "./ChangePasswordStepper";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "184px",
  },
}));

export default function ChangePasswordDialog(props: any) {
  const toastr = require("toastr");
  const classes = useStyles();

  useEffect(() => {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };
  }, []);

  return (
    <div>
      <Dialog
        open={props.show}
        maxWidth={"md"}
        fullWidth
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change Password</DialogTitle>
        <DialogContent style={{ width: "100%" }}>
          <DialogContentText id="alert-dialog-description">
            <ChangePasswordStepper closeDialog={props.onClose} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
