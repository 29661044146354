import React, { useEffect, useContext, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import GetAppIcon from "@material-ui/icons/GetApp";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { appContext } from "../App";
import DeleteModal from "../components/DeleteModal";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router";
import {
  getAllRatingOrganizationMasters,
  deleteRatingOrganizationMasterByUUID,
  getAllRatings,
  getAllOrganizationNames,
} from "../Api/api";
import { Delete } from "@material-ui/icons";
import AddRatingOrganizationMaster from "../components/AddRatingOrganizationMaster";
import RatingDialog from "../components/RatingDialog";
import OrganizationDialog from "../components/OrganizationDialog";
import IdleTimoutConfig from "../Helpers/IdleTimeoutConfig";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const headCells = [
  { id: "id", numeric: true, disablePadding: false, label: "Serial No" },
  {
    id: "organizationName",
    numeric: true,
    disablePadding: false,
    label: "Organization Name",
  },
  { id: "rating", numeric: true, disablePadding: false, label: "Rating" },
  { id: "action", numeric: false, disablePadding: true, label: "Action" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "96%",
    margin: "35px auto",
    height: " 0px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const RatingOrganizationMaster = (props) => {
  const classes = useStyles();
  const { authUser, setAuthUser } = useContext(appContext);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [mainLoadingIs, setmainLoadingIs] = React.useState(false);
  const [ratingOrganizationMasters, setRatingOrganizationMasters] =
    React.useState([]);
  const [
    selectedRatingOrganizationMaster,
    setSelectedRatingOrganizationMaster,
  ] = React.useState(null);
  const [isConfirmationDeleteModalOpen, setIsConfirmationDeleteModalOpen] =
    React.useState(false);
  const [showAddRatingOrganizationModal, setShowAddRatingOrganizationModal] =
    React.useState(false);
  const [ratings, setRatings] = React.useState([]);
  const [showRatingDialog, setShowRatingDialog] = React.useState(false);
  const [showOrganizationDialog, setShowOrganizationDialog] =
    React.useState(false);
  const [organizationNames, setOrganizationNames] = React.useState([]);

  useEffect(() => {
    if (authUser && authUser.uuid) {
      getAllRatingOrganizationMasters()
        .then((response) => {
          if (response && response.status === 200) {
            setRatingOrganizationMasters(response.data);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  useEffect(() => {
    if (authUser && authUser.uuid) {
      getAllRatings()
        .then((response) => {
          if (response && response.status === 200) {
            setRatings(response.data);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  useEffect(() => {
    if (authUser && authUser.uuid) {
      getAllOrganizationNames()
        .then((response) => {
          if (response && response.status === 200) {
            setOrganizationNames(response.data);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  const handleRatingOrganizationAddClick = (event) => {
    event.preventDefault();
    setShowAddRatingOrganizationModal(true);
  };

  const handleAddIssuerTypeOnClick = (event) => {
    event.preventDefault();
    setShowRatingDialog(true);
  };

  const handleOrganizationNameOnClick = (event) => {
    event.preventDefault();
    setShowOrganizationDialog(true);
  };

  const renderAddOrgNameButton = () => {
    return (
      <Button
        className="btnClass"
        variant="outlined"
        onClick={(event) => handleOrganizationNameOnClick(event)}
        color="primary"
        style={{
          float: "right",
          margin: "25px 10px 15px",

          cursor: "pointer",
        }}
      >
        Add Organization Name
      </Button>
    );
  };

  const renderAddRatingButton = () => {
    return (
      <Button
        className="btnClass"
        variant="outlined"
        onClick={(event) => handleAddIssuerTypeOnClick(event)}
        color="primary"
        style={{
          float: "right",
          margin: "25px 10px 15px",
          cursor: "pointer",
        }}
      >
        Add Rating
      </Button>
    );
  };

  const renderAddButtonForRatingMaster = () => {
    return (
      <AddCircleIcon
        className="btnClass"
        onClick={(event) => handleRatingOrganizationAddClick(event)}
        color="primary"
        fontSize="large"
        style={{
          float: "right",
          margin: "25px 10px 15px",
          cursor: "pointer",
        }}
      />
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRatingOrganizationDeleteClick = (
    event,
    ratingOrganizationMaster
  ) => {
    event.preventDefault();
    setSelectedRatingOrganizationMaster(ratingOrganizationMaster);
    setIsConfirmationDeleteModalOpen(true);
  };

  const renderDeleteIcon = (row, labelId) => {
    return (
      <TableCell id={labelId} scope="row" padding="none">
        <DeleteIcon
          onClick={(event) => handleRatingOrganizationDeleteClick(event, row)}
          color="primary"
          disabled
          style={{
            cursor: "pointer",
          }}
        />
      </TableCell>
    );
  };

  const renderRatingMasterTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={ratingOrganizationMasters.length}
          />
          <TableBody>
            {stableSort(
              ratingOrganizationMasters,
              getComparator(order, orderBy)
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    padding="default"
                    selected={isItemSelected}
                  >
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell id={labelId} scope="row" padding="default">
                      {row.organizationName}
                    </TableCell>
                    <TableCell id={labelId} scope="row" padding="default">
                      {row.rating}
                    </TableCell>
                    {renderDeleteIcon(row, labelId)}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const closeDeleteModal = () => {
    setSelectedRatingOrganizationMaster(null);
    setIsConfirmationDeleteModalOpen(false);
  };

  const deleteRatingOrganizationMaster = () => {
    if (authUser && authUser.uuid) {
      deleteRatingOrganizationMasterByUUID(
        selectedRatingOrganizationMaster.uuid
      )
        .then((response) => {
          if (response && response.status === 200) {
            toastr.success("Deleted Rating Organization Master successfully!");
            let filteredRatingOrganizationMaster =
              ratingOrganizationMasters.filter(
                (ratingOrganizationMaster) =>
                  ratingOrganizationMaster.uuid !=
                  selectedRatingOrganizationMaster.uuid
              );
            setRatingOrganizationMasters(filteredRatingOrganizationMaster);
            setSelectedRatingOrganizationMaster(null);
            setIsConfirmationDeleteModalOpen(false);
          }
        })
        .catch((error) => toastr.error(error));
    }
  };

  const handleCloseForAddModal = () => {
    setShowAddRatingOrganizationModal(false);
    getAllRatingOrganizationMasters()
      .then((response) => {
        if (response && response.status === 200) {
          setRatingOrganizationMasters(response.data);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const handleCloseForRatingDialog = () => {
    setShowRatingDialog(false);
    getAllRatings()
      .then((response) => {
        if (response && response.status === 200) {
          setRatings(response.data);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const handleOrganizationDialogClose = (event, organizationNames) => {
    event.preventDefault();
    setShowOrganizationDialog(false);
    setOrganizationNames(organizationNames);
  };

  const renderOrganizationNameDialog = () => {
    return (
      <OrganizationDialog
        show={showOrganizationDialog}
        onCloseDialog={handleOrganizationDialogClose}
        organizationNames={organizationNames}
      />
    );
  };

  const renderRatingDialog = () => {
    return (
      <RatingDialog
        show={showRatingDialog}
        onCloseModal={handleCloseForRatingDialog}
      />
    );
  };

  const getFormattedOrganizationNames = () => {
    return organizationNames.map((organizationName) => organizationName.name);
  };

  const renderAddRatingMasterModal = () => {
    return (
      <AddRatingOrganizationMaster
        openModal={showAddRatingOrganizationModal}
        closeModal={handleCloseForAddModal}
        organizationTypes={getFormattedOrganizationNames()}
        availableRatings={ratings}
      />
    );
  };

  const renderDeleteConfirmationModal = () => {
    return (
      <DeleteModal
        primaryText="Delete Rating Organization Master ?"
        secondaryText="Are you sure you want to delete this Rating Organization Master ?"
        isOpen={isConfirmationDeleteModalOpen}
        handleClose={closeDeleteModal}
        confirmDelete={deleteRatingOrganizationMaster}
      />
    );
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div style={{ fontSize: "40px", float: "left" }}>
          Rating Organization Master
        </div>
        {renderAddButtonForRatingMaster()}
        {renderAddOrgNameButton()}
        {renderAddRatingButton()}
        {renderRatingMasterTable()}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={ratingOrganizationMasters.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {renderDeleteConfirmationModal()}
        {renderAddRatingMasterModal()}
        {renderRatingDialog()}
        {renderOrganizationNameDialog()}
      </Paper>
      {/* <IdleTimoutConfig /> */}
    </div>
  );
};

export default RatingOrganizationMaster;
