import React, { useEffect } from "react";
import { makeGETRequest } from "../../Api/api";
import { OpenIssue } from "./Interfaces";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { Grid, Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { openDebtIssuesDataGridCells, getIPOAPIBaseURL } from "./helpers";
import IPOChangePassword from "../../components/IPOChangePassword/IPOChangePassword";

const toastr = require("toastr");
export default function OpenDebtIssues(props: any) {
  const [openIssues, setOpenIssues] = React.useState<OpenIssue[]>([]);
  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    React.useState<boolean>(false);

  let baseUrl = getIPOAPIBaseURL();

  useEffect(() => {
    makeGETRequest(baseUrl + "/ipo/list/")
      .then((resp) => {
        setOpenIssues(resp.data);
      })
      .catch((err) => toastr.error(err));
  }, []);

  return (
    <Grid className="securityTypeDataGird">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>Open Issues</div>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="outlined"
                onClick={(event: any) => {
                  event.preventDefault();
                  setShowChangePasswordDialog(true);
                }}
                style={{
                  float: "right",
                  cursor: "pointer",
                }}
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ height: "100%", width: "100%" }}>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            rows={openIssues}
            columns={openDebtIssuesDataGridCells}
            getRowId={(row) => row.isin}
            autoHeight
          />
        </div>
      </Paper>
      {showChangePasswordDialog && (
        <IPOChangePassword
          show={showChangePasswordDialog}
          handleClose={(event: any) => {
            setShowChangePasswordDialog(false);
          }}
        />
      )}
    </Grid>
  );
}
