import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Button, TextField } from "@material-ui/core";
import GenericDialog from "../Dialog/GenericDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AllocationRatioTable(props: any) {
  const [showAddAllocationRatioDialog, setShowAllocationRatioDialog] =
    React.useState<boolean>(false);
  const initialState = {
    id: -1,
    category: "",
    percentage: "",
    allocation_amount: "",
  };
  const [allocationRatioInitialState, setAllocationRatioInitialState] =
    React.useState<any>(initialState);
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);

  function handleChange(event: any) {
    setAllocationRatioInitialState((previousValues: any) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  }

  function generateInputProps() {
    return (
      <Grid item xs={12} style={{ margin: "20px 0", padding: "0 20px" }}>
        <Grid item xs={12} style={{ margin: "20px 0", padding: "0 20px" }}>
          <TextField
            type="text"
            name="category"
            variant="outlined"
            fullWidth
            label="Category"
            value={allocationRatioInitialState.category}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} style={{ margin: "20px 0", padding: "0 20px" }}>
          <TextField
            type="text"
            name="percentage"
            variant="outlined"
            fullWidth
            label="Percentage"
            value={allocationRatioInitialState.percentage}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} style={{ margin: "20px 0", padding: "0 20px" }}>
          <TextField
            type="text"
            name="allocation_amount"
            variant="outlined"
            fullWidth
            label="Allocation Amount"
            value={allocationRatioInitialState.allocation_amount}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid className="holidayMastersDataGrid">
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>
                Allocation Ratio
              </div>
            </Grid>
            <Grid item xs="auto">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={(event) => {
                  setShowAllocationRatioDialog(true);
                }}
              >
                Add Allocation Ratio
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Serial</StyledTableCell>
              <StyledTableCell>Category </StyledTableCell>
              <StyledTableCell align="right">Percentage</StyledTableCell>
              <StyledTableCell align="right">Allocation Amount</StyledTableCell>
              <StyledTableCell align="right">Edit</StyledTableCell>
              <StyledTableCell align="right">Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.allocationRatio.length > 0 &&
              props.allocationRatio.map((row: any) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {row.id}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.category}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.percentage}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.allocation_amount}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {
                      <EditIcon
                        onClick={(
                          event: React.MouseEvent<SVGSVGElement, MouseEvent>
                        ) => {
                          event.preventDefault();
                          setAllocationRatioInitialState(row);
                          setShowAllocationRatioDialog(true);
                          setIsEditMode(true);
                        }}
                        color="primary"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    }
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {
                      <DeleteIcon
                        onClick={(
                          event: React.MouseEvent<SVGSVGElement, MouseEvent>
                        ) => {
                          event.preventDefault();
                          props.removeAllocationRatioRecord(row.id);
                        }}
                        color="primary"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    }
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showAddAllocationRatioDialog && (
        <GenericDialog
          show={showAddAllocationRatioDialog}
          heading="Create Allocation Ratio"
          inputProps={generateInputProps()}
          onClose={(event: React.MouseEvent) => {
            event.preventDefault();
            setShowAllocationRatioDialog(false);
          }}
          handleSubmit={(event: React.MouseEvent) => {
            if (isEditMode) {
              props.updateAllocationRatioRecord(allocationRatioInitialState);
              setIsEditMode(false);
            } else {
              props.addAllocationRatioRecord(allocationRatioInitialState);
            }
            setShowAllocationRatioDialog(false);
            setAllocationRatioInitialState(initialState);
          }}
          submitButtonText={isEditMode ? "Update" : "Create"}
          cancelButtonText="Discard"
        />
      )}
    </>
  );
}
