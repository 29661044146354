import React, { useRef } from "react";
import {
  makeGETRequestOnUserService,
  makePOSTRequestOnUserService,
} from "../../Api/api";
import toastr from "toastr";
import { Grid, Tooltip, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import UserCard from "./UserCard";
import List from "@mui/material/List";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import useForceUpdate from "use-force-update";
import WhatsappIcon from "../../images/whatsappIcon.png";
import WhatsappCampaignDialog from "../../components/Dialog/WhatsappCampaignDialog";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BoxTableHeader from "./BoxTableHeader.tsx";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GenericCategoryBox from "./GenericCategoryBox";
import SearchIcon from "@mui/icons-material/Search";
import GenericDialog from "../../components/Dialog/GenericDialog";
import AdminPanelUserDropdownComponent from "./AdminPanelUserDropdownComponent";
import IdleTimoutConfig from "../../Helpers/IdleTimeoutConfig";

const DateTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginLeft: 5,
  },
}))(Tooltip);

function getUpdatedCategorySelectAllStatus(campaignCategories) {
  let selectAllStatus = {};
  Object.keys(campaignCategories).map((categoryName) => {
    selectAllStatus[categoryName] = false;
  });
  return selectAllStatus;
}

function addNameAndIsChecked(users) {
  let formatted = { ...users };
  let updatedWithCompleteKYCs = [];
  let updatedAllUsers = [];
  let updatedWithoutKYC = [];
  let updatedWithPendingKYCs = [];

  users.withCompleteKYCs.map((user) => {
    user.name = `${user.firstName} ${user.lastName}`;
    user.isChecked = false;
    updatedWithCompleteKYCs.push(user);
  });

  users.allUsers.map((user) => {
    user.name = `${user.firstName} ${user.lastName}`;
    user.isChecked = false;
    updatedAllUsers.push(user);
  });

  users.usersWithoutKYCs.map((user) => {
    user.name = `${user.firstName} ${user.lastName}`;
    user.isChecked = false;
    updatedWithoutKYC.push(user);
  });

  users.withIncompleteKYCs.map((user) => {
    user.name = `${user.firstName} ${user.lastName}`;
    user.isChecked = false;
    updatedWithPendingKYCs.push(user);
  });

  formatted.allUsers = updatedAllUsers;
  formatted.usersWithoutKYCs = updatedWithoutKYC;
  formatted.withCompleteKYCs = updatedWithCompleteKYCs;

  return formatted;
}

function getCountForIndividualMessage(timeStamp, messages) {
  let counter = 0;
  for (var iter = 0; iter < messages.length; iter++) {
    let date1 = moment(messages[iter].insertedAt, "YYYY-MM-DD hh:mm:a").format(
      "MMMM DD, YYYY"
    );
    let date2 = moment(timeStamp, "YYYY-MM-DD hh:mm:a").format("MMMM DD, YYYY");
    if (date1 === date2) counter += 1;
  }
  return counter;
}

function getCountOnGivenDate(sentWhatsappMessages) {
  let counterList = [];
  sentWhatsappMessages.map((message) => {
    counterList.push(
      getCountForIndividualMessage(message.insertedAt, sentWhatsappMessages)
    );
  });
  return counterList;
}

function optionsForLineChart(dates) {
  return {
    chart: {
      height: 420,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "",
      align: "left",
    },
    grid: {
      row: {
        colors: ["#17d11d", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: dates,
    },
  };
}

function makeSeries(labels) {
  return {
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: false,
    },
    labels,
  };
}

function unCheckAll(users) {
  let formatted = { ...users };
  let updatedWithCompleteKYCs = [];
  let updatedAllUsers = [];
  let updatedWithoutKYC = [];
  let updatedWithPendingKYCs = [];

  users.withCompleteKYCs.map((user) => {
    user.isChecked = false;
    updatedWithCompleteKYCs.push(user);
  });

  users.allUsers.map((user) => {
    user.isChecked = false;
    updatedAllUsers.push(user);
  });

  users.usersWithoutKYCs.map((user) => {
    user.isChecked = false;
    updatedWithoutKYC.push(user);
  });

  users.withIncompleteKYCs.map((user) => {
    user.isChecked = false;
    updatedWithPendingKYCs.push(user);
  });

  formatted.allUsers = updatedAllUsers;
  formatted.usersWithoutKYCs = updatedWithoutKYC;
  formatted.withCompleteKYCs = updatedWithCompleteKYCs;

  return formatted;
}

function uncheckAllCustomCategories(campaignCategories) {
  let formatted = [];
  campaignCategories.map((category) => {
    category.isChecked = false;
    formatted.push(category);
  });
  return formatted;
}

export default function WhatsappCampaign(props) {
  const [users, setUsers] = React.useState({});
  const [showModalLoader, setShowModalLoader] = React.useState(false);
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const forceUpdate = useForceUpdate();
  const [showSelectMediaDialog, setShowSelectMediaDialog] =
    React.useState(false);
  const [message, setMessage] = React.useState("");
  const [selectedIndividualUser, setSelectedIndividualUser] =
    React.useState(null);
  const [eventType, setEventType] = React.useState(null);
  const [sentWhatsappMessages, setSentWhatsappMessages] = React.useState([]);
  const inputFile = useRef(null);
  const [uploadedExcelFile, setUploadedExcelFile] = React.useState(null);
  const [selectAllWithCompleteKYC, setSelectAllWithCompleteKYC] =
    React.useState(false);
  const [selectAllWithoutKYC, setSelectAllWithoutKYC] = React.useState(false);
  const [selectAllWithIncompleteKYC, setSelectAllWithIncompleteKYC] =
    React.useState(false);
  const [selectAllUsers, setSelectAllUsers] = React.useState(false);
  const [showCategoryNameInput, setShowCategoryNameInput] = React.useState("");
  const [campaignCategories, setCampaignCategories] = React.useState([]);
  const [selectAllStatusForCategories, setSelectAllStatusForCategories] =
    React.useState(null);
  const [selectedMobileNumbers, setSelectedMobileNumbers] = React.useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = React.useState("");
  const [showSearchDropdown, setShowSearchDropdown] = React.useState(false);

  React.useEffect(() => {
    let loginUser = JSON.parse(localStorage.getItem("loginUser"));
    makeGETRequestOnUserService(
      `/whatsapp_campaign/categories/list/${loginUser.uuid}/`
    )
      .then((response) => {
        if (response && response.status === 200) {
          setCampaignCategories(response.data);
          setSelectAllStatusForCategories(
            getUpdatedCategorySelectAllStatus(response.data)
          );
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  React.useEffect(() => {
    makeGETRequestOnUserService("/whatsapp_campaign/get_users/")
      .then((response) => {
        setUsers(addNameAndIsChecked(response.data));
        setHasLoaded(true);
      })
      .catch((error) => {
        toastr.error(error);
      });
  }, []);

  React.useEffect(() => {
    makeGETRequestOnUserService("/whatsapp_campaign/list/messages/")
      .then((response) => {
        if (response && response.status === 200) {
          setSentWhatsappMessages(response.data);
          forceUpdate();
        }
      })
      .catch((error) => {
        toastr.error(error);
      });
  }, []);

  function renderSkeletonComponent() {
    return (
      <Box sx={{ width: "560px" }}>
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Box>
    );
  }

  function sendWhatsappMessageToIndividualUser(
    message,
    media,
    loginUser,
    templateId,
    variableValues
  ) {
    let formData = new FormData();
    formData.append("message", message);
    if (media) formData.append("uploadedMedia", media);
    formData.append(
      "mobileNumbers",
      JSON.stringify([selectedIndividualUser.mobileNumber])
    );
    formData.append("sentBy", loginUser.name);
    formData.append("isSuperuser", loginUser.isSuperuser || false);
    formData.append("uuid", loginUser.uuid);
    formData.append("templateUUID", templateId);
    formData.append("variableValues", JSON.stringify(variableValues));

    makePOSTRequestOnUserService(
      "/whatsapp_campaign/send_bulk_message/",
      formData
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Message sent successfully !");
          setSelectedIndividualUser(null);
          setEventType("");
          setShowSelectMediaDialog(!showSelectMediaDialog);
        }
      })
      .catch((error) => toastr.error(error));
  }

  function sendMessageToCustomUsersFromCategory(
    message,
    media,
    loginUser,
    templateId,
    variableValues
  ) {
    let formData = new FormData();
    formData.append("message", message);
    if (media) formData.append("uploadedMedia", media);
    formData.append("mobileNumbers", selectedMobileNumbers);
    formData.append("sentBy", loginUser.name);
    formData.append("isSuperuser", loginUser.isSuperuser || false);
    formData.append("uuid", loginUser.uuid);
    formData.append("templateUUID", templateId);
    formData.append("variableValues", JSON.stringify(variableValues));

    makePOSTRequestOnUserService(
      "/whatsapp_campaign/send_bulk_message/",
      formData
    )
      .then((response) => {
        let currentState = campaignCategories;
        currentState[selectedCategoryName] = uncheckAllCustomCategories(
          campaignCategories[selectedCategoryName]
        );
        setCampaignCategories(currentState);
        setEventType("");
        setSelectedMobileNumbers([]);
        setShowSelectMediaDialog(!showSelectMediaDialog);
        toastr.success("Whatsapp message successfully sent!");
      })
      .catch((error) => toastr.error(error));
  }

  function sendMessageToUsersFromFile(
    message,
    media,
    loginUser,
    categoryName,
    templateId,
    variableValues
  ) {
    let formData = new FormData();
    formData.append("excelFile", uploadedExcelFile);
    formData.append("message", message);
    if (media) formData.append("uploadedMedia", media);
    formData.append("sentBy", loginUser.name);
    formData.append("isSuperuser", loginUser.isSuperuser || false);
    formData.append("uuid", loginUser.uuid);
    if (categoryName) formData.append("categoryName", categoryName);
    formData.append("templateUUID", templateId);
    formData.append("variableValues", JSON.stringify(variableValues));

    makePOSTRequestOnUserService("/whatsapp_campaign/send_from_file/", formData)
      .then((response) => {
        if (response && response.status === 200) {
          setEventType("");
          setShowSelectMediaDialog(!showSelectMediaDialog);
          setUploadedExcelFile(null);
          setShowCategoryNameInput(!showCategoryNameInput);
          toastr.success("Message sent successfully.");
          window.location.reload();
        }
      })
      .catch((error) => toastr.error(error));
  }

  function sendMessageToAllUserCategory(
    message,
    media,
    loginUser,
    templateId,
    variableValues
  ) {
    let filteredUsers = users.allUsers
      .filter((user) => user.isChecked)
      .map((user) => user.mobileNumber);
    let formData = new FormData();
    formData.append("message", message);
    if (media) formData.append("uploadedMedia", media);
    formData.append("mobileNumbers", JSON.stringify(filteredUsers));
    formData.append("sentBy", loginUser.name);
    formData.append("isSuperuser", loginUser.isSuperuser || false);
    formData.append("uuid", loginUser.uuid);
    formData.append("templateUUID", templateId);
    formData.append("variableValues", JSON.stringify(variableValues));

    makePOSTRequestOnUserService(
      "/whatsapp_campaign/send_bulk_message/",
      formData
    )
      .then((response) => {
        toastr.success("Message sent successfully !");
        setUsers(unCheckAll(users));
        setShowSelectMediaDialog(!showSelectMediaDialog);
        setEventType("");
        forceUpdate();
      })
      .catch((error) => toastr.error(error));
  }

  function sendMessageToUsersWithIncompleteKYC(
    message,
    media,
    loginUser,
    templateId,
    variableValues
  ) {
    let filteredUsers = users.withIncompleteKYCs
      .filter((user) => user.isChecked)
      .map((user) => user.mobileNumber);
    let formData = new FormData();
    formData.append("message", message);
    if (media) formData.append("uploadedMedia", media);
    formData.append("mobileNumbers", JSON.stringify(filteredUsers));
    formData.append("sentBy", loginUser.name);
    formData.append("isSuperuser", loginUser.isSuperuser || false);
    formData.append("uuid", loginUser.uuid);
    formData.append("templateUUID", templateId);
    formData.append("variableValues", JSON.stringify(variableValues));

    makePOSTRequestOnUserService(
      "/whatsapp_campaign/send_bulk_message/",
      formData
    )
      .then((response) => {
        toastr.success("Message sent successfully !");
        setUsers(unCheckAll(users));
        setShowSelectMediaDialog(!showSelectMediaDialog);
        setEventType("");
        forceUpdate();
      })
      .catch((error) => toastr.error(error));
  }

  function sendMessageToWithoutKYCUsers(
    message,
    media,
    loginUser,
    templateId,
    variableValues
  ) {
    let filteredUsers = users.usersWithoutKYCs
      .filter((user) => user.isChecked)
      .map((user) => user.mobileNumber);
    let formData = new FormData();
    formData.append("message", message);
    if (media) formData.append("uploadedMedia", media);
    formData.append("mobileNumbers", JSON.stringify(filteredUsers));
    formData.append("sentBy", loginUser.name);
    formData.append("isSuperuser", loginUser.isSuperuser || false);
    formData.append("uuid", loginUser.uuid);
    formData.append("templateUUID", templateId);
    formData.append("variableValues", JSON.stringify(variableValues));

    makePOSTRequestOnUserService(
      "/whatsapp_campaign/send_bulk_message/",
      formData
    )
      .then((response) => {
        toastr.success("Message sent successfully !");
        setUsers(unCheckAll(users));
        setShowSelectMediaDialog(!showSelectMediaDialog);
        setEventType("");
        forceUpdate();
      })
      .catch((error) => toastr.error(error));
  }

  function sendMessageWithCompleteKYCUsers(
    message,
    media,
    loginUser,
    templateId,
    variableValues
  ) {
    let filteredUsers = users.withCompleteKYCs
      .filter((user) => user.isChecked)
      .map((user) => user.mobileNumber);
    let formData = new FormData();
    formData.append("message", message);
    if (media) {
      formData.append("uploadedMedia", media);
    }
    formData.append("mobileNumbers", JSON.stringify(filteredUsers));
    formData.append("sentBy", loginUser.name);
    formData.append("isSuperuser", loginUser.isSuperuser || false);
    formData.append("uuid", loginUser.uuid);
    formData.append("templateUUID", templateId);
    formData.append("variableValues", JSON.stringify(variableValues));

    makePOSTRequestOnUserService(
      "/whatsapp_campaign/send_bulk_message/",
      formData
    )
      .then((response) => {
        toastr.success("Message sent successfully !");
        setUsers(unCheckAll(users));
        setShowSelectMediaDialog(!showSelectMediaDialog);
        setEventType("");
        forceUpdate();
      })
      .catch((error) => toastr.error(error));
  }

  function confirmSendMessage(
    event,
    message,
    media,
    categoryName = null,
    templateId,
    variableValues
  ) {
    event.preventDefault();
    setShowModalLoader(!showModalLoader);
    let loginUser = JSON.parse(localStorage.getItem("loginUser"));

    if (selectedIndividualUser) {
      sendWhatsappMessageToIndividualUser(
        message,
        media,
        loginUser,
        templateId,
        variableValues
      );
    } else {
      switch (eventType) {
        case "withCompleteKYC": {
          sendMessageWithCompleteKYCUsers(
            message,
            media,
            loginUser,
            templateId,
            variableValues
          );
          break;
        }

        case "withoutKYC": {
          sendMessageToWithoutKYCUsers(
            message,
            media,
            loginUser,
            templateId,
            variableValues
          );
          break;
        }

        case "withIncompleteKYCs": {
          sendMessageToUsersWithIncompleteKYC(
            message,
            media,
            loginUser,
            templateId,
            variableValues
          );
          break;
        }

        case "allUsers": {
          sendMessageToAllUserCategory(
            message,
            media,
            loginUser,
            templateId,
            variableValues
          );
          break;
        }

        case "excelFileUpload": {
          sendMessageToUsersFromFile(
            message,
            media,
            loginUser,
            categoryName,
            templateId,
            variableValues
          );
          break;
        }

        case "categoryUsers": {
          sendMessageToCustomUsersFromCategory(
            message,
            media,
            loginUser,
            templateId,
            variableValues
          );
          break;
        }

        default: {
          toastr.error("Invalid Case !");
          break;
        }
      }
    }
  }

  function handleCheckBoxSelectionWithoutKYC(event, selectedElement) {
    event.preventDefault();
    let currentState = users;
    let usersWithoutKYCs = [];
    users.usersWithoutKYCs.map((user) => {
      if (user.mobileNumber === selectedElement.mobileNumber) {
        user.isChecked = !user.isChecked;
        usersWithoutKYCs.push(user);
      } else usersWithoutKYCs.push(user);
    });
    currentState.usersWithoutKYCs = usersWithoutKYCs;
    setUsers(currentState);
    forceUpdate();
  }

  function selectIndividualUser(event, currentlySelected) {
    event.preventDefault();
    setSelectedIndividualUser(currentlySelected);
    setShowSelectMediaDialog(!showSelectMediaDialog);
  }

  function handleCheckBoxSelectionForUsersWithIncompleteKYC(
    event,
    selectedElement
  ) {
    event.preventDefault();
    let currentState = users;
    let updatedWithPendingKYCs = [];
    users.withIncompleteKYCs.map((user) => {
      if (user.mobileNumber === selectedElement.mobileNumber) {
        user.isChecked = !user.isChecked;
        updatedWithPendingKYCs.push(user);
      } else updatedWithPendingKYCs.push(user);
    });
    currentState.withIncompleteKYCs = updatedWithPendingKYCs;
    setUsers(currentState);
    forceUpdate();
  }

  function handleCheckBoxSelectionForAllUsers(event, selectedElement) {
    event.preventDefault();
    let currentState = users;
    let updatedAllUsers = [];
    users.allUsers.map((user) => {
      if (user.mobileNumber === selectedElement.mobileNumber) {
        user.isChecked = !user.isChecked;
        updatedAllUsers.push(user);
      } else updatedAllUsers.push(user);
    });
    currentState.allUsers = updatedAllUsers;
    setUsers(currentState);
    forceUpdate();
  }

  function handleCheckBoxSelectionWithCompleteKYC(event, selectedElement) {
    event.preventDefault();

    let currentState = users;
    let updatedWithCompleteKYCs = [];
    users.withCompleteKYCs.map((user) => {
      if (user.mobileNumber === selectedElement.mobileNumber) {
        user.isChecked = !user.isChecked;
        updatedWithCompleteKYCs.push(user);
      } else updatedWithCompleteKYCs.push(user);
    });
    currentState.withCompleteKYCs = updatedWithCompleteKYCs;
    setUsers(currentState);
    forceUpdate();
  }

  function sendWhatsappMessageToAllUsersCategory(event) {
    event.preventDefault();
    let filteredUsers = users.allUsers.filter((user) => user.isChecked);

    if (filteredUsers.length === 0) {
      toastr.warning(
        "You need to select at least one user in order to use this feature."
      );
    } else {
      setEventType("allUsers");
      setShowSelectMediaDialog(!showSelectMediaDialog);
    }
  }

  function sendWhatsappMessageToUsersWithIncompleteKYC(event) {
    event.preventDefault();
    let filteredUsers = users.withIncompleteKYCs.filter(
      (user) => user.isChecked
    );
    if (filteredUsers.length === 0) {
      toastr.warning(
        "You need to select at least one user in order to use this feature."
      );
    } else {
      setEventType("withIncompleteKYCs");
      setShowSelectMediaDialog(!showSelectMediaDialog);
    }
  }

  function sendMessageToUsersWithoutKYC(event) {
    event.preventDefault();
    let filteredUsers = users.usersWithoutKYCs.filter((user) => user.isChecked);
    if (filteredUsers.length === 0) {
      toastr.warning(
        "You need to select at least one user in order to use this feature."
      );
    } else {
      setEventType("withoutKYC");
      setShowSelectMediaDialog(!showSelectMediaDialog);
    }
  }

  function sendWhatsappMessageToSelectedUsersWithompleteKYC(event) {
    event.preventDefault();
    let filteredUsers = users.withCompleteKYCs.filter((user) => user.isChecked);

    if (filteredUsers.length === 0) {
      toastr.warning(
        "You need to select at least one user in order to use this feature."
      );
    } else {
      setEventType("withCompleteKYC");
      setShowSelectMediaDialog(!showSelectMediaDialog);
    }
  }

  function onSelectAllUsers(event) {
    event.preventDefault();
    setSelectAllUsers(!selectAllUsers);
    let currentState = users;
    let updatedAllUsers = [];
    users.allUsers.map((user) => {
      user.isChecked = !user.isChecked;
      updatedAllUsers.push(user);
    });
    currentState.allUsers = updatedAllUsers;
    setUsers(currentState);
  }

  function onSelectAllWithIncompleteKYC(event) {
    event.preventDefault();
    setSelectAllWithIncompleteKYC(!selectAllWithIncompleteKYC);
    let currentState = users;
    let updatedWithIncompleteKYCs = [];
    users.withIncompleteKYCs.map((user) => {
      user.isChecked = !user.isChecked;
      updatedWithIncompleteKYCs.push(user);
    });
    currentState.withIncompleteKYCs = updatedWithIncompleteKYCs;
    setUsers(currentState);
  }

  function onSelectAllWithoutKYC(event) {
    event.preventDefault();
    setSelectAllWithoutKYC(!selectAllWithoutKYC);
    let currentState = users;
    let updatedWithoutKYCs = [];
    users.usersWithoutKYCs.map((user) => {
      user.isChecked = !user.isChecked;
      updatedWithoutKYCs.push(user);
    });
    currentState.usersWithoutKYCs = updatedWithoutKYCs;
    setUsers(currentState);
  }

  function onSelectAllWithCompleteKYC(event) {
    event.preventDefault();
    setSelectAllWithCompleteKYC(!selectAllWithCompleteKYC);
    let currentState = users;
    let updatedWithCompleteKYCs = [];
    users.withCompleteKYCs.map((user) => {
      user.isChecked = !user.isChecked;
      updatedWithCompleteKYCs.push(user);
    });
    currentState.withCompleteKYCs = updatedWithCompleteKYCs;
    setUsers(currentState);
  }

  function renderWithCompleteKYC() {
    return (
      <Grid className="dashCard">
        <DateTip
          placement="right"
          interactive
          title="Users with Complete KYCs"
          enterTouchDelay="10"
        >
          <Grid className="cardValue">
            <Grid container className="">
              <Typography variant="h6" noWrap>
                With Complete KYCs
              </Typography>
              <DateTip
                placement="right"
                interactive
                title="Send whatsapp message to selected users."
                enterTouchDelay="10"
              >
                <Button
                  onClick={sendWhatsappMessageToSelectedUsersWithompleteKYC}
                >
                  <img src={WhatsappIcon} width="28px" height="28px" />
                </Button>
              </DateTip>
            </Grid>
            <Grid className="recentTransactions">
              <Grid className="gridComTabel tableHade">
                <BoxTableHeader
                  checked={selectAllWithCompleteKYC}
                  onSelectAll={onSelectAllWithCompleteKYC}
                />
                <List
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                    margin: "5px 0",
                    overflow: "auto",
                    maxHeight: 400,
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  <Grid className="corpCompMainContainer">
                    <Grid container style={{ height: "100%" }}>
                      <Grid item xs>
                        <div className="tradeTableContainer">
                          <>
                            <Grid className="recentTransactions">
                              <Grid className="gridComTabel tableHade"></Grid>
                              <Grid
                                className="recentTransactionsScroll"
                                id="scrollableDiv"
                              >
                                {hasLoaded
                                  ? users.withCompleteKYCs.map((row, index) => {
                                      return (
                                        <UserCard
                                          row={row}
                                          index={index}
                                          handleCheckBoxSelection={
                                            handleCheckBoxSelectionWithCompleteKYC
                                          }
                                          selectIndividualUser={
                                            selectIndividualUser
                                          }
                                        />
                                      );
                                    })
                                  : renderSkeletonComponent()}
                              </Grid>
                            </Grid>
                          </>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </DateTip>
      </Grid>
    );
  }

  function renderAllUsers() {
    return (
      <Grid className="dashCard">
        <DateTip
          placement="right"
          interactive
          title="All Users."
          enterTouchDelay="10"
        >
          <Grid className="cardValue">
            <Grid container className="">
              <Typography variant="h6" noWrap>
                All Users
              </Typography>
              <DateTip
                placement="right"
                interactive
                title="Send whatsapp message to selected users."
                enterTouchDelay="10"
              >
                <Button onClick={sendWhatsappMessageToAllUsersCategory}>
                  <img src={WhatsappIcon} width="28px" height="28px" />
                </Button>
              </DateTip>
            </Grid>
            <Grid className="recentTransactions">
              <Grid className="gridComTabel tableHade">
                <BoxTableHeader
                  checked={selectAllUsers}
                  onSelectAll={onSelectAllUsers}
                />
                <List
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                    margin: "5px 0",
                    overflow: "auto",
                    maxHeight: 400,
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  <Grid className="corpCompMainContainer">
                    <Grid container style={{ height: "100%" }}>
                      <Grid item xs>
                        <div className="tradeTableContainer">
                          <>
                            <Grid className="recentTransactions">
                              <Grid className="gridComTabel tableHade"></Grid>
                              <Grid
                                className="recentTransactionsScroll"
                                id="scrollableDiv"
                              >
                                {hasLoaded
                                  ? users.allUsers.map((row, index) => {
                                      return (
                                        <UserCard
                                          row={row}
                                          index={index}
                                          handleCheckBoxSelection={
                                            handleCheckBoxSelectionForAllUsers
                                          }
                                          selectIndividualUser={
                                            selectIndividualUser
                                          }
                                        />
                                      );
                                    })
                                  : renderSkeletonComponent()}
                              </Grid>
                            </Grid>
                          </>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </DateTip>
      </Grid>
    );
  }

  function renderWithIncompleteKYC() {
    return (
      <Grid className="dashCard">
        <DateTip
          placement="right"
          interactive
          title="Users who haven't started their KYC yet."
          enterTouchDelay="10"
        >
          <Grid className="cardValue">
            <Grid container className="">
              <Typography variant="h6" noWrap>
                Users with Incomplete KYC
              </Typography>
              <DateTip
                placement="right"
                interactive
                title="Send whatsapp message to selected users."
                enterTouchDelay="10"
              >
                <Button onClick={sendWhatsappMessageToUsersWithIncompleteKYC}>
                  <img src={WhatsappIcon} width="28px" height="28px" />
                </Button>
              </DateTip>
            </Grid>
            <Grid className="recentTransactions">
              <Grid className="gridComTabel tableHade">
                <BoxTableHeader
                  checked={selectAllWithIncompleteKYC}
                  onSelectAll={onSelectAllWithIncompleteKYC}
                />
                <List
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                    margin: "5px 0",
                    overflow: "auto",
                    maxHeight: 400,
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  <Grid className="corpCompMainContainer">
                    <Grid container style={{ height: "100%" }}>
                      <Grid item xs>
                        <div className="tradeTableContainer">
                          <>
                            <Grid className="recentTransactions">
                              <Grid className="gridComTabel tableHade"></Grid>
                              <Grid
                                className="recentTransactionsScroll"
                                id="scrollableDiv"
                              >
                                {hasLoaded
                                  ? users.withIncompleteKYCs.map(
                                      (row, index) => {
                                        return (
                                          <UserCard
                                            row={row}
                                            index={index}
                                            handleCheckBoxSelection={
                                              handleCheckBoxSelectionForUsersWithIncompleteKYC
                                            }
                                            selectIndividualUser={
                                              selectIndividualUser
                                            }
                                          />
                                        );
                                      }
                                    )
                                  : renderSkeletonComponent()}
                              </Grid>
                            </Grid>
                          </>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </DateTip>
      </Grid>
    );
  }

  function renderWithoutKYC() {
    return (
      <Grid className="dashCard">
        <DateTip
          placement="right"
          interactive
          title="Users who haven't started their KYC yet."
          enterTouchDelay="10"
        >
          <Grid className="cardValue">
            <Grid container className="">
              <Typography variant="h6" noWrap>
                Without KYC
              </Typography>
              <DateTip
                placement="right"
                interactive
                title="Send whatsapp message to selected users."
                enterTouchDelay="10"
              >
                <Button onClick={sendMessageToUsersWithoutKYC}>
                  <img src={WhatsappIcon} width="28px" height="28px" />
                </Button>
              </DateTip>
            </Grid>
            <Grid className="recentTransactions">
              <Grid className="gridComTabel tableHade">
                <BoxTableHeader
                  checked={selectAllWithoutKYC}
                  onSelectAll={onSelectAllWithoutKYC}
                />
                <List
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    bgcolor: "background.paper",
                    position: "relative",
                    margin: "5px 0",
                    overflow: "auto",
                    maxHeight: 400,
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  <Grid className="corpCompMainContainer">
                    <Grid container style={{ height: "100%" }}>
                      <Grid item xs>
                        <div className="tradeTableContainer">
                          <>
                            <Grid className="recentTransactions">
                              <Grid className="gridComTabel tableHade"></Grid>
                              <Grid
                                className="recentTransactionsScroll"
                                id="scrollableDiv"
                              >
                                {hasLoaded
                                  ? users.usersWithoutKYCs.map((row, index) => {
                                      return (
                                        <UserCard
                                          row={row}
                                          index={index}
                                          handleCheckBoxSelection={
                                            handleCheckBoxSelectionWithoutKYC
                                          }
                                          selectIndividualUser={
                                            selectIndividualUser
                                          }
                                        />
                                      );
                                    })
                                  : renderSkeletonComponent()}
                              </Grid>
                            </Grid>
                          </>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </DateTip>
      </Grid>
    );
  }

  function renderHeatMap() {
    return (
      <ReactApexChart
        options={optionsForLineChart(
          sentWhatsappMessages.map((message) =>
            moment(message.insertedAt, "YYYY-MM-DD hh:mm:a").format(
              "MMMM DD, YYYY"
            )
          )
        )}
        series={[
          {
            name: "Message Count",
            data: getCountOnGivenDate(sentWhatsappMessages),
          },
        ]}
        type="line"
        height={420}
      />
    );
  }

  function onFileUpload(event) {
    setUploadedExcelFile(event.target.files[0]);
    setShowSelectMediaDialog(!showSelectMediaDialog);
    setEventType("excelFileUpload");
    setShowCategoryNameInput(!showCategoryNameInput);
  }

  function handleCheckBoxSelectionForCustomCategories(
    event,
    category,
    categoryName
  ) {
    event.preventDefault();
    let currentState = campaignCategories;
    let updatedCategories = [];
    currentState[categoryName].map((c) => {
      if (c.uuid === category.categoryId) {
        c.isChecked = !c.isChecked;
        updatedCategories.push(c);
      } else updatedCategories.push(c);
    });
    currentState[categoryName] = updatedCategories;
    setCampaignCategories(currentState);
    forceUpdate();
  }

  function onSelectAllWithCurrentCategory(event, categoryName) {
    event.preventDefault();
    let currentState = campaignCategories;
    let updatedCategories = [];
    currentState[categoryName].map((category) => {
      category.isChecked = !category.isChecked;
      updatedCategories.push(category);
    });
    currentState[categoryName] = updatedCategories;
    setCampaignCategories(currentState);
    let currentSelectAllState = selectAllStatusForCategories;
    currentSelectAllState[categoryName] = !currentSelectAllState[categoryName];
    setSelectAllStatusForCategories(currentSelectAllState);
    forceUpdate();
  }

  function sendMessageToUserFromCustomCategory(event, category, categoryName) {
    event.preventDefault();
    setSelectedIndividualUser(category);
    setShowSelectMediaDialog(!showSelectMediaDialog);
  }

  function sendMessageToSelectedUsers(event, categoryName) {
    event.preventDefault();
    let selectedMobileNumbers = campaignCategories[categoryName]
      .filter((categoryObject) => categoryObject.isChecked)
      .map((categoryObject) => categoryObject.whatsappMessage.mobileNumber);
    if (selectedMobileNumbers.length === 0) {
      toastr.warning(
        "You need to select at least one user in order to use this feature."
      );
    } else {
      setSelectedCategoryName(categoryName);
      setSelectedMobileNumbers(selectedMobileNumbers);
      setEventType("categoryUsers");
      setShowSelectMediaDialog(!showSelectMediaDialog);
    }
  }

  function updatedCategoryName(event, newCategoryName, oldCategoryName) {
    event.preventDefault();
    let currentState = campaignCategories;
    currentState[newCategoryName] = currentState[oldCategoryName];
    delete currentState[oldCategoryName];
    setCampaignCategories(currentState);
    forceUpdate();
  }

  function renderCampaignCategoryCards() {
    if (
      campaignCategories &&
      selectAllStatusForCategories &&
      Object.keys(campaignCategories).length > 0
    ) {
      return Object.keys(campaignCategories).map((categoryName) => (
        <Grid item md={12} xs={12} style={{ maxWidth: "600px" }}>
          <GenericCategoryBox
            categories={campaignCategories[categoryName]}
            categoryName={categoryName}
            handleCheckBoxSelection={handleCheckBoxSelectionForCustomCategories}
            onSelectAllWithCurrentCategory={onSelectAllWithCurrentCategory}
            areAllChecked={selectAllStatusForCategories[categoryName]}
            selectIndividualUser={sendMessageToUserFromCustomCategory}
            sendMessageToSelectedUsers={sendMessageToSelectedUsers}
            updatedCategoryName={updatedCategoryName}
          />
        </Grid>
      ));
    }
  }

  return (
    <Grid className="QuotesDataGrid">
      <Paper>
        <Grid style={{ margin: "20px 0" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>
                Whatsapp Campaign
              </div>
            </Grid>

            <Button
              style={{
                float: "right",
                margin: "25px 10px 15px",
                cursor: "pointer",
              }}
              startIcon={<SearchIcon />}
              variant="contained"
              color="success"
              onClick={(event) => {
                event.preventDefault();
                setShowSearchDropdown(!showSearchDropdown);
              }}
            >
              Search Categories
            </Button>
            <Grid item xs>
              <Button
                style={{
                  float: "right",
                  margin: "25px 10px 15px",
                  cursor: "pointer",
                }}
                startIcon={<VisibilityIcon />}
                variant="contained"
                color="success"
                onClick={(event) => {
                  event.preventDefault();
                  props.history.push("whatsapp_messages/all");
                }}
              >
                Show All Messages
              </Button>
              <Button
                style={{
                  float: "right",
                  margin: "25px 10px 15px",
                  cursor: "pointer",
                }}
                startIcon={<CloudUploadIcon />}
                variant="contained"
                color="success"
                onClick={(event) => {
                  event.preventDefault();
                  document.getElementById("excelFileUpload").click();
                }}
              >
                Upload Excel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Grid className="">
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                {/* 
                TODO: Commenting for now. I think this is causing the page to be slow but maybe this contributes around 40% to the problem
                <Grid className="portfolioAllocation">
                  {
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        {
                          <>
                            <Grid className="portfolioAllocationValue">
                              <Typography className="chartTitle">
                                KYC Stats
                              </Typography>
                              {hasLoaded && (
                                <ReactApexChart
                                  options={makeSeries([
                                    "With Incomplete KYC",
                                    "With Complete KYC",
                                    "Without KYC",
                                  ])}
                                  series={[
                                    users.withIncompleteKYCs.length,
                                    users.withCompleteKYCs.length,
                                    users.usersWithoutKYCs.length,
                                  ]}
                                  type="pie"
                                  width="100%"
                                />
                              )}
                            </Grid>
                          </>
                        }
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Grid className="portfolioAllocationValue">
                          <Grid
                            item
                            md="12"
                            xs="12"
                            className="chartCompSection"
                          >
                            <Grid container>
                              <Grid item xs>
                                <Typography className="chartTitle">
                                  Sent Messages
                                </Typography>
                              </Grid>
                            </Grid>
                            {hasLoaded && renderHeatMap()}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid item md={12} xs={12}>
          <Grid container style={{ gap: 50 }}>
            <Grid item md={12} xs={12} style={{ maxWidth: "600px" }}>
              {renderWithCompleteKYC()}
            </Grid>
            <Grid item md={12} xs={12} style={{ maxWidth: "600px" }}>
              {renderWithoutKYC()}
            </Grid>
            <Grid item md={12} xs={12} style={{ maxWidth: "600px" }}>
              {renderWithIncompleteKYC()}
            </Grid>
            <Grid item md={12} xs={12} style={{ maxWidth: "600px" }}>
              {renderAllUsers()}
            </Grid>
            {renderCampaignCategoryCards()}
          </Grid>
          {showSelectMediaDialog ? (
            <WhatsappCampaignDialog
              open={showSelectMediaDialog}
              handleClose={(event) => {
                event.preventDefault();
                setShowSelectMediaDialog(!showSelectMediaDialog);
              }}
              handleSubmit={confirmSendMessage}
              showModalLoader={showModalLoader}
              showCategoryNameInput={showCategoryNameInput}
            />
          ) : (
            ""
          )}
          {showSearchDropdown ? (
            <GenericDialog
              show={showSearchDropdown}
              heading="Select Admin/Superuser"
              inputProps={<AdminPanelUserDropdownComponent />}
              onClose={(event) => {
                event.preventDefault();
                setShowSearchDropdown(!showSearchDropdown);
              }}
              handleSubmit={(event) => {}}
            />
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <input
        type="file"
        id="excelFileUpload"
        // value={inputFile}
        onChange={onFileUpload}
        style={{ display: "none" }}
      />
      {/* <IdleTimoutConfig /> */}
    </Grid>
  );
}
