import React, { useContext } from "react";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { Grid } from "@material-ui/core";
import dataGridCells from "./DataGridUtils";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { makeGETRequestOnUserService } from "../../Api/api";
import GenericDialog from "../../components/Dialog/GenericDialog";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import IdleTimoutConfig from "../../Helpers/IdleTimeoutConfig";

function getFilteredRows(rows, startDate, endDate) {
  let filtered = [];
  let counter = 1;

  rows.map((row) => {
    let convertedToDate = new Date(row.date);
    if (startDate <= convertedToDate && convertedToDate <= endDate) {
      let updatedObject = { ...row };
      updatedObject.id = counter;
      filtered.push(updatedObject);
      counter++;
    }
  });
  return filtered;
}

export default function LoyaltyPointsHistory(props) {
  const [rows, setRows] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [showDateRangeDialog, setShowDateRangeDialog] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [filterApplied, setFilterApplied] = React.useState(false);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [originalRows, setOriginalRows] = React.useState([]);

  React.useEffect(() => {
    makeGETRequestOnUserService("/loyalty_point/list")
      .then((response) => {
        if (response && response.status === 200) {
          setRows(response.data);
          setOriginalRows(response.data);
          setIsLoaded(true);
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  function handleDateRangeIconOnClick(event) {
    event.preventDefault();
    setShowDateRangeDialog(true);
  }

  function onCloseGenericDialog(event) {
    event.preventDefault();
    setStartDate(new Date());
    setEndDate(new Date());
    setShowDateRangeDialog(false);
  }

  function applyFilter(event) {
    event.preventDefault();
    if (startDate > endDate) {
      toastr.error("Start Date cannot be greater than End Date");
    } else {
      setFilterApplied(true);
      setFilteredRows(getFilteredRows(rows, startDate, endDate));
      setShowDateRangeDialog(false);
      setStartDate(new Date());
      setEndDate(new Date());
    }
  }

  function generateInputProps() {
    return (
      <Grid container item xs={12} spacing={1}>
        <React.Fragment>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item justify="space-around" xs={8}>
                <KeyboardDatePicker
                  margin="normal"
                  id="startdate-picker-dialog"
                  label="Start Date"
                  format="dd/MM/yyyy"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item justify="space-around" xs={8}>
                <KeyboardDatePicker
                  margin="normal"
                  id="enddate-picker-dialog"
                  label="End Date"
                  format="dd/MM/yyyy"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </React.Fragment>
      </Grid>
    );
  }

  function clearFilters(event) {
    event.preventDefault();
    setRows(originalRows);
    setFilterApplied(false);
    setFilteredRows([]);
  }

  function renderDataGrid() {
    return (
      <Grid className="securityMasterDataGird">
        <Paper>
          <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs>
                <div style={{ fontSize: "36px", float: "left" }}>
                  Loyalty Point History
                </div>
              </Grid>
              <Stack direction="row" spacing={1}>
                <DateRangeIcon
                  onClick={handleDateRangeIconOnClick}
                  color="#00008B"
                  fontSize="large"
                  style={{
                    float: "right",
                    "margin-top": "29px",
                    "margin-right": "30px",
                    cursor: "pointer",
                  }}
                />
                {filterApplied ? (
                  <Chip
                    label="Clear Filter"
                    variant="outlined"
                    style={{
                      float: "right",
                      "margin-top": "29px",
                      "margin-right": "30px",
                      cursor: "pointer",
                    }}
                    onDelete={clearFilters}
                  />
                ) : (
                  ""
                )}
              </Stack>
              <div style={{ height: "100vh", width: "100%" }}>
                <DataGrid
                  className="securityTypeDataGrid abc"
                  components={{ Toolbar: GridToolbar }}
                  rows={filterApplied ? filteredRows : rows}
                  columns={dataGridCells}
                  getRowId={(row) => row.id}
                  pagination
                />
              </div>
            </Grid>
          </Grid>
        </Paper>
        {showDateRangeDialog ? (
          <GenericDialog
            show={showDateRangeDialog}
            heading="Date wise filter"
            inputProps={generateInputProps()}
            onClose={onCloseGenericDialog}
            handleSubmit={applyFilter}
          />
        ) : (
          ""
        )}
        {/* <IdleTimoutConfig> </IdleTimoutConfig> */}
      </Grid>
    );
  }

  function renderSkeleton() {
    return (
      <Box
        sx={{ width: 900 }}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Box>
    );
  }

  if (isLoaded) {
    return renderDataGrid();
  } else {
    return renderSkeleton();
  }
}
