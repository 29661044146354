import React from "react";
// import { useIdleTimer } from "react-idle-timer";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import { makePUTRequestOnAuthService } from "../Api/api";
import SnackbarComponent from "../components/Snackbar/GenericSnackbar";

function IdleTimeoutConfig(props) {
  //   let timeoutValue = 7200000;
  //   const [showSnackbar, setShowSnackbar] = React.useState(false);
  //   const [remainingTime, setRemainingTime] = React.useState(timeoutValue);
  //
  //   const onIdle = () => {
  //     toastr.warning("You have been logged out due to inactivity!");
  //     localStorage.clear();
  //     props.history.push("/");
  //   };
  //
  //   const onAction = (event) => {
  //     console.log("On Action", event);
  //     renewToken();
  //   };
  //
  //   const onActive = (event) => {
  //     console.log("On Active", event);
  //     renewToken();
  //   };
  //
  //   function renewToken(event) {
  //     if (event) event.preventDefault();
  //     let currentUser = JSON.parse(localStorage.getItem("loginUser"));
  //     makePUTRequestOnAuthService("/auth/renew_token/", {
  //       accessToken: localStorage.getItem("accessToken"),
  //       mobileNumber: currentUser.mobileNumber,
  //       platform: window.location.origin,
  //     })
  //       .then((response) => {
  //         toastr.success("Auth token renewed successfully!");
  //         setShowSnackbar(false);
  //         reset();
  //       })
  //       .catch((error) => toastr.error(error));
  //   }
  //
  //   React.useEffect(() => {
  //     const interval = setInterval(() => {
  //       if (getRemainingTime() <= Math.ceil(timeoutValue / 2)) {
  //         setShowSnackbar(!showSnackbar);
  //       } else if (getRemainingTime() === 0) {
  //         onIdle();
  //       }
  //       setRemainingTime(getRemainingTime());
  //     }, 1000);
  //   }, []);
  //
  //   const {
  //     start,
  //     reset,
  //     pause,
  //     resume,
  //     isIdle,
  //     isPrompted,
  //     getRemainingTime,
  //     getElapsedTime,
  //     getLastIdleTime,
  //     getLastActiveTime,
  //     getTotalIdleTime,
  //     getTotalActiveTime,
  //   } = useIdleTimer({
  //     onIdle,
  //     timeout: timeoutValue, // 30 mins
  //     promptTimeout: 0,
  //     events: [
  //       "mousemove",
  //       "keydown",
  //       "wheel",
  //       "DOMMouseScroll",
  //       "mousewheel",
  //       "mousedown",
  //       "touchstart",
  //       "touchmove",
  //       "MSPointerDown",
  //       "MSPointerMove",
  //       "visibilitychange",
  //     ],
  //     immediateEvents: [],
  //     debounce: 0,
  //     throttle: 0,
  //     eventsThrottle: 200,
  //     element: document,
  //     startOnMount: true,
  //     startManually: false,
  //     stopOnIdle: false,
  //     crossTab: false,
  //     syncTimers: 0,
  //   });
  //
  //   if (showSnackbar) {
  //     return (
  //       <>
  //         <SnackbarComponent
  //           buttonText="Renew Token"
  //           handleClose={(event) => {
  //             if (event) event.preventDefault();
  //             setShowSnackbar(false);
  //           }}
  //           snackbarMessage={`You are about to be logged out in ${Math.floor(
  //             (remainingTime / 1000) % 60
  //           )} seconds.`}
  //           open={showSnackbar}
  //           handleButtonOnClick={renewToken}
  //         />
  //       </>
  //     );
  //   } else return <> </>;
  return "";
}

export default withRouter(IdleTimeoutConfig);
