import * as React from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";

const columns = [
  { field: "serial", headerName: "Serial", width: 150, editable: false },
  { field: "name", headerName: "Name", width: 190, editable: false },
  {
    field: "mobileNumber",
    headerName: "Mobile Number",
    width: 190,
    editable: false,
  },
  {
    field: "loggedInAt",
    headerName: "Logged In At",
    width: 190,
    editable: false,
  },
];

export default function LoggedInUsers(props: any) {
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={props.loggedInUsers}
        columns={columns}
        pageSize={5}
        components={{ Toolbar: GridToolbar }}
        rowsPerPageOptions={[5]}
        getRowId={(row) => row.uuid}
      />
    </div>
  );
}
