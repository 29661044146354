import React, { useEffect } from "react";
import { makePOSTRequest } from "../../Api/api";
import { categoriesDemandDataGridCells, getIPOAPIBaseURL } from "./helpers";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { CategoriesDemand } from "./Interfaces";
import { addSerialNumber } from "../../Helpers/SecurityMasterHelpers";

const toastr = require("toastr");

export default function GetCategoriesDemand() {
  let baseUrl = getIPOAPIBaseURL();

  const [categoriesDemand, setCategoriesDemand] = React.useState<
    CategoriesDemand[]
  >([]);

  useEffect(() => {
    makePOSTRequest(baseUrl + "/ipo/demandscheduleipoapiview_ipo/", {
      symbol: "MMFSLNCD1",
      issuetype: "DBT",
    })
      .then((response) => {
        setCategoriesDemand(addSerialNumber(response.data));
      })
      .catch((error) => toastr.error(error));
  }, []);

  return (
    <Grid className="securityTypeDataGird">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>
                Categories Demand
              </div>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ height: "100%", width: "100%" }}>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            rows={categoriesDemand}
            columns={categoriesDemandDataGridCells}
            getRowId={(row) => row.serial}
            autoHeight
          />
        </div>
      </Paper>
    </Grid>
  );
}
