import moment from "moment";

export function getIPOAPIBaseURL() {
  let baseUrl = "http://localhost:8000";
  if (process.env.REACT_APP_ENVIRONMENT === "staging") {
    baseUrl = "https://staging-admin-api.smestbonds.com";
  } else if (process.env.REACT_APP_ENVIRONMENT === "prod") {
    baseUrl = "https://admin-api.smestbonds.com";
  }

  return baseUrl;
}

export const ipoBidDataCells = [
  { field: "symbol", headerName: "Symbol", width: 190, editable: false },
  { field: "series", headerName: "Series", width: 150, editable: false },
  { field: "price", headerName: "Price", width: 150, editable: false },
  { field: "qty", headerName: "Qty", width: 150, editable: false },
  { field: "flag", headerName: "Flag", width: 140, editable: false },
  { field: "issuetype", headerName: "Issue Type", width: 150, editable: false },
  { field: "errorcode", headerName: "Error Code", width: 150, editable: false },
  { field: "message", headerName: "Message", width: 200, editable: false },
];

export const ipoOrdersDataGridCells = [
  {
    field: "insertedAt",
    headerName: "Date",
    width: 200,
    renderCell: (params: any) => {
      const rowData = params.row;
      return moment(rowData.insertedAt).format("(HH[H]:mm[M]) MMM DD, YYYY");
    },
  },
  { field: "scripid", headerName: "ScripID", width: 150 },
  {
    field: "issuer_name",
    headerName: "Issuer of Security",
    width: 200,
  },
  { field: "chequeamount", headerName: "Investment Amount", width: 150 },
  { field: "applicantname", headerName: "Applicant Name", width: 200 },
  { field: "allotmentstatus", headerName: "Status", width: 150 },
];

export const issueTimeDataGridCells = [
  { field: "scripid", headerName: "Scrip ID", width: 150 },
  { field: "category", headerName: "Category", width: 140 },
  { field: "series", headerName: "Series", width: 120 },
  { field: "type", headerName: "Type", width: 120 },
  { field: "opendate", headerName: "Open Date", width: 150 },
  { field: "opentime", headerName: "Open Time", width: 150 },
  { field: "closedate", headerName: "Close Date", width: 150 },
  { field: "closetime", headerName: "Close Time", width: 150 },
  { field: "message", headerName: "Message", width: 150 },
];

export const categoriesDemandDataGridCells = [
  { field: "symbol", headerName: "Symbol", width: 190, editable: false },
  {
    field: "totalapplication",
    headerName: "Total Application",
    width: 190,
    editable: false,
  },
  { field: "category", headerName: "Category", width: 190, editable: false },
  {
    field: "subcategory",
    headerName: "Sub Category",
    width: 190,
    editable: false,
  },
  { field: "type", headerName: "Type", width: 190, editable: false },
  { field: "qty", headerName: "Qty", width: 190, editable: false },
  { field: "issuetype", headerName: "Issue Type", width: 190, editable: false },
  { field: "errorcode", headerName: "Error Code", width: 190, editable: false },
  { field: "message", headerName: "Message", width: 190, editable: false },
];

export const openDebtIssuesDataGridCells = [
  { field: "symbol", headerName: "Symbol", width: 190, editable: false },
  { field: "name", headerName: "Name", width: 190, editable: false },
  { field: "isin", headerName: "ISIN", width: 190, editable: false },
  { field: "category", headerName: "Category", width: 190, editable: false },
  {
    field: "issuertype",
    headerName: "Issuer Type",
    width: 190,
    editable: false,
  },
  {
    field: "opendatetime",
    headerName: "Open Date Time",
    width: 190,
    editable: false,
  },
  {
    field: "closedatetime",
    headerName: "Closed Date Time",
    width: 190,
    editable: false,
  },
  {
    field: "floorprice",
    headerName: "Floor Price",
    width: 190,
    editable: false,
  },
  {
    field: "ceilingprice",
    headerName: "Ceiling Price",
    width: 190,
    editable: false,
  },
  { field: "cuttoff", headerName: "Cut Off", width: 190, editable: false },
  { field: "tickprice", headerName: "Tick Price", width: 190, editable: false },
  {
    field: "minbidqty",
    headerName: "Min Bid Qty",
    width: 190,
    editable: false,
  },
  {
    field: "maxbidqty",
    headerName: "Max Bid Qty",
    width: 190,
    editable: false,
  },
  {
    field: "tradinglot",
    headerName: "Trading Lot",
    width: 190,
    editable: false,
  },
  {
    field: "mintotalqty",
    headerName: "Min Total Qty",
    width: 190,
    editable: false,
  },
  { field: "minvalue", headerName: "Min Value", width: 190, editable: false },
  { field: "maxvalue", headerName: "Max Value", width: 190, editable: false },
  {
    field: "discounttype",
    headerName: "Discount Type",
    width: 190,
    editable: false,
  },
  {
    field: "discountvalue",
    headerName: "Discount Value",
    width: 190,
    editable: false,
  },
  {
    field: "asbanonasba",
    headerName: "Asba or Non Asba",
    width: 190,
    editable: false,
  },
  {
    field: "tplusmodificationfrom",
    headerName: "T Plus Modification From",
    width: 190,
    editable: false,
  },
  {
    field: "tplusmodificationto",
    headerName: "T Plus Modification To",
    width: 190,
    editable: false,
  },
  { field: "errorcode", headerName: "Error Code", width: 190, editable: false },
  { field: "message", headerName: "Message", width: 190, editable: false },
];
