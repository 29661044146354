import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { appContext } from "../App";
import {
  createSecurityMaster,
  uploadBulkSecurityMaster,
  deleteSecurityMasterByISIN,
  updateSecurityMaster,
  checkIfSecurityMasterPresent,
  makeGETRequest,
  makePUTRequest,
  makePOSTRequest,
  makeDELETERequest,
} from "../Api/api";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddSecurityDialog from "../components/AddSecurityDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BulkUploadModal from "../components/BulkUploadModal";
import toastr from "toastr";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DeleteModal from "../components/DeleteModal";
import EditSecurityDialog from "../components/EditSecurityDialog";
import SnackbarComponent from "../components/Snackbar/Snackbar";
import { getAppropriateErrorMessage } from "../Helpers/helpers";
import ValidateSecurityDialog from "../components/SecurityMaster/ValidateSecurityDialog";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { TextField, Grid } from "@material-ui/core";
import GenericDialog from "../components/Dialog/GenericDialog";
import FullScreenDialog from "../components/Dialog/FullScreenDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import {
  eligibleForDateChangeSecurityMastersDataCells,
  addSerialAndParseDates,
  formattifyForDateChange,
  addSerialNumber,
  cashFlowDataGridCells,
  formattifyForUpdateAllDates,
} from "../Helpers/SecurityMasterHelpers";
import MoneyIcon from "@mui/icons-material/Money";
import DownloadIcon from "@mui/icons-material/Download";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IdleTimoutConfig from "../Helpers/IdleTimeoutConfig";
import HistoryIcon from "@mui/icons-material/History";
import SecurityMasterUpdateHistoryTable from "../components/SecurityMaster/SecurityMasterUpdateHistoryTable";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CheckIcon from "@material-ui/icons/Check";
import Switch from "@mui/material/Switch";

const SecurityMaster = (props) => {
  const { authUser, setAuthUser } = useContext(appContext);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [securities, setSecurities] = useState([]);
  const [showAddSecurityDialog, setShowAddSecurityDialog] =
    React.useState(false);
  const [showBulkUploadModal, setShowBulkUploadModal] = React.useState(false);
  const [toBeDeleted, setToBeDeleted] = React.useState(null);
  const [showSecurityDeleteModal, setShowSecurityDeleteModal] =
    React.useState(false);
  const [toBeEdited, setToBeEdited] = React.useState(null);
  const [editModalState, setEditModalState] = React.useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showValidateSecurityDialog, setShowValidateSecurityDialog] =
    React.useState(false);
  const [showCopySecurityMasterModal, setShowCopySecurityMasterModal] =
    React.useState(false);
  const [showGenericDialog, setShowGenericDialog] = React.useState(false);
  const [isin, setISIN] = React.useState("");
  const [selectedISIN, setSelectedISIN] = React.useState("");
  const [showFullScreenDialog, setShowFullScreenDialog] = React.useState(false);
  const [showGenericDialogForChangeDates, setShowGenericDialogForChangeDates] =
    React.useState(false);
  const [selectedForDateChange, setSelectedForDateChange] =
    React.useState(null);
  const [
    eligibleForDateChangeSecurityMasters,
    setEligibleForDateChangeSecurityMasters,
  ] = React.useState([]);
  const [selectedSecurity, setSelectedSecurity] = React.useState(null);
  const [showCashFlowDialog, setShowCashFlowDialog] = React.useState(false);
  const [cashFlow, setCashFlow] = React.useState([]);
  const [showImportSecurityModal, setShowImportSecurityModal] =
    React.useState(false);
  const [
    showSecurityMasterUpdateHistoryDialog,
    setShowSecurityMasterUpdateHistoryDialog,
  ] = React.useState(false);
  const [securityMasterUpdateHistory, setSecurityMasterUpdateHistory] =
    React.useState({});
  const [isinForRationaleUpload, setISINForRationaleUpload] =
    React.useState("");
  const [showFileUploadDialog, setShowFileUploadDialog] = React.useState(false);
  const [showFileUploadDialogForIM, setShowFileUploadDialogForIM] =
    React.useState(false);
  const [showDeleteForCashflowDialog, setShowDeleteForCashflowDialog] =
    React.useState(false);
  const [cashflowRecordIdToBeDeleted, setCashflowRecordIdToBeDeleted] =
    React.useState(null);
  const [cashflowRecordsLockState, setCashflowRecordsLockState] =
    React.useState(false);
  const [pushImages, setPushImages] = React.useState(false);

  const dataGridCells = [
    { field: "serial", headerName: "Serial", width: 250, editable: false },
    { field: "isin", headerName: "ISIN", width: 250, editable: false },
    {
      field: "securityName",
      headerName: "Security Name",
      width: 300,
      editable: false,
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,

      renderCell: () => {
        return (
          <EditIcon
            fontSize="medium"
            style={{
              float: "left",
              "margin-top": "10px",
              "margin-left": "14px",
              cursor: "pointer",
              fill: "#f59b42",
            }}
          />
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (row) => {
        return (
          <DeleteIcon
            color="error"
            onClick={(event) => handleSecurityMasterDelete(event, row)}
            fontSize="medium"
            style={{
              float: "left",
              "margin-top": "10px",
              "margin-left": "14px",
              cursor: "pointer",
            }}
          />
        );
      },
    },
    {
      field: "uploadRationale",
      headerName: "Upload Rationale",
      sortable: false,
      width: 170,
      disableClickEventBubbling: true,

      renderCell: (row) => {
        if (row.row.isRationaleUploaded) {
          return (
            <CheckIcon
              color="green"
              fontSize="medium"
              style={{
                float: "left",
                "margin-top": "10px",
                "margin-left": "14px",
                cursor: "pointer",
              }}
            />
          );
        } else {
          return (
            <CloudUploadIcon
              fontSize="medium"
              style={{
                float: "left",
                "margin-top": "10px",
                "margin-left": "14px",
                cursor: "pointer",
              }}
            />
          );
        }
      },
    },
    {
      field: "uploadIM",
      headerName: "Upload IM",
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,

      renderCell: (row) => {
        if (row.row.isIMUploaded) {
          return (
            <CheckIcon
              color="green"
              fontSize="medium"
              style={{
                float: "left",
                "margin-top": "10px",
                "margin-left": "14px",
                cursor: "pointer",
              }}
            />
          );
        } else {
          return (
            <CloudUploadIcon
              fontSize="medium"
              style={{
                float: "left",
                "margin-top": "10px",
                "margin-left": "14px",
                cursor: "pointer",
              }}
            />
          );
        }
      },
    },
    {
      field: "cashFlow",
      headerName: "Cash Flow Details",
      sortable: false,
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (row) => {
        return (
          <MoneyIcon
            onClick={(event) => handleCashFlowIconOnClick(event, row)}
            color="success"
            fontSize="medium"
            style={{
              float: "left",
              "margin-top": "10px",
              "margin-left": "14px",
              cursor: "pointer",
            }}
          />
        );
      },
    },
    {
      field: "export",
      headerName: "Export To JSON",
      sortable: false,
      width: 160,
      disableClickEventBubbling: true,
      renderCell: (row) => {
        return (
          <DownloadIcon
            onClick={(event) => handleExportIconOnClick(event, row)}
            fontSize="medium"
            style={{
              float: "left",
              "margin-top": "10px",
              "margin-left": "14px",
              cursor: "pointer",
              fill: "#bff542",
            }}
          />
        );
      },
    },
    {
      field: "history",
      headerName: "View Change History",
      sortable: false,
      width: 190,
      disableClickEventBubbling: true,

      renderCell: () => {
        return (
          <HistoryIcon
            color="warning"
            fontSize="medium"
            style={{
              float: "left",
              "margin-top": "10px",
              "margin-left": "14px",
              cursor: "pointer",
            }}
          />
        );
      },
    },
    {
      field: "downloadInitialState",
      headerName: "Download Initial State",
      sortable: false,
      width: 220,
      disableClickEventBubbling: true,

      renderCell: () => {
        return (
          <CloudDownloadIcon
            fontSize="medium"
            style={{
              float: "left",
              "margin-top": "10px",
              "margin-left": "14px",
              fill: "#f542d7",
              cursor: "pointer",
            }}
          />
        );
      },
    },
  ];

  function updateAllDatesForSecurityMasters(event) {
    event.preventDefault();
    makePUTRequest("/admin/security_master/change_all_dates/", {
      isinValues: formattifyForUpdateAllDates(
        eligibleForDateChangeSecurityMasters
      ),
    })
      .then((response) => {
        if (response.status === 200) {
          toastr.success("Updated all Call/Maturity Dates successfully!");
          setShowGenericDialog(false);
        }
      })
      .catch((error) => toastr.error(error));
  }

  function generateInputPropsForCashFlowDialog() {
    return (
      <Grid
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          bottom: "-10%",
          width: "90%",
          transform: "translate(-50%, -50%)",
        }}
        className="securityMasterDataGird"
      >
        <FormControlLabel
          control={<Switch checked={cashflowRecordsLockState} />}
          onChange={(event) => {
            event.preventDefault();
            makePUTRequest(
              `/admin/security_master/lock_cashflow/${
                selectedSecurity.row.isin
              }/${!cashflowRecordsLockState}/`
            )
              .then((resp) => {
                setCashflowRecordsLockState(!cashflowRecordsLockState);
                if (resp.status === 200) {
                  toastr.success(
                    "Cashflow Records lock status has been updated successfully!"
                  );
                }
              })
              .catch((error) => {
                toastr.error(error);
              });
          }}
          label={cashflowRecordsLockState ? "On" : "Off"}
        />

        <Button
          variant="outlined"
          onClick={(event) => {
            window.open(
              `/cashflow_record/create/${selectedSecurity.row.isin}/uuid`,
              "_blank"
            );
          }}
          style={{
            float: "right",
            margin: "25px 10px 15px",
            cursor: "pointer",
          }}
          startIcon={<AddCircleIcon />}
        >
          Create
        </Button>
        <Paper>
          <div
            style={{
              height: "100vh",
              width: "100%",
            }}
          >
            <DataGrid
              pagination
              className="securityMasterDataGrid hgn"
              components={{ Toolbar: GridToolbar }}
              rows={addSerialNumber(cashFlow)}
              onCellClick={currentlySelectedForCashflowTable}
              columns={cashFlowDataGridCells}
              getRowId={(row) => row.serial}
            />
          </div>
        </Paper>
      </Grid>
    );
  }

  function generateInputPropsForEligibleSecurityMasters() {
    if (eligibleForDateChangeSecurityMasters.length > 0) {
      return (
        <Grid
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            bottom: "-10%",
            width: "90%",
            transform: "translate(-50%, -50%)",
          }}
          className="securityMasterDataGird"
        >
          <Paper>
            <div
              style={{
                height: "100vh",
                width: "100%",
              }}
            >
              <DataGrid
                className="securityMasterDataGrid hgn"
                components={{ Toolbar: GridToolbar }}
                rows={addSerialAndParseDates(
                  eligibleForDateChangeSecurityMasters
                )}
                onCellClick={currentlySelected}
                columns={eligibleForDateChangeSecurityMastersDataCells}
                getRowId={(row) => row.isin}
              />
            </div>
          </Paper>
        </Grid>
      );
    } else {
      return (
        <Box
          sx={{ width: 900 }}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
          <Skeleton animation={false} />
          <Skeleton animation={false} />
          <Skeleton animation={false} />
          <Skeleton animation={false} />
          <Skeleton animation={false} />
        </Box>
      );
    }
  }

  function generateInputPropsForCopySecurity() {
    let isinValues = securities.map((securityMaster) => securityMaster.isin);
    return (
      <>
        <Grid item xs={12} style={{ "margin-top": "10px" }}>
          <TextField
            type="text"
            name="customerName"
            variant="outlined"
            fullWidth
            disabled
            label="ISIN"
            value={isin}
          />
        </Grid>
        <Grid item xs={12} style={{ "margin-top": "10px" }}>
          <Autocomplete
            id="free-solo-demo"
            freeSolo={true}
            options={isinValues}
            onChange={(event, enteredValue) => setSelectedISIN(enteredValue)}
            autoHighlight={true}
            value={selectedISIN}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Copy From"
                margin="normal"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </>
    );
  }

  function handleSubmitForCopySecurityMaster(event) {
    event.preventDefault();
    let requestURL = `/admin/security_master/copy/`;
    makePOSTRequest(requestURL, {
      newISIN: isin,
      existingISIN: selectedISIN,
    })
      .then((response) => {
        if (response && response.status === 201) {
          toastr.success("Created Security Master!");
          setISIN("");
          setSecurities(response.data);
          setSelectedISIN("");
          setShowCopySecurityMasterModal(false);
        }
      })
      .catch((error) => toastr.error(error));
  }

  function generateInputProps() {
    return (
      <Grid item xs={12}>
        <TextField
          type="text"
          name="isin"
          variant="outlined"
          fullWidth
          label="ISIN"
          defaultValue={isin}
          onChange={(event) => setISIN(event.target.value)}
        />
      </Grid>
    );
  }

  function closeGenericDialog(event) {
    event.preventDefault();
    setShowGenericDialog(false);
    setISIN("");
    setSelectedISIN("");
  }

  function checkISINAvailability(event) {
    event.preventDefault();
    if (isin.toLowerCase() === "tbl") {
      setShowGenericDialog(false);
      setShowCopySecurityMasterModal(true);
      toastr.success("Valid ISIN!");
    } else {
      checkIfSecurityMasterPresent(isin)
        .then((response) => {
          if (response && response.status === 200 && !response.data) {
            setShowGenericDialog(false);
            setShowCopySecurityMasterModal(true);
            toastr.success("Valid ISIN!");
          } else {
            toastr.error("Security master with the given ISIN already exists.");
          }
        })
        .catch((error) => toastr.error(error));
    }
  }

  function addSerialNumberToSecurities(users) {
    let formatted = [];
    for (let iterator = 0; iterator < users.length; iterator++) {
      let userWithSerial = users[iterator];
      userWithSerial.serial = iterator + 1;
      formatted.push(userWithSerial);
    }
    return formatted;
  }

  const fetchAllSecurities = () => {
    let requestURL = "/admin/security_master/list/formatted/";
    makeGETRequest(requestURL)
      .then((response) => {
        if (response && response.status === 200) {
          setSecurities(addSerialNumberToSecurities(response.data));
        }
      })
      .catch((error) => toastr.error(error));
  };

  useEffect(() => {
    if (authUser && authUser.uuid && currentPageNumber > 0) {
      fetchAllSecurities();
    }
  }, []);

  const handleValidateSecurityDialogState = (event) => {
    event.preventDefault();
    setShowValidateSecurityDialog(true);
  };

  const handleBulkUploadModalState = (event) => {
    event.preventDefault();
    setShowBulkUploadModal(true);
  };

  const renderBulkUploadButton = () => {
    return (
      <Tooltip
        arrow
        title="Bulk Upload Security Masters from an Excel/CSV file."
      >
        <Button
          className="btnClass"
          variant="outlined"
          onClick={(event) => handleBulkUploadModalState(event)}
          color="primary"
          style={{
            float: "right",
            margin: "25px 10px 15px",
            cursor: "pointer",
          }}
        >
          Bulk Upload
        </Button>
      </Tooltip>
    );
  };

  const renderAddCircleButton = () => {
    return (
      <Tooltip
        arrow
        title="Create a new Security Master. Make sure the ISIN is unique."
      >
        <Button
          variant="outlined"
          onClick={(event) => handleValidateSecurityDialogState(event)}
          style={{
            float: "right",
            margin: "25px 10px 15px",
            cursor: "pointer",
          }}
          startIcon={<AddCircleIcon />}
        >
          Create
        </Button>
      </Tooltip>
    );
  };

  const handleOnCloseDialog = (event) => {
    event.preventDefault();
    setShowAddSecurityDialog(false);
    fetchAllSecurities();
  };

  const handleOnCloseEditDialog = (event) => {
    event.preventDefault();
    setEditModalState(false);
    fetchAllSecurities();
  };

  const updateSecurity = (event, updated) => {
    event.preventDefault();
    let loginUser = JSON.parse(localStorage.getItem("loginUser"));
    updated.changedBy = loginUser.name;
    updateSecurityMaster(updated)
      .then((resp) => {
        if (resp && resp.status === 200) {
          toastr.success("Updated Security Master");
          setEditModalState(false);
          fetchAllSecurities();
        }
      })
      .catch((error) => {
        let errorMessage = getAppropriateErrorMessage(error);
        setErrorMessage(errorMessage);
        setShowErrorSnackbar(true);
      });
  };

  const handleSecuritySubmit = (event, requestData) => {
    event.preventDefault();
    let loginUser = JSON.parse(localStorage.getItem("loginUser"));
    requestData.changedBy = loginUser.name;
    createSecurityMaster(requestData)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          toastr.success("Created security master successfully");
          setShowAddSecurityDialog(false);
          fetchAllSecurities();
          setPushImages(true);
        }
      })
      .catch((error) => {
        let errorMessage = getAppropriateErrorMessage(error);
        setErrorMessage(errorMessage);
        setShowErrorSnackbar(true);
        setPushImages(true);
      })
      .finally(() => setPushImages(false));
  };

  function downloadAsJSON(text, name) {
    const a = document.createElement("a");
    const type = name.split(".").pop();
    a.href = URL.createObjectURL(
      new Blob([text], { type: `text/${type === "txt" ? "plain" : type}` })
    );
    a.download = name;
    a.click();
  }

  function handleExportIconOnClick(event, row) {
    event.preventDefault();
    makeGETRequest(`/admin/security_master/export/${row?.id}`)
      .then((response) => {
        if (response.status === 200) {
          downloadAsJSON(
            JSON.stringify(response.data, null, 2),
            `${row.id}.json`
          );
        }
      })
      .catch((error) => toastr.error(error));
  }

  function handleCashFlowIconOnClick(event, row) {
    event.preventDefault();
    setSelectedSecurity(row);
    setCashflowRecordsLockState(row.row.areCashflowRecordsLocked);
    setShowCashFlowDialog(true);
    makeGETRequest(`/admin/security_master/cashflow/${row.row.isin}`)
      .then((response) => {
        if (response.status === 200) {
          setCashFlow(response.data);
        }
      })
      .catch((error) => {
        toastr.error(error);
      });

    makeGETRequest(
      `/admin/security_master/cashflow_record/lock_state/${row.row.isin}`
    )
      .then((response) => {
        if (response.status === 200) {
          setCashflowRecordsLockState(response.data.areCashFlowRecordsLocked);
        }
      })
      .catch((error) => {
        toastr.error(error);
      });
  }

  const handleSecurityMasterDelete = (event, row) => {
    event.preventDefault();
    setToBeDeleted(row.row);
    setShowSecurityDeleteModal(true);
  };

  function handleUploadRationaleOnClick(isin) {
    setISINForRationaleUpload(isin);
    setShowFileUploadDialog(true);
  }

  function handleUploadIMClick(isin) {
    setISINForRationaleUpload(isin);
    setShowFileUploadDialogForIM(true);
  }

  function handleIMFileUpload(file) {
    let formData = new FormData();
    formData.append("isin", isinForRationaleUpload);
    formData.append("file", file);
    makePUTRequest("/admin/security_master/im/upload/", formData)
      .then((resp) => {
        if (resp.status === 200 && resp.data.success) {
          toastr.success("Uploaded IM successfully!");
          setISINForRationaleUpload(false);
          setShowFileUploadDialogForIM(false);
        }
      })
      .catch((error) => toastr.error(error));
  }

  function handleRationaleFileUpload(file) {
    let formData = new FormData();
    formData.append("isin", isinForRationaleUpload);
    formData.append("file", file);
    makePUTRequest("/admin/security_master/rationale/upload/", formData)
      .then((resp) => {
        if (resp.status === 200 && resp.data.success) {
          toastr.success("Uploaded Rationale successfully!");
          setISINForRationaleUpload(false);
          setShowFileUploadDialog(false);
        }
      })
      .catch((error) => toastr.error(error));
  }

  function handleDownloadInitialStateOnClick(isin) {
    makeGETRequest(`/admin/security_master/initial_state/download/${isin}`)
      .then((response) => {
        if (response.status === 200) {
          downloadAsJSON(
            JSON.stringify(response.data, null, 2),
            `${isin}.json`
          );
        } else {
          toastr.warning("Security Master Initial State not available!");
        }
      })
      .catch((error) =>
        toastr.warning("Security Master Initial State not available!")
      );
  }

  function handleShowHistoryOnClick(row) {
    makeGETRequest(`/admin/security_master_update_history/list/${row.isin}/`)
      .then((response) => {
        if (response.data.length === 0) {
          toastr.warning("No history available for the given security master!");
          return;
        }
        setSecurityMasterUpdateHistory({
          isin: row.isin,
          history: response.data,
        });
        setShowSecurityMasterUpdateHistoryDialog(
          !showSecurityMasterUpdateHistoryDialog
        );
      })
      .catch((error) => toastr.error(error));
  }

  const handleEditIconClick = (row) => {
    let requestURL = `/admin/security_master/get/${row.isin}/`;
    makeGETRequest(requestURL)
      .then((response) => {
        if (response && response.status === 200) {
          setToBeEdited(response.data);
          setEditModalState(true);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const handleOnCloseForBulkUpload = (event, securityMasters) => {
    event.preventDefault();
    if (securityMasters !== undefined) {
      setSecurities(securityMasters);
    }
    setShowBulkUploadModal(false);
  };

  const handleSubmitForSecurityMaster = (event, selectedFile) => {
    event.preventDefault();

    if (authUser && authUser.uuid && selectedFile !== null) {
      let formData = new FormData();
      formData.append("csvFile", selectedFile);
      uploadBulkSecurityMaster(formData)
        .then((response) => {
          if (
            response &&
            (response.status === 201 || response.status === 200)
          ) {
            setShowBulkUploadModal(false);
            setSecurities(response.data);
            toastr.success("Successfully uploaded Security Masters!");
          }
        })
        .catch((error) => toastr.error(error));
    }
  };

  function handleListEligibleSecurityMaster(event) {
    makeGETRequest("/admin/security_master/date_change_eligibility/list/")
      .then((response) => {
        if (response.status === 200) {
          if (response.data.length === 0) {
            toastr.warning(
              "There are no Security masters eligible for call/maturity date change."
            );
          } else {
            setEligibleForDateChangeSecurityMasters(response.data);
            setShowFullScreenDialog(!showFullScreenDialog);
          }
        }
      })
      .catch((error) => toastr.error(error));
  }

  function importSecurityMaster(event, selectedFile) {
    event.preventDefault();
    let formData = new FormData();
    formData.append("jsonFile", selectedFile);
    formData.append(
      "changedBy",
      JSON.parse(localStorage.getItem("loginUser"))?.name
    );
    makePOSTRequest("/admin/security_master/import/", formData)
      .then((_) => {
        setShowImportSecurityModal(!showImportSecurityModal);
      })
      .catch((error) => toastr.error(error));
  }

  function renderImportIcon() {
    return (
      <Button
        variant="outlined"
        onClick={(event) => {
          event.preventDefault();
          setShowImportSecurityModal(!showImportSecurityModal);
        }}
        style={{
          float: "right",
          margin: "25px 10px 15px",
          cursor: "pointer",
        }}
        startIcon={<CloudUploadIcon />}
      >
        Import
      </Button>
    );
  }

  function handleExportAllClick(event) {
    event.preventDefault();
    makeGETRequest("/admin/security_master/export_all/")
      .then((resp) => {
        if (resp.status === 200) {
          toastr.success("Security Master data downloaded successfully!");
        }
      })
      .catch((error) => toastr.error(error));
  }

  function renderExportAllButton() {
    return (
      <Tooltip arrow title="Export all security master in a JSON file.">
        <Button
          className="btnClass"
          variant="outlined"
          onClick={handleExportAllClick}
          color="primary"
          style={{
            float: "right",
            margin: "25px 10px 15px",
            cursor: "pointer",
          }}
        >
          Export all
        </Button>
      </Tooltip>
    );
  }

  function renderSecurityMasterEligibleForDateChange() {
    return (
      <Tooltip
        arrow
        title="Check list of all security masters that are eligible for call/maturity date change based on IP Payment"
      >
        <Button
          className="btnClass"
          variant="outlined"
          onClick={handleListEligibleSecurityMaster}
          color="primary"
          style={{
            float: "right",
            margin: "25px 10px 15px",
            cursor: "pointer",
          }}
        >
          Change Dates
        </Button>
      </Tooltip>
    );
  }

  function renderCopySecurityMasterButton() {
    return (
      <Tooltip
        arrow
        title="Copy details from an existing Security Master and create a new Security Master with a different ISIN"
      >
        <Button
          className="btnClass"
          variant="outlined"
          onClick={(event) => setShowGenericDialog(!showGenericDialog)}
          color="primary"
          startIcon={<ContentCopyIcon />}
          style={{
            float: "right",
            margin: "25px 10px 15px",
            cursor: "pointer",
          }}
        >
          Copy Security
        </Button>
      </Tooltip>
    );
  }

  const renderBulkUploadModal = () => {
    return (
      <BulkUploadModal
        show={showBulkUploadModal}
        onCloseModal={handleOnCloseForBulkUpload}
        submitHandler={handleSubmitForSecurityMaster}
        heading={"Import Security Master"}
        records={securities}
      />
    );
  };

  const closeDeleteModal = () => {
    setShowSecurityDeleteModal(false);
    setToBeDeleted(null);
  };

  const removeDeletedSecurityMaster = (securityMasterISIN) => {
    return securities.filter(
      (security) => security.isin !== securityMasterISIN
    );
  };

  const deleteSecurityMaster = (event, row) => {
    deleteSecurityMasterByISIN(toBeDeleted.isin, authUser.uuid)
      .then((response) => {
        if (response && (response.status === 204 || response.status === 200)) {
          toastr.success("Successfully deleted security master");
          setShowSecurityDeleteModal(false);

          let updatedSecurityMasters = removeDeletedSecurityMaster(
            toBeDeleted.isin
          );
          setSecurities(updatedSecurityMasters);
          setToBeDeleted(null);
        }
      })
      .catch((error) => {
        let errorMessage = getAppropriateErrorMessage(error);
        setErrorMessage(errorMessage);
        setShowErrorSnackbar(true);
      });
  };

  const renderDeleteSecurityModal = () => {
    return (
      <DeleteModal
        primaryText="Delete Security Master"
        secondaryText="Are you sure you want to delete this Security Master ?"
        isOpen={showSecurityDeleteModal}
        handleClose={closeDeleteModal}
        confirmDelete={deleteSecurityMaster}
      />
    );
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowErrorSnackbar(false);
  };

  const closeValidateSecurityDialog = (event) => {
    event.preventDefault();
    setShowValidateSecurityDialog(false);
  };

  const validateISIN = (enteredISIN) => {
    if (enteredISIN.toLowerCase() === "tbl") {
      setShowValidateSecurityDialog(false);
      toastr.success("Valid ISIN!");
      setShowAddSecurityDialog(true);
    } else {
      checkIfSecurityMasterPresent(enteredISIN)
        .then((response) => {
          if (response && response.status === 200 && !response.data) {
            setShowValidateSecurityDialog(false);
            toastr.success("Valid ISIN!");
            setShowAddSecurityDialog(true);
          } else {
            toastr.error(
              "Security master with the given ISIN already exists. Please check"
            );
          }
        })
        .catch((error) => toastr.error(error));
    }
  };

  function handleConfirmationForChangeDates(event) {
    event.preventDefault();
    makePUTRequest(
      "/admin/security_master/change_dates_as_per_ip_payment/",
      formattifyForDateChange(selectedForDateChange)
    )
      .then((response) => {
        if (response.status === 200) {
          toastr.success(
            "Updated Maturity/Call date for Security Master successfully!"
          );
          setSelectedForDateChange(null);
          setShowGenericDialogForChangeDates(false);
          setShowFullScreenDialog(!showFullScreenDialog);
        }
      })
      .catch((error) => {
        toastr.error(error);
      });
  }

  function handleCashflowRecordDelete(row) {
    setShowDeleteForCashflowDialog(true);
    setCashflowRecordIdToBeDeleted(row.uuid);
  }

  function handleCashflowRecordEdit(row) {
    window.open(
      `/cashflow_record/update/${selectedSecurity.row.isin}/${row.uuid}`,
      "_blank"
    );
  }

  function currentlySelectedForCashflowTable(params) {
    params.field === "Delete" && handleCashflowRecordDelete(params.row);
    params.field === "Edit" && handleCashflowRecordEdit(params.row);
  }

  const currentlySelected = (params) => {
    if (params.field === "changeIndividual") {
      setSelectedForDateChange(params.row);
      setShowGenericDialogForChangeDates(!showGenericDialogForChangeDates);
    }
    params.field === "edit" && handleEditIconClick(params.row);
    params.field === "history" && handleShowHistoryOnClick(params.row);
    params.field === "downloadInitialState" &&
      handleDownloadInitialStateOnClick(params.row.isin);
    params.field === "uploadRationale" &&
      handleUploadRationaleOnClick(params.row.isin);
    params.field === "uploadIM" && handleUploadIMClick(params.row.isin);
  };

  return (
    <Grid className="securityMasterDataGird">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "40px", float: "left", padding: "10px" }}>
                Securities
              </div>
            </Grid>

            {renderAddCircleButton()}
            {renderImportIcon()}
            {renderCopySecurityMasterButton()}
            {renderBulkUploadButton()}
            {renderBulkUploadModal()}
            {renderSecurityMasterEligibleForDateChange()}
            {renderExportAllButton()}
            {renderDeleteSecurityModal()}
          </Grid>
        </Grid>
        <div
          style={{
            height: "100vh",
            width: "100%",
          }}
        >
          <DataGrid
            pagination
            className="securityMasterDataGrid hgn"
            components={{ Toolbar: GridToolbar }}
            rows={securities}
            onCellClick={currentlySelected}
            columns={dataGridCells}
            getRowId={(row) => row.isin}
          />
        </div>
        {editModalState ? (
          <EditSecurityDialog
            show={editModalState}
            onCloseDialog={handleOnCloseEditDialog}
            securityMaster={toBeEdited}
            onSubmit={updateSecurity}
          />
        ) : (
          ""
        )}
        {showErrorSnackbar ? (
          <SnackbarComponent
            handleClose={handleSnackbarClose}
            errorMessage={errorMessage}
          />
        ) : (
          ""
        )}
        <ValidateSecurityDialog
          show={showValidateSecurityDialog}
          handleClose={closeValidateSecurityDialog}
          validateISIN={validateISIN}
        />
        <AddSecurityDialog
          show={showAddSecurityDialog}
          onCloseDialog={handleOnCloseDialog}
          onSubmit={handleSecuritySubmit}
          pushImages={pushImages}
        />
        {showGenericDialog ? (
          <GenericDialog
            show={showGenericDialog}
            heading="Check ISIN availability"
            inputProps={generateInputProps()}
            onClose={closeGenericDialog}
            handleSubmit={checkISINAvailability}
          />
        ) : (
          ""
        )}
        {showCopySecurityMasterModal ? (
          <GenericDialog
            show={showCopySecurityMasterModal}
            heading="Copy values from existing Security Master"
            inputProps={generateInputPropsForCopySecurity()}
            onClose={(event) => {
              setShowCopySecurityMasterModal(!showCopySecurityMasterModal);
              setISIN("");
              setSelectedISIN("");
            }}
            handleSubmit={handleSubmitForCopySecurityMaster}
          />
        ) : (
          ""
        )}

        {showGenericDialogForChangeDates ? (
          <GenericDialog
            show={showGenericDialogForChangeDates}
            heading="Change Call and/or Maturity Dates"
            inputProps="Are you sure you want to change call/maturity dates for this Security Master?"
            onClose={(event) => {
              setShowGenericDialogForChangeDates(
                !showGenericDialogForChangeDates
              );
              setSelectedForDateChange(null);
            }}
            handleSubmit={handleConfirmationForChangeDates}
          />
        ) : (
          ""
        )}
        {showFullScreenDialog ? (
          <FullScreenDialog
            show={showFullScreenDialog}
            handleClose={(event) =>
              setShowFullScreenDialog(!showFullScreenDialog)
            }
            showSaveButton={true}
            saveButtonText="Update All"
            onSumitSaveButton={updateAllDatesForSecurityMasters}
            inputProps={generateInputPropsForEligibleSecurityMasters()}
            heading="Security Masters eligible for date change (Call Date and Maturity Date)"
          />
        ) : (
          ""
        )}
        {showCashFlowDialog ? (
          <FullScreenDialog
            show={showCashFlowDialog}
            handleClose={(event) => {
              setShowCashFlowDialog(!showCashFlowDialog);
              setSelectedSecurity(null);
              setCashFlow([]);
            }}
            showSaveButton={false}
            saveButtonText=""
            onSumitSaveButton={(event) => {}}
            inputProps={generateInputPropsForCashFlowDialog()}
            heading={`Cash Flow for ${selectedSecurity.row.isin}`}
          />
        ) : (
          ""
        )}
        {showImportSecurityModal ? (
          <BulkUploadModal
            show={showImportSecurityModal}
            onCloseModal={(event) => {
              event.preventDefault();
              setShowImportSecurityModal(!showImportSecurityModal);
            }}
            submitHandler={importSecurityMaster}
            heading="Import Security Master"
          />
        ) : (
          ""
        )}
        {showSecurityMasterUpdateHistoryDialog ? (
          <FullScreenDialog
            show={showSecurityMasterUpdateHistoryDialog}
            handleClose={(event) => {
              setShowSecurityMasterUpdateHistoryDialog(
                !showSecurityMasterUpdateHistoryDialog
              );
              setSecurityMasterUpdateHistory({});
            }}
            showSaveButton={false}
            saveButtonText=""
            onSumitSaveButton={() => {}}
            inputProps={
              <SecurityMasterUpdateHistoryTable
                history={securityMasterUpdateHistory}
              />
            }
            heading={`Update History for ${securityMasterUpdateHistory.isin}.`}
          />
        ) : (
          ""
        )}
        {showFileUploadDialog && (
          <BulkUploadModal
            show={showFileUploadDialog}
            onCloseModal={(event) => {
              setShowFileUploadDialog(false);
            }}
            submitHandler={(event, file) => {
              handleRationaleFileUpload(file);
            }}
            heading={"Upload"}
            records={[]}
          />
        )}

        {showFileUploadDialogForIM && (
          <BulkUploadModal
            show={showFileUploadDialogForIM}
            onCloseModal={(event) => {
              setShowFileUploadDialogForIM(false);
            }}
            submitHandler={(event, file) => {
              handleIMFileUpload(file);
            }}
            heading={"Upload"}
            records={[]}
          />
        )}
        {showDeleteForCashflowDialog && (
          <GenericDialog
            show={showDeleteForCashflowDialog}
            heading="Delete Cashflow Record"
            inputProps="Are you sure you want to delete this cashflow record?"
            onClose={(event) => {
              setShowDeleteForCashflowDialog(!showDeleteForCashflowDialog);
              setCashflowRecordIdToBeDeleted(null);
            }}
            handleSubmit={(event) => {
              event.preventDefault();
              makeDELETERequest(
                `/admin/security_master/cashflow_record/delete/${cashflowRecordIdToBeDeleted}/`
              )
                .then((resp) => {
                  if (resp.status === 204) {
                    toastr.success("Deleted Cashflow Record successfully");
                    setShowDeleteForCashflowDialog(
                      !showDeleteForCashflowDialog
                    );
                    setCashflowRecordIdToBeDeleted(null);
                  }
                })
                .catch((error) => toastr.error(error));
            }}
          />
        )}
      </Paper>
      {/* <IdleTimoutConfig /> */}
    </Grid>
  );
};

export default withRouter(SecurityMaster);
