import { Moment } from "moment";
import IBondDetails, { CallOption, Maturity } from "./interfaces";
import moment from "moment";
import { makePOSTRequest } from "../../Api/api";

function calcBusinessDays(startDate: Moment, endDate: Moment) {
  var day = moment(startDate);
  var businessDays = 0;

  while (day.isSameOrBefore(endDate, "day")) {
    if (day.day() != 0 && day.day() != 6) businessDays++;
    day.add(1, "d");
  }
  return businessDays;
}

export const gsec_sdl_xirr = async (
  bondDetails: IBondDetails,
  Price?: number,
  date?: Moment,
  trackUsage?: boolean,
  units?: number
) => {
  return makePOSTRequest(`admin/xirr/gsec/calculate/`, {
    trackUsage,
    interest: Number(bondDetails.coupons[0].rate),
    clean_price:
      Price ??
      (bondDetails.quoteDetails
        ? Number(bondDetails.quoteDetails.sellPrice)
        : 100),
    previous_ip_date: moment(bondDetails.lastIPDate, "YYYY-MM-DD").format(
      "DD/MM/YYYY"
    ),
    frequency_of_interest: bondDetails.frequencyOfInterest,
    maturity_dates: bondDetails.maturities.map((item: Maturity) => ({
      date: item.date,
      amount: Number(item.amount),
    })),
    call_dates: bondDetails.callOptions?.map((item: CallOption) => ({
      date: item.date,
      amount: Number(item.amount),
    })),
    isin: bondDetails.isin,
    settlement_days: calcBusinessDays(moment(), date!),
    settlement_date:
      date?.format("DD/MM/YYYY") ??
      (bondDetails.quoteDetails
        ? moment(bondDetails.quoteDetails.settlementDate, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          )
        : moment(bondDetails.sellOrderSettlementDate, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          )),
  });
};

export const zcb_mld_xirr = async (
  bondDetails: IBondDetails,
  Price?: number,
  date?: Moment,
  trackUsage?: boolean,
  units?: number
) => {
  return makePOSTRequest(`admin/xirr/zcb/calculate/`, {
    trackUsage,
    interest: 0.0,
    buy_price: 0,
    clean_price:
      Price ??
      (bondDetails.quoteDetails
        ? Number(bondDetails.quoteDetails.sellPrice)
        : 100),
    shut_period: bondDetails.recordDate,
    con_date: bondDetails.ipPayment,
    maturity_dates: bondDetails.maturities.map((item: Maturity) => ({
      date: item.date,
      amount: Number(item.amount),
    })),
    call_dates: bondDetails.callOptions?.map((item: CallOption) => ({
      date: item.date,
      amount: Number(item.amount),
    })),
    isin: bondDetails.isin,
    settlement_date:
      date?.format("DD/MM/YYYY") ??
      (bondDetails.quoteDetails
        ? moment(bondDetails.quoteDetails.settlementDate, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          )
        : moment(bondDetails.sellOrderSettlementDate, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          )),
  });
};

export const corporateBonds_moneyMarket_xirr = async (
  bondDetails: IBondDetails,
  Price?: number,
  date?: Moment,
  trackUsage?: boolean,
  units?: number
) => {
  return makePOSTRequest(`admin/xirr/calculate/`, {
    trackUsage,
    interest: Number(bondDetails.coupons[0].rate),
    clean_price:
      Price ??
      (bondDetails.quoteDetails
        ? Number(bondDetails.quoteDetails.sellPrice)
        : 100),
    previous_ip_date: moment(bondDetails.lastIPDate, "YYYY-MM-DD").format(
      "DD/MM/YYYY"
    ),
    issue_date: moment(bondDetails.issueDate, "YYYY-MM-DD").format(
      "DD/MM/YYYY"
    ), // issueDate = allotmentDate,
    frequency_of_interest: bondDetails.frequencyOfInterest,
    shut_period: bondDetails.recordDate,
    con_date: bondDetails.ipPayment,
    maturity_dates: bondDetails.maturities.map((item: Maturity) => ({
      date: item.date,
      amount: Number(item.amount),
    })),
    call_dates: bondDetails.callOptions?.map((item: CallOption) => ({
      date: item.date,
      amount: Number(item.amount),
    })),
    year_count:
      bondDetails.divisibilityFactor === 0
        ? 365
        : bondDetails.divisibilityFactor,
    ip_date_option: bondDetails.lastIPDateOptions,
    isin: bondDetails.isin,
    next_ip_date: moment(bondDetails.nextIPDate, "YYYY-MM-DD").format(
      "DD/MM/YYYY"
    ),
    settlement_date:
      date?.format("DD/MM/YYYY") ??
      (bondDetails.quoteDetails
        ? moment(bondDetails.quoteDetails.settlementDate, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          )
        : moment(bondDetails.sellOrderSettlementDate, "YYYY-MM-DD").format(
            "DD/MM/YYYY"
          )),
  });
};
