import React, { useState, useEffect } from "react";
import toastr from "toastr";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "184px",
  },
}));

const BulkUploadModal = (props) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };
  }, []);

  const handleChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const resetStateAndClose = (event) => {
    event.persist();
    setSelectedFile(null);
    props.onCloseModal(event);
  };

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={(event) => resetStateAndClose(event)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.heading}</DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            <Grid item xs={12}>
              <input type="file" name="file" onChange={handleChange} />
            </Grid>
          </DialogContentText>
          {props.inputProps ? props.inputProps : ""}
        </DialogContent>
        <DialogActions>
          <Button onClick={(event) => resetStateAndClose(event)}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={(event) => props.submitHandler(event, selectedFile)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BulkUploadModal;
