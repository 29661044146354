import * as React from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import GenericDialog from "../Dialog/GenericDialog";
import { TextField } from "@material-ui/core";

interface ChipData {
  key: number;
  label: string;
}

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function ChipsArrayComponent(props: any) {
  const { chipData } = props;
  const [showAddVariableDialog, setShowAddVariableDialog] =
    React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>("");

  const handleDelete = (chipToDelete: ChipData) => () => {
    props.handleDelete(chipToDelete);
  };

  function toggleAddDialog(
    event: React.MouseEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    setShowAddVariableDialog(!showAddVariableDialog);
  }

  function generateInputProps() {
    return (
      <TextField
        style={{ left: "13px" }}
        id="standard-basic"
        label="Variable Value"
        variant="outlined"
        defaultValue={value}
        onChange={(event: any) => {
          event.preventDefault();
          setValue(event.target.value);
        }}
        size="medium"
      />
    );
  }

  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        listStyle: "none",
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {chipData.map((data: ChipData) => {
        let icon;

        return (
          <ListItem key={data.key}>
            <Chip
              icon={icon}
              color="info"
              label={data.label}
              onDelete={handleDelete(data)}
            />
          </ListItem>
        );
      })}
      <AddCircleIcon onClick={toggleAddDialog} />
      {showAddVariableDialog ? (
        <GenericDialog
          show={showAddVariableDialog}
          heading="Add dynamic value"
          inputProps={generateInputProps()}
          onClose={toggleAddDialog}
          handleSubmit={(
            event: React.MouseEvent<HTMLButtonElement, MouseEvent>
          ) => {
            event.preventDefault();
            let currentValue = value;
            setValue("");
            toggleAddDialog(event);
            props.addValueToChip(event, currentValue);
          }}
        />
      ) : (
        ""
      )}
    </Paper>
  );
}
