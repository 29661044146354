import React from "react";
import { Grid } from "@material-ui/core";
import { makeGETRequestOnUserService, makePOSTRequest } from "../../Api/api";
import { Button } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import KYCModuleErroneousRecords from "./KYCModuleErroneousRecords";
const toastr = require("toastr");

export default function KYCModule(props: any): React.ReactElement {
  const [showFileUploadDialog, setShowFileUploadDialog] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [showErroneousRecordsDialog, setShowErroneousRecordsDialog] =
    React.useState(false);

  function uploadExcelFileForKYCModule(event: any) {
    event.preventDefault();
    setShowFileUploadDialog(!showFileUploadDialog);
  }

  function manipulateShowErroneousDialogState(event: any) {
    event.preventDefault();
    setShowErroneousRecordsDialog(!showErroneousRecordsDialog);
  }

  function uploadFile(file: any): void {
    let formData = new FormData();
    formData.append("excelFile", file);
    makePOSTRequest("/admin/kyc_module/docs/generate/", formData)
      .then((_response) => {
        toastr.success("Uploaded excel file successfully!");
      })
      .catch((error) => toastr.error(error));
  }

  const b64toBlob = (b64Data: any, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  function downloadDigioDocs(event: any) {
    event.preventDefault();
    setShowLoader(true);
    makeGETRequestOnUserService("/digio/download_digio_docs/")
      .then((response) => {
        var result = response.data;
        var a = document.createElement("a");
        if (window.URL && window.Blob && "download" in a) {
          var blob = b64toBlob(result.download.data, result.download.mimetype);
          var url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = result.download.filename;
          a.click();
          window.URL.revokeObjectURL(url);
        }
        toastr.success("Download started...");
        setShowLoader(false);
        props.handleClose(event);
      })
      .catch((error) => console.error(error));
  }

  return (
    <>
      {showFileUploadDialog ? (
        <Grid item xs={12}>
          <input
            type="file"
            name="file"
            onChange={(event) => {
              if (!event.target.files) return;
              else uploadFile(event?.target?.files[0]);
            }}
          />
        </Grid>
      ) : (
        ""
      )}

      <Button
        className="btnClass"
        variant="contained"
        onClick={manipulateShowErroneousDialogState}
        color="error"
        style={{
          float: "right",
          margin: "25px 10px 15px",
          cursor: "pointer",
        }}
      >
        Erroneous
      </Button>

      <Button
        className="btnClass"
        variant="outlined"
        onClick={uploadExcelFileForKYCModule}
        color="primary"
        style={{
          float: "right",
          margin: "25px 10px 15px",
          cursor: "pointer",
        }}
      >
        Upload
      </Button>
      {showLoader ? (
        <CircularProgress
          style={{
            float: "right",
            margin: "25px 10px 15px",
            cursor: "pointer",
          }}
        />
      ) : (
        <Button
          className="btnClass"
          variant="outlined"
          onClick={downloadDigioDocs}
          color="primary"
          style={{
            float: "right",
            margin: "25px 10px 15px",
            cursor: "pointer",
          }}
        >
          Download Zip
        </Button>
      )}
      {showErroneousRecordsDialog ? (
        <KYCModuleErroneousRecords
          open={showErroneousRecordsDialog}
          handleClose={manipulateShowErroneousDialogState}
        />
      ) : (
        ""
      )}
    </>
  );
}
