import React from "react";
import { renderToString } from "react-dom/server";
import ReactApexChart from "react-apexcharts";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";

import {
  Avatar,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import ThumbDownOffAltTwoToneIcon from "@mui/icons-material/ThumbDownOffAltTwoTone";
import ThumbUpOffAltTwoToneIcon from "@mui/icons-material/ThumbUpOffAltTwoTone";
import BulletPoint from "../../images/BulletPoint.png";
import RatingStar from "../../images/RatingStar.png";
import { ApexOptions } from "apexcharts";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

interface IProfitRevenue {
  profit: string;
  revenue: string;
}

interface IAxisChartData {
  series: ApexAxisChartSeries;
  options: ApexOptions;
}

interface INonAxisChartData {
  series: ApexNonAxisChartSeries;
  options: ApexOptions;
}

const fontFamily = "Lexend";
const styles = {
  section: {
    fontFamily,
    background: "#ffffff 0% 0% no-repeat padding-box",
    borderRadius: 3,
    p: 1.5,
    mb: 1.5,
  },
  heading: {
    fontFamily,
    fontWeight: "bold",
    fontSize: [12, 13],
    color: "#2a324c",
    mb: 1,
  },
  value: {
    fontFamily,
    fontSize: [12, 13],
  },
  button: {
    fontFamily,
    textTransform: "none",
    m: [1, 1, 0],
    px: 1,
    color: "#EC4F56",
    background: "#ffe8e8",
    borderRadius: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ":hover": {
      color: "#EC4F56",
      background: "#ffe8e8",
      outline: "2px solid #EC4F56",
      outlineOffset: 2,
    },
  },
};

const lineChart: IAxisChartData = {
  series: [
    {
      name: "Revenue (CR)",
      data: [
        {
          x: 1996,
          y: 322,
        },
        {
          x: 1997,
          y: 324,
        },
        {
          x: 1998,
          y: 329,
        },
        {
          x: 1999,
          y: 342,
        },
        {
          x: 2000,
          y: 348,
        },
        {
          x: 2001,
          y: 334,
        },
        {
          x: 2002,
          y: 325,
        },
        {
          x: 2003,
          y: 316,
        },
        {
          x: 2004,
          y: 318,
        },
        {
          x: 2005,
          y: 330,
        },
        {
          x: 2006,
          y: 355,
        },
        {
          x: 2007,
          y: 366,
        },
        {
          x: 2008,
          y: 337,
        },
        {
          x: 2009,
          y: 352,
        },
        {
          x: 2010,
          y: 377,
        },
        {
          x: 2011,
          y: 383,
        },
        {
          x: 2012,
          y: 344,
        },
        {
          x: 2013,
          y: 366,
        },
        {
          x: 2014,
          y: 389,
        },
        {
          x: 2015,
          y: 334,
        },
      ],
    },
    {
      name: "Profit (CR)",
      data: [
        {
          x: 1996,
          y: 162,
        },
        {
          x: 1997,
          y: 90,
        },
        {
          x: 1998,
          y: 50,
        },
        {
          x: 1999,
          y: 77,
        },
        {
          x: 2000,
          y: 35,
        },
        {
          x: 2001,
          y: -45,
        },
        {
          x: 2002,
          y: -88,
        },
        {
          x: 2003,
          y: -120,
        },
        {
          x: 2004,
          y: -156,
        },
        {
          x: 2005,
          y: -123,
        },
        {
          x: 2006,
          y: -88,
        },
        {
          x: 2007,
          y: -66,
        },
        {
          x: 2008,
          y: -45,
        },
        {
          x: 2009,
          y: -29,
        },
        {
          x: 2010,
          y: -45,
        },
        {
          x: 2011,
          y: -88,
        },
        {
          x: 2012,
          y: -132,
        },
        {
          x: 2013,
          y: -146,
        },
        {
          x: 2014,
          y: -169,
        },
        {
          x: 2015,
          y: -184,
        },
      ],
    },
  ],
  options: {
    chart: {
      type: "area",
      height: "100%",
      offsetX: -15,
      width: "100%",
      toolbar: { show: false },
      fontFamily: "Lexend",
      foreColor: "#8e8da4",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      lineCap: "round",
      width: 2,
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {},
        offsetY: 0,
        offsetX: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      tickAmount: 10,
      floating: false,
      labels: {
        style: {},
        offsetY: 0,
        offsetX: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      opacity: 0.5,
    },
    tooltip: {
      x: {
        format: "yyyy",
      },
      fixed: {
        enabled: false,
        position: "topRight",
      },
    },
    grid: {
      yaxis: {
        lines: {
          offsetX: -30,
        },
      },
      padding: {
        left: 10,
      },
    },
    legend: {
      show: true,
      offsetY: 400,
      markers: {
        width: 40,
      },
      itemMargin: {
        horizontal: 15,
      },
      containerMargin: {
        top: 20,
      },
    },
  },
};

const prosCons = (pros: Array<any>, cons: Array<any>) => {
  if (!(pros || cons)) return [];
  const big = pros.length > cons.length ? pros : cons;
  let arr = [];
  for (let i = 0; i < big.length; i++) arr.push({ pro: pros[i], con: cons[i] });
  return arr;
};

export default function AnalysisReportPreview(props: any): React.ReactElement {
  const { report } = props;

  function getFormattedSeries(): number[] {
    return report?.shareholdingPatterns?.map((shareholdingPattern: any) =>
      typeof shareholdingPattern.value === "string"
        ? parseInt(shareholdingPattern.value)
        : shareholdingPattern.value
    );
  }

  function getLabels(): string[] {
    return report?.shareholdingPatterns?.map(
      (shareholdingPattern: any) => shareholdingPattern.tag
    );
  }

  const CustomTooltip = (op: any) => {
    return (
      <Grid container p={2.5}>
        <Grid item xs={6}>
          <Typography variant="body1" sx={styles.value}>
            Promoters
          </Typography>
          <Typography variant="body1" sx={styles.value}>
            {getLabels()[op.op.seriesIndex]}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Typography variant="body1" sx={styles.value}>
            Shares
          </Typography>
          <Typography variant="body1" sx={styles.value}>
            {op.op.series[op.op.seriesIndex]}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const pieChart: INonAxisChartData = {
    series: getFormattedSeries(),
    options: {
      chart: {
        fontFamily: "Lexend",
        foreColor: "#8e8da4",
        type: "pie",
      },
      tooltip: {
        onDatasetHover: {
          highlightDataSeries: true,
        },
        fillSeriesColor: true,
        custom: (options) => renderToString(<CustomTooltip op={options} />),
      },
      labels: getLabels(),
      legend: {
        position: "bottom",
        show: true,
        offsetY: 400,
        markers: {
          width: 40,
        },
        itemMargin: {
          horizontal: 15,
        },
        containerMargin: {
          top: 20,
        },
      },
    },
  };

  return (
    <>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} md={7}>
          <Grid container>
            <Grid item xs={12} sx={styles.section}>
              <Typography variant="h6" sx={styles.heading}>
                About The Issuer
              </Typography>
              <Typography variant="body1" sx={styles.value}>
                {report.aboutTheIssuer || "NA"}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={styles.section}>
              <Typography variant="h6" sx={styles.heading}>
                Rating Drivers
              </Typography>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <ThumbUpOffAltTwoToneIcon
                    sx={{ mr: 1, mt: 0.3, color: "green", fontSize: 17 }}
                  />
                  <Typography variant="h6" sx={styles.heading}>
                    Pros
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <ThumbDownOffAltTwoToneIcon
                    sx={{ mr: 1, mt: 0.5, color: "red", fontSize: 17 }}
                  />
                  <Typography variant="h6" sx={styles.heading}>
                    Cons
                  </Typography>
                </Grid>
                {prosCons(report.pros, report.cons).map(
                  ({ pro, con }, index) => (
                    <>
                      {pro && (
                        <Grid
                          item
                          xs={6}
                          key={index}
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <StarBorderRoundedIcon
                            sx={{
                              mr: 1,
                              mt: 0.1,
                              color: "green",
                              fontSize: 16,
                            }}
                          />
                          <Typography variant="body1" sx={styles.value}>
                            {typeof pro === "string" ? pro : pro.pro}
                          </Typography>
                        </Grid>
                      )}
                      {con && (
                        <Grid
                          item
                          xs={6}
                          key={index}
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <StarBorderRoundedIcon
                            sx={{ mr: 1, mt: 0.1, color: "red", fontSize: 16 }}
                          />
                          <Typography variant="body1" sx={styles.value}>
                            {typeof con === "string" ? con : con.con}
                          </Typography>
                        </Grid>
                      )}
                    </>
                  )
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={styles.section}>
              <Typography variant="h6" sx={styles.heading}>
                Lookout on financials
              </Typography>
              <ReactQuill
                value={report.lookoutOnFinancials}
                readOnly={true}
                theme={"bubble"}
              />
            </Grid>
            <Grid item xs={12} sx={styles.section}>
              <Typography variant="h6" sx={styles.heading}>
                Use of the issue
              </Typography>
              <Typography variant="body1" sx={styles.value}>
                {report.useOfTheIssue || "NA"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          <Grid container>
            <Grid item xs={12} sx={styles.section}>
              <Typography variant="h6" sx={styles.heading}>
                Management
              </Typography>
              <List component="ul" disablePadding>
                {report?.managementRows?.map((managementRow: any) => (
                  <ListItem alignItems="flex-start" disableGutters>
                    <ListItemAvatar
                      sx={{ minWidth: "max-content", mt: 0, mr: 1.3 }}
                    >
                      <img
                        alt={managementRow.name}
                        src={`data:image/png;base64,${managementRow?.avatar}`}
                        style={{
                          width: 120,
                          height: 120,
                          border: "6px solid #FFFFFF",
                          boxShadow: "10px 10px 30px 0px #2A324C33",
                        }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="h6" sx={styles.heading}>
                          {managementRow.name}
                        </Typography>
                      }
                      secondary={
                        <List component="ul" dense disablePadding>
                          <ListItem
                            alignItems="flex-start"
                            disableGutters
                            disablePadding
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: "max-content",
                                mt: 0.7,
                                mr: 0.8,
                              }}
                            >
                              <img src={BulletPoint} alt="Bullet Point" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body1" sx={styles.value}>
                                  {managementRow.information}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </List>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} sx={styles.section}>
              <Typography variant="h6" sx={styles.heading}>
                Interest Payment History
              </Typography>
              <Container
                disableGutters
                sx={{
                  display: "flex",
                  alignItems: ["flex-start", "center"],
                  mt: 2,
                }}
              >
                <Container
                  disableGutters
                  sx={{
                    m: "0 15px 10px 0 !important",
                    display: "flex",
                    flexDirection: "row",
                    width: "max-content",
                  }}
                >
                  {Array(report.interestPaymentHistory || 0).fill(
                    <img src={RatingStar} alt="Rating Star" />
                  )}
                </Container>
                <Typography variant="body1" sx={styles.value}>
                  {report.interestPaymentHistoryText || "NA"}
                </Typography>
              </Container>
            </Grid>
            <Grid item xs={12} sx={styles.section}>
              <Typography variant="h6" sx={styles.heading}>
                Quarterly Revenue as compared to Net Profit
              </Typography>
              <ReactApexChart
                options={lineChart.options}
                series={lineChart.series}
                type="area"
                height={350}
              />
            </Grid>
            <Grid item xs={12} sx={styles.section}>
              <Typography variant="h6" sx={styles.heading}>
                Shareholding Pattern
              </Typography>
              <ReactApexChart
                options={pieChart.options}
                series={pieChart.series}
                type="pie"
                height={380}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
