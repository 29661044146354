import React, { useEffect, useContext, useState } from "react";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { appContext } from "../App";
import { getAllHolidayMasters, deleteHolidayMaster } from "../Api/api";
import AddHolidayMaster from "../components/AddHolidayMaster";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { Grid } from "@material-ui/core";
import IdleTimoutConfig from "../Helpers/IdleTimeoutConfig";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "96%",
    margin: "35px auto",
    height: " 0px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const HolidayMaster = (props) => {
  const classes = useStyles();
  const { authUser, setAuthUser } = useContext(appContext);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [holidayMasters, setHolidayMasters] = React.useState([]);
  const [showAddHolidayMasterModal, setShowAddHolidayMasterModal] =
    React.useState(false);
  const [showDeleteHolidayMasterModal, setShowDeleteHolidayMasterModal] =
    React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  useEffect(() => {
    if (authUser && authUser.uuid) {
      getAllHolidayMasters()
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            setRows(response.data);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, [showAddHolidayMasterModal]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleDeleteIconOnClick = (row) => {
    if (authUser && authUser.uuid) {
      let holidayMasterUUID = row.uuid;
      deleteHolidayMaster(holidayMasterUUID)
        .then((response) => {
          if (response && response.status === 204) {
            toastr.success("Successfully deleted Holiday Master");
            let filtered = rows.filter(
              (holidayMaster) => holidayMaster.uuid != holidayMasterUUID
            );
            setRows(filtered);
          }
        })
        .catch((error) => {
          toastr.error(error);
        });
    }
  };

  const handleAddHolidayMasterOnclick = (event) => {
    event.preventDefault();
    setShowAddHolidayMasterModal(true);
  };

  const handleOnCloseModal = () => {
    setShowAddHolidayMasterModal(false);
    getAllHolidayMasters()
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          setHolidayMasters(response.data);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const [rows, setRows] = React.useState([]);
  const dataGridCells = [
    { field: "serial", headerName: "Serial", width: 190, editable: false },
    { field: "date", headerName: "Date", width: 250, editable: false },
    { field: "remarks", headerName: "Remarks", width: 300, editable: false },
    { field: "action", headerName: "Action", width: 250, editable: false },

    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,

      renderCell: () => {
        return (
          <DeleteIcon
            color="primary"
            fontSize="medium"
            style={{
              float: "left",
              "margin-top": "10px",
              "margin-left": "14px",
              cursor: "pointer",
            }}
          />
        );
      },
    },
  ];
  const currentlySelected = (params) => {
    params.field === "delete" && handleDeleteIconOnClick(params.row);
  };
  return (
    <Grid className="holidayMastersDataGrid">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>
                {" "}
                Holiday Master{" "}
              </div>
            </Grid>
            <Grid item xs="auto">
              <AddCircleIcon
                color="primary"
                fontSize="large"
                onClick={(event) => handleAddHolidayMasterOnclick(event)}
                style={{
                  float: "right",
                  "margin-top": "29px",
                  "margin-right": "30px",
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <DataGrid
            className="holidayMasterDataGrid abc"
            components={{ Toolbar: GridToolbar }}
            rows={rows}
            columns={dataGridCells}
            onCellClick={currentlySelected}
            getRowId={(row) => row.uuid}
            autoHeight
          />
        </div>

        <AddHolidayMaster
          openModal={showAddHolidayMasterModal}
          onCloseModal={() => handleOnCloseModal()}
        />
      </Paper>
      {/* <IdleTimoutConfig /> */}
    </Grid>
  );
};

export default HolidayMaster;
