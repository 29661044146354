import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import toastr from "toastr";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableFooter from "@material-ui/core/TableFooter";
import { makeGETRequestOnUserService } from "../../../Api/api";
import { appContext } from "../../../App";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData()];
export default function OrderHistoryTable(props) {
  const classes = useStyles();
  const { authUser, setAuthUser } = React.useContext(appContext);
  const [user, setUser] = React.useState([]);
  const { currentUser } = props;
  const [showAddOrderModal, setShowAddOrderModal] = React.useState(false);

  useEffect(() => {
    if (authUser && authUser.uuid) {
      let requestURL =
        "/order_management/getAllOrders/" + currentUser.uuid + "/";
      makeGETRequestOnUserService(requestURL)
        .then((response) => {
          if (response && response.status === 200) {
            response.data ? setUser(response.data) : setUser([]);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, []);
  const handleAddIconOnClick = (event) => {
    event.preventDefault();
    setShowAddOrderModal(true);
  };
  const closeModal = () => {
    setShowAddOrderModal(false);
  };

  return (
    <Paper className={classes.paper}>
      <div style={{ fontSize: "40px", float: "left" }}>Order History</div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell>Order Type</TableCell>
              <TableCell align="right">Order ID</TableCell>
              <TableCell align="right">UUID</TableCell>
              <TableCell align="right">Security name</TableCell>
              <TableCell align="right">ISIN</TableCell>
              <TableCell align="right">Settlement Type</TableCell>
              <TableCell align="right">No. of units</TableCell>
              <TableCell align="right">Purchase Price</TableCell>
              <TableCell align="right">Spread</TableCell>
              <TableCell align="right">Sell Price</TableCell>
              <TableCell align="right">Sell Yield</TableCell>
              <TableCell align="right">Order Status</TableCell>
              <TableCell>Principal</TableCell>
              <TableCell align="right">Accrued</TableCell>
              <TableCell align="right">Stamp Duty</TableCell>
              <TableCell align="right">TCS</TableCell>
              <TableCell align="right">Transaction Cost</TableCell>
              <TableCell align="right">Payment Date</TableCell>
              <TableCell align="right">Settlement Type</TableCell>
              <TableCell align="right">Total inv Amt</TableCell>
              <TableCell align="right">Amount blocked</TableCell>
              <TableCell align="right">Amount Available</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.orderType}
                </TableCell>
                <TableCell align="right">{row.orderID}</TableCell>
                <TableCell align="right">{row.uuid}</TableCell>
                <TableCell align="right">{row.securityName}</TableCell>
                <TableCell align="right">{row.isin}</TableCell>
                <TableCell align="right">{row.settlementType}</TableCell>
                <TableCell align="right">{row.noOfUnits}</TableCell>
                <TableCell align="right">{row.purchasePrice}</TableCell>
                <TableCell align="right">{row.spread}</TableCell>
                <TableCell align="right">{row.sellPrice}</TableCell>
                <TableCell align="right">{row.sellYield}</TableCell>
                <TableCell align="right">{row.orderStatus}</TableCell>
                <TableCell align="right">{row.principal}</TableCell>
                <TableCell align="right">{row.accrued}</TableCell>
                <TableCell align="right">{row.stampDuty}</TableCell>
                <TableCell align="right">{row.tcs}</TableCell>
                <TableCell align="right">{row.transactionCost}</TableCell>
                <TableCell align="right">{row.paymentDate}</TableCell>
                <TableCell align="right">{row.settlementType}</TableCell>
                <TableCell align="right">{row.totalInvAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
