import React from 'react';
import { Grid, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@material-ui/core/Typography";


const DateTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginLeft: 5,
  },
}))(Tooltip);

export default function BoxTableHeader(props : any) {
    return (
        <Grid container >
        <Grid item xs={2} md={2} >
          <DateTip
            placement="right"
            interactive
            title="Select All"
            enterTouchDelay={10}
          >
            <Checkbox
            style={{
              left: "28px",
              bottom: "10px"
            }}
              checked={props.checked}
              onChange={props.onSelectAll}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
          </DateTip>
        </Grid>
        <Grid item xs={2} md={4}>
          <Typography variant="h5" noWrap>
            Mobile Number
          </Typography>
        </Grid>
        <Grid item xs={2} md={2}>
          <Typography variant="h5" noWrap>
            Name
          </Typography>
        </Grid>
        <Grid item xs={2} md={4}>
          <Typography variant="h5" noWrap>
            Send Message
          </Typography>
        </Grid>
      </Grid>
    )
}