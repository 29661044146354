export default class UserHelpers {
  constructor() {
    this.kycDetailsDefaultState = {
      image: "",
      details: false,
      type: "",
      label: "",
      extension: "",
      backSideImage: "",
      backSideImageName: "",
    };
  }
  static getFormattedKYCDocumentsForStepper(
    panCardDetails,
    proofOfAddressDetails,
    bankDetails,
    dematDetails
  ) {
    let formatted = [];
    if (panCardDetails === null) {
      formatted.push(this.kycDetailsDefaultState);
    } else {
      formatted.push({
        image: panCardDetails.panCardImage,
        details: panCardDetails,
        type: "panCard",
        extension: panCardDetails.extension,
        backSideImage: "",
        backSideImageName: "",
      });
    }

    if (proofOfAddressDetails !== null) {
      formatted.push({
        image: proofOfAddressDetails.image,
        details: proofOfAddressDetails,
        type: "proofOfAddress",
        extension:
          "type" in proofOfAddressDetails &&
          proofOfAddressDetails.type === "Aadhar"
            ? proofOfAddressDetails.extension
            : "",
        backSideImage:
          "type" in proofOfAddressDetails &&
          proofOfAddressDetails.type === "Aadhar"
            ? proofOfAddressDetails?.backSideImage
            : "",
        backSideImageName:
          "type" in proofOfAddressDetails &&
          proofOfAddressDetails.type === "Aadhar"
            ? proofOfAddressDetails?.backSideImageName
            : "",
      });
    } else {
      formatted.push(this.kycDetailsDefaultState);
    }

    if (bankDetails !== null) {
      formatted.push({
        image: bankDetails.chequeImage,
        details: bankDetails,
        type: "bankDetails",
        extension: "",
      });
    } else {
      formatted.push(this.kycDetailsDefaultState);
    }

    if (dematDetails !== null) {
      formatted.push({
        image: dematDetails.image,
        details: dematDetails,
        type: "dematDetails",
        extension: dematDetails.extension,
      });
    } else {
      formatted.push(this.kycDetailsDefaultState);
    }
    return formatted;
  }

  static getValueOrEmptyForDemat(detailsObject, key) {
    if (!detailsObject) {
      return "";
    } else return detailsObject[key];
  }

  static getValueOrEmptyForPan(panCardDetails, key) {
    if (panCardDetails === undefined) {
      return "";
    } else return panCardDetails.details[key];
  }

  static convertedToBlob(selectedFile) {
    return new Blob([selectedFile], { type: selectedFile.type });
  }

  static removePrefix(base64) {
    if (base64.includes("base64")) {
      return base64.split("base64,")[1];
    } else return base64;
  }

  static removeDeletedChildAccount(childAccounts, uuid) {
    return childAccounts.filter((account) => account.uuid !== uuid);
  }
}
