import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { TextField, Grid } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textField: {
    width: "184px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RegistrarDetailsDialog = (props) => {
  const classes = useStyles();
  const { show, onCloseDialog, onSubmit, onChange, currentState } = props;

  const resetStateAndClose = (event) => {
    event.preventDefault();
    onCloseDialog(event);
  };

  const renderNameOfRegistrar = () => {
    return (
      <TextField
        type="text"
        name="nameOfRegistrar"
        variant="outlined"
        fullWidth
        label="Name of Registrar"
        defaultValue={currentState.nameOfRegistrar}
        onChange={(event) => onChange(event)}
      />
    );
  };

  const renderRegistrarAddress = () => {
    return (
      <TextField
        type="text"
        name="address"
        variant="outlined"
        fullWidth
        label="Registrar Address"
        defaultValue={currentState.address}
        onChange={(event) => onChange(event)}
      />
    );
  };

  const renderPhone = () => {
    return (
      <TextField
        type="text"
        name="phone"
        variant="outlined"
        fullWidth
        label="Phone"
        defaultValue={currentState.phone}
        onChange={(event) => onChange(event)}
      />
    );
  };

  const renderEmail = () => {
    return (
      <TextField
        type="text"
        name="email"
        variant="outlined"
        fullWidth
        label="Email"
        defaultValue={currentState.email}
        onChange={(event) => onChange(event)}
      />
    );
  };

  const renderWebsite = () => {
    return (
      <TextField
        type="text"
        name="website"
        variant="outlined"
        fullWidth
        label="Website"
        defaultValue={currentState.website}
        onChange={(event) => onChange(event)}
      />
    );
  };

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={(event) => resetStateAndClose(event)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Registrar Details</DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            <Grid item xs={12}>
              {renderNameOfRegistrar()}
            </Grid>
            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              {renderRegistrarAddress()}
            </Grid>

            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              {renderPhone()}
            </Grid>

            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              {renderEmail()}
            </Grid>

            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              {renderWebsite()}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(event) => resetStateAndClose(event)}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={(event) => onSubmit(event)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RegistrarDetailsDialog;
