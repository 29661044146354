import React from "react";
import {
  makeDELETERequestOnUserServiceWithQueryParameter,
  makeGETRequestOnUserService,
} from "../Api/api";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import DeleteIcon from "@material-ui/icons/Delete";
import GenericDialog from "../components/Dialog/GenericDialog";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import IdleTimoutConfig from "../Helpers/IdleTimeoutConfig";

function getFilteredEmailVerificationRecords(
  uuid: string,
  emailVerificationRecords: EmailVerificationRecord[]
) {
  return emailVerificationRecords.filter(
    (emailVerificationRecord: EmailVerificationRecord) =>
      emailVerificationRecord.uuid !== uuid
  );
}

const headCells = [
  { id: "id", numeric: true, disablePadding: false, label: "Serial No" },
  {
    id: "Email Id",
    numeric: true,
    disablePadding: false,
    label: "Email Id",
  },
  {
    id: "Verification Code",
    numeric: true,
    disablePadding: false,
    label: "Verification Code",
  },

  {
    id: "Inserted At",
    numeric: true,
    disablePadding: false,
    label: "Inserted At",
  },
  { id: "Delete", numeric: false, disablePadding: true, label: "Delete" },
];

function EnhancedTableHead(props: any) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function descendingComparator(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "96%",
    margin: "35px auto",
    height: " 0px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function stableSort(array: any, comparator: any) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

function getComparator(order: string, orderBy: string) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

interface EmailVerificationRecord {
  uuid: string;
  insertedAt: string;
  otp: string;
  updatedAt: string;
  emailId: string;
}

export default function EmailVerifications(props: any) {
  const toastr = require("toastr");
  const moment = require("moment");
  const classes = useStyles();

  const initialState = {
    uuid: "",
    emailId: "",
    otp: "",
    insertedAt: "",
    updatedAt: "",
  };

  const [emailVerificationRecords, setEmailVerificationRecords] =
    React.useState<EmailVerificationRecord[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState<any>([]);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [toBeDeleted, setToBeDeleted] =
    React.useState<EmailVerificationRecord>(initialState);

  React.useEffect(() => {
    makeGETRequestOnUserService("/email_verifications/list/")
      .then((response) => {
        if (response && response.status === 200)
          setEmailVerificationRecords(response.data);
      })
      .catch((error) => toastr.error(error));
  }, []);

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (name: any[]) => selected.indexOf(name) !== -1;

  const renderDeleteIcon = (row: any, labelId: string) => {
    return (
      <TableCell id={labelId} scope="row" padding="none">
        <DeleteIcon
          onClick={(event) => {
            event.preventDefault();
            setShowDeleteModal(!showDeleteModal);
            setToBeDeleted(row);
          }}
          color="primary"
          style={{
            cursor: "pointer",
          }}
        />
      </TableCell>
    );
  };

  if (emailVerificationRecords.length === 0) {
    return (
      <Box
        sx={{ width: 900 }}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <h1> Nothing to show here :(</h1>
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Box>
    );
  } else {
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div style={{ fontSize: "40px", float: "left" }}>
            Email Verification Records
          </div>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={emailVerificationRecords.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={emailVerificationRecords.length}
                onSelectAllClick={() => {}}
              />
              <TableBody>
                {stableSort(
                  emailVerificationRecords,
                  getComparator(order, orderBy)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: any) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell id={labelId} scope="row" padding="default">
                          {row.emailId}
                        </TableCell>
                        <TableCell id={labelId} scope="row" padding="default">
                          {row.otp}
                        </TableCell>
                        <TableCell id={labelId} scope="row" padding="default">
                          {moment(row.insertedAt).format("DD MMM, YYYY")}
                        </TableCell>
                        {renderDeleteIcon(row, labelId)}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {showDeleteModal ? (
          <GenericDialog
            show={showDeleteModal}
            heading="Delete Email Verification Record "
            inputProps={
              "Are you sure you want to delete this email verification record?"
            }
            onClose={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.preventDefault();
              setShowDeleteModal(!showDeleteModal);
            }}
            handleSubmit={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.preventDefault();
              makeDELETERequestOnUserServiceWithQueryParameter(
                `email_verifications/delete/${toBeDeleted.uuid}/`
              ).then((response: any) => {
                if (response.status === 204 || response.status === 200) {
                  toastr.success("Deleted Email Verification successfully!");
                  setShowDeleteModal(!showDeleteModal);
                  setEmailVerificationRecords(
                    getFilteredEmailVerificationRecords(
                      toBeDeleted.uuid,
                      emailVerificationRecords
                    )
                  );
                  setToBeDeleted(initialState);
                }
              });
            }}
          />
        ) : (
          ""
        )}
        // <IdleTimoutConfig />
      </div>
    );
  }
}
