import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const isSuperuser = JSON.parse(localStorage.getItem("loginUser"))?.isSuperuser;

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const dataGridCells = [
  { field: "orderID", headerName: "Order ID", width: 190, editable: false },
  { field: "orderType", headerName: "Order Type", width: 150, editable: false },
  { field: "smestID", headerName: "Smest ID", width: 240, editable: false },
  { field: "orderDate", headerName: "Order Date", width: 190, editable: false },
  { field: "orderTime", headerName: "Order Time", width: 190, editable: false },
  {
    field: "customerName",
    headerName: "Customer Name",
    width: 190,
    editable: false,
  },
  {
    field: "orderStatus",
    headerName: "Order Status",
    width: 190,
    editable: false,
  },
  { field: "isin", headerName: "ISIN", width: 190, editable: false },
  {
    field: "securityName",
    headerName: "Security Name",
    width: 190,
    editable: false,
  },
  {
    field: "noOfUnits",
    headerName: "No Of Units",
    width: 190,
    editable: false,
  },
  {
    field: "settlementType",
    headerName: "Sell Settlement Type",
    width: 190,
    editable: false,
  },
  {
    field: "faceValue",
    headerName: "Order Face Value",
    width: 190,
    editable: false,
  },
  {
    field: "reasonForHold",
    headerName: "Reason For Hold",
    width: 190,
    editable: false,
  },
  {
    field: "edit",
    headerName: "Edit",
    sortable: false,
    width: 130,
    disableClickEventBubbling: true,
    renderCell: () => {
      return (
        <EditIcon
          color="primary"
          fontSize="medium"
          style={{
            float: "left",
            "margin-top": "10px",
            "margin-left": "14px",
            cursor: "pointer",
          }}
        />
      );
    },
  },
  {
    field: "delete",
    headerName: "Delete",
    sortable: false,
    width: 130,
    disableClickEventBubbling: true,
    renderCell: () => {
      if (isSuperuser) {
        return (
          <DeleteIcon
            color="primary"
            fontSize="medium"
            style={{
              float: "left",
              "margin-top": "10px",
              "margin-left": "14px",
              cursor: "pointer",
            }}
          />
        );
      }
    },
  },
];
