import axios from "axios";
import { axiosInstance } from "./setAuthorizationToken";
import { userServiceAxiosInstance } from "./setUserServiceToken";
import { authServiceAxiosInstance } from "./setAuthServiceToken";
import Axios from "axios";

var paymentServiceAxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_SERVICE_BASE_URL,
});
paymentServiceAxiosInstance.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = "Token " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

var activityLogsServiceAxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_ACTIVITY_LOGS_SERVICE_BASE_URL,
});
activityLogsServiceAxiosInstance.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = "Token " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const superuserLogin = (values) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`admin/superuser/login/`, values)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllIssuerNames = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/issuer_names/get_all/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllSecurities = (currentPageNumber) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/security?page=${currentPageNumber}`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllRatings = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/ratings/get_all/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createOrganizationName = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`admin/organization_name/add/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteOrganizationNameByUUID = (organizationNameUUID) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/organization_name/delete/${organizationNameUUID}/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllOrganizationNames = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/organization_names/get_all/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteRatingByUUID = (ratingUUID) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/rating/delete/${ratingUUID}/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

function generateHeadersWithToken(token) {
  return {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };
}

function generateRequestHeadersWithUserId(headers) {
  return {
    "Content-Type": "application/json",
    UserId: headers.userId,
    Authorization: `Token ${headers.accessToken}`,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    Origin: window.location.origin,
  };
}

export const makeSuperuserAdminNamesRequest = (requestURL, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .get(requestURL, {
        headers: generateRequestHeadersWithUserId(headers),
      })
      .then((result) => {
        if (result) resolve(result);
      })
      .catch((error) => reject(error));
  });
};

export const sendAdminUserOTP = (requestData, token = null) => {
  if (token) {
    return new Promise((resolve, reject) => {
      axios
        .post(`users/admin_user/send/otp/`, requestData, {
          headers: generateHeadersWithToken(token),
        })
        .then((result) => {
          if (result) resolve(result);
        })
        .catch((error) => reject(error));
    });
  } else {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`users/admin_user/send/otp/`, requestData)
        .then((result) => {
          if (result) {
            resolve(result);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export const sendOTPToMobile = (requestData, token = null) => {
  if (token) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin/otp/send/`, requestData, {
          headers: generateHeadersWithToken(token),
        })
        .then((result) => {
          if (result) {
            resolve(result);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`admin/otp/send/`, requestData)
        .then((result) => {
          if (result) {
            resolve(result);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export const validateOTPForAdminUser = (requestData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`users/admin_user/validate/otp/`, requestData)
      .then((result) => {
        if (result) resolve(result);
      })
      .catch((error) => reject(error));
  });
};

export const validateOTPForUser = (requestData, token) => {
  if (token) {
    return new Promise((resolve, reject) => {
      axios
        .post(`admin/otp/validate/`, requestData, {
          headers: generateHeadersWithToken(token),
        })
        .then((result) => {
          if (result) {
            resolve(result);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`admin/otp/validate/`, requestData)
        .then((result) => {
          if (result) {
            resolve(result);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export const changeSuperuserPassword = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(`admin/superuser/change_password/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllHolidayMasters = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/holiday_master/all/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteHolidayMaster = (holidayMasterUUID) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/holiday_master/delete/${holidayMasterUUID}/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createHolidayMaster = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`admin/holiday_master/add/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllIssuerTypes = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/issuer_type/all/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllIssuerMasters = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/issuer_master/all/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteIssuerMasterByUUID = (issuerMasterUUID) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/issuer_master/delete/${issuerMasterUUID}/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createIssuerMaster = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`admin/issuer_master/add/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createRating = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`admin/rating/add/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addIssuerType = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`admin/issuer_type/add/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteIssuerTypeByUUID = (issuerTypeUUID) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/issuer_type/delete/${issuerTypeUUID}/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllRatingOrganizationMasters = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/rating_organization_master/get/all/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteRatingOrganizationMasterByUUID = (
  ratingOrganizationMasterUUID
) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(
        `admin/rating_organization_master/delete/${ratingOrganizationMasterUUID}/`
      )
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addRatingOrganizationMaster = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`admin/rating_organization_master/add/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadBulkIssuerNames = (selectedFile) => {
  const token = localStorage.getItem("accessToken");
  let headers = {
    "content-type": "application/x-www-form-urlencoded",
    authorization: "Token " + token,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(`admin/issuer_name/bulk_upload/`, selectedFile, {
        headers: headers,
      })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadBulkRatings = (selectedFile) => {
  const token = localStorage.getItem("accessToken");
  let headers = {
    "content-type": "application/x-www-form-urlencoded",
    authorization: "Token " + token,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(`admin/rating/bulk_upload/`, selectedFile, {
        headers: headers,
      })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const bulkUploadOrganizationNames = (formData) => {
  const token = localStorage.getItem("accessToken");
  let headers = {
    "content-type": "application/x-www-form-urlencoded",
    authorization: "Token " + token,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(`admin/organization_name/bulk_upload/`, formData, {
        headers: headers,
      })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadBulkSecurityMaster = (formData) => {
  const token = localStorage.getItem("accessToken");
  let headers = {
    "content-type": "application/x-www-form-urlencoded",
    authorization: "Token " + token,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(`admin/security_master/bulk_upload/`, formData, {
        headers: headers,
      })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllSecurityTypes = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/security_types/get_all/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllRatingsAndOrganizationNames = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/rating_organization_master/get_all/rating/organization_name/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createSecurityMaster = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`admin/security_master/add/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createSecurityType = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`admin/security_type/add/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSecurityType = (uuid) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/security_type/delete/${uuid}/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSecurityMasterByISIN = (isin, uuid) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/security_master/delete/${isin}/user/${uuid}/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllIssuerMasterArticles = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/issuer_master/articles/get/all/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteIssuerMasterArticleByUUID = (issuerMasterArticleUUID) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/article/delete/${issuerMasterArticleUUID}/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createIssuerMasterArticle = (requestData) => {
  const token = localStorage.getItem("AccessToken");
  let headers = {
    "content-type": "application/x-www-form-urlencoded",
    authorization: "Bearer " + token,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(`admin/article/create/`, requestData, { headers: headers })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateIssuerMasterArticle = (requestData) => {
  const token = localStorage.getItem("AccessToken");
  let headers = {
    "content-type": "application/x-www-form-urlencoded",
    authorization: "Bearer " + token,
  };
  return new Promise((resolve, reject) => {
    axios
      .put(`admin/article/update/`, requestData, { headers: headers })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addIssuerName = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`admin/issuer_name/add/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteIssuerName = (issuerNameUUID) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/issuer_name/delete/${issuerNameUUID}/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllQuotes = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/quotes/get/all/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSecurityRatingOrganizationRelationship = (payload) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/rating_organization_master_relationship/delete/`, {
        data: payload,
      })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSecurityCouponRelationship = (payload) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/security_coupon_relationship/delete/`, { data: payload })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSecurityCallOptionRelationship = (payload) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/security_calloption_relationship/delete/`, {
        data: payload,
      })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSecurityPutOptionRelationship = (payload) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/security_putoption_relationship/delete/`, {
        data: payload,
      })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSecurityMaturityRelationship = (payload) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/security_maturity_relationship/delete/`, { data: payload })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateSecurityMaster = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(`admin/security_master/update/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getArticlesForIssuerMaster = (issuerMasterUUID) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/issuer_master_article/get/${issuerMasterUUID}/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addNewQuote = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`admin/quotes/calculate/v2/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllISINAndSecurityNames = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/security_master/isin/security_names/get/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteQuote = (quoteUUID, uuid) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/quotes/delete/${quoteUUID}/user/${uuid}/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateExistingQuote = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(`admin/quotes/update/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const pushAllQuotes = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(`admin/quotes/push/all/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTradeFloorState = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/tradefloor/state/get/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const changeTradeFloorModalState = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(`admin/tradefloor/state/change/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateIssuerMaster = (formData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(`admin/issuer_master/update/`, formData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const pushSingleQuote = (requestData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(`admin/quotes/push/`, requestData)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteAllQuotesFromTradefloor = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`admin/quotes/delete_all/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const checkIfSecurityMasterPresent = (isin) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`admin/security_master/check/${isin}/`)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makeGETRequest = (url) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makeDELETERequest = (url) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(url)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makeDELETERequestWithQueryParameter = (url) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(url)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makeDELETERequestOnUserServiceWithQueryParameter = (url) => {
  return new Promise((resolve, reject) => {
    userServiceAxiosInstance
      .delete(url)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const downloadSubscribers = (url) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url, { responseType: "blob" })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const downloadUserServiceFileUsingGET = (url) => {
  return new Promise((resolve, reject) => {
    userServiceAxiosInstance
      .get(url, { responseType: "blob" })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const downloadUserServiceFileUsingPOST = (url, requestBody) => {
  return new Promise((resolve, reject) => {
    userServiceAxiosInstance
      .post(url, requestBody, { responseType: "blob" })
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makePOSTRequestOnUserService = (url, requestBody) => {
  return new Promise((resolve, reject) => {
    userServiceAxiosInstance
      .post(url, requestBody)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makePUTRequestOnAuthService = (url, requestBody) => {
  return new Promise((resolve, reject) => {
    authServiceAxiosInstance
      .put(url, requestBody)
      .then((result) => {
        if (result) resolve(result);
      })
      .catch((error) => reject(error));
  });
};

export const makePUTRequestOnUserService = (url, requestBody) => {
  return new Promise((resolve, reject) => {
    userServiceAxiosInstance
      .put(url, requestBody)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makeGETRequestOnActivityLogsService = (url) => {
  return new Promise((resolve, reject) => {
    activityLogsServiceAxiosInstance
      .get(url)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((error) => reject(error));
  });
};

export const makeGETRequestOnUserServiceAndDownloadFileResponse = (url) => {
  return new Promise((resolve, reject) => {
    userServiceAxiosInstance
      .get(url, { responseType: "blob" })
      .then((response) => {
        if (response.status === 200) {
          const contentDisposition = response.headers["content-disposition"];
          const filename = contentDisposition
            ? contentDisposition.split("filename=")[1]
            : "KYC_Details.zip";
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          resolve({ blob, filename });
        } else {
          reject(
            new Error(`Failed to download ZIP file. Status: ${response.status}`)
          );
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makeGETRequestOnUserService = (url) => {
  return new Promise((resolve, reject) => {
    userServiceAxiosInstance
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makePOSTRequestOnPaymentService = (url, requestBody) => {
  return new Promise((resolve, reject) => {
    paymentServiceAxiosInstance
      .post(url, requestBody)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makePUTRequestOnPaymentService = (url, requestBody) => {
  return new Promise((resolve, reject) => {
    paymentServiceAxiosInstance
      .put(url, requestBody)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makeGETRequestOnPaymentService = (url) => {
  return new Promise((resolve, reject) => {
    paymentServiceAxiosInstance
      .get(url)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makePOSTRequest = (url, requestBody) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(url, requestBody)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const makePUTRequest = (url, requestBody) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(url, requestBody)
      .then((result) => {
        if (result) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
