import React, { useEffect } from "react";
import { makeGETRequestOnUserService } from "../../Api/api";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { addSerialNumber } from "../../Helpers/SecurityMasterHelpers";

const cashFlowDataGridCells = [
  { field: "serial", headerName: "Serial", width: 200, editable: false },
  { field: "isin", headerName: "ISIN", width: 200, editable: false },
  {
    field: "date",
    headerName: "Date",
    width: 200,
    editable: false,
  },
  { field: "amount", headerName: "Amount", width: 200, editable: false },
];

export default function CashFlowRecordsTab(props: any) {
  const toastr = require("toastr");
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [cashFlow, setCashFlow] = React.useState([]);

  useEffect(() => {
    makeGETRequestOnUserService(
      `/order_management/calendar/info/get/${props.currentUser.uuid}/`
    )
      .then((response: any) => {
        if (response.data.length === 0) {
          toastr.warning("There are no cash flow records to show.");
        } else {
          setCashFlow(response.data);
          setIsLoaded(true);
        }
      })
      .catch((error: any) => toastr.error(error));
  }, []);

  const currentlySelected = (params: any) => {};

  if (!isLoaded) {
    return (
      <Box
        sx={{ width: 900 }}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Box>
    );
  } else {
    return (
      <Grid
        style={{
          position: "absolute",
          left: "60%",
          top: "50%",
          bottom: "-10%",
          width: "90%",
          transform: "translate(-50%, -50%)",
        }}
        className="securityMasterDataGird"
      >
        <Paper>
          <div
            style={{
              height: "100vh",
              width: "70%",
            }}
          >
            <DataGrid
              pagination
              className="securityMasterDataGrid hgn"
              components={{ Toolbar: GridToolbar }}
              rows={addSerialNumber(cashFlow)}
              onCellClick={currentlySelected}
              columns={cashFlowDataGridCells}
              getRowId={(row) => row.serial}
            />
          </div>
        </Paper>
      </Grid>
    );
  }
}
