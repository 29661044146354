import React, { useEffect } from "react";
import toastr from "toastr";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function UpdateRatingModal(props) {
  return (
    <div>
      <Dialog
        fullWidth={props?.fullWidth || false}
        maxWidth={props?.maxWidth || "sm"}
        open={props.show}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.heading}</DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            {props.inputProps}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.showCancelButton && (
            <Button onClick={props.onClose}>
              {props.cancelButtonText ? props.cancelButtonText : "Cancel"}
            </Button>
          )}
          {props.showSubmitButton === false ? (
            ""
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={props.handleSubmit}
            >
              {props.submitButtonText ? props.submitButtonText : "Submit"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
