import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Grid } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { Select } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeGETRequest, makePUTRequestOnUserService } from "../../Api/api";
import TextField from "@mui/material/TextField";
import Listbox from "../Listbox";
import toastr from "toastr";
import { StyledTextInput } from "../StyledComponents/StyledInputs";
import { sxRoot, sxText, sxTypo, sxBox } from "../../Helpers/helpers";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

const convertToDate = (date) => {
  if (date) {
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = date.getFullYear();
    return dd + "/" + mm + "/" + yyyy;
  } else return "";
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BlueCheckbox = withStyles({
  root: {
    color: blue[800],
    "&$checked": {
      color: blue[800],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function EditUserDialog(props) {
  const classes = useStyles();
  const [currentState, setCurrentState] = React.useState(props.user);
  let accountTypeOptions = [
    "Individual Resident",
    "Individual Non-Resident",
    "Hindu Undivided Family",
    "Sole Proprietor",
    "Limited Liability Partnership",
    "Partnership",
    "Private Limited",
    "Trust",
    "Public Limited",
  ];
  const [leadOpen, setLeadOpen] = React.useState(false);
  const [lead, setLead] = React.useState({
    name: props.user?.leads?.leadowner,
    id: props.user?.leads?.lead_uuid,
    mobile: props.user?.leads?.leadownerphone,
    email: props.user?.leads?.leadowneremail,
  });
  const [options, setOptions] = React.useState([]);
  const loading = leadOpen && options.length === 0;
  const [statusOpen, setStatusOpen] = React.useState(false);
  const [status, setStatus] = React.useState(props.user?.leadsstatus || "Raw");
  const [followUpDate, setFollowUpDate] = React.useState(
    props.user?.leadstofollow_up || ""
  );
  const [remark, setRemark] = React.useState(props.user?.leadsremarks || "");
  const [ucc, setUCC] = React.useState(props.user?.ucc_external_id || "");

  function handleUCCUpdate() {
    makePUTRequestOnUserService("/order_management_v2/user/ucc_update/", {
      ucc_external_id: ucc,
      user_id: props.user?.uuid,
    })
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("UCC Updated successfully!");
        }
      })
      .catch((error) => toastr.error(error));
  }

  React.useEffect(() => {
    if (!leadOpen) {
      setOptions([]);
    }
  }, [leadOpen]);

  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    makeGETRequest("/users/all/list/")
      .then((response) => {
        if (response && response.status === 200) {
          const { data } = response;
          if (active) {
            if (response.data.length === 0) {
              toastr.warning("No Admin users found !");
            } else {
              const optionsArray = data.map((item) => ({
                name: `${item.firstName} ${item.lastName}`,
                id: item.uuid,
                mobile: item.mobileNumber,
                email: item.emailId,
              }));
              setOptions([...optionsArray]);
            }
          }
        }
      })
      .catch((error) => toastr.error(error));
    return () => {
      active = false;
    };
  }, [loading]);

  function handleChange(event) {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  }

  const handleCheckBox = (event) => {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.checked,
    }));
  };

  function renderAccountType() {
    return (
      <FormControl
        variant="outlined"
        className={classes.formControl}
        style={{ width: "80%" }}
      >
        <InputLabel id="demo-simple-select-outlined-label">
          Account Type
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          name="accountType"
          value={currentState.accountType}
          onChange={handleChange}
          label="Account Type"
        >
          {accountTypeOptions &&
            accountTypeOptions.length > 0 &&
            accountTypeOptions.map((accountTypeOption, index) => {
              return (
                <MenuItem value={accountTypeOption} key={index}>
                  {accountTypeOption}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  }

  if (props.open) {
    return (
      <div>
        <Dialog
          fullScreen
          open={props.open}
          onClose={props.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Update Details
              </Typography>
              <Button
                autoFocus
                color="inherit"
                onClick={(event) => {
                  props.handleSubmit(event, currentState);
                }}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <Box sx={{ width: ["100%", 400], mx: "auto", mt: 5 }}>
            <Autocomplete
              id={"user-select"}
              value={lead}
              onChange={(_, newValue) => {
                setLead(newValue);
                setCurrentState((previousValues) => ({
                  ...previousValues,
                  leads: {
                    ...previousValues.leads,
                    lead_uuid: newValue?.id ?? "",
                    leadowner: newValue?.name ?? "",
                    leadownerphone: newValue?.mobile ?? "",
                    leadowneremail: newValue?.email ?? "",
                  },
                }));
              }}
              open={leadOpen}
              onOpen={() => {
                setLeadOpen(true);
              }}
              onClose={() => {
                setLeadOpen(false);
              }}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => option.name}
              options={options}
              loading={loading}
              size="medium"
              sx={sxRoot}
              popupIcon={<ExpandMoreIcon />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Lead Owner"
                  sx={(theme) => sxText(theme)}
                  id={"user-select"}
                  fullWidth
                  margin="dense"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="error" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              renderOption={(props, option, { selected }) => (
                <Typography {...props} sx={sxTypo(selected)} variant="h6">
                  {option.name}
                </Typography>
              )}
              PaperComponent={({ children }) => {
                return <Box sx={sxBox}>{children}</Box>;
              }}
              ListboxComponent={Listbox}
            />
            <Autocomplete
              id={"user-status"}
              value={status}
              onChange={(_, newValue) => {
                setStatus(newValue);
                setCurrentState((previousValues) => ({
                  ...previousValues,
                  leadsstatus: newValue ?? "",
                  ...(newValue !== "Bad Timing" && { leadstofollow_up: "" }),
                  ...(newValue !== "Others" && { leadsremarks: "" }),
                }));
              }}
              open={statusOpen}
              onOpen={() => {
                setStatusOpen(true);
              }}
              onClose={() => {
                setStatusOpen(false);
              }}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => option}
              options={[
                "Raw",
                "Assigned",
                "Bad Timing",
                "In Talks",
                "Qualified",
                "Not Interested",
                "Others",
                "Existing Investor",
              ]}
              size="medium"
              sx={sxRoot}
              popupIcon={<ExpandMoreIcon />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Lead Status"
                  sx={(theme) => sxText(theme)}
                  id={"user-status"}
                  fullWidth
                  margin="dense"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
              renderOption={(props, option, { selected }) => (
                <Typography {...props} sx={sxTypo(selected)} variant="h6">
                  {option}
                </Typography>
              )}
              PaperComponent={({ children }) => {
                return <Box sx={sxBox}>{children}</Box>;
              }}
              ListboxComponent={Listbox}
            />
            {status === "Bad Timing" && (
              <FormControl
                fullWidth
                variant="standard"
                style={{ marginTop: 10 }}
              >
                <InputLabel shrink htmlFor="followUpDate">
                  Follow Up Date
                </InputLabel>
                <StyledTextInput
                  id="followUpDate"
                  type="date"
                  value={followUpDate}
                  onChange={(event) => {
                    setFollowUpDate(event.target.value);
                    setCurrentState((previousValues) => ({
                      ...previousValues,
                      leadstofollow_up: event.target.value ?? "",
                    }));
                  }}
                />
              </FormControl>
            )}
            {status === "Others" && (
              <FormControl
                fullWidth
                variant="standard"
                style={{ marginTop: 10 }}
              >
                <InputLabel shrink htmlFor="remark">
                  Remarks
                </InputLabel>
                <StyledTextInput
                  id="remark"
                  value={remark}
                  onChange={(event) => {
                    setRemark(event.target.value);
                    setCurrentState((previousValues) => ({
                      ...previousValues,
                      leadsremarks: event.target.value ?? "",
                    }));
                  }}
                />
              </FormControl>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <FormControl variant="standard" style={{ marginTop: 10 }}>
                <InputLabel shrink htmlFor="ucc">
                  Smest ID
                </InputLabel>
                <StyledTextInput
                  id="smestId"
                  disabled
                  value={props.user?.smestID}
                />
              </FormControl>
              <FormControl
                variant="standard"
                style={{ marginTop: 10, marginLeft: 10 }}
              >
                <InputLabel shrink htmlFor="ucc">
                  Update UCC
                </InputLabel>
                <StyledTextInput
                  id="ucc"
                  value={ucc}
                  onChange={(event) => {
                    setUCC(event.target.value);
                  }}
                />
              </FormControl>
              <IconButton
                sx={{ mb: 0.5, ml: 1, color: "#7659EE", p: 0 }}
                onClick={handleUCCUpdate}
              >
                <ArrowCircleRightTwoToneIcon fontSize="large" />
              </IconButton>
            </Box>
          </Box>
          <Grid className="editUserDetailsDialogSection">
            <Grid container spacing={3}>
              <Grid item lg={4} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">First Name</Grid>
                  <TextField
                    id="firstName"
                    name="firstName"
                    variant="standard"
                    value={currentState.firstName || ""}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <Grid item lg={4} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">Maiden Name</Grid>
                  <TextField
                    id="maidenName"
                    name="maidenName"
                    variant="standard"
                    value={currentState.maidenName || ""}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <Grid item lg={4} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">Last Name</Grid>
                  <Typography variant="h6" noWrap>
                    <TextField
                      id="lastName"
                      name="lastName"
                      variant="standard"
                      value={currentState.lastName || ""}
                      onChange={handleChange}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">Mobile Number</Grid>
                  <TextField
                    id="mobileNumber"
                    name="mobileNumber"
                    variant="standard"
                    value={currentState.mobileNumber || ""}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">Email ID</Grid>
                  <Typography variant="h6" noWrap>
                    <TextField
                      id="emailId"
                      name="emailId"
                      variant="standard"
                      value={currentState.emailId || ""}
                      onChange={handleChange}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">Father's Name</Grid>
                  <TextField
                    id="fathersName"
                    name="fathersName"
                    variant="standard"
                    value={currentState.fathersName || ""}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">Mother's Name</Grid>
                  <Typography variant="h6" noWrap>
                    <TextField
                      id="motherName"
                      name="motherName"
                      variant="standard"
                      value={currentState.motherName || ""}
                      onChange={handleChange}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">Gender</Grid>
                  <TextField
                    id="gender"
                    name="gender"
                    variant="standard"
                    value={currentState.gender || ""}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">maritalStatus</Grid>
                  <Typography variant="h6" noWrap>
                    <TextField
                      id="maritalStatus"
                      name="maritalStatus"
                      variant="standard"
                      value={currentState.maritalStatus || ""}
                      onChange={handleChange}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={12} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">Address</Grid>
                  <Typography variant="h6" noWrap>
                    <TextField
                      id="address"
                      name="address"
                      variant="standard"
                      style={{ width: "500px" }}
                      fullWidth
                      value={currentState.address || ""}
                      onChange={handleChange}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  {renderAccountType()}
                </Grid>
              </Grid>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">City</Grid>
                  <Typography variant="h6" noWrap>
                    <TextField
                      id="city"
                      name="city"
                      variant="standard"
                      value={currentState.city || ""}
                      onChange={handleChange}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">Pincode</Grid>
                  <TextField
                    id="pincode"
                    name="pincode"
                    variant="standard"
                    value={currentState.pincode || ""}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">State</Grid>
                  <Typography variant="h6" noWrap>
                    <TextField
                      id="state"
                      name="state"
                      variant="standard"
                      value={currentState.state || ""}
                      onChange={handleChange}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">Annual Income</Grid>
                  <TextField
                    id="annualIncome"
                    name="annualIncome"
                    variant="standard"
                    value={currentState.annualIncome || ""}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">Birth Country</Grid>
                  <Typography variant="h6" noWrap>
                    <TextField
                      id="birthCountry"
                      name="birthCountry"
                      variant="standard"
                      value={currentState.birthCountry || ""}
                      onChange={handleChange}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">Occupation</Grid>
                  <TextField
                    id="occupation"
                    name="occupation"
                    variant="standard"
                    value={currentState.occupation || ""}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">GSTIN</Grid>
                  <Typography variant="h6" noWrap>
                    <TextField
                      id="gstin"
                      name="gstin"
                      variant="standard"
                      value={currentState.gstin || ""}
                      onChange={handleChange}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Grid className="profileInfoDateTitle">Nationality</Grid>
                  <TextField
                    id="nationality"
                    name="nationality"
                    variant="standard"
                    value={currentState.nationality || ""}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Typography variant="h6" noWrap>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid item justify="space-around" xs={8}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog"
                          label="Date Of Birth"
                          format="dd/MM/yyyy"
                          value={currentState.dateOfBirth}
                          onChange={(date) => {
                            let state = currentState;
                            state.dateOfBirth = convertToDate(date);
                            setCurrentState(state);
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <FormControlLabel
                    control={
                      <BlueCheckbox
                        checked={currentState.hasPoliticalExposure}
                        onChange={handleCheckBox}
                        name="hasPoliticalExposure"
                      />
                    }
                    label="Has Political Exposure ?"
                  />
                </Grid>
              </Grid>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Typography variant="h6" noWrap>
                    <FormControlLabel
                      control={
                        <BlueCheckbox
                          checked={currentState.investedBefore}
                          onChange={handleCheckBox}
                          name="investedBefore"
                        />
                      }
                      label="Invested Before ?"
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <FormControlLabel
                    control={
                      <BlueCheckbox
                        checked={currentState.isPanCardSubmitted}
                        onChange={handleCheckBox}
                        name="isPanCardSubmitted"
                      />
                    }
                    label="Is Pan Card Submitted"
                  />
                </Grid>
              </Grid>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Typography variant="h6" noWrap>
                    <FormControlLabel
                      control={
                        <BlueCheckbox
                          checked={currentState.isAddressProofSubmitted}
                          onChange={handleCheckBox}
                          name="isAddressProofSubmitted"
                        />
                      }
                      label="Is Address Proof Submitted"
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <FormControlLabel
                    control={
                      <BlueCheckbox
                        checked={currentState.isChequeSubmitted}
                        onChange={handleCheckBox}
                        name="isChequeSubmitted"
                      />
                    }
                    label="Is Cheque Submitted"
                  />
                </Grid>
              </Grid>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Typography variant="h6" noWrap>
                    <FormControlLabel
                      control={
                        <BlueCheckbox
                          checked={currentState.isPennyDropComplete}
                          onChange={handleCheckBox}
                          name="isPennyDropComplete"
                        />
                      }
                      label="Is Penny Drop Complete"
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <FormControlLabel
                    control={
                      <BlueCheckbox
                        checked={currentState.isDematProofSubmitted}
                        onChange={handleCheckBox}
                        name="isDematProofSubmitted"
                      />
                    }
                    label="Is Demat Proof Submitted"
                  />
                </Grid>
              </Grid>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <Typography variant="h6" noWrap>
                    <FormControlLabel
                      control={
                        <BlueCheckbox
                          checked={currentState.isESignComplete}
                          onChange={handleCheckBox}
                          name="isESignComplete"
                        />
                      }
                      label="Is E-Sign Complete"
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <FormControlLabel
                    control={
                      <BlueCheckbox
                        checked={currentState.areKYCDetailsVerified}
                        onChange={handleCheckBox}
                        name="areKYCDetailsVerified"
                      />
                    }
                    label="Are KYC Details Verified"
                  />
                </Grid>
              </Grid>
              <Grid item lg={6} sm={12}>
                <Grid className="editUserDetailsDate">
                  <FormControlLabel
                    control={
                      <BlueCheckbox
                        checked={currentState.canSendWhatsappUpdates}
                        onChange={handleCheckBox}
                        name="canSendWhatsappUpdates"
                      />
                    }
                    label="Enable Whatsapp Updates ?"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </div>
    );
  } else {
    return "";
  }
}
