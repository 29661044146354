import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import {
  makePUTRequestOnUserService,
  makeGETRequestOnUserService,
} from "../../Api/api";
import toastr from "toastr";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { Base64 } from "js-base64";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

function checkIfUserCanCompleteKYC(props) {
  let personalDetails = {
    maritalStatus: props?.currentUser?.maritalStatus,
    hasPoliticalExposure: props?.currentUser?.hasPoliticalExposure,
    maidenName: props?.currentUser?.maidenName,
    motherName: props?.currentUser?.motherName,
    annualIncome: props?.currentUser?.annualIncome,
    gender: props?.currentUser?.gender,
    occupation: props?.currentUser?.occupation,
  };
  const propertiesToCheck = [
    "bankDetails",
    "dematDetails",
    "panCard",
    "proofOfAddressDetails",
  ];

  for (const property of propertiesToCheck) {
    if (!props[property]) {
      return false;
    }
  }

  return (
    props?.bankDetails &&
    Object.keys(props.bankDetails).length > 0 &&
    props?.panCard &&
    Object.keys(props.panCard).length > 0 &&
    props?.dematDetails &&
    Object.keys(props.dematDetails).length > 0 &&
    props?.proofOfAddressDetails &&
    Object.keys(props.proofOfAddressDetails).length > 0 &&
    personalDetails &&
    Object.keys(personalDetails).length > 0
  );
}

export default function CompleteKYC(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const canCompleteKYC = checkIfUserCanCompleteKYC(props);

  const markUserKYCAsComplete = async () => {
    try {
      let response = await makePUTRequestOnUserService("/kyc/mark_complete/", {
        user_id: props.userUUID,
        admin_name: JSON.parse(localStorage.getItem("loginUser"))?.name,
      });
      if (response && response.status === 200) {
        toastr.success("User KYC has been marked complete.");
      }
    } catch (error) {
      toastr.error(error);
    }
  };

  const completeUserKYC = (event) => {
    event.preventDefault();
    markUserKYCAsComplete();
  };

  function dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  function generateKYCDoc(event) {
    event.preventDefault();
    if (canCompleteKYC) {
      setIsLoading(true);
      makeGETRequestOnUserService(
        `/digio/doc/generate/${props.currentUser.uuid}/`
      )
        .then((response) => {
          setIsLoading(false);
          if (response && response.status === 200) {
            let encoded = Base64.encode(response.data);
            const blob = dataURItoBlob(encoded);
            const url = URL.createObjectURL(blob);
            window.open(url, "_blank");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toastr.error(error);
        });
    } else {
      toastr.warning("One or more required documents missing!");
    }
  }

  if (isLoading) {
    return (
      <Box
        sx={{ width: 900 }}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Box>
    );
  } else {
    if (canCompleteKYC) {
      return (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<SaveIcon />}
            onClick={completeUserKYC}
          >
            Complete KYC
          </Button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<SaveIcon />}
            onClick={generateKYCDoc}
          >
            Generate KYC Doc
          </Button>
        </div>
      );
    }
  }
}
