import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import Close from "@material-ui/icons/Close";
import toastr from "toastr";
import {
  TextField,
  Button,
  Grid,
  Fade,
  Backdrop,
  Modal,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { appContext } from "../App";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createOrganizationName } from "../Api/api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "184px",
  },
}));

const AddOrganizationNameModal = (props) => {
  const classes = useStyles();
  const { authUser, setAuthUser } = useContext(appContext);
  const [organizationName, setOrganizationName] = React.useState([]);

  useEffect(() => {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };
  }, []);

  const resetStateAndClose = (event) => {
    event.preventDefault();
    setOrganizationName("");
    props.onCloseModal(event);
  };

  const handleChange = (event) => {
    event.persist();
    setOrganizationName(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (authUser && authUser.uuid) {
      createOrganizationName({ name: organizationName })
        .then((response) => {
          if (response && response.status === 201) {
            toastr.success("Created organization name successfully !");
            setOrganizationName("");
            props.confirmAdd(event, response.data);
          }
        })
        .catch((error) => toastr.error(error));
    }
  };

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={(event) => resetStateAndClose(event)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Organization Name</DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            <Grid item xs={12}>
              <TextField
                type="text"
                name="organizationName"
                variant="outlined"
                fullWidth
                label="Organization Name"
                defaultValue={organizationName}
                onChange={(event) => handleChange(event)}
              />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(event) => resetStateAndClose(event)}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={(event) => submitHandler(event)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddOrganizationNameModal;
