import React from "react";
import GenericTable from "../GenericTable/GenericTable";
import GenericDialog from "../Dialog/GenericDialog";
import { Grid, Button, TextField } from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Management } from "./Interfaces";
import DeleteIcon from "@material-ui/icons/Delete";
import UserHelpers from "../Users/UserHelpers";

export default function ManagementComponent(props: any): React.ReactElement {
  const [showAddManagementModal, setShowAddManagementModal] =
    React.useState<boolean>(false);
  const managementInitialState = {
    name: "",
    information: "",
    avatar: "",
  };
  const [currentManagement, setCurrentManagement] = React.useState<Management>(
    managementInitialState
  );
  const [selectedFile, setSelectedFile] = React.useState<any>(null);
  const [base64, setBase64] = React.useState<string>("");

  React.useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => setBase64(UserHelpers.removePrefix(reader.result));
      reader.readAsDataURL(UserHelpers.convertedToBlob(selectedFile));
    }
  }, [selectedFile]);

  function handleChange(event: any): void {
    event.persist();
    setCurrentManagement((previousValues: any) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  }

  function handleChangeForFileUpload(event: any) {
    setSelectedFile(event.target.files[0]);
  }

  function generateInputProps(): React.ReactElement {
    return (
      <Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            name="name"
            variant="outlined"
            fullWidth
            label="Name"
            defaultValue={currentManagement.name}
            onChange={handleChange}
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <TextareaAutosize
            style={{ width: "100%" }}
            aria-label="minimum height"
            rowsMin={12}
            name="information"
            placeholder="Information"
            defaultValue={currentManagement.information}
            onChange={handleChange}
          />
        </Grid>
        <br />

        <Grid item xs={12}>
          <input
            accept="image/*"
            type="file"
            name="file"
            onChange={handleChangeForFileUpload}
          />
        </Grid>
      </Grid>
    );
  }

  function getFormattedManagementRowsWithJSX(
    managementRows: Management[]
  ): any[] {
    let formatted: any[] = [];
    if (managementRows) {
      managementRows.map((management: Management, index: number) => {
        formatted.push({
          index: index,
          name: management.name,
          information:
            typeof management.information === "string"
              ? management.information
              : management.information.join("; "),
          avatar: (
            <img
              src={`data:image/png;base64,${management?.avatar}`}
              alt={`data:image/png;base64,${props?.bankDetails?.chequeImage}`}
              style={{ width: 24, height: 24 }}
            />
          ),
          delete: (
            <DeleteIcon
              onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                event.preventDefault();
                props.removeFromRows(management.index);
              }}
              color="primary"
              style={{
                cursor: "pointer",
              }}
            />
          ),
        });
      });
    }
    return formatted;
  }

  return (
    <>
      <Grid className="holidayMastersDataGrid">
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              {props.heading}
            </Grid>
            <Grid item xs="auto">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  event.preventDefault();
                  setShowAddManagementModal(!showAddManagementModal);
                }}
              >
                Add Management Information
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <GenericTable
        columnNames={["Avatar", "Delete", "Information", "Name"]} // Make sure to arrange the column names in alphabetical order.
        rows={getFormattedManagementRowsWithJSX(props.managementRows)}
      />
      {showAddManagementModal ? (
        <GenericDialog
          show={showAddManagementModal}
          heading="Add Management"
          inputProps={generateInputProps()}
          onClose={(event: any) => {
            setSelectedFile(null);
            setShowAddManagementModal(!showAddManagementModal);
          }}
          handleSubmit={() => {
            let currentState = currentManagement;
            currentState.avatar = base64;
            props.addNewRow(currentState);
            setCurrentManagement(managementInitialState);
            setSelectedFile(null);
            setBase64("");
            setShowAddManagementModal(!showAddManagementModal);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
