import React from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { Grid, TextField, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Paper from "@material-ui/core/Paper";
import { BlackListedUser, UUIDAndMobileNumber } from "./Interfaces";
import GenericDialog from "../../components/Dialog/GenericDialog";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { makeGETRequestOnUserService } from "../../Api/api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IdleTimoutConfig from "../../Helpers/IdleTimeoutConfig";

const moment = require("moment");
const toastr = require("toastr");

function addSerialNumber(users: BlackListedUser[]): BlackListedUser[] {
  let withSerialNumber: BlackListedUser[] = [];
  for (let iter = 0; iter < users.length; iter++) {
    let updatedUser: BlackListedUser = users[iter];
    updatedUser.serial = iter + 1;
    updatedUser.insertedAt = moment(users[iter].insertedAt).format(
      "MMM Do, YYYY"
    );
    withSerialNumber.push(updatedUser);
  }
  return withSerialNumber;
}

function getFilteredBlacklistedUsers(
  toBeDeleted: string,
  blacklistedUsers: BlackListedUser[]
): BlackListedUser[] {
  return blacklistedUsers.filter(
    (blacklistedUser) => blacklistedUser.uuid !== toBeDeleted
  );
}

function generateHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("accessToken")}`,
  };
}

export default function BlacklistedUsers(props: any) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [blacklistedUsers, setBlacklistedUsers] = React.useState<
    BlackListedUser[]
  >([]);
  const [toBeDeleted, setToBeDeleted] = React.useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] =
    React.useState(false);
  const [uuidAndMobileNumbers, setUUIDAndMobileNumbers] = React.useState<
    UUIDAndMobileNumber[]
  >([]);
  const [showAddUserToBlacklistDialog, setShowAddUserToBlacklistDialog] =
    React.useState<boolean>(false);
  const selectedRecordInitialState = {
    uuid: "",
    mobileNumber: "",
    emailId: "",
  };
  const [selectedRecord, setSelectedRecord] =
    React.useState<UUIDAndMobileNumber>(selectedRecordInitialState);

  const dataGridCells = [
    { field: "serial", headerName: "Serial", width: 350, editable: false },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      width: 350,
      editable: false,
    },
    {
      field: "insertedAt",
      headerName: "Created At",
      width: 350,
      editable: false,
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 400,
      disableClickEventBubbling: true,
      renderCell: (row: any) => {
        return (
          <DeleteIcon
            onClick={(event: any) => handleDeleteIconOnClick(event, row)}
            color="primary"
            fontSize="medium"
            style={{
              float: "left",
              cursor: "pointer",
            }}
          />
        );
      },
    },
  ];

  function handleDeleteIconOnClick(event: any, row: any) {
    event.preventDefault();
    setToBeDeleted(row.row.uuid);
    setShowConfirmationDialog(!showConfirmationDialog);
  }

  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_AUTH_SERVER_BASE_URL + "blacklist/list/", {
        headers: generateHeaders(),
      })
      .then((response: any) => {
        if (response.status === 200) {
          setIsLoading(false);
          setBlacklistedUsers(addSerialNumber(response.data));
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  React.useEffect(() => {
    makeGETRequestOnUserService("/user/uuid_and_mobile/list/")
      .then((response: any) => {
        setUUIDAndMobileNumbers(response.data);
      })
      .catch((error: any) => toastr.error(error));
  }, []);

  function addToBlacklist(event: React.MouseEvent) {
    axios
      .post(
        process.env.REACT_APP_AUTH_SERVER_BASE_URL + "blacklist/create/",
        {
          ...selectedRecord,
          userId: selectedRecord.uuid,
        },
        {
          headers: generateHeaders(),
        }
      )
      .then((response: any) => {
        let loggedInUsersMobile = JSON.parse(
          localStorage.getItem("loginUser") || "{}"
        ).mobileNumber;
        if (selectedRecord.mobileNumber === loggedInUsersMobile) {
          localStorage.clear();
          props.history.push("/");
        }
        if (response?.status === 200) {
          setBlacklistedUsers(response?.data);
          setSelectedRecord(selectedRecordInitialState);
          setShowAddUserToBlacklistDialog(false);
          toastr.success("Added User to blacklist successfully!");
        }
      })
      .catch((error: any) => toastr.error(error));
  }

  function removeFromBlacklist(event: any) {
    event.preventDefault();
    axios
      .delete(
        `${process.env.REACT_APP_AUTH_SERVER_BASE_URL}blacklist/delete/${toBeDeleted}/`,
        {
          headers: generateHeaders(),
        }
      )
      .then((response: any) => {
        setShowConfirmationDialog(false);
        setBlacklistedUsers(
          getFilteredBlacklistedUsers(toBeDeleted, blacklistedUsers)
        );
        setToBeDeleted("");
        toastr.success("Removed user from blacklist successfully!");
      })
      .catch((error: any) => toastr.error(error));
  }

  function renderSkeleton() {
    return (
      <Box
        sx={{ width: 900 }}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Box>
    );
  }

  function generateInputPropsEmailCase() {
    return (
      <Grid item xs={12}>
        <Autocomplete
          id="free-solo-demo"
          freeSolo={true}
          options={uuidAndMobileNumbers
            .map(
              (uuidAndMobileNumber: UUIDAndMobileNumber) =>
                uuidAndMobileNumber.emailId
            )
            .filter((record) => record)}
          onChange={(event, enteredValue) => {
            let filtered = uuidAndMobileNumbers.filter(
              (record: UUIDAndMobileNumber) => record.emailId === enteredValue
            )[0];
            if (filtered) {
              setSelectedRecord(filtered);
            }
          }}
          autoHighlight={true}
          value={selectedRecord.emailId}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Email"
              margin="normal"
              variant="outlined"
            />
          )}
        />
      </Grid>
    );
  }

  function generateInputProps() {
    return (
      <Grid item xs={12}>
        <Autocomplete
          id="free-solo-demo"
          freeSolo={true}
          options={uuidAndMobileNumbers.map(
            (uuidAndMobileNumber: UUIDAndMobileNumber) =>
              uuidAndMobileNumber.mobileNumber
          )}
          onChange={(event, enteredValue) => {
            let filtered = uuidAndMobileNumbers.filter(
              (record: UUIDAndMobileNumber) =>
                record.mobileNumber === enteredValue
            )[0];
            if (filtered) {
              setSelectedRecord(filtered);
            }
          }}
          autoHighlight={true}
          value={selectedRecord.mobileNumber}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Mobile Number"
              margin="normal"
              variant="outlined"
            />
          )}
        />
      </Grid>
    );
  }

  function renderDataGrid() {
    return (
      <Grid className="securityMasterDataGird">
        <Paper>
          <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs>
                <div style={{ fontSize: "36px", float: "left" }}>
                  Blacklisted Users
                </div>
              </Grid>
              <Grid item xs="auto">
                <Button
                  className="btnClass"
                  variant="outlined"
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault();
                    setShowAddUserToBlacklistDialog(
                      !showAddUserToBlacklistDialog
                    );
                  }}
                >
                  Add by Mobile
                </Button>
              </Grid>

              <div style={{ height: "100vh", width: "100%" }}>
                <DataGrid
                  className="securityTypeDataGrid abc"
                  components={{ Toolbar: GridToolbar }}
                  rows={blacklistedUsers}
                  columns={dataGridCells}
                  getRowId={(row: any) => row.uuid}
                  pagination
                />
              </div>
            </Grid>
          </Grid>
        </Paper>
        {showConfirmationDialog ? (
          <GenericDialog
            show={showConfirmationDialog}
            heading="Remove from blacklist?"
            inputProps="Are you sure you want to remove this user from whitelist?"
            onClose={(event: React.MouseEvent) => {
              event.preventDefault();
              setShowConfirmationDialog(!showConfirmationDialog);
            }}
            handleSubmit={removeFromBlacklist}
          />
        ) : (
          ""
        )}

        {showAddUserToBlacklistDialog ? (
          <GenericDialog
            show={showAddUserToBlacklistDialog}
            heading="Add user to blacklist"
            inputProps={generateInputProps()}
            onClose={(event: React.MouseEvent) => {
              event.preventDefault();
              setShowAddUserToBlacklistDialog(!showAddUserToBlacklistDialog);
            }}
            handleSubmit={addToBlacklist}
          />
        ) : (
          ""
        )}
        {/* <IdleTimoutConfig> </IdleTimoutConfig> */}
      </Grid>
    );
  }

  if (isLoading) {
    return renderSkeleton();
  } else {
    return renderDataGrid();
  }
}
