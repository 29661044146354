import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import moment, { Moment } from "moment";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { alpha } from "@mui/material/styles";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { shallow } from "zustand/shallow";
import useCalc, { ICashflow } from "../../store/calc";
import {
  getUpdatedAmountByStampDuty,
  sortDate,
  toINR,
} from "../../Helpers/functions";
import toastr from "toastr";
import {
  corporateBonds_moneyMarket_xirr,
  corporateBonds_moneyMarket_xnpv,
  gsec_sdl_xirr,
  gsec_sdl_xnpv,
  zcb_mld_xirr,
  zcb_mld_xnpv,
} from "../../Helpers/calc-apis";
import styles from "../../Helpers/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

function isGsecSdlTBill(securityType: string) {
  return [
    "State Development Loans",
    "Government Securities",
    "T Bill",
  ].includes(securityType);
}

const Inputs = () => {
  const [
    units,
    date,
    Price,
    Yield,
    isMMY,
    zoom,
    bondInfo,
    breakup,
    incrementUnit,
    decrementUnit,
    getCalculatedValues,
    getYield,
    getPrice,
    getDate,
    getUnits,
    getMMY,
    getCashfow,
  ] = useCalc(
    (state: any) => [
      state.units,
      state.date,
      state.Price,
      state.Yield,
      state.isMMY,
      state.zoom,
      state.bondInfo,
      state.breakup,
      state.incrementUnit,
      state.decrementUnit,
      state.getCalculatedValues,
      state.getYield,
      state.getPrice,
      state.getDate,
      state.getUnits,
      state.getMMY,
      state.getCashfow,
    ],
    shallow
  );
  const [isIncrementing, setIsIncrementing] = useState(false);
  const [isDecrementing, setIsDecrementing] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [showYieldError, setShowYieldError] = useState(false);
  const [showPriceError, setShowPriceError] = useState(false);
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [loading, setLoading] = useState(false);
  const buttonDisableCondition =
    units && date && (Price || Yield) ? false : true;
  let faceValue = Number(bondInfo?.faceValue.split(" ")[0])!;
  let interest = Number(bondInfo?.coupons[0].rate)!;
  let securityType = bondInfo?.securityType.type;
  let maxDate = bondInfo?.callOptions?.at(-1)?.date
    ? moment(
        bondInfo?.callOptions?.sort((a: any, b: any) => sortDate(a, b)).at(-1)
          ?.date,
        "DD/MM/YYYY"
      )
    : moment(
        bondInfo?.maturities?.sort((a: any, b: any) => sortDate(a, b)).at(-1)
          ?.date,
        "DD/MM/YYYY"
      );

  useEffect(() => {
    if (isIncrementing) {
      getCalculatedValues(null);
      interval.current = setInterval(() => incrementUnit(), 100);
    }
    if (isDecrementing) {
      getCalculatedValues(null);
      interval.current = setInterval(() => decrementUnit(), 100);
    }

    return () => {
      if (interval.current) clearInterval(interval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIncrementing, isDecrementing]);

  const startIncrementing = () => {
    timeout.current = setTimeout(() => {
      setIsIncrementing(true);
    }, 500);
  };

  const stopIncrementing = () => {
    setIsIncrementing(false);
    if (timeout.current) clearTimeout(timeout.current);
  };

  const startDecrementing = () => {
    timeout.current = setTimeout(() => {
      setIsDecrementing(true);
    }, 500);
  };

  const stopDecrementing = () => {
    setIsDecrementing(false);
    if (timeout.current) clearTimeout(timeout.current);
  };

  const isWeekend = (date: Moment) => {
    const day = date.day();
    return day === 0 || day === 6;
  };

  const displayMessage = () =>
    toastr.error(
      "You have exhausted you daily usage limit for Yield Calculator. Limit will get renewed at 12:00 AM."
    );

  const corporateBonds_moneyMarket_xirr_apiCall = async () => {
    try {
      setLoading(true);
      const response = await corporateBonds_moneyMarket_xirr(
        bondInfo!,
        Price!,
        date!,
        true,
        units
      );
      if (
        response &&
        response.status === 200 &&
        !response.data.hasOwnProperty("message")
      ) {
        response.data.xirr
          ? getYield(response.data.xirr)
          : (function () {
              getYield(response.data.Simple_Interest);
              getMMY(true);
            })();
        let marketValue = (faceValue * Price!) / 100;
        let accuredDays = Number(response.data.cashflow.Days[0]);
        let daysInYear = Number(response.data.cashflow.Year[1]);
        let accruedInterest =
          (((faceValue * interest) / 100) * accuredDays) /
          bondInfo?.divisibilityFactor!;
        let InvestmentAmount = units * (marketValue + accruedInterest);
        let StampDuty = getUpdatedAmountByStampDuty(InvestmentAmount);
        InvestmentAmount += StampDuty!;
        let IpDates = response.data.cashflow.AdjustedValueDate.map(
          (date: string) => moment(date, "DD/MM/YYYY").format("DD MMM")
        );
        getCalculatedValues({
          investmentAmount: InvestmentAmount,
          principalAmount: marketValue * units,
          accruedInterest: accruedInterest * units,
          stampDuty: Number(StampDuty),
          accruedDays: accuredDays,
          noOfBonds: units,
          Yield: response.data.xirr
            ? response.data.xirr
            : response.data.Simple_Interest,
          ipDates: IpDates,
          price: Price!,
          settlementDate: date!,
        });
        //Generate Cashflow Below
        const cashflowArray: ICashflow[] = [];
        for (
          let i = 1;
          i < response.data.cashflow.AdjustedValueDate.length;
          i++
        ) {
          const interest = Number(response.data.cashflow.Interest[i]);
          const maturity = Number(response.data.cashflow.Maturity[i]);
          if (interest !== 0) {
            const cashflow: ICashflow = {
              date: response.data.cashflow.AdjustedValueDate[i],
              amount: (interest * faceValue) / 100,
              type: "Interest",
            };
            cashflowArray.push(cashflow);
          }
          if (maturity !== 0) {
            const cashflow: ICashflow = {
              date: response.data.cashflow.AdjustedValueDate[i],
              amount: (maturity * faceValue) / 100,
              type: "Maturity",
            };
            cashflowArray.push(cashflow);
          }
        }
        getCashfow(cashflowArray);
      } else {
        displayMessage();
      }
      setLoading(false);
    } catch (error: any) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  const corporateBonds_moneyMarket_xnpv_apiCall = async () => {
    try {
      setLoading(true);
      const response = await corporateBonds_moneyMarket_xnpv(
        bondInfo!,
        Yield!,
        date!,
        true,
        units
      );
      if (
        response &&
        response.status === 200 &&
        !response.data.hasOwnProperty("message")
      ) {
        response.data.Bond_Price_Xnpv
          ? getPrice(response.data.Bond_Price_Xnpv)
          : (function () {
              getPrice(response.data.Bond_Price_Simple);
              getMMY(true);
            })();
        let marketValue =
          (faceValue *
            (response.data.Bond_Price_Xnpv ||
              response.data.Bond_Price_Simple)) /
          100;
        let accuredDays = Number(response.data.cashflow.Days[0]);
        let daysInYear = Number(response.data.cashflow.Year[1]);
        let accruedInterest =
          (((faceValue * interest) / 100) * accuredDays) /
          bondInfo?.divisibilityFactor!;
        let InvestmentAmount = units * (marketValue + accruedInterest);
        let StampDuty = getUpdatedAmountByStampDuty(InvestmentAmount);
        InvestmentAmount += StampDuty!;
        let IpDates = response.data.cashflow.AdjustedValueDate.map(
          (date: string) => moment(date, "DD/MM/YYYY").format("DD MMM")
        );
        getCalculatedValues({
          investmentAmount: InvestmentAmount,
          principalAmount: marketValue * units,
          accruedInterest: accruedInterest * units,
          stampDuty: Number(StampDuty),
          accruedDays: accuredDays,
          noOfBonds: units,
          Yield: Yield!,
          ipDates: IpDates,
          price:
            response.data.Bond_Price_Xnpv || response.data.Bond_Price_Simple,
          settlementDate: date!,
        });
        const cashflowArray: ICashflow[] = [];
        for (
          let i = 1;
          i < response.data.cashflow.AdjustedValueDate.length;
          i++
        ) {
          const interest = Number(response.data.cashflow.Interest[i]);
          const maturity = Number(response.data.cashflow.Maturity[i]);
          if (interest !== 0) {
            const cashflow: ICashflow = {
              date: response.data.cashflow.AdjustedValueDate[i],
              amount: (interest * faceValue) / 100,
              type: "Interest",
            };
            cashflowArray.push(cashflow);
          }
          if (maturity !== 0) {
            const cashflow: ICashflow = {
              date: response.data.cashflow.AdjustedValueDate[i],
              amount: (maturity * faceValue) / 100,
              type: "Maturity",
            };
            cashflowArray.push(cashflow);
          }
        }
        getCashfow(cashflowArray);
      } else {
        displayMessage();
      }
      setLoading(false);
    } catch (error: any) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  const zcb_mld_xirr_apiCall = async () => {
    try {
      setLoading(true);
      const response = await zcb_mld_xirr(
        bondInfo!,
        Price!,
        date!,
        true,
        units
      );
      if (
        response &&
        response.status === 200 &&
        !response.data.hasOwnProperty("message")
      ) {
        response.data.xirr
          ? getYield(response.data.xirr)
          : (function () {
              getYield(response.data.Simple_Interest);
              getMMY(true);
            })();
        let marketValue = (faceValue * Price!) / 100;
        let lastIpDate = moment(bondInfo?.lastIPDate, "YYYY-MM-DD").format(
          "MM/DD/YYYY"
        );
        let settlementDate = date?.format("MM/DD/YYYY");
        let accuredDays =
          Number(response.data.cashflow?.Days[0]) ||
          Number(moment(settlementDate).diff(lastIpDate, "days"));
        let daysInYear =
          Number(response.data.cashflow?.Year[1]) ||
          (date?.isLeapYear() && date?.month() === 1 && date?.date() === 29)
            ? 366
            : 365;
        let accruedInterest =
          (((faceValue * interest) / 100) * accuredDays) /
          bondInfo?.divisibilityFactor!;
        let InvestmentAmount = units * (marketValue + accruedInterest);
        let StampDuty = getUpdatedAmountByStampDuty(InvestmentAmount);
        InvestmentAmount += StampDuty!;
        let IpDates = response.data.cashflowDf.dates.map((date: string) =>
          moment(date, "YYYY-MM-DD").format("DD MMM")
        );
        getCalculatedValues({
          investmentAmount: InvestmentAmount,
          principalAmount: marketValue * units,
          accruedInterest: accruedInterest * units,
          stampDuty: Number(StampDuty),
          accruedDays: accuredDays,
          noOfBonds: units,
          Yield: response.data.xirr
            ? response.data.xirr
            : response.data.Simple_Interest,
          ipDates: IpDates,
          price: Price!,
          settlementDate: date!,
        });
        const cashflowArray: ICashflow[] = [];
        for (let i = 1; i < response.data.cashflowDf.dates.length; i++) {
          const date = response.data.cashflowDf.dates[i];
          const amount = Number(response.data.cashflowDf.amounts[i]);
          const cashflow: ICashflow = {
            date: moment(date, "YYYY-MM-DD").format("DD/MM/YYYY").toString(),
            amount: (amount * faceValue) / 100,
            type: "Maturity",
          };
          cashflowArray.push(cashflow);
        }
        getCashfow(cashflowArray);
      } else {
        displayMessage();
      }
      setLoading(false);
    } catch (error: any) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  const zcb_mld__xnpv_apiCall = async () => {
    try {
      setLoading(true);
      const response = await zcb_mld_xnpv(
        bondInfo!,
        Yield!,
        date!,
        true,
        units
      );
      if (
        response &&
        response.status === 200 &&
        !response.data.hasOwnProperty("message")
      ) {
        response.data.Bond_Price_Xnpv
          ? getPrice(response.data.Bond_Price_Xnpv)
          : (function () {
              getPrice(response.data.Bond_Price_Simple);
              getMMY(true);
            })();
        let marketValue =
          (faceValue *
            (response.data.Bond_Price_Xnpv ||
              response.data.Bond_Price_Simple)) /
          100;
        let lastIpDate = moment(bondInfo?.lastIPDate, "YYYY-MM-DD").format(
          "MM/DD/YYYY"
        );
        let settlementDate = date?.format("MM/DD/YYYY");
        let accuredDays =
          Number(response.data.cashflow?.Days[0]) ||
          Number(moment(settlementDate).diff(lastIpDate, "days"));
        let daysInYear =
          Number(response.data.cashflow?.Year[1]) ||
          (date?.isLeapYear() && date?.month() === 1 && date?.date() === 29)
            ? 366
            : 365;
        let accruedInterest =
          (((faceValue * interest) / 100) * accuredDays) /
          bondInfo?.divisibilityFactor!;
        let InvestmentAmount = units * (marketValue + accruedInterest);
        let StampDuty = getUpdatedAmountByStampDuty(InvestmentAmount);
        InvestmentAmount += StampDuty!;
        let IpDates = response.data.cashflowDf.dates.map((date: string) =>
          moment(date, "YYYY-MM-DD").format("DD MMM")
        );
        getCalculatedValues({
          investmentAmount: InvestmentAmount,
          principalAmount: marketValue * units,
          accruedInterest: accruedInterest * units,
          stampDuty: Number(StampDuty),
          accruedDays: accuredDays,
          noOfBonds: units,
          Yield: Yield!,
          ipDates: IpDates,
          price:
            response.data.Bond_Price_Xnpv || response.data.Bond_Price_Simple,
          settlementDate: date!,
        });
        const cashflowArray: ICashflow[] = [];
        for (let i = 1; i < response.data.cashflowDf.dates.length; i++) {
          const date = response.data.cashflowDf.dates[i];
          const amount = Number(response.data.cashflowDf.amounts[i]);
          const cashflow: ICashflow = {
            date: moment(date, "YYYY-MM-DD").format("DD/MM/YYYY").toString(),
            amount: (amount * faceValue) / 100,
            type: "Maturity",
          };
          cashflowArray.push(cashflow);
        }
        getCashfow(cashflowArray);
      } else {
        displayMessage();
      }
      setLoading(false);
    } catch (error: any) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  const gsec_sdl_xirr_apiCall = async () => {
    try {
      setLoading(true);
      const response = await gsec_sdl_xirr(
        bondInfo!,
        Price!,
        date!,
        true,
        units
      );
      if (
        response &&
        response.status === 200 &&
        !response.data.hasOwnProperty("message")
      ) {
        response.data.Yield_Half_Yearly
          ? getYield(response.data.Yield_Half_Yearly)
          : (function () {
              getYield(response.data.Simple_Interest);
              getMMY(true);
            })();
        let marketValue = (faceValue * Price!) / 100;
        let accuredDays = Number(response.data.cashflow.Days[0]);
        let daysInYear = Number(response.data.cashflow.Year[1]);
        let accruedInterest =
          (((faceValue * interest) / 100) * accuredDays) /
          bondInfo?.divisibilityFactor!;
        let InvestmentAmount = units * (marketValue + accruedInterest);
        let StampDuty = getUpdatedAmountByStampDuty(InvestmentAmount);
        // StampDuty Not required on G-secs
        // InvestmentAmount += StampDuty!;
        let IpDates = response.data.cashflow.ValueDate.map((date: string) =>
          moment(date, "DD/MM/YYYY").format("DD MMM")
        );
        getCalculatedValues({
          investmentAmount: InvestmentAmount,
          principalAmount: marketValue * units,
          accruedInterest: accruedInterest * units,
          stampDuty: Number(StampDuty),
          accruedDays: accuredDays,
          noOfBonds: units,
          Yield: response.data.Yield_Half_Yearly,
          ipDates: IpDates,
          price: Price!,
          settlementDate: date!,
        });
        const cashflowArray: ICashflow[] = [];
        for (let i = 1; i < response.data.cashflow.ValueDate.length; i++) {
          const interest = Number(response.data.cashflow.Interest[i]);
          const maturity = Number(response.data.cashflow.Maturity[i]);
          if (interest !== 0) {
            const cashflow: ICashflow = {
              date: response.data.cashflow.ValueDate[i],
              amount: (interest * faceValue) / 100,
              type: "Interest",
            };
            cashflowArray.push(cashflow);
          }
          if (maturity !== 0) {
            const cashflow: ICashflow = {
              date: response.data.cashflow.ValueDate[i],
              amount: (maturity * faceValue) / 100,
              type: "Maturity",
            };
            cashflowArray.push(cashflow);
          }
        }
        getCashfow(cashflowArray);
      } else {
        displayMessage();
      }
      setLoading(false);
    } catch (error: any) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  const gsec_sdl_xnpv_apiCall = async () => {
    try {
      setLoading(true);
      const response = await gsec_sdl_xnpv(
        bondInfo!,
        Yield!,
        date!,
        true,
        units
      );
      if (
        response &&
        response.status === 200 &&
        !response.data.hasOwnProperty("message")
      ) {
        response.data.CleanPrice_Compound
          ? getPrice(response.data.CleanPrice_Compound)
          : (function () {
              getPrice(response.data.CleanPrice_Simple);
              getMMY(true);
            })();
        let marketValue =
          (faceValue *
            (response.data.CleanPrice_Compound ||
              response.data.CleanPrice_Simple)) /
          100;
        let accuredDays = Number(response.data.cashflow.Days[0]);
        let daysInYear = Number(response.data.cashflow.Year[1]);
        let accruedInterest =
          (((faceValue * interest) / 100) * accuredDays) /
          bondInfo?.divisibilityFactor!;
        let InvestmentAmount = units * (marketValue + accruedInterest);
        let StampDuty = getUpdatedAmountByStampDuty(InvestmentAmount);
        // StampDuty Not required on G-secs
        // InvestmentAmount += StampDuty!;
        let IpDates = response.data.cashflow.ValueDate.map((date: string) =>
          moment(date, "DD/MM/YYYY").format("DD MMM")
        );
        getCalculatedValues({
          investmentAmount: InvestmentAmount,
          principalAmount: marketValue * units,
          accruedInterest: accruedInterest * units,
          stampDuty: Number(StampDuty),
          accruedDays: accuredDays,
          noOfBonds: units,
          Yield: Yield!,
          ipDates: IpDates,
          price:
            response.data.CleanPrice_Compound ||
            response.data.CleanPrice_Simple,
          settlementDate: date!,
        });
        const cashflowArray: ICashflow[] = [];
        for (let i = 1; i < response.data.cashflow.ValueDate.length; i++) {
          const interest = Number(response.data.cashflow.Interest[i]);
          const maturity = Number(response.data.cashflow.Maturity[i]);
          if (interest !== 0) {
            const cashflow: ICashflow = {
              date: response.data.cashflow.ValueDate[i],
              amount: (interest * faceValue) / 100,
              type: "Interest",
            };
            cashflowArray.push(cashflow);
          }
          if (maturity !== 0) {
            const cashflow: ICashflow = {
              date: response.data.cashflow.ValueDate[i],
              amount: (maturity * faceValue) / 100,
              type: "Maturity",
            };
            cashflowArray.push(cashflow);
          }
        }
        getCashfow(cashflowArray);
      } else {
        displayMessage();
      }
      setLoading(false);
    } catch (error: any) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  const priceRange = Price! >= 1 && Price! <= 999.9999;
  const yieldRange = Yield! >= 1 && Yield! <= 99.9999;
  const is_Vanill_Xirr = Price && !Yield && securityType === "Corporate Bonds";
  const is_Vanill_Xnpv = Yield && !Price && securityType === "Corporate Bonds";
  const is_Zcb_Mld_Xirr =
    Price &&
    !Yield &&
    (securityType === "Market Linked Debentures" ||
      securityType === "Zero Coupon Bonds");
  const is_Zcb_Mld_Xnpv =
    Yield &&
    !Price &&
    (securityType === "Market Linked Debentures" ||
      securityType === "Zero Coupon Bonds");
  const is_gsec_sdl_tBill_Xirr =
    Price &&
    !Yield &&
    (securityType === "State Development Loans" ||
      securityType === "Government Securities" ||
      securityType === "T Bill");
  const is_gsec_sdl_tBill_Xnpv =
    Yield &&
    !Price &&
    (securityType === "State Development Loans" ||
      securityType === "Government Securities" ||
      securityType === "T Bill");

  const handleCalculate = () => {
    let shutPeriod = bondInfo?.recordDate;
    let maxDateMinusShutPeriod = maxDate.clone().subtract(shutPeriod, "days");
    if (date && date.isBetween(maxDateMinusShutPeriod, maxDate, null, "(]")) {
      toastr.error(`Security is in Shut Period`);
    } else {
      if (is_Vanill_Xirr) {
        priceRange
          ? corporateBonds_moneyMarket_xirr_apiCall()
          : setShowPriceError(true);
      }
      if (is_Vanill_Xnpv) {
        yieldRange
          ? corporateBonds_moneyMarket_xnpv_apiCall()
          : setShowYieldError(true);
      }
      if (is_Zcb_Mld_Xirr) {
        priceRange ? zcb_mld_xirr_apiCall() : setShowPriceError(true);
      }
      if (is_Zcb_Mld_Xnpv) {
        yieldRange ? zcb_mld__xnpv_apiCall() : setShowYieldError(true);
      }
      if (is_gsec_sdl_tBill_Xirr) {
        priceRange ? gsec_sdl_xirr_apiCall() : setShowPriceError(true);
      }
      if (is_gsec_sdl_tBill_Xnpv) {
        yieldRange ? gsec_sdl_xnpv_apiCall() : setShowPriceError(true);
      }
    }
  };

  return (
    <Box sx={{ display: breakup && !zoom ? "none" : "block" }}>
      <Box sx={{ mt: 2, ...styles.flexBetweenCenter, flexWrap: "wrap" }}>
        <Box sx={{ width: "48%", mb: 2 }}>
          <Typography
            sx={{
              color: `${alpha("#2A324C", 0.5)}`,
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            No. of Bonds
          </Typography>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <Input
              value={units}
              onChange={(event) => {
                if (isNaN(Number(event.target.value))) getUnits(0);
                else if (Number(event.target.value) >= 0)
                  getUnits(Number(event.target.value));
                getCalculatedValues(null);
                getYield(null);
                getPrice(null);
              }}
              onBlur={(event) => {
                if (Number(event.target.value) === 0) getUnits(1);
              }}
              type="text"
              inputMode="numeric"
              disabled={!bondInfo}
              disableUnderline
              sx={{
                bgcolor: "#F2F3F4",
                py: 1,
                px: 1.5,
                borderRadius: "10px",
                "& .MuiInput-input": {
                  textAlign: "center",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                    },
                },
              }}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    onTouchStart={startDecrementing}
                    onTouchEnd={stopDecrementing}
                    onMouseDown={startDecrementing}
                    onMouseUp={stopDecrementing}
                    onClick={() => {
                      decrementUnit();
                      getCalculatedValues(null);
                      getYield(null);
                      getPrice(null);
                    }}
                    sx={{
                      background:
                        "linear-gradient(135deg, #E8E8E8 0%, #FFFFFF 100%)",
                      boxShadow:
                        "-8px 8px 16px rgba(205, 205, 205, 0.2), 8px -8px 16px rgba(205, 205, 205, 0.2), -8px -8px 16px rgba(255, 255, 255, 0.9), 8px 8px 20px rgba(205, 205, 205, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(205, 205, 205, 0.5)",
                      ":hover": {
                        boxShadow:
                          "inset -8px 8px 16px rgba(205, 205, 205, 0.2), inset 8px -8px 16px rgba(205, 205, 205, 0.2), inset -8px -8px 16px rgba(255, 255, 255, 0.9), inset 8px 8px 20px rgba(205, 205, 205, 0.9), 1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(205, 205, 205, 0.5)",
                      },
                    }}
                  >
                    <RemoveIcon sx={{ color: "red" }} />
                  </IconButton>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onTouchStart={startIncrementing}
                    onTouchEnd={stopIncrementing}
                    onMouseDown={startIncrementing}
                    onMouseUp={stopIncrementing}
                    onClick={() => {
                      incrementUnit();
                      getCalculatedValues(null);
                      getYield(null);
                      getPrice(null);
                    }}
                    sx={{
                      background:
                        "linear-gradient(135deg, #E8E8E8 0%, #FFFFFF 100%)",
                      boxShadow:
                        "-8px 8px 16px rgba(205, 205, 205, 0.2), 8px -8px 16px rgba(205, 205, 205, 0.2), -8px -8px 16px rgba(255, 255, 255, 0.9), 8px 8px 20px rgba(205, 205, 205, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(205, 205, 205, 0.5)",
                      ":hover": {
                        boxShadow:
                          "inset -8px 8px 16px rgba(205, 205, 205, 0.2), inset 8px -8px 16px rgba(205, 205, 205, 0.2), inset -8px -8px 16px rgba(255, 255, 255, 0.9), inset 8px 8px 20px rgba(205, 205, 205, 0.9), 1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(205, 205, 205, 0.5)",
                      },
                    }}
                  >
                    <AddIcon sx={{ color: "red" }} />
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{
                sx: { fontWeight: 700 },
              }}
            />
          </FormControl>
        </Box>
        <Box sx={{ width: "48%", mb: 2 }}>
          <Typography
            sx={{
              color: `${alpha("#2A324C", 0.5)}`,
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            Settlement Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={date}
              disabled={!bondInfo}
              shouldDisableDate={isWeekend}
              maxDate={maxDate}
              onChange={(newValue) => {
                getDate(newValue);
                getCalculatedValues(null);
                getYield(null);
                getPrice(null);
              }}
              inputFormat="DD/MM/YYYY"
              toolbarTitle="Select Settlement Date"
              open={openCalendar}
              onClose={() => setOpenCalendar(!openCalendar)}
              showToolbar
              closeOnSelect
              PaperProps={{
                sx: {
                  borderRadius: "20px !important",
                },
              }}
              DialogProps={{
                sx: {
                  "& .MuiPaper-root": { borderRadius: "20px !important" },
                },
              }}
              PopperProps={{
                sx: {
                  "& .MuiPaper-root": { borderRadius: "20px !important" },
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onKeyDown={(event) => event.preventDefault()}
                  variant="standard"
                  sx={{
                    bgcolor: "#F2F3F4",
                    py: 1,
                    pr: 1,
                    pl: 2,
                    borderRadius: "10px",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setOpenCalendar(!openCalendar)}
                        >
                          <CalendarMonthIcon sx={{ color: "red" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: { fontWeight: 700 },
                  }}
                  placeholder="DD/MM/YYYY"
                  // helperText={params?.inputProps?.placeholder}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ width: "48%", mb: 2 }}>
          <Typography
            sx={{
              color: `${alpha("#2A324C", 0.5)}`,
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            Enter Price per 100
          </Typography>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <Input
              value={Price || ""}
              onChange={(event) => {
                getPrice(Number(event.target.value));
                getYield(null);
                getCalculatedValues(null);
                setShowPriceError(false);
                setShowYieldError(false);
                getMMY(false);
              }}
              type="number"
              disabled={!bondInfo}
              disableUnderline
              sx={{
                bgcolor: "#F2F3F4",
                py: 1,
                px: 2,
                borderRadius: "10px",
                "& .MuiInput-input": {
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                    },
                },
              }}
              placeholder="000.0000"
              startAdornment={
                <InputAdornment position="start">
                  <Typography sx={{ fontWeight: 700, color: "secondary.main" }}>
                    ₹
                  </Typography>
                </InputAdornment>
              }
              inputProps={{
                sx: { fontWeight: 700 },
              }}
            />
            <FormHelperText>
              {showPriceError ? (
                <Box
                  component="span"
                  sx={{
                    color: "secondary.main",
                    fontSize: 10,
                    whiteSpace: "nowrap",
                  }}
                >
                  Allowable Range is ₹1 - ₹999.9999
                </Box>
              ) : (
                <Box
                  component="span"
                  sx={{ fontSize: 10, whiteSpace: "nowrap" }}
                >
                  Offer Price:{" "}
                  {bondInfo?.quoteDetails && (
                    <Box
                      component="span"
                      sx={{ color: "success.main", fontWeight: 600 }}
                    >
                      {toINR(bondInfo?.quoteDetails?.sellPrice, 4)}
                    </Box>
                  )}
                </Box>
              )}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box sx={{ width: "48%", mb: 2 }}>
          <Typography
            sx={{
              color: `${alpha("#2A324C", 0.5)}`,
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            Enter Yield(%){" "}
            {Price && Yield && (
              <Box
                component="span"
                sx={{
                  color: "info.main",
                  fontWeight: 800,
                }}
              >
                {isMMY
                  ? "MMY"
                  : !isMMY && isGsecSdlTBill(securityType as string)
                  ? "Semi-Annual"
                  : ""}
              </Box>
            )}
          </Typography>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <Input
              value={Yield || ""}
              onChange={(event) => {
                getYield(Number(event.target.value));
                getPrice(null);
                getCalculatedValues(null);
                setShowYieldError(false);
                setShowPriceError(false);
                getMMY(false);
              }}
              type="number"
              disabled={!bondInfo}
              disableUnderline
              sx={{
                bgcolor: "#F2F3F4",
                py: 1,
                px: 2,
                borderRadius: "10px",
                "& .MuiInput-input": {
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                    },
                },
              }}
              placeholder="00.000"
              endAdornment={
                <InputAdornment position="end">
                  <Typography sx={{ fontWeight: 700, color: "secondary.main" }}>
                    %
                  </Typography>
                </InputAdornment>
              }
              inputProps={{
                sx: { fontWeight: 700 },
              }}
            />
            <FormHelperText>
              {showYieldError ? (
                <Box
                  component="span"
                  sx={{
                    color: "secondary.main",
                    fontSize: 10,
                    whiteSpace: "nowrap",
                  }}
                >
                  Allowable Range is 1% - 99.9999%
                </Box>
              ) : (
                <Box
                  component="span"
                  sx={{ fontSize: 10, whiteSpace: "nowrap" }}
                >
                  Offer Yield:{" "}
                  {bondInfo?.quoteDetails && (
                    <Box
                      component="span"
                      sx={{ color: "success.main", fontWeight: 600 }}
                    >
                      {bondInfo?.quoteDetails.sellYield}%
                    </Box>
                  )}
                </Box>
              )}
            </FormHelperText>
          </FormControl>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.flexCenterCenter,
          "& .Mui-disabled": { bgcolor: `${alpha("#7569EE", 0.3)} !important` },
        }}
      >
        <Button
          onClick={handleCalculate}
          disabled={buttonDisableCondition}
          variant="contained"
          color="info"
          sx={{
            width: "40%",
            p: 1,
            ":hover": {
              bgcolor: "info.main",
            },
          }}
        >
          {loading
            ? "Loading..."
            : `Calculate ${
                !Price && Yield ? "Price" : !Yield && Price ? "Yield" : ""
              }`}
        </Button>
      </Box>
    </Box>
  );
};

export default Inputs;
