import React from "react";
import { makeGETRequest } from "../../Api/api";
import { IPOSecurityMaster } from "../../pages/IPO/Interfaces";
import { TextField, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const toastr = require("toastr");

const useStyles = makeStyles((theme) => ({
  marginClass: {
    marginTop: "10px",
  },
}));

export default function CreateIPOQuote(props: any): React.ReactElement {
  const PAYMENT_TERMS = ["Monthly", "Yearly", "Cumulative", "Quarterly"];
  const classes = useStyles();
  const { quote, setQuote, handleChange, editable, editMode } = props;
  const [ipoSecurityMasters, setIPOSecurityMasters] = React.useState<
    IPOSecurityMaster[]
  >([]);

  React.useEffect(() => {
    makeGETRequest("/ipo/security_master/list/")
      .then((response) => {
        setIPOSecurityMasters(
          response.data.map((resp: any) => resp.ipo_security_master)
        );
      })
      .catch((error) => toastr.error(error));
  }, []);

  return (
    <Grid item xs={12}>
      <Autocomplete
        id="free-solo-demo"
        freeSolo={true}
        options={ipoSecurityMasters.map(
          (ipoSecurityMaster: IPOSecurityMaster) => ipoSecurityMaster.uin
        )}
        onChange={(event, enteredValue) => {
          let filteredSecurityMaster = ipoSecurityMasters.filter(
            (securityMaster: IPOSecurityMaster) =>
              securityMaster.uin === enteredValue
          )[0];
          setQuote({
            ...quote,
            uin: enteredValue || "",
            nature_of_instrument: filteredSecurityMaster.nature_of_instrument,
            ipo_security_master_id: filteredSecurityMaster.uuid,
          });
        }}
        autoHighlight={true}
        value={editMode ? editable.uin : quote?.uin}
        renderInput={(params) => (
          <TextField
            {...params}
            label="UIN"
            margin="normal"
            variant="outlined"
          />
        )}
      />
      <Grid item xs={12}>
        <TextField
          type="text"
          name="nature_of_instrument"
          variant="outlined"
          fullWidth
          disabled
          label="Nature of Instrument"
          value={
            editMode
              ? editable.nature_of_instrument
              : quote.nature_of_instrument
          }
        />
      </Grid>
      <br />

      <Grid item xs={12}>
        <TextField
          type="text"
          name="series_frontend"
          variant="outlined"
          fullWidth
          label="Series Frontend"
          onChange={handleChange}
          value={editMode ? editable.series_frontend : quote.series_frontend}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.marginClass}
          type="text"
          name="series_backend"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          label="Series Backend"
          value={editMode ? editable.series_backend : quote.series_backend}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.marginClass}
          type="text"
          name="category"
          variant="outlined"
          fullWidth
          label="Category"
          onChange={handleChange}
          value={editMode ? editable.category : quote.category}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.marginClass}
          type="text"
          name="category_subcode"
          variant="outlined"
          fullWidth
          label="Category Subcode"
          onChange={handleChange}
          value={editMode ? editable.category_subcode : quote.category_subcode}
        />
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <React.Fragment>
          <Grid item xs={6} className={classes.marginClass}>
            <FormControl variant="outlined" style={{ width: "200px" }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Payment Term *
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="payment_term"
                value={editMode ? editable.payment_term : quote.payment_term}
                onChange={(event) => {
                  setQuote({
                    ...quote,
                    payment_term: event.target.value,
                  });
                }}
                label="Payment Terms *"
              >
                {PAYMENT_TERMS &&
                  PAYMENT_TERMS.length > 0 &&
                  PAYMENT_TERMS.map((paymentTerm, index) => {
                    return (
                      <MenuItem value={paymentTerm} key={paymentTerm}>
                        {paymentTerm}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} className={classes.marginClass}>
            <TextField
              type="text"
              name="quote_yield"
              variant="outlined"
              fullWidth
              label="Yield"
              defaultValue={editMode ? editable.quote_yield : quote.quote_yield}
              onChange={handleChange}
            />
          </Grid>
        </React.Fragment>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.marginClass}
          type="text"
          name="tenure"
          variant="outlined"
          fullWidth
          label="Tenure"
          value={editMode ? editable.tenure : quote.tenure}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
}
