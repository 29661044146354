import React, { useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import Avatar from "@material-ui/core/Avatar";
import { TextField, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { appContext } from "../App";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createIssuerMaster, updateIssuerMaster } from "../Api/api";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "184px",
  },
}));

function getFilteredIssuerName(issuerName, issuerNames) {
  return issuerNames.filter((record) => record.name === issuerName)[0].uuid;
}

const AddIssuerMasterModal = (props) => {
  const classes = useStyles();
  const { authUser, setAuthUser } = useContext(appContext);
  const [issuerType, setIssuerType] = React.useState(props.issuerType);
  const [issuerName, setIssuerName] = React.useState(props.issuerName);
  const [issuerCode, setIssuerCode] = React.useState(
    props.toBeEdited ? props.toBeEdited.issuerCode : ""
  );
  const [uploadedFile, setUploadedFile] = React.useState("");

  useEffect(() => {
    if (props.mode === "Edit") {
      setIssuerCode(props.issuerCode);
    } else setIssuerCode("");
  }, [props]);

  useEffect(() => {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };
  }, []);

  const handleOnSelect = (event) => {
    event.preventDefault();
    setIssuerType(event.target.value);
  };

  const handleIssuerCodeChange = (event) => {
    event.preventDefault();
    setIssuerCode(event.target.value);
  };

  const handleChange = (event, enteredValue) => {
    setIssuerName(enteredValue);
  };
  const handleOnChange = (event, enterValue) => {
    setIssuerCode(enterValue);
  };

  const submitHandler = (event) => {
    if (props.mode === "Create") {
      if (authUser && authUser.uuid) {
        let formData = new FormData();
        formData.append("issuerName", issuerName);
        formData.append("issuerCode", issuerCode);
        formData.append("issuerTypeUUID", issuerType.uuid);
        formData.append("logo", uploadedFile);

        createIssuerMaster(formData)
          .then((response) => {
            if (response && response.status === 201) {
              setIssuerName("");
              setIssuerCode("");
              setUploadedFile(null);
              setIssuerType("");
              props.onCloseModal(event, response.data);
              toastr.success("Successfully Added Issuer Master!");
              props.updateIssuerMaster(event, response.data);
            }
          })
          .catch((errorResponse) => {
            let response = errorResponse.response.data;
            if ("non_field_errors" in response) {
              toastr.error(response.non_field_errors[0]);
            } else {
              toastr.error(errorResponse);
            }
          });
      }
    } else if (props.mode === "Edit") {
      let formData = new FormData();
      let issuerNameUUID = getFilteredIssuerName(
        issuerName || props.issuerName,
        props.issuerNames
      );
      formData.append(
        "issuerName",
        issuerName === "" ? issuerName : props.issuerName
      );

      let filteredIssuerType = props.issuerTypes.filter(
        (i) => i.type === issuerType.type
      )[0];

      const converted = "data:image/png;base64," + props.image;
      fetch(converted)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          formData.append("issuerTypeUUID", filteredIssuerType.uuid);
          formData.append("issuerCode", issuerCode);
          uploadedFile && formData.append("logo", uploadedFile);
          formData.append("issuerMasterUUID", props.issuerMasterUUID);
          formData.append("issuerNameUUID", issuerNameUUID);

          updateIssuerMaster(formData)
            .then((response) => {
              if (response && response.status === 200) {
                setIssuerName("");
                setIssuerCode("");
                setUploadedFile(null);
                setIssuerType("");
                props.onCloseModal(event, response.data);
                toastr.success("Successfully updated Issuer Master!");
              }
            })
            .catch((error) => toastr.error(error));
        });
    }
  };

  const renderIssuerNameDropdown = () => {
    let currentValue = props.issuerName ? props.issuerName : issuerName;
    return (
      <Grid item xs={12}>
        <Autocomplete
          id="free-solo-demo"
          freeSolo={true}
          value={currentValue}
          options={props.issuerNames.map((issuerName) => issuerName.name)}
          onChange={(event, enteredValue) => handleChange(event, enteredValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Issuer Name"
              margin="normal"
              variant="outlined"
            />
          )}
        />
      </Grid>
    );
  };

  const renderIssuerCode = () => {
    return (
      <Grid item xs={12}>
        <TextField
          label="Issuer Code"
          margin="normal"
          variant="outlined"
          onChange={handleIssuerCodeChange}
          value={issuerCode}
        />
      </Grid>
    );
  };

  const renderIssuerTypeDropdown = () => {
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Issuer Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={issuerType}
            onChange={(event) => handleOnSelect(event)}
            label="Issuer Type"
          >
            {props.issuerTypes &&
              props.issuerTypes.length > 0 &&
              props.issuerTypes.map((issuerType, index) => (
                <MenuItem value={issuerType} key={index}>
                  {issuerType.type}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const handleFileSelection = (event) => {
    event.preventDefault();
    setUploadedFile(event.target.files[0]);
  };

  const renderFileUpload = () => {
    let condition = props.mode === "Create";
    return (
      <Grid container item spacing={1}>
        <React.Fragment>
          <Grid item xs={4}>
            <Avatar
              style={{ height: "100px", width: "100px" }}
              variant="square"
              src={
                condition
                  ? uploadedFile
                  : `data:image/png;base64, ${props.image}`
              }
              className={classes.square}
            ></Avatar>
          </Grid>
          <Grid item xs={4}>
            <input type="file" name="file" onChange={handleFileSelection} />
          </Grid>
        </React.Fragment>
      </Grid>
    );
  };

  return (
    <div>
      <Dialog
        open={props.openModal}
        onClose={props.onCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Issuer Master</DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            {renderIssuerTypeDropdown()}
            {renderIssuerNameDropdown()}
            {renderIssuerCode()}
            {renderFileUpload()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCloseModal}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={submitHandler}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(AddIssuerMasterModal);
