import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DeleteModal from "./DeleteModal";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function ImageCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [toBeDeleted, setToBeDeleted] = React.useState(null);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDeleteArticleOnClick = (event, issuerMasterArticle) => {
    event.preventDefault();
    setShowDeleteModal(true);
    setToBeDeleted(issuerMasterArticle.uuid);
  };

  const closeDeleteModal = (event) => {
    event.preventDefault();
    setShowDeleteModal(false);
    setToBeDeleted(null);
  };

  const deleteIssuerMasterArticle = (event) => {
    event.preventDefault();
    props.onDeleteArticle(event, toBeDeleted);
    setToBeDeleted(null);
    setShowDeleteModal(false);
  };

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          title={props.issuerMasterArticle.title}
          subheader={props.issuerMasterArticle.publishedAt}
        />
        <CardMedia
          className={classes.media}
          image={`data:image/png;base64, ${props.issuerMasterArticle._imageUrl}`}
          title="Paella dish"
        />
        <CardContent></CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="add to favorites">
            <DeleteIcon
              onClick={(event) =>
                handleDeleteArticleOnClick(event, props.issuerMasterArticle)
              }
            />
          </IconButton>
          <IconButton aria-label="share">
            <EditIcon
              onClick={(event) =>
                props.handleEditIconOnClick(event, props.issuerMasterArticle)
              }
            />
          </IconButton>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>{props.issuerMasterArticle.body}</Typography>
          </CardContent>
        </Collapse>
      </Card>
      <DeleteModal
        primaryText="Delete Article ?"
        secondaryText="Are you sure you want to delete this Article ?"
        isOpen={showDeleteModal}
        handleClose={closeDeleteModal}
        confirmDelete={deleteIssuerMasterArticle}
      />
    </>
  );
}
