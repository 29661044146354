import React, { useEffect } from "react";
import toastr from "toastr";
import { TextField, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { makeDELETERequestOnUserServiceWithQueryParameter } from "../../Api/api";
import UserHelpers from "./UserHelpers";
import Input from "@mui/material/Input";

const BankDetailsInformationGrid = (props) => {
  const initialState = {
    bankName: props?.bankDetails?.bankName || "",
    beneficiaryName: props?.bankDetails?.beneficiaryName || "",
    accountNumber: props?.bankDetails?.accountNumber || "",
    ifscCode: props?.bankDetails?.ifscCode || "",
    branchName: props?.bankDetails?.branchName || "",
  };
  const [currentState, setCurrentState] = React.useState(initialState);
  const [createCond, setCreateCond] = React.useState(
    props.bankDetails?.accountNumber === ""
  );
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [base64, setBase64] = React.useState(props?.bankDetails?.image || "");
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  useEffect(() => {
    if (selectedFile !== null) {
      const reader = new FileReader();
      reader.onload = () => setBase64(UserHelpers.removePrefix(reader.result));
      reader.readAsDataURL(UserHelpers.convertedToBlob(selectedFile));
    }
  }, [selectedFile]);

  const addBankDetails = (event) => {
    event.preventDefault();
    props.addBankDetails(event, currentState, selectedFile);
  };

  const saveBankDetails = (event) => {
    event.preventDefault();
    props.saveBankDetails(event, currentState, selectedFile);
  };

  function deleteBankDetails(event) {
    event.preventDefault();
    makeDELETERequestOnUserServiceWithQueryParameter(
      `/kyc/bank_detail/delete/${props.currentUser.uuid}/${currentState.accountNumber}/`
    )
      .then((response) => {
        if (response?.status === 204) {
          toastr.success("Deleted bank details !");
          setShowDeleteModal(!showDeleteModal);
          setCurrentState({
            bankeName: "",
            beneficiaryName: "",
            accountNumber: "",
            ifscCode: "",
          });
          setCurrentState(initialState);
          setCreateCond(true);
          setSelectedFile(null);
          props.nullifyBankDetails(event);
        }
      })
      .catch((error) => toastr.error(error));
  }

  const handleInputChange = (field, value) => {
    setCurrentState({ ...currentState, [field]: value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  function dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  function downloadFile(e, b64) {
    e.preventDefault();
    const blob = dataURItoBlob(b64);
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  }

  return (
    <>
      <TextField
        label="Bank Name"
        variant="outlined"
        fullWidth
        margin="dense"
        value={currentState.bankName}
        onChange={(e) => handleInputChange("bankName", e.target.value)}
      />
      <TextField
        label="Beneficiary Name"
        variant="outlined"
        fullWidth
        margin="dense"
        value={currentState.beneficiaryName}
        onChange={(e) => handleInputChange("beneficiaryName", e.target.value)}
      />
      <TextField
        label="Branch Name"
        variant="outlined"
        fullWidth
        margin="dense"
        value={currentState.branchName}
        onChange={(e) => handleInputChange("branchName", e.target.value)}
      />
      <TextField
        label="Account Number"
        variant="outlined"
        fullWidth
        margin="dense"
        value={currentState.accountNumber}
        onChange={(e) => handleInputChange("accountNumber", e.target.value)}
      />
      <TextField
        label="IFSC Code"
        variant="outlined"
        fullWidth
        margin="dense"
        value={currentState.ifscCode}
        onChange={(e) => handleInputChange("ifscCode", e.target.value)}
      />
      <Input
        type="file"
        style={{ marginTop: "20px" }}
        accept="image/*"
        onChange={handleImageChange}
      />
      {selectedFile && selectedFile?.type && (
        <Grid item>
          <img
            src={URL.createObjectURL(selectedFile)}
            alt="Preview"
            style={{ maxWidth: "100%", maxHeight: "200px" }}
          />
        </Grid>
      )}

      {!selectedFile && base64 && (
        <Grid item>
          <img
            src={`data:image/png;base64, ${base64}`}
            alt="Preview"
            style={{ maxWidth: "100%", maxHeight: "200px" }}
          />
        </Grid>
      )}

      {props?.bankDetails?.image_filename &&
        props?.bankDetails?.image_filename.toLowerCase().endsWith(".pdf") && (
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => downloadFile(e, props.bankDetails.image)}
          >
            Download PDF
          </Button>
        )}
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={createCond ? addBankDetails : saveBankDetails}
          style={{ marginRight: "10px", marginTop: "20px" }}
        >
          Submit
        </Button>
        {!createCond && (
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: "20px" }}
            onClick={(event) => {
              event.preventDefault();
              setShowDeleteModal(!showDeleteModal);
            }}
          >
            Delete
          </Button>
        )}
      </Grid>
    </>
  );
};

export default BankDetailsInformationGrid;
