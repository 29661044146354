import { alpha } from "@mui/material/styles";

const styles = {
  flexStartCenter: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  flexEndCenter: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  flexAroundCenter: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  flexAroundEnd: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-end",
  },
  flexCenterCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexCenterEnd: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  flexCenterStart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  flexStartStart: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  flexEndEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  flexStartEnd: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  flexEndStart: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  flexBetweenEnd: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  flexBetweenCenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexBetweenStart: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  flexColumnCenterCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  flexColumnCenterStart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  flexColumnStartCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  flexColumnStartStart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  flexColumnBetweenStart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  flexColumnBetweenEnd: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  flexColumnStartEnd: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  flexColumnStartStretch: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
  },
  flexColumnEndStart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  flexColumnBetweenCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexEvenlyCenter: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  menuTabsHorizontal: {
    "span.MuiTabs-indicator": {
      height: "33px",
      bottom: 8,
      borderRadius: 5,
      bgcolor: `${alpha("#FFF", 0.1)}`,
      transition: "all 1000ms cubic-bezier(0.25, 0.1, 0, 0.86) 100ms",
    },
    ".MuiTab-root": {
      textTransform: "none",
      color: `${alpha("#FFF", 0.5)}`,
      ":hover": {
        color: `${alpha("#FFF", 0.7)}`,
      },
    },
    ".Mui-selected": {
      color: "#FFF !important",
    },
    "span.MuiTouchRipple-root": {
      display: "none",
    },
  },
  menuTabsVertical: {
    "span.MuiTabs-indicator": {
      borderRadius: 5,
      bgcolor: "#7569ee",
      transition: "all 1000ms cubic-bezier(0.25, 0.1, 0, 0.86) 100ms",
      width: 5,
    },
    ".MuiTab-root": {
      textTransform: "none",
      color: `${alpha("#FFF", 0.5)}`,
      fontSize: 13,
      py: 1,
      minHeight: "max-content",
      alignItems: "flex-end",
    },
    ".Mui-selected": {
      color: "#FFF !important",
    },
    "span.MuiTouchRipple-root": {
      display: "none",
    },
  },
  filterTabs: {
    position: "relative",
    ".hr": {
      position: "absolute",
      zIndex: 1,
      bottom: -5,
      left: 1,
      border: 0,
      width: "100%",
      height: 1.5,
      borderRadius: 50,
      background:
        "linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.537116) 62.76%, rgba(255, 255, 255, 0) 100%)",
    },
    "span.MuiTabs-indicator": {
      zIndex: 2,
      height: 3,
      bottom: 1.2,
      borderRadius: 50,
      bgcolor: "#7569EE",
      transition: "all 1000ms cubic-bezier(0.25, 0.1, 0, 0.86) 100ms",
    },
    ".MuiTab-root": {
      pb: 0,
      textTransform: "uppercase",
      color: `${alpha("#FFF", 0.5)}`,
    },
    ".Mui-selected": {
      color: "#7569EE !important",
    },
    "span.MuiTouchRipple-root": {
      display: "none",
    },
  },
  label: {
    color: `${alpha("#2A324C", 0.5)}`,
    fontSize: 10,
  },
  value: {
    color: "#2A324C",
    fontWeight: 800,
    fontSize: [13, "initial"],
  },
  positionAbsoluteCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  lineClamp2: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  font12: {
    color: "#2A324C",
    fontWeight: 800,
    fontSize: 12,
  },
  otpInputStyles: {
    fontFamily: "inherit",
    color: "#7569ee",
    background: "transparent",
    border: "1px solid #bababa",
    borderRadius: "12px",
    fontWeight: 800,
    fontSize: 16,
    flex: "auto",
    maxWidth: "50px",
    height: "50px",
  },
};

export default styles;
