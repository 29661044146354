import React, { useEffect, useContext } from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Avatar from "@material-ui/core/Avatar";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { makeGETRequest, makePOSTRequest } from "../Api/api";
import toastr from "toastr";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "184px",
  },
}));

const WeeklySmest = (props) => {
  const classes = useStyles();
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const initialState = {
    text: "",
    author: "",
    base64Image: "",
  };
  const [currentState, setCurrentState] = React.useState(initialState);

  React.useEffect(() => {
    let requestURL = "weekly_gyan/get/";
    makeGETRequest(requestURL)
      .then((response) => {
        if (response && response.status === 200) {
          setCurrentState({
            author: response.data.author,
            text: response.data.text,
            image: response.data.image,
          });
        }
      })
      .catch((error) => toastr.error(error));
  }, [props.show]);

  const resetStateAndClose = (event) => {
    event.preventDefault();
    setCurrentState(initialState);
    props.onCloseModal(event);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData();
    let requestURL = "/weekly_gyan/create/";
    formData.append("text", currentState.text);
    formData.append("author", currentState.author);
    formData.append("image", uploadedFile);
    makePOSTRequest(requestURL, formData)
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Created SMEST Weekly Gyan Quote!");
          resetStateAndClose(event);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const handleChange = (event) => {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleFileSelection = (event) => {
    event.preventDefault();
    setUploadedFile(event.target.files[0]);
  };

  const renderPictureUpload = () => {
    let condition = props.mode === "Create";
    return (
      <Grid container item spacing={1}>
        <React.Fragment>
          <Grid item xs={4}>
            <Avatar
              style={{ height: "100px", width: "100px" }}
              variant="square"
              src={
                condition
                  ? uploadedFile
                  : `data:image/png;base64, ${
                      currentState.image || props.image
                    }`
              }
              className={classes.square}
            ></Avatar>
          </Grid>
          <Grid item xs={4}>
            <input type="file" name="file" onChange={handleFileSelection} />
          </Grid>
        </React.Fragment>
      </Grid>
    );
  };

  return (
    <div>
      <React.Fragment>
        <Dialog
          fullWidth="sm"
          maxWidth="sm"
          open={props.show}
          onClose={resetStateAndClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            Weekly SMEST Gyan
          </DialogTitle>
          <DialogContent>
            <TextField
              type="text"
              variant="outlined"
              fullWidth
              label="Author"
              name="author"
              defaultValue={currentState.author}
              onChange={handleChange}
            />
            <br />
            <br />
            <TextareaAutosize
              style={{ width: "535px" }}
              aria-label="minimum height"
              rowsMin={12}
              placeholder="Quote"
              onChange={handleChange}
              name="text"
              defaultValue={currentState.text}
            />
            <br />
            <br />
            {renderPictureUpload()}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={resetStateAndClose}>
              Close
            </Button>
            <Button color="primary" onClick={handleSubmit}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
};

export default WeeklySmest;
