import React from "react";
import Box from "@mui/material/Box";
import { shallow } from "zustand/shallow";
import moment from "moment";
import useCalc from "../../store/calc";
import { sortDate, toINR } from "../../Helpers/functions";
import styles from "../../Helpers/styles";

const CashflowPDF = () => {
  const [bondInfo, calculatedValues, cashflow, units] = useCalc(
    (state) => [
      state.bondInfo,
      state.calculatedValues,
      state.cashflow,
      state.units,
    ],
    shallow
  );

  return (
    <>
      <Box component="table" id="heading" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>Cashflow for {bondInfo?.isin}</th>
          </tr>
        </thead>
      </Box>
      <Box component="table" id="disclaimer" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>
              Disclaimer: The actual dates and interest amounts can vary
              depending on Bank closures, holidays etc. Tax Deducted at Source
              (TDS) is applicable on interest receivable based on relevant tax
              regulations. You can avail TDS credit while filing your Income Tax
              Return (ITR).
            </th>
          </tr>
        </thead>
      </Box>
      {/* Basic Info Below */}
      <Box component="table" id="issuerName" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>Security Name</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{bondInfo?.securityName}</td>
          </tr>
        </tbody>
      </Box>
      <Box component="table" id="creditRating" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>Credit Rating</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {bondInfo?.ratingOrganizationMasters
                ?.map(
                  ({ rating: { rating }, organization: { name } }: any) => ({
                    rating,
                    ratingOrg: name,
                  })
                )
                .map((value, index) => (
                  <React.Fragment key={index}>
                    <Box component={"span"}>{value.rating}</Box>
                    <Box component={"span"} sx={styles.label}>
                      {" "}
                      by{" "}
                    </Box>
                    <Box component={"span"} sx={{ color: "info.main" }}>
                      {value.ratingOrg}
                    </Box>
                    <br />
                  </React.Fragment>
                ))}
            </td>
          </tr>
        </tbody>
      </Box>
      <Box component="table" id="frequency" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>Coupon Frequency</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{bondInfo?.frequencyOfInterest}</td>
          </tr>
        </tbody>
      </Box>
      <Box component="table" id="faceValue" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>Face Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{toINR(bondInfo?.faceValue.split(" ")[0], 4)}</td>
          </tr>
        </tbody>
      </Box>
      <Box component="table" id="coupon" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>Coupon</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{bondInfo?.coupons[0].rate}%</td>
          </tr>
        </tbody>
      </Box>
      <Box component="table" id="ytmYtc" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>YTM or YTC</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{bondInfo?.callOptions?.length ? "YTC" : "YTM"}</td>
          </tr>
        </tbody>
      </Box>
      <Box component="table" id="callOrMaturityDate" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>
              {bondInfo?.callOptions?.at(-1)?.date
                ? "Call Date"
                : "Maturity Date"}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {bondInfo?.callOptions?.at(-1)?.date
                ? bondInfo?.callOptions?.sort((a, b) => sortDate(a, b)).at(-1)
                    ?.date
                : bondInfo?.maturities?.sort((a, b) => sortDate(a, b)).at(-1)
                    ?.date}
            </td>
          </tr>
        </tbody>
      </Box>
      <Box component="table" id="security" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>Security</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{bondInfo?.securedOrUnsecured}</td>
          </tr>
        </tbody>
      </Box>
      <Box component="table" id="noOfBonds" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>No. of Bonds</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{calculatedValues?.noOfBonds}</td>
          </tr>
        </tbody>
      </Box>
      <Box component="table" id="settlementDate" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>Settlement Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{calculatedValues?.settlementDate.format("MMM DD,YYYY")}</td>
          </tr>
        </tbody>
      </Box>
      <Box component="table" id="principalAmount" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>Principal Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{toINR(calculatedValues?.principalAmount, 2)}</td>
          </tr>
        </tbody>
      </Box>
      <Box component="table" id="accruedInterest" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>Accrued Interest</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{toINR(calculatedValues?.accruedInterest, 2)}</td>
          </tr>
        </tbody>
      </Box>
      <Box component="table" id="stampDuty" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>Stamp Duty</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{calculatedValues?.stampDuty}</td>
          </tr>
        </tbody>
      </Box>
      <Box component="table" id="settlementAmount" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>Total Amount Payable</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{toINR(calculatedValues?.investmentAmount, 2)}</td>
          </tr>
        </tbody>
      </Box>
      <Box component="table" id="price" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{toINR(calculatedValues?.price, 4)}</td>
          </tr>
        </tbody>
      </Box>
      <Box component="table" id="yield" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>Yield</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{calculatedValues?.Yield}%</td>
          </tr>
        </tbody>
      </Box>
      {/* Actual Cashflow Below */}
      <Box component="table" id="cashflowTable" sx={{ display: "none" }}>
        <thead>
          <tr>
            <th>Year</th>
            <th>Payment Date</th>
            <th>Amount</th>
            <th>Payout Type</th>
          </tr>
        </thead>
        <tbody>
          {(cashflow ?? []).map(({ date, amount, type }, index) => (
            <tr key={index}>
              <td>{moment(date, "DD/MM/YYYY").format("YYYY")}</td>
              <td>{moment(date, "DD/MM/YYYY").format("DD MMM")}</td>
              <td>
                {toINR((amount as number) * (isNaN(units) ? 1 : units), 2)}
              </td>
              <td>{type}</td>
            </tr>
          ))}
        </tbody>
      </Box>
    </>
  );
};

export default CashflowPDF;
