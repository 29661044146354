import React, { useEffect, useContext, useState } from "react";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { appContext } from "../App";
import { getAllSecurityTypes, deleteSecurityType } from "../Api/api";
import AddSecurityTypeModal from "../components/AddSecurityTypeModal";
import DeleteModal from "../components/DeleteModal";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { Grid } from "@material-ui/core";
import IdleTimoutConfig from "../Helpers/IdleTimeoutConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "96%",
    margin: "35px auto",
    height: " 0px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const SecurityType = (props) => {
  const classes = useStyles();
  const { authUser, setAuthUser } = useContext(appContext);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [mainLoadingIs, setmainLoadingIs] = React.useState(false);
  const [securityTypes, setSecurityTypes] = React.useState([]);
  const [showAddSecurityTypeModal, setShowAddSecurityTypeModal] =
    React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [toBeDeleted, setToBeDeleted] = React.useState(null);

  const dataGridCells = [
    { field: "serial", headerName: "Serial", width: 350, editable: false },
    { field: "type", headerName: "Type", width: 450, editable: false },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 450,
      disableClickEventBubbling: true,

      renderCell: (row) => {
        return (
          <DeleteIcon
            onClick={(event) => handleDeleteIconOnClick(event, row)}
            color="primary"
            fontSize="medium"
            style={{
              float: "left",
              "margin-top": "10px",
              "margin-left": "14px",
              cursor: "pointer",
            }}
          />
        );
      },
    },
  ];
  function addSerialNumberToUsers(users) {
    let formatted = [];
    for (let iterator = 0; iterator < users.length; iterator++) {
      let userWithSerial = users[iterator];
      userWithSerial.serial = iterator + 1;
      formatted.push(userWithSerial);
    }
    return formatted;
  }
  useEffect(() => {
    if (authUser && authUser.uuid) {
      getAllSecurityTypes()
        .then((response) => {
          if (response && response.status === 200) {
            response.data
              ? setSecurityTypes(addSerialNumberToUsers(response.data))
              : setSecurityTypes([]);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleAddSecurityTypeModal = (event) => {
    event.preventDefault();
    setShowAddSecurityTypeModal(true);
  };

  const deleteSecurityTypeMaster = (event) => {
    event.preventDefault();
    let securityTypeUUID = toBeDeleted.uuid;
    deleteSecurityType(securityTypeUUID)
      .then((response) => {
        if (response && (response.status === 204 || response.status === 200)) {
          toastr.success("Successfully deleted Security Types");
          let filtered = securityTypes.filter(
            (securityType) => securityType.uuid != securityTypeUUID
          );
          setSecurityTypes(filtered);
          setShowDeleteModal(false);
          setToBeDeleted(null);
        }
      })
      .catch((error) => {
        toastr.error(error);
      });
  };

  const handleDeleteIconOnClick = (event, row) => {
    event.preventDefault();
    setShowDeleteModal(true);
    setToBeDeleted(row.row);
  };

  const handleOnCloseModal = (event) => {
    event.preventDefault();
    setShowAddSecurityTypeModal(false);
    getAllSecurityTypes()
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          setSecurityTypes(response.data);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setToBeDeleted(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid className="securityTypeDataGird">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>
                Security Type
              </div>
            </Grid>
            <Grid item xs="auto">
              <AddCircleIcon
                color="primary"
                fontSize="large"
                onClick={(event) => handleAddSecurityTypeModal(event)}
                style={{
                  float: "right",
                  "margin-top": "29px",
                  "margin-right": "30px",
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <div style={{ height: "100%", width: "100%" }}>
          <DataGrid
            className="securityTypeDataGrid abc"
            components={{ Toolbar: GridToolbar }}
            rows={securityTypes}
            columns={dataGridCells}
            getRowId={(row) => row.uuid}
            autoHeight
          />
        </div>
        <AddSecurityTypeModal
          openModal={showAddSecurityTypeModal}
          onCloseModal={handleOnCloseModal}
        />
        <DeleteModal
          primaryText="Delete Security Type Master"
          secondaryText="Are you sure you want to delete this security type ?"
          isOpen={showDeleteModal}
          handleClose={closeDeleteModal}
          confirmDelete={(event) => deleteSecurityTypeMaster(event)}
        />
      </Paper>
      {/* <IdleTimoutConfig /> */}
    </Grid>
  );
};

export default SecurityType;
