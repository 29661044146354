import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import toastr from "toastr";
import { appContext } from "../../App";
import OrderDetails from "./OrderDetails";
import { Grid, TextField, Button } from "@material-ui/core";
import { withRouter } from "react-router";
import {
  makeGETRequestOnUserService,
  makePOSTRequest,
  makePUTRequest,
  makePUTRequestOnUserService,
  downloadUserServiceFileUsingPOST,
  downloadUserServiceFileUsingGET,
  makePOSTRequestOnUserService,
} from "../../Api/api";
import EditOrderModal from "./EditOrderModal";
import ChangeOrderStatusModal from "./ChangeOrderStatusModal";
import OrderDialog from "./OrderDialog";
import TableCell from "@material-ui/core/TableCell";
import { saveAs } from "file-saver";
import CircularProgress from "@mui/material/CircularProgress";
import GenericDialog from "../Dialog/GenericDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },
  table: {
    minWidth: 1050,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function checkIfOnlyUnitsAreChanged(updated, original) {
  return (
    updated.settlementType === original.settlementType &&
    updated.noOfUnits !== original.noOfUnits &&
    updated.purchasePrice === original.purchasePrice &&
    updated.spread === original.spread
  );
}

const OrderTable = (props) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState(null);
  const { authUser, setAuthUser } = useContext(appContext);
  const [toBeEdited, setToBeEdited] = React.useState(null);
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [toBeChanged, setToBeChanged] = React.useState(null);
  const [showChangeStatusModal, setShowChangeStatusModal] =
    React.useState(false);
  const [showOrderDialog, setShowOrderDialog] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] =
    React.useState(false);

  useEffect(() => {
    let orderUUID = props.match.params.orderUUID;
    let requestURL = `/order_management/get_order_details/${orderUUID}/`;
    makeGETRequestOnUserService(requestURL)
      .then((response) => {
        if (response && response.status === 200) {
          setOrder(response.data);
          setIsLoaded(true);
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  function handleOnConfirmCreateCashFlowRecords(event) {
    makePOSTRequestOnUserService("/order_management/cashflow_dates/create/", {
      orderId: order.orderID,
    })
      .then((response) => {
        setShowConfirmationDialog(!showConfirmationDialog);
        toastr.success("Created CashFlow records successfully!");
      })
      .catch((error) => toastr.error(error));
  }

  const editOrderDetails = (event, details) => {
    event.preventDefault();
    if (authUser && authUser.uuid) {
      if (checkIfOnlyUnitsAreChanged(details, toBeEdited)) {
        let requestURL = "/admin/get_updated_principal/";
        let requestBody = {
          noOfUnits: details.noOfUnits,
          principal: toBeEdited.principal,
          accrued: toBeEdited.accrued,
          totalInvAmount: toBeEdited.totalInvAmount,
          transactionCost: toBeEdited.transactionCost,
          isin: toBeEdited.isin,
        };

        makePUTRequest(requestURL, requestBody)
          .then((response) => {
            if (response && response.status === 200) {
              let editable = toBeEdited;
              editable.accrued = response.data.accrued;
              editable.noOfUnits = requestBody.noOfUnits;
              editable.principal = response.data.principal;
              editable.stampDuty = response.data.stampDuty;
              editable.tcs = response.data.tcs;
              editable.totalInvAmount = response.data.totalInvAmount;
              editable.transactionCost = response.data.transactionCost;
              editable.securityMasterFaceValue =
                response.data.securityMasterFaceValue;
              setToBeEdited(editable);
              setShowEditModal(false);
              setShowOrderDialog(true);
            }
          })
          .catch((error) => toastr.error(error));
      } else {
        let requestURL = "/admin/update_order_params/";
        let requestBody = toBeEdited;
        requestBody.noOfUnits = details.noOfUnits;
        requestBody.purchasePrice = details.purchasePrice;
        requestBody.spread = details.spread;
        requestBody.settlementType = details.settlementType;

        makePOSTRequest(requestURL, requestBody)
          .then((response) => {
            if (response && response.status === 200) {
              let updatedOrder = toBeEdited;
              updatedOrder.accrued = response.data.accruedInterest;
              updatedOrder.noOfUnits = requestBody.noOfUnits;
              updatedOrder.principal = response.data.principal;
              updatedOrder.stampDuty = response.data.stampDuty;
              updatedOrder.tcs = response.data.tcs;
              updatedOrder.totalInvAmount = response.data.totalConsideration;
              updatedOrder.transactionCost = response.data.transactionCost;
              updatedOrder.sellYield = response.data.xirr;
              updatedOrder.sellPrice = response.data.sellPrice;
              updatedOrder.securityMasterFaceValue =
                response.data.securityMasterFaceValue;
              setToBeEdited(updatedOrder);
              setShowEditModal(false);
              setShowOrderDialog(true);
            }
          })
          .catch((error) => toastr.error(error));
      }
    }
  };

  const updateOrder = (event) => {
    event.preventDefault();
    let requestURL = "/order_management/updateOrder/";
    makePUTRequestOnUserService(requestURL, toBeEdited)
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Order details updated successfully !");
          props.history.push("/orders");
        }
      })
      .catch((error) => toastr.error(error));
  };

  const closeOrderDialog = (event) => {
    event.preventDefault();
    setShowOrderDialog(false);
  };

  const changeOrderStatus = (event, state) => {
    event.preventDefault();
    if (state.status === "Cancelled") {
      if (authUser && authUser.uuid) {
        let requestURL = `/order_management/reject/${toBeChanged.orderID}/`;
        makePUTRequestOnUserService(requestURL, {})
          .then((response) => {
            if (response && response.status === 200) {
              toastr.success("Order status updated successfully !");
              props.history.push("/orders");
            }
          })
          .catch((error) => toastr.error(error));
      }
    } else {
      if (authUser && authUser.uuid) {
        state.orderUUID = toBeChanged.uuid;
        let requestURL = "/order_management/changeOrderStatus/";
        makePUTRequestOnUserService(requestURL, state)
          .then((response) => {
            if (response && response.status === 200) {
              toastr.success("Order status updated successfully !");
              props.history.push("/orders");
            }
          })
          .catch((error) => toastr.error(error));
      }
    }
  };

  const closeChangeOrderStatusModal = (event) => {
    event.preventDefault();
    setShowChangeStatusModal(false);
    setToBeChanged(null);
  };

  const closeEditOrderModal = (event) => {
    event.preventDefault();
    setShowEditModal(false);
    setToBeEdited(null);
  };

  const handleOnChangeStatus = (event) => {
    event.preventDefault();
    setToBeChanged(order);
    setShowChangeStatusModal(true);
  };

  const handleEditIconOnClick = (event) => {
    if (order.orderStatus === "Pending") {
      event.preventDefault();
      setToBeEdited(order);
      setShowEditModal(true);
    } else {
      toastr.warning("Only orders with Pending status can be modified");
    }
  };

  const renderReadOnlyInput = (value, label) => {
    return (
      <TableCell align="left">
        <TextField
          id="standard-basic"
          label="Outlined"
          variant="outlined"
          disabled={true}
          name={"key"}
          placeholder={"placeholder"}
          defaultValue={value}
        />
      </TableCell>
    );
  };

  const handleDownloadSignedDealsheet = (event) => {
    event.preventDefault();
    if (order.isDealsheetUploaded) {
      let requestURL = `order_management/download_dealsheet/manual/${order.orderID}/`;
      downloadUserServiceFileUsingGET(requestURL)
        .then((response) => {
          if (response && response.status === 200) {
            saveAs(
              response.data,
              `signed_manual_dealsheet_${order.orderID}.pdf`
            );
          }
        })
        .catch((error) => console.log(error));
    } else {
      toastr.error("Dealsheet for this order is not available");
    }
  };

  const handleDownloadUnsignedDealsheet = (event) => {
    event.preventDefault();
    let requestURL = "/order_management/get_order_dealsheet/";
    downloadUserServiceFileUsingPOST(requestURL, { orderID: order.orderID })
      .then((response) => {
        if (response && response.status === 200) {
          saveAs(response.data, `dealsheet_${order.orderID}.pdf`);
        }
      })
      .catch((error) => console.log(error));
  };

  if (isLoaded) {
    return (
      <div className={classes.root}>
        <Grid className="pageTitle">
          <Grid container spacing={3} justify="center">
            <Grid item md={9} xs={12}>
              {order ? (
                <Grid container alignItems="center">
                  <Grid item xs="auto">
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        "margin-right": "15px",
                      }}
                      onClick={handleDownloadUnsignedDealsheet}
                    >
                      Download Unsigned Dealsheet
                    </Button>
                  </Grid>

                  <Grid item xs="auto">
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        "margin-right": "15px",
                      }}
                      onClick={handleDownloadSignedDealsheet}
                    >
                      Download Signed Dealsheet
                    </Button>
                  </Grid>

                  <Grid item xs="auto">
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        "margin-right": "15px",
                      }}
                      onClick={(event) => handleEditIconOnClick(event)}
                    >
                      Modify
                    </Button>
                  </Grid>
                  <Grid item xs="auto">
                    <Button
                      variant="contained"
                      style={{
                        "margin-right": "15px",
                        "margin-left": "15px",
                        "margin-top": "15px",
                      }}
                      color="primary"
                      onClick={(event) => handleOnChangeStatus(event)}
                    >
                      Change Order Status
                    </Button>
                  </Grid>
                  <Grid item xs="auto">
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        "margin-right": "15px",
                        "margin-left": "15px",
                        "margin-top": "15px",
                      }}
                      onClick={(event) => {
                        event.preventDefault();
                        setShowConfirmationDialog(!showConfirmationDialog);
                      }}
                    >
                      Create CashFlow Dates
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          {order !== null ? (
            <Grid className="profileshowConntan">
              <OrderDetails userProfileData={order} />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {showEditModal ? (
          <EditOrderModal
            show={showEditModal}
            order={toBeEdited}
            closeModal={closeEditOrderModal}
            editOrderDetails={editOrderDetails}
          />
        ) : (
          ""
        )}
        {showOrderDialog ? (
          <OrderDialog
            closeOrderDialog={closeOrderDialog}
            open={showOrderDialog}
            order={toBeEdited}
            updateOrder={updateOrder}
          />
        ) : (
          ""
        )}
        {showChangeStatusModal ? (
          <ChangeOrderStatusModal
            show={showChangeStatusModal}
            closeModal={closeChangeOrderStatusModal}
            orderStatus={toBeChanged.orderStatus}
            reason={toBeChanged.reasonForHold}
            changeOrderStatus={changeOrderStatus}
          />
        ) : (
          ""
        )}
        {showConfirmationDialog ? (
          <GenericDialog
            show={showConfirmationDialog}
            heading="Create Cashflow Records"
            inputProps={
              "Are you sure you want to create cashflow records? NOTE: only do this when there are no previous cashflow records created for this order."
            }
            onClose={(event) => {
              event.preventDefault();
              setShowConfirmationDialog(!showConfirmationDialog);
            }}
            handleSubmit={handleOnConfirmCreateCashFlowRecords}
          />
        ) : (
          ""
        )}
      </div>
    );
  } else {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    );
  }
};
export default withRouter(OrderTable);
