import React from "react";
import FullScreenDialog from "../Dialog/FullScreenDialog";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { addSerialNumber } from "../../Helpers/SecurityMasterHelpers";
import { makeGETRequestOnUserService } from "../../Api/api";
const toastr = require("toastr");

const dataGridCells = [
  { field: "serial", headerName: "Serial", width: 150, editable: false },
  { field: "panNumber", headerName: "Pan Number", width: 300, editable: false },
  {
    field: "accountNumber",
    headerName: "Account Number",
    width: 300,
    editable: false,
  },
  { field: "emailId", headerName: "Email Id", width: 300, editable: false },
  {
    field: "whatsappNumber",
    headerName: "Whatsapp Number",
    width: 300,
    editable: false,
  },
];

export default function KYCModuleErroneousRecords(
  props: any
): React.ReactElement {
  const [faultyRecords, setFaultyRecords] = React.useState([]);

  React.useEffect(() => {
    makeGETRequestOnUserService("/digio/kyc_user/list/")
      .then((response: any) => {
        setFaultyRecords(response.data);
      })
      .catch((error: any) => toastr.error(error));
  }, []);

  return (
    <FullScreenDialog
      heading="Faulty Records"
      showSecondarySaveButton={false}
      show={props.open}
      handleClose={props.handleClose}
      showSaveButton={false}
      saveButtonText="Download All"
      inputProps={
        <div
          style={{
            height: "100vh",
            width: "100%",
          }}
        >
          <DataGrid
            pagination
            className="QuotesDataGrid"
            components={{ Toolbar: GridToolbar }}
            rows={addSerialNumber(faultyRecords)}
            columns={dataGridCells}
            getRowId={(row) => row.serial}
          />
        </div>
      }
    />
  );
}
