import React from "react";
import {
  makeDELETERequestOnUserServiceWithQueryParameter,
  makeGETRequestOnUserService,
  makePUTRequestOnUserService,
} from "../Api/api";
import GenericDialog from "../components/Dialog/GenericDialog";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { Grid, TextField } from "@material-ui/core";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IdleTimoutConfig from "../Helpers/IdleTimeoutConfig";
const moment = require("moment");

interface NotificationUpdate {
  uuid: string;
  type: string;
  text: string;
}

interface Notification {
  uuid: string;
  insertedAt: string;
  text: string;
  type: string;
  updatedAt: string;
  imageName: string;
  serial?: number;
  createdAt?: string;
}

const dataGridCells = [
  { field: "serial", headerName: "ID", width: 100, editable: false },
  { field: "text", headerName: "Text", width: 500, editable: false },
  { field: "type", headerName: "Type", width: 150, editable: false },
  { field: "createdAt", headerName: "Created At", width: 250, editable: false },
  {
    field: "Edit",
    headerName: "Edit",
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,

    renderCell: () => {
      return (
        <EditIcon
          color="primary"
          fontSize="medium"
          style={{
            float: "left",
            marginLeft: "10px",
            marginTop: "14px",
            cursor: "pointer",
          }}
        />
      );
    },
  },
  {
    field: "Delete",
    headerName: "Delete",
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,

    renderCell: () => {
      return (
        <DeleteIcon
          color="primary"
          fontSize="medium"
          style={{
            float: "left",
            marginLeft: "10px",
            marginTop: "14px",
            cursor: "pointer",
          }}
        />
      );
    },
  },
];

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getUpdatedNotifications(
  updated: NotificationUpdate,
  notifications: Notification[]
): Notification[] {
  let formatted: Notification[] = [];
  notifications.map((notification: Notification) => {
    if (notification.uuid === updated.uuid) {
      notification.type = updated.type;
      notification.text = updated.text;
      formatted.push(notification);
    } else formatted.push(notification);
  });
  return formatted;
}

function addSerialNumber(notifications: Notification[]): Notification[] {
  let formatted: Notification[] = [];
  for (var iter = 0; iter < notifications.length; iter++) {
    let updated: Notification = notifications[iter];
    updated.serial = iter + 1;
    updated.createdAt = moment(notifications[iter].insertedAt).format(
      "MMMM Do YYYY, h:mm:ss a"
    );
    updated.type = updated.type
      .split("_")
      .map((val: string) => capitalizeFirstLetter(val))
      .join(" ");
    formatted.push(updated);
  }
  return formatted;
}

export default function Notifications(props: any) {
  const toastr = require("toastr");
  const initialState = {
    uuid: "",
    type: "",
    text: "",
  };
  const [notifications, setNotifications] = React.useState<Notification[]>([]);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [toBeDeleted, setToBeDeleted] = React.useState<string>("");
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [toBeUpdated, setToBeUpdated] =
    React.useState<NotificationUpdate>(initialState);

  React.useEffect(() => {
    makeGETRequestOnUserService("notification/list/")
      .then((response: any) => {
        setNotifications(addSerialNumber(response.data));
      })
      .catch((error: any) => toastr.error(error));
  }, []);

  function handleDeleteIconOnClick(row: any) {
    setShowDeleteModal(!showDeleteModal);
    setToBeDeleted(row.uuid);
  }

  function handleEditIconOnClick(row: any) {
    setShowEditModal(!showEditModal);
    setToBeUpdated({
      uuid: row.uuid,
      type: row.type,
      text: row.text,
    });
  }

  const currentlySelected = (params: any) => {
    if (params.field === "Delete") {
      handleDeleteIconOnClick(params.row);
    } else if (params.field === "Edit") {
      handleEditIconOnClick(params.row);
    }
  };

  function deleteNotification(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();
    makeDELETERequestOnUserServiceWithQueryParameter(
      `notification/delete/${toBeDeleted}/`
    )
      .then((response: any) => {
        if (response.status === 204 || response.status === 200) {
          toastr.success("Deleted Notification successfully!");
          setNotifications(
            notifications.filter(
              (notification: Notification) => notification.uuid !== toBeDeleted
            )
          );
          setShowDeleteModal(!showDeleteModal);
          setToBeDeleted("");
        }
      })
      .catch((error: any) => toastr.error(error));
  }

  function handleChange(event: any) {
    event.persist();
    setToBeUpdated((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  }

  function updateNotification(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();
    makePUTRequestOnUserService("notification/update/", toBeUpdated).then(
      (response) => {
        if (response?.status === 200) {
          toastr.success("Updated Notification successfully !");
          setShowEditModal(!showEditModal);
          setToBeUpdated(initialState);
          setNotifications(getUpdatedNotifications(toBeUpdated, notifications));
        }
      }
    );
  }

  function generateInputProps() {
    return (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={6}>
          <TextField
            type="text"
            name="purchaseYield"
            variant="outlined"
            fullWidth
            label="Type"
            defaultValue={toBeUpdated.type}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            name="text"
            variant="outlined"
            fullWidth
            label="Notification Text"
            defaultValue={toBeUpdated.text}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    );
  }

  if (notifications.length === 0) {
    return (
      <Box
        sx={{ width: 900 }}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <h1> Nothing to show here :(</h1>
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Box>
    );
  } else {
    return (
      <Grid className="subscriptionsDataGrid">
        <Paper>
          <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs>
                <div style={{ fontSize: "36px", float: "left" }}>
                  All Notifications
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              height: "100vh",
              width: "100%",
            }}
          >
            <DataGrid
              className="subscripionDataGrid abc"
              components={{ Toolbar: GridToolbar }}
              rows={notifications}
              columns={dataGridCells}
              onCellClick={currentlySelected}
              getRowId={(row: any) => row.uuid}
              pagination
            />
          </div>
        </Paper>
        {showDeleteModal ? (
          <GenericDialog
            show={showDeleteModal}
            heading="Delete Notification"
            inputProps="Are you sure you want to delete this notification?"
            onClose={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.preventDefault();
              setShowDeleteModal(!showDeleteModal);
              setToBeDeleted("");
            }}
            handleSubmit={deleteNotification}
          />
        ) : (
          ""
        )}
        {showEditModal ? (
          <GenericDialog
            show={showEditModal}
            heading="Update Notification"
            inputProps={generateInputProps()}
            onClose={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.preventDefault();
              setShowEditModal(!showEditModal);
              setToBeUpdated(initialState);
            }}
            handleSubmit={updateNotification}
          />
        ) : (
          ""
        )}
        {/* <IdleTimoutConfig /> */}
      </Grid>
    );
  }
}
