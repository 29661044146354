import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "./styles.css";
import "react-quill/dist/quill.snow.css";
import { Grid } from "@material-ui/core";

export default function RichTextEditorComponent(
  props: any
): React.ReactElement {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  return (
    <>
      <Grid className="holidayMastersDataGrid">
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              {props.heading}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="text-editor">
        <link
          rel="stylesheet"
          href="node_modules/react-quill/dist/quill.snow.css"
        ></link>
        <link
          rel="stylesheet"
          href="node_modules/react-quill/dist/quill.bubble.css"
        ></link>
        <link
          rel="stylesheet"
          href="node_modules/react-quill/dist/quill.core.css"
        ></link>
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          onChange={(html: any) => {
            props.setLookoutOnFinancials(html);
          }}
          value={props.lookoutOnFinancials}
          placeholder=""
          bounds={".app"}
        ></ReactQuill>
      </div>
    </>
  );
}
