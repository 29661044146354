import React, { useEffect, useContext } from "react";
import UserTable from "../../components/Users/UserTable";
import IdleTimoutConfig from "../../Helpers/IdleTimeoutConfig";

const Users = (props) => {
  return (
    <>
      <UserTable />
      {/* <IdleTimoutConfig /> */}
    </>
  );
};

export default Users;
