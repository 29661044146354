import React, { useEffect } from "react";
import { makeGETRequest } from "../Api/api";
import toastr from "toastr";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Typography } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import IdleTimoutConfig from "../Helpers/IdleTimeoutConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },
  table: {
    minWidth: 1050,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function ProfilePage(props) {
  const classes = useStyles();

  const [userDetails, setUserDetails] = React.useState(null);

  React.useEffect(() => {
    let uuid = props.match.params.uuid;
    makeGETRequest(`/admin/get_superuser_or_admin_user/${uuid}/`)
      .then((response) => {
        if (response.status === 200) {
          setUserDetails(response.data);
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  const profileDetails = () => {
    if (userDetails) {
      return (
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} justify="flex-end" alignItems="flex-end">
            <Grid className="profileInfoDateTitle">
              <Avatar>{userDetails?.firstName[0]?.toUpperCase()}</Avatar>
            </Grid>
          </Grid>
          <Grid item lg={6} sm={12}>
            <Grid className="profileInfoDate">
              <Grid className="profileInfoDateTitle">First Name</Grid>
              <Typography variant="h6" noWrap>
                {userDetails.firstName || "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={6} sm={12}>
            <Grid className="profileInfoDate">
              <Grid className="profileInfoDateTitle">Last Name</Grid>
              <Typography variant="h6" noWrap>
                {userDetails.lastName || "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={6} sm={12}>
            <Grid className="profileInfoDate">
              <Grid className="profileInfoDateTitle">Mobile Number</Grid>
              <Typography variant="h6" noWrap>
                {userDetails.mobileNumber || "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={6} sm={12}>
            <Grid className="profileInfoDate">
              <Grid className="profileInfoDateTitle">Email ID</Grid>
              <Typography variant="h6" noWrap>
                {userDetails.emailId || "--"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    } else return "";
  };

  return (
    <div className={classes.root}>
      <Grid className="pageTitle">
        <Grid item xs>
          <div style={{ fontSize: "36px", float: "left" }}>Profile</div>
        </Grid>
        <Grid container spacing={3} justify="center">
          <Grid item md={9} xs={12}>
            <Grid container alignItems="center">
              <Grid className="userProfileDetailsCard">{profileDetails()}</Grid>

              <Grid item xs="auto"></Grid>
              <Grid item xs="auto"></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <IdleTimoutConfig /> */}
    </div>
  );
}
