import React from "react";

import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

export default function UCCDetailsDialog(props) {
  function handleJSONDataOnChange(updated) {
    props.updateJsonData(updated.jsObject);
  }

  return (
    <div style={{ maxWidth: "1400px", maxHeight: "100%" }}>
      <JSONInput
        id="a_unique_id"
        locale={locale}
        placeholder={props.jsonData}
        colors={{
          string: "#DAA520", // overrides theme colors with whatever color value you want
        }}
        theme="dark_journey"
        height="100%"
        onChange={handleJSONDataOnChange}
      />
    </div>
  );
}
