import React, { useState, useContext, useEffect } from "react";
import toast from "toastr";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makePOSTRequest, superuserLogin } from "../Api/api";
import { appContext } from "../App";
import MultiFactorAuth from "./MultiFactorAuth/MultiFactorAuth";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginSection = (props) => {
  const classes = useStyles();
  const initialState = {
    emailId: "",
    plainTextPassword: "",
  };
  const { authUser, setAuthUser } = useContext(appContext);
  const [showPassword, setShowPassword] = useState(true);
  const [loginValues, setLoginValues] = useState(initialState);
  const [password, setPassword] = useState(initialState);
  const [showMultiFactorAuthScreen, setShowMultiFactorAuthScreen] =
    useState(false);
  const superuserDetailsInitialState = {
    mobileNumber: "",
    userUUID: "",
  };
  const [superuserDetails, setSuperuserDetails] = React.useState(
    superuserDetailsInitialState
  );
  const [userDetails, setUserDetails] = React.useState(null);
  const [accessToken, setAccessToken] = React.useState(null);
  const [otpPreference, setOTPPreference] = React.useState("Mobile");

  useEffect(() => {
    toast.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginDetails = (event) => {
    event.persist();
    setLoginValues((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      submitDetails(event);
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const submitDetails = (event) => {
    // Here loginValues.emailId represents mobile Number in case mobile number is added
    if (loginValues.emailId && password) {
      makePOSTRequest("/admin/superuser/initiate_auth/", {
        mobile_number: loginValues.emailId,
        password: password,
      })
        .then((resp) => {
          if (resp.status === 200) {
            localStorage.setItem("session", resp.data.session);
            setShowMultiFactorAuthScreen(true);
            setSuperuserDetails({
              mobileNumber: loginValues.emailId,
            });
            toast.success("An OTP has been sent to your mobile number.");
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Invalid Credentials");
        });
    }
  };

  function returnLoginScreen() {
    return (
      <Container
        style={{ backgroundColor: "#FFFFF" }}
        className="mainLogin"
        maxWidth="xs"
      >
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Superuser Login
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              onChange={handleLoginDetails}
              onKeyPress={(e) => handleEnterClick(e)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="emailId"
              label="Mobile Number"
              name="emailId"
              autoComplete="emailId"
              autoFocus
            />
            <FormControl
              className="full-width border-radius"
              style={{ width: "100%" }}
            >
              <TextField
                onChange={handlePasswordChange}
                onKeyPress={(e) => handleEnterClick(e)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "password" : "text"}
                id="password"
                autoComplete="current-password"
              />
              <InputAdornment position="end" className="showpassword">
                <IconButton
                  aria-label="toggle password visibility"
                  style={{
                    top: "18px",
                    position: "absolute",
                    right: "18px",
                  }}
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </FormControl>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Send OTP to
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={otpPreference}
                onChange={(event) => {
                  event.preventDefault();
                  setOTPPreference(event.target.value);
                }}
              >
                <FormControlLabel
                  value="Mobile"
                  control={<Radio />}
                  label="Mobile"
                />
                {/* <FormControlLabel
                  value="Email"
                  control={<Radio />}
                  label="Email"
                /> */}
              </RadioGroup>
            </FormControl>

            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={submitDetails}
            >
              Sign In
            </Button>
            <Link href="/admin_user/login">
              Admin User? Sign in to your Admin user account here
            </Link>
            <Grid container>
              <Grid item xs></Grid>
            </Grid>
          </form>
        </div>
      </Container>
    );
  }

  return (
    <Grid>
      {!showMultiFactorAuthScreen ? (
        returnLoginScreen()
      ) : (
        <MultiFactorAuth
          superuserDetails={superuserDetails}
          userDetails={userDetails}
          accessToken={accessToken}
          otpPreference={otpPreference}
        />
      )}
    </Grid>
  );
};

export default LoginSection;
