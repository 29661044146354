import React from "react";
import Close from "@material-ui/icons/Close";
import { TextField, Button, Grid, Fade, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function EditCashFlowDateDialog(props: any) {
  const toastr = require("toastr");

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Edit Date and/or Amount
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="amount"
                  variant="outlined"
                  fullWidth
                  label="Amount"
                  defaultValue={props.cashFlowRecord.amount}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    props.handleAmountChange(event, event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={new Date(props.cashFlowRecord.createdAt)}
                    onChange={(date: any) => {
                      props.handleDateChange(date);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={props.handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
