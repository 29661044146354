import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import Button from "@mui/material/Button";

export const jsonDataInitialState = {
  bond_type: "",
  deal_type: "",
  bid_offer: "",
  rating: "",
  rating_agency: "",
  value: "0.0",
  minimum_order_value: "0.0",
  type_of_yield: "",
  yield: "0.0",
  price: "0.0",
  settlement_type: "",
  gfd: "0",
  deal_time_hours: "",
  deal_time_minutes: "",
  otootm: "OTO",
  oto_participant_name: "",
  pro_client: "PRO",
  buyer_client_name: "",
  direct_brokered: "DIRECT",
  seller_broker_name: "",
  buyer_broker_name: "",
  negotiable_flag: "NO",
  disclosed_identity: "NO",
  initiator_custodian: "",
  initiator_reference_number: "",
  initiator_comment: "",
  user: "",
  isin: "",
  sell_yield: 0.0,
  sell_price: 0.0,
  accrued_interest: "",
  principal_amount: "",
  stamp_duty: "",
  settlement_amount: "",
};

export const callLogsDataGridCells = [
  {
    field: "AccuredInterest",
    headerName: "Accrued Interest",
    width: 180,
    editable: true,
  },
  { field: "Dealtime", headerName: "Deal Time", width: 200, editable: true },
  { field: "ERRORCODE", headerName: "Error Code", width: 150, editable: true },
  {
    field: "ISINNumber",
    headerName: "ISIN Number",
    width: 180,
    editable: true,
  },
  {
    field: "InternalOrderNumber",
    headerName: "Internal Order Number",
    width: 250,
    editable: true,
  },
  { field: "MESSAGE", headerName: "Message", width: 300, editable: true },
  {
    field: "MinRespValue",
    headerName: "Min. Response Value",
    width: 210,
    editable: true,
  },
  {
    field: "OrderStatus",
    headerName: "Order Status",
    width: 200,
    editable: true,
  },
  { field: "Price", headerName: "Price", width: 150, editable: true },
  { field: "RFQDealID", headerName: "RFQ Deal ID", width: 200, editable: true },
  {
    field: "RFQOrdernumber",
    headerName: "RFQ Order Number",
    width: 250,
    editable: true,
  },
  {
    field: "TotalConsideration",
    headerName: "Total Consideration",
    width: 250,
    editable: true,
  },
  { field: "Value", headerName: "Value", width: 150, editable: true },
  { field: "Yield", headerName: "Yield", width: 150, editable: true },
  { field: "Filler2", headerName: "Filler 2", width: 150, editable: true },
  { field: "Filler3", headerName: "Filler 3", width: 150, editable: true },
  { field: "Filler4", headerName: "Filler 4", width: 150, editable: true },
  { field: "Filler5", headerName: "Filler 5", width: 150, editable: true },
];

export const dataGridCells = [
  { field: "sellerName", headerName: "Source", width: 100, editable: false },
  {
    field: "insertedAt",
    headerName: "Date",
    width: 200,
    editable: false,
    renderCell: (params: any) => {
      const rowData = params.row;
      return moment(rowData.insertedAt).format("(HH[H]:mm[M]) MMM DD, YYYY");
    },
  },
  {
    field: "settlementDate",
    headerName: "Settlement Date",
    width: 200,
    editable: false,
  },
  { field: "isin", headerName: "ISIN", width: 150, editable: false },
  {
    field: "securityName",
    headerName: "Security Name",
    width: 200,
    editable: false,
  },
  {
    field: "noOfBonds",
    headerName: "No. of Bonds",
    width: 100,
    editable: false,
  },
  {
    field: "buyerFullName",
    headerName: "Buyer Name",
    width: 200,
    editable: false,
  },
  {
    field: "paymentProcess",
    headerName: "Payment Mode",
    width: 200,
    editable: false,
  },
  {
    field: "Edit",
    headerName: "Edit",
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,
    renderCell: () => {
      return (
        <EditIcon
          color="primary"
          fontSize="medium"
          style={{
            float: "left",
            marginLeft: "10px",
            marginTop: "14px",
            cursor: "pointer",
          }}
        />
      );
    },
  },
  {
    field: "Status",
    headerName: "Status",
    valueGetter: (params: any) => params.row.dealStatus,
    sortable: false,
    width: 200,
    disableClickEventBubbling: true,
    renderCell: (params: any) => {
      const rowData = params.row;
      return (
        <Button
          fullWidth
          variant="outlined"
          color={
            rowData.dealStatus === "Pending"
              ? "warning"
              : rowData.dealStatus === "Confirmed"
              ? "secondary"
              : rowData.dealStatus === "Cancelled"
              ? "error"
              : "success"
          }
        >
          {rowData.dealStatus}
        </Button>
      );
    },
  },
];

export function getFormattedAPICallLogs(logs: any[]): object[] {
  let formatted: object[] = [];
  for (var iter = 0; iter < logs.length; iter++) {
    formatted.push({
      id: logs[iter]._id,
      ...(logs[iter].RFQOrderResponceList
        ? logs[iter].RFQOrderResponceList[0]
        : logs[iter].RFQModCanResponceList[0]),
    });
  }
  return formatted;
}

export function convertToCamelCase<T>(objs: T[]): T[] {
  return objs.map((obj, index) => {
    const camelCasedObj: any = {};
    for (let prop in obj) {
      const camelCasedProp = prop.replace(/_([a-z])/g, (match, p1) =>
        p1.toUpperCase()
      );
      camelCasedObj[camelCasedProp] = obj[prop];
      camelCasedObj["serial"] = index + 1;
    }
    return camelCasedObj as T;
  });
}
