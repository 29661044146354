import React from "react";
import {
  makeGETRequestOnUserService,
  makePOSTRequestOnUserService,
  makeDELETERequestOnUserServiceWithQueryParameter,
} from "../../Api/api";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Grid, TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import GenericDialog from "../../components/Dialog/GenericDialog";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import IdleTimoutConfig from "../../Helpers/IdleTimeoutConfig";

const moment = require("moment");

interface WhatsappTemplate {
  uuid: string;
  templateName: string;
  templateText: string;
  insertedAt: string;
  updatedAt: string;
  serial?: number;
}

interface Template {
  templateName: string;
  templateText: string;
  variableCount: number;
}

const dataGridCells = [
  { field: "serial", headerName: "ID", width: 100, editable: false },
  {
    field: "templateName",
    headerName: "Template Name",
    width: 200,
    editable: false,
  },
  {
    field: "templateText",
    headerName: "Template Text",
    width: 500,
    editable: false,
    renderCell: (params: any) => (
      <Tooltip title={params?.row?.templateText || ""}>
        <span className="table-cell-truncate">
          {params?.row?.templateText || ""}
        </span>
      </Tooltip>
    ),
  },
  {
    field: "insertedAt",
    headerName: "Inserted At",
    width: 250,
    editable: false,
  },
  {
    field: "Delete",
    headerName: "Delete",
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,

    renderCell: () => {
      return (
        <DeleteIcon
          color="primary"
          fontSize="medium"
          style={{
            float: "left",
            marginLeft: "10px",
            marginTop: "14px",
            cursor: "pointer",
          }}
        />
      );
    },
  },
];

function formattifyRecords(templates: WhatsappTemplate[]): WhatsappTemplate[] {
  let formatted: WhatsappTemplate[] = [];
  for (var iter = 0; iter < templates.length; iter++) {
    templates[iter].serial = iter + 1;
    templates[iter].insertedAt = moment(templates[iter].insertedAt).format(
      "MMMM Do YYYY, h:mm:ss a"
    );
    formatted.push(templates[iter]);
  }
  return formatted;
}

export default function WhatsappTemplates(props: any) {
  const toastr = require("toastr");
  const [whatsappTemplates, setWhatsappTemplates] = React.useState<
    WhatsappTemplate[]
  >([]);
  const [showAddWhatsappTemplatesDialog, setShowAddWhatsappTemplatesDialog] =
    React.useState<boolean>(false);
  const initialState = {
    templateName: "",
    templateText: "",
    variableCount: 0,
  };
  const [currentState, setCurrentState] =
    React.useState<Template>(initialState);
  const [toBeDeleted, setToBeDeleted] = React.useState<string>("");
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    makeGETRequestOnUserService("whatsapp_campaign/templates/list/")
      .then((response: any) => {
        if (response?.status === 200) {
          setWhatsappTemplates(formattifyRecords(response.data));
          setIsLoading(false);
        }
      })
      .catch((error: any) => toastr.error(error));
  }, []);

  function handleChange(event: any) {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  }

  function generateInputProps() {
    return (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={6}>
          <TextField
            type="text"
            name="templateName"
            variant="outlined"
            fullWidth
            label="Template Name"
            defaultValue={currentState.templateName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            name="templateText"
            variant="outlined"
            fullWidth
            multiline
            label="Template Text"
            defaultValue={currentState.templateText}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            name="variableCount"
            variant="outlined"
            fullWidth
            multiline
            label="Variable Count"
            defaultValue={currentState.variableCount}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    );
  }

  function deleteWhatsappTemplate(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();
    makeDELETERequestOnUserServiceWithQueryParameter(
      `/whatsapp_campaign/templates/delete/${toBeDeleted}/`
    )
      .then((response: any) => {
        setShowDeleteModal(!showDeleteModal);
        setWhatsappTemplates(
          whatsappTemplates.filter(
            (whatsappTemplate: WhatsappTemplate) =>
              whatsappTemplate.uuid !== toBeDeleted
          )
        );
        setToBeDeleted("");
        toastr.success("Deleted Whatsapp Template successfully !");
      })
      .catch((error: any) => toastr.error(error));
  }

  function createWhatsappTemplate(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();
    makePOSTRequestOnUserService(
      "/whatsapp_campaign/templates/create/",
      currentState
    )
      .then((response: any) => {
        if (response?.status === 200) {
          setWhatsappTemplates(formattifyRecords(response.data));
          setShowAddWhatsappTemplatesDialog(!showAddWhatsappTemplatesDialog);
          setCurrentState(initialState);
        }
      })
      .catch((error: any) => toastr.error(error));
  }

  function handleDeleteIconOnClick(row: any) {
    setToBeDeleted(row.uuid);
    setShowDeleteModal(!showDeleteModal);
  }

  function currentlySelected(params: any) {
    if (params.field === "Delete") {
      handleDeleteIconOnClick(params.row);
    }
  }

  if (isLoading) {
    return (
      <Box
        sx={{ width: 900 }}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Box>
    );
  } else {
    return (
      <Grid className="securityTypeDataGird">
        <Paper>
          <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs>
                <div style={{ fontSize: "36px", float: "left" }}>
                  Whatsapp Templates (Approved)
                </div>
              </Grid>
              <Grid item xs="auto">
                <AddCircleIcon
                  color="primary"
                  fontSize="large"
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault();
                    setShowAddWhatsappTemplatesDialog(
                      !showAddWhatsappTemplatesDialog
                    );
                  }}
                  style={{
                    float: "left",
                    marginLeft: "10px",
                    marginTop: "14px",
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              height: "100vh",
              width: "100%",
            }}
          >
            <DataGrid
              className="securityTypeDataGrid"
              components={{ Toolbar: GridToolbar }}
              rows={whatsappTemplates}
              columns={dataGridCells}
              onCellClick={currentlySelected}
              getRowId={(row: any) => row.uuid}
              autoHeight
            />
          </div>
        </Paper>
        {showAddWhatsappTemplatesDialog ? (
          <GenericDialog
            show={showAddWhatsappTemplatesDialog}
            heading="Create Whatsapp Template"
            inputProps={generateInputProps()}
            onClose={(event: React.MouseEvent) => {
              event.preventDefault();
              setShowAddWhatsappTemplatesDialog(
                !showAddWhatsappTemplatesDialog
              );
            }}
            handleSubmit={createWhatsappTemplate}
          />
        ) : (
          ""
        )}
        {showDeleteModal ? (
          <GenericDialog
            show={showDeleteModal}
            heading="Delete Whatsapp Template"
            inputProps="Are you sure you want to delete this template?"
            onClose={(event: React.MouseEvent) => {
              event.preventDefault();
              setShowDeleteModal(!showDeleteModal);
            }}
            handleSubmit={deleteWhatsappTemplate}
          />
        ) : (
          ""
        )}
        {/* <IdleTimoutConfig /> */}
      </Grid>
    );
  }
}
