import jsPDF from "jspdf";
import autoTable, { Styles, UserOptions } from "jspdf-autotable";
import { PlusJakartaSansFontBase64String } from "./PlusJakartaSans-base64String";
import ICalculatedValues from "../interfaces/ICalculatedValues";
import IBondDetails from "../interfaces/IBondDetails";
import { imgData } from "./smestLogoBase64ImgData";

const downloadCashflow = (
  bondInfo: IBondDetails,
  calculatedValues: ICalculatedValues
) => {
  const doc = new jsPDF({ filters: ["ASCIIHexEncode"] });
  var totalPagesExp = "{total_pages_count_string}";
  doc.addFileToVFS(
    "PlusJakartaSans-VariableFont_wght-normal.ttf",
    PlusJakartaSansFontBase64String
  );
  doc.addFont(
    "PlusJakartaSans-VariableFont_wght-normal.ttf",
    "PlusJakartaSans-VariableFont_wght",
    "normal"
  );
  doc.setFont("PlusJakartaSans-VariableFont_wght", "normal");

  doc.addImage(`data:image/png;base64,${imgData}`, "PNG", 153, 5, 45, 18);

  autoTable(doc, {
    html: "#heading",
    theme: "plain",
    headStyles: {
      fillColor: "#7659EE",
      textColor: "#FFFFFF",
    },
    startY: 23,
    styles: {
      font: "PlusJakartaSans-VariableFont_wght",
      fontSize: 15,
      cellWidth: "auto",
      halign: "center",
    },
  });

  const headStyles: Partial<Styles> = {
    fillColor: "#FFFFFF",
    textColor: "#7659EE",
    cellPadding: { bottom: 2, left: 2 },
    fontSize: 10,
  };

  const bodyStyles: Partial<Styles> = {
    cellPadding: { top: 0, left: 2 },
    fillColor: "#FFFFFF",
    fontStyle: "bold",
  };

  const marginRight = { right: 107 };

  const marginLeft = { left: 107 };

  const tableStyles: Partial<Styles> = {
    font: "PlusJakartaSans-VariableFont_wght",
    fontSize: 10,
    cellWidth: "auto",
    halign: "left",
  };

  const commonProps: UserOptions = {
    theme: "plain",
    headStyles,
    bodyStyles,
    showHead: "firstPage",
    styles: tableStyles,
  };

  autoTable(doc, {
    html: "#issuerName",
    ...commonProps,
    startY: 40,
    margin: marginRight,
  });

  autoTable(doc, {
    html: "#creditRating",
    ...commonProps,
    startY: 40,
    margin: marginLeft,
  });

  autoTable(doc, {
    html: "#frequency",
    ...commonProps,
    startY: 60,
    margin: marginRight,
  });

  autoTable(doc, {
    html: "#faceValue",
    ...commonProps,
    startY: 60,
    margin: marginLeft,
  });

  autoTable(doc, {
    html: "#coupon",
    ...commonProps,
    startY: 80,
    margin: marginRight,
  });

  autoTable(doc, {
    html: "#ytmYtc",
    ...commonProps,
    startY: 80,
    margin: marginLeft,
  });

  autoTable(doc, {
    html: "#callOrMaturityDate",
    ...commonProps,
    startY: 100,
    margin: marginRight,
  });

  autoTable(doc, {
    html: "#security",
    ...commonProps,
    startY: 100,
    margin: marginLeft,
  });

  autoTable(doc, {
    html: "#noOfBonds",
    ...commonProps,
    startY: 120,
    margin: marginRight,
  });

  autoTable(doc, {
    html: "#settlementDate",
    ...commonProps,
    startY: 120,
    margin: marginLeft,
  });

  autoTable(doc, {
    html: "#principalAmount",
    ...commonProps,
    startY: 140,
    margin: marginRight,
  });

  autoTable(doc, {
    html: "#accruedInterest",
    ...commonProps,
    startY: 140,
    margin: marginLeft,
  });

  autoTable(doc, {
    html: "#stampDuty",
    ...commonProps,
    startY: 160,
    margin: marginRight,
  });

  autoTable(doc, {
    html: "#settlementAmount",
    ...commonProps,
    startY: 160,
    margin: marginLeft,
  });

  autoTable(doc, {
    html: "#price",
    ...commonProps,
    startY: 180,
    margin: marginRight,
  });

  autoTable(doc, {
    html: "#yield",
    ...commonProps,
    startY: 180,
    margin: marginLeft,
  });

  autoTable(doc, {
    html: "#cashflowTable",
    theme: "grid",
    headStyles: {
      fillColor: "#7659EE",
    },
    bodyStyles: {
      textColor: "#7659EE",
    },
    styles: {
      font: "PlusJakartaSans-VariableFont_wght",
      fontSize: 10,
      cellWidth: "auto",
      halign: "center",
    },
  });

  autoTable(doc, {
    html: "#disclaimer",
    theme: "plain",
    headStyles: {
      fillColor: "#FFFFFF",
    },
    bodyStyles: {
      textColor: "#9a9a9a",
    },
    styles: {
      font: "PlusJakartaSans-VariableFont_wght",
      fontSize: 8,
      cellWidth: "auto",
      halign: "left",
    },
  });

  doc.save(
    `${bondInfo?.isin}_cashflow_${calculatedValues?.price}@${calculatedValues?.Yield}.pdf`
  );
};

export default downloadCashflow;
