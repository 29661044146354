import React, { useState, useEffect } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import NotificationCloseIcon from "../../images/notificationCloseIcon.svg";

const Ticker = ({ items, interval, handleClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const tickerInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, interval);

    return () => {
      clearInterval(tickerInterval);
    };
  }, [items.length, interval]);

  function renderSlider() {
    return <span style={{ paddingRight: "5px" }}>{items[currentIndex]}</span>;
  }

  return (
    <Toolbar>
      <marquee width="100%" direction="left" height="auto">
        {renderSlider()}
      </marquee>
      <Grid style={{ padding: "0px 0px 0px 20px" }}>
        <IconButton
          className="headerFloatingBtn"
          aria-label="notification"
          style={{
            backgroundImage: "url(" + NotificationCloseIcon + ")",
          }}
          onClick={handleClose}
        ></IconButton>
      </Grid>{" "}
    </Toolbar>
  );
};

export default Ticker;
