import React, { ReactNode } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { alpha, styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Zoom from "@mui/material/Zoom";
import { shallow } from "zustand/shallow";
import moment from "moment";
import CashflowPDF from "./CashflowPDF";
import styles from "../../Helpers/styles";
import { toINR } from "../../Helpers/functions";
import useCalc from "../../store/calc";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ICalculatedValues from "../../interfaces/ICalculatedValues";
import MailIcon from "@mui/icons-material/Mail";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import IBondDetails from "../../interfaces/IBondDetails";
import downloadCashflow from "../../Helpers/downloadCashflowFunction";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#2A324C",
    borderRadius: "8px",
    fontSize: 12,
  },
}));

const SettlementAmount = ({
  calculatedValues,
}: {
  calculatedValues: ICalculatedValues;
}) => (
  <Box sx={styles.flexColumnCenterCenter}>
    <Typography
      sx={{
        color: `${alpha("#2A324C", 0.5)}`,
        fontSize: 12,
        fontWeight: 600,
        whiteSpace: "nowrap",
      }}
    >
      {toINR(calculatedValues?.price, 4)} @{calculatedValues?.Yield}%
    </Typography>
    <Typography
      variant="h6"
      noWrap
      sx={{
        color: "#2A324C",
        fontSize: 15,
        fontWeight: 700,
        whiteSpace: "nowrap",
      }}
    >
      {toINR(calculatedValues?.investmentAmount, 2)}
    </Typography>
  </Box>
);

const Actions = () => {
  const [openShare, setOpenShare] = React.useState(false);
  const [openCopy, setOpenCopy] = React.useState(false);
  const [zoom, bondInfo, calculatedValues, breakup, getBreakup] = useCalc(
    (state: any) => [
      state.zoom,
      state.bondInfo,
      state.calculatedValues,
      state.breakup,
      state.getBreakup,
    ],
    shallow
  );

  const handleShareTooltipClose = () => {
    setOpenShare(false);
  };

  const handleShareTooltipOpen = () => {
    setOpenShare(true);
  };

  const subject = `Calculations for ${bondInfo?.isin}`;

  const text = `Hi, Checkout this bond price calculation on smest.in
*${bondInfo?.securityName} (${bondInfo?.isin})*
Rating: *${bondInfo?.ratingOrganizationMasters[0]?.rating?.rating} by ${
    bondInfo?.ratingOrganizationMasters[0]?.organization?.name
  }*
IP Frequency: *${bondInfo?.frequencyOfInterest}*
Type: *${
    bondInfo?.securedOrUnsecured === "Senior Secured" ? "Secured" : "Unsecured"
  }*
Face Value: *${toINR(bondInfo?.faceValue.split(" ")[0], 4)}*  

${bondInfo?.callOptions?.at(-1)?.date ? "Call Date" : "Maturity"}: ${
    bondInfo?.callOptions?.at(-1)?.date
      ? bondInfo?.callOptions?.at(-1)?.date
      : bondInfo?.maturities?.at(-1)?.date
  }
Coupon: ${bondInfo?.coupons[0].rate}%
No. of Bonds: *${calculatedValues?.noOfBonds}*
Price: *${toINR(calculatedValues?.price, 4)}*
Yield: *${calculatedValues?.Yield}%*
Settlement On: *${calculatedValues?.settlementDate.format("DD/MM/YYYY")}*
Principal Amount: ${toINR(calculatedValues?.principalAmount, 2)}
Accrued Interest: ${toINR(calculatedValues?.accruedInterest, 2)}
Stamp Duty: ${toINR(calculatedValues?.stampDuty, 2)}
Settlement Amount: *${toINR(calculatedValues?.investmentAmount, 2)}*`;

  return (
    <Box sx={styles.flexEndCenter}>
      {!zoom && (
        <Typography
          sx={{
            color: "#EC4F56",
            fontSize: 12,
            fontWeight: 600,
            cursor: "pointer",
            whiteSpace: "nowrap",
          }}
          onClick={() => getBreakup(!breakup)}
        >
          {breakup ? "Hide" : "View"} Breakup
        </Typography>
      )}
      <ClickAwayListener onClickAway={handleShareTooltipClose}>
        <div>
          <BootstrapTooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleShareTooltipClose}
            open={openShare}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <Box sx={styles.flexBetweenCenter}>
                <BootstrapTooltip
                  title="Share via Whatsapp"
                  arrow
                  placement="bottom-end"
                  TransitionComponent={Zoom}
                >
                  <Box
                    component="a"
                    target="_blank"
                    href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                      text
                    )}`}
                    sx={{ p: 0.5 }}
                  >
                    <WhatsAppIcon />
                  </Box>
                </BootstrapTooltip>
                <BootstrapTooltip
                  title="Share via Email"
                  arrow
                  placement="bottom-end"
                  TransitionComponent={Zoom}
                >
                  <Box
                    component="a"
                    target="_blank"
                    href={`mailto:?subject=${subject}&body=${encodeURIComponent(
                      text
                    )}`}
                    sx={{ p: 0.5 }}
                  >
                    <MailIcon />
                  </Box>
                </BootstrapTooltip>
              </Box>
            }
          >
            <IconButton
              sx={{
                p: 0,
                ml: 0.5,
              }}
              onClick={handleShareTooltipOpen}
            >
              <ShareIcon />
            </IconButton>
          </BootstrapTooltip>
        </div>
      </ClickAwayListener>
      <IconButton
        sx={{
          p: 0,
          ml: 0.5,
        }}
        onClick={() => copy(text)}
      >
        <ContentCopyIcon />
      </IconButton>
    </Box>
  );
};

export const Values = ({
  value,
  label,
}: {
  value: string | ReactNode;
  label: string;
}) => (
  <Box sx={{ mb: 1, width: "50%" }}>
    <Typography
      sx={{
        color: "#2A324C",
        fontSize: 12,
        fontWeight: 700,
      }}
    >
      {value}
    </Typography>
    <Typography
      variant="h6"
      noWrap
      sx={{
        color: `${alpha("#2A324C", 0.5)}`,
        fontSize: 11,
        fontWeight: 500,
      }}
    >
      {label}
    </Typography>
  </Box>
);

const Breakup = ({
  calculatedValues,
  bondInfo,
}: {
  calculatedValues: ICalculatedValues;
  bondInfo: IBondDetails;
}) => (
  <Box sx={{ ...styles.flexBetweenCenter, flexWrap: "wrap", mt: 2 }}>
    <Values value={`${calculatedValues?.noOfBonds}`} label="No. of Bonds" />
    <Values
      value={`${calculatedValues?.accruedDays}(${moment(
        bondInfo.lastIPDate,
        "YYYY-MM-DD"
      ).format("DD/MM/YYYY")})`}
      label="Accrued Days(Last IP Date)"
    />
    <Values
      value={`${toINR(calculatedValues?.principalAmount, 2)}`}
      label="Principal Amount"
    />
    <Values
      value={`${toINR(calculatedValues?.accruedInterest, 2)}`}
      label="Accrued Interest"
    />
    {!(
      bondInfo?.securityType.type === "State Development Loans" ||
      bondInfo?.securityType.type === "Government Securities" ||
      bondInfo?.securityType.type === "T Bill" ||
      bondInfo?.securityType.type === "Treasury Bill"
    ) && (
      <Values
        value={`${toINR(calculatedValues?.stampDuty, 2)}`}
        label="Stamp Duty"
      />
    )}
    <Values
      value={`${toINR(calculatedValues?.investmentAmount, 2)} @${
        calculatedValues?.Yield
      }%`}
      label="Settlement Amount"
    />
  </Box>
);

const Calculations = () => {
  const [zoom, bondInfo, calculatedValues, breakup] = useCalc(
    (state) => [
      state.zoom,
      state.bondInfo,
      state.calculatedValues,
      state.breakup,
    ],
    shallow
  );

  return (
    <>
      {zoom ? (
        <Box>
          <Box
            sx={{
              p: 2,
              bgcolor: `${alpha("#EC4F56", 0.1)}`,
              borderRadius: "20px",
              mt: 1,
            }}
          >
            <SettlementAmount calculatedValues={calculatedValues!} />
          </Box>
          <Box sx={{ ...styles.flexBetweenCenter, mt: 1 }}>
            <Typography
              sx={{
                color: "#9397A4",
                fontSize: 12,
                fontWeight: 600,
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
            >
              Breakup
            </Typography>
            <Actions />
          </Box>
          <Breakup calculatedValues={calculatedValues!} bondInfo={bondInfo!} />
        </Box>
      ) : (
        <Box
          sx={{
            mt: 2,
            p: 2,
            bgcolor: `${alpha("#EC4F56", 0.1)}`,
            borderRadius: "20px",
          }}
        >
          {breakup ? (
            <Box sx={styles.flexColumnStartEnd}>
              <Actions />
              <Breakup
                calculatedValues={calculatedValues!}
                bondInfo={bondInfo!}
              />
            </Box>
          ) : (
            <Box sx={{ ...styles.flexBetweenCenter, flexWrap: "wrap" }}>
              <SettlementAmount calculatedValues={calculatedValues!} />
              <Actions />
            </Box>
          )}
          <Box sx={{ ...styles.flexCenterCenter, mt: 1 }}>
            <Box
              sx={{
                ...styles.flexCenterCenter,
                bgcolor: "#FFF",
                p: 1,
                pl: 2,
                borderRadius: 50,
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                sx={{
                  color: "#2A324C",
                  fontSize: 13,
                  fontWeight: 500,
                }}
              >
                Download Cashflow
              </Typography>
              <BootstrapTooltip title="As PDF" arrow placement="top-end">
                <IconButton
                  sx={{
                    bgcolor: "secondary.main",
                    ml: 1,
                    boxShadow: "6px 6px 20px 0px #F33A4333",
                    ":hover": {
                      bgcolor: "secondary.main",
                    },
                  }}
                  onClick={() =>
                    bondInfo && calculatedValues
                      ? downloadCashflow(bondInfo, calculatedValues)
                      : toastr.error(`Error Generating Cashflow`)
                  }
                >
                  <PictureAsPdfIcon sx={{ color: "red" }} />
                </IconButton>
              </BootstrapTooltip>
              {/* TODO - Need Excel in V2 */}
              {/* <BootstrapTooltip title="As Excel" arrow placement="top-start">
                <IconButton
                  sx={{
                    bgcolor: "#51CB61",
                    ml: 1,
                    boxShadow: "6px 6px 20px 0px #51CB6133",
                    ":hover": {
                      bgcolor: "#51CB61",
                    },
                  }}
                >
                  <Image
                    src="/icons/excel.svg"
                    width={18}
                    height={18}
                    alt="Excel Icon"
                  />
                </IconButton>
              </BootstrapTooltip> */}
            </Box>
          </Box>
        </Box>
      )}
      <CashflowPDF />
    </>
  );
};

export default Calculations;
