import React from "react";
import {
  makeGETRequestOnUserService,
  makePOSTRequestOnUserService,
} from "../../Api/api";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import GenericCategoryBox from "./GenericCategoryBox";
import { Grid, Tooltip, Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { CampaignCategory, LoginUser } from "./Interfaces";
import useForceUpdate from "use-force-update";
import WhatsappCampaignDialog from "../../components/Dialog/WhatsappCampaignDialog";

function getUpdatedCategorySelectAllStatus(
  categories: Record<string, Array<any>>
): Record<string, boolean> {
  let selectAllStatus: Record<string, boolean> = {};
  Object.keys(categories).map((categoryName) => {
    selectAllStatus[categoryName] = false;
  });
  return selectAllStatus;
}

function addIsChecked(
  categories: Record<string, Array<any>>
): Record<string, Array<any>> {
  let formatted: Record<string, Array<any>> = {};
  Object.keys(categories).map((categoryName: string) => {
    let updated: Array<CampaignCategory> = [];
    categories[categoryName].map((category) => {
      category.isChecked = false;
      updated.push(category);
    });
    formatted[categoryName] = updated;
  });
  return formatted;
}

function uncheckAllCustomCategories(categories: Array<any>): Array<any> {
  let formatted: Array<any> = [];
  categories.map((category) => {
    category.isChecked = false;
    formatted.push(category);
  });
  return formatted;
}

export default function UserSpecificCategories(props: any) {
  const toastr = require("toastr");
  const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);
  const [userCategories, setUserCategories] = React.useState<any>({});
  const [selectAllStatusForCategories, setSelectAllStatusForCategories] =
    React.useState<any>({});
  const [showSelectMediaDialog, setShowSelectMediaDialog] =
    React.useState<boolean>(false);
  const [selectedMobileNumbers, setSelectedMobileNumbers] = React.useState<
    Array<string>
  >([]);
  const [selectedCategoryName, setSelectedCategoryName] =
    React.useState<string>("");
  const forceUpdate = useForceUpdate();

  React.useEffect(() => {
    let userId: string = props.match.params.userId;
    makeGETRequestOnUserService(`/whatsapp_campaign/categories/list/${userId}/`)
      .then((response: any) => {
        if (response && response.status === 200) {
          setUserCategories(addIsChecked(response.data));
          setSelectAllStatusForCategories(
            getUpdatedCategorySelectAllStatus(response.data)
          );
          setHasLoaded(true);
        }
      })
      .catch((error: any) => toastr.error(error));
  }, []);

  function handleCheckBoxSelectionForCustomCategories(
    event: any,
    category: any,
    categoryName: string
  ): void {
    event.preventDefault();
    let currentState: Record<string, Array<any>> = userCategories;
    let updatedCategories: Array<any> = [];
    currentState[categoryName].map((c) => {
      if (c.uuid === category.categoryId) {
        c.isChecked = !c.isChecked;
        updatedCategories.push(c);
      } else updatedCategories.push(c);
    });
    currentState[categoryName] = updatedCategories;
    setUserCategories(currentState);
    forceUpdate();
  }

  function onSelectAllWithCurrentCategory(event: any, categoryName: any): void {
    event.preventDefault();
    let currentState = userCategories;
    let updated: any = [];
    currentState[categoryName].map((category: any) => {
      category.isChecked = !category.isChecked;
      updated.push(category);
    });
    currentState[categoryName] = updated;
    setUserCategories(currentState);
    let currentSelectAllStatus = selectAllStatusForCategories;
    currentSelectAllStatus[categoryName] =
      !currentSelectAllStatus[categoryName];
    setSelectAllStatusForCategories(currentSelectAllStatus);
    forceUpdate();
  }

  function sendMessageToUserFromCustomCategory(
    event: any,
    category: any,
    categoryName: string
  ): void {
    event.preventDefault();
    setSelectedMobileNumbers([category.mobileNumber]);
    setSelectedCategoryName(categoryName);
    setShowSelectMediaDialog(!showSelectMediaDialog);
  }

  function sendMessageToSelectedUsers(event: any, categoryName: string): void {
    event.preventDefault();
    let selectedMobileNumbers: Array<string> = userCategories[categoryName]
      .filter((category: CampaignCategory) => category.isChecked)
      .map(
        (category: CampaignCategory) => category.whatsappMessage.mobileNumber
      );
    if (selectedMobileNumbers.length === 0) {
      toastr.warning(
        "You need to select at least one user in order to use this feature."
      );
    } else {
      setSelectedCategoryName(categoryName);
      setSelectedMobileNumbers(selectedMobileNumbers);
      setShowSelectMediaDialog(!showSelectMediaDialog);
    }
  }

  function confirmSendMessage(
    event: any,
    message: string,
    media: File,
    categoryName: any,
    currentTemplateUUID: string
  ): void {
    event.preventDefault();
    let formData: any = new FormData();
    const loginUser: LoginUser = JSON.parse(
      localStorage.getItem("loginUser") || ""
    );
    formData.append("message", message);
    if (media) formData.append("uploadedMedia", media);
    formData.append("mobileNumbers", selectedMobileNumbers || []);
    formData.append("sentBy", loginUser.name);
    formData.append("isSuperuser", loginUser.isSuperuser || false);
    formData.append("uuid", loginUser.uuid);
    formData.append("templateUUID", currentTemplateUUID);
    makePOSTRequestOnUserService(
      "/whatsapp_campaign/send_bulk_message/",
      formData
    )
      .then((response: any) => {
        let currentState: any = userCategories;
        currentState[selectedCategoryName] = uncheckAllCustomCategories(
          userCategories[selectedCategoryName]
        );
        setUserCategories(currentState);
        setSelectedMobileNumbers([]);
        setShowSelectMediaDialog(!showSelectMediaDialog);
        toastr.success("Whatsapp message successfully sent!");
      })
      .catch((error) => toastr.error(error));
  }

  if (hasLoaded) {
    return (
      <Grid className="QuotesDataGrid">
        <Paper>
          <div style={{ fontSize: "36px", float: "left" }}>
            Custom Categories
          </div>
        </Paper>
        <br />
        <Grid>
          <Grid item md={12} xs={12}>
            <Grid container style={{ gap: 50 }}>
              {Object.keys(userCategories).map((categoryName) => (
                <Grid item md={12} xs={12} style={{ maxWidth: "600px" }}>
                  <GenericCategoryBox
                    categories={userCategories[categoryName]}
                    categoryName={categoryName}
                    handleCheckBoxSelection={
                      handleCheckBoxSelectionForCustomCategories
                    }
                    onSelectAllWithCurrentCategory={
                      onSelectAllWithCurrentCategory
                    }
                    areAllChecked={selectAllStatusForCategories[categoryName]}
                    selectIndividualUser={sendMessageToUserFromCustomCategory}
                    sendMessageToSelectedUsers={sendMessageToSelectedUsers}
                    updatedCategoryName={""}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {showSelectMediaDialog ? (
          <WhatsappCampaignDialog
            open={showSelectMediaDialog}
            handleClose={(event: any) => {
              event.preventDefault();
              setShowSelectMediaDialog(!showSelectMediaDialog);
            }}
            handleSubmit={confirmSendMessage}
            showCategoryNameInput={false}
          />
        ) : (
          ""
        )}
      </Grid>
    );
  } else {
    return (
      <Box
        sx={{ width: 900 }}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Box>
    );
  }
}
