import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { appContext } from "../../App";
import {
  sendOTPToMobile,
  validateOTPForUser,
  changeSuperuserPassword,
} from "../../Api/api";
import OtpInput from "react-otp-input";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withRouter } from "react-router-dom";

const steps = ["Enter Mobile Number", "Verify OTP", "Enter New Password"];

const useStyles = makeStyles((theme: any) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface PasswordState {
  currentPassword: string;
  newPassword: string;
}

function ChangePasswordStepper(props: any) {
  const classes = useStyles();
  const toastr = require("toastr");
  const initialState: PasswordState = {
    currentPassword: "",
    newPassword: "",
  };

  const [currentState, setCurrentState] =
    React.useState<PasswordState>(initialState);
  const [activeStep, setActiveStep] = React.useState(0);
  const [mobileNumber, setMobileNumber] = React.useState<string>("");
  const { authUser, setAuthUser } = React.useContext(appContext);
  const [otp, setOTP] = React.useState("");
  const [isError, setIsError] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showOldPassword, setShowOldPassword] = React.useState<boolean>(false);

  function sendOTP() {
    if ((authUser && authUser.uuid, mobileNumber && mobileNumber !== "")) {
      let requestData = {
        userUUID: authUser.uuid,
        mobileNumber: mobileNumber,
        otpPreference: "Mobile",
        isSuperuser: authUser.isSuperuser,
      };
      sendOTPToMobile(requestData)
        .then((response: any) => {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          if (response && response.status === 200) {
            toastr.success("OTP sent to entered mobile number");
          }
        })
        .catch((error: any) => {
          toastr.error(
            "There was an error while sending OTP. Please try again later"
          );
        });
    }
  }

  function verifyOTP() {
    if (authUser && authUser.uuid) {
      let requestData = {
        userUUID: authUser.uuid,
        otp: otp,
        otpPreference: "Mobile",
        isSuperuser: authUser.isSuperuser,
      };
      validateOTPForUser(requestData)
        .then((response: any) => {
          if (response && response.status === 200) {
            toastr.success("Mobile number verified successfully!");
            setActiveStep((currentStep: number) => currentStep + 1);
          }
        })
        .catch((error: any) => {
          toastr.error(error);
        });
    }
  }

  function changePassword() {
    if (authUser && authUser.uuid) {
      let requestData = {
        userUUID: authUser.uuid,
        oldPassword: currentState.currentPassword,
        newPassword: currentState.newPassword,
        isSuperuser: authUser.isSuperuser,
      };

      changeSuperuserPassword(requestData)
        .then((response: any) => {
          if (response && response.status === 200) {
            toastr.success(
              "Password updated successfully. Please login once again"
            );
            localStorage.clear();
            if (authUser.isSuperuser) {
              window.location = "/";
            } else {
              window.location = "/admin_user/login";
            }
            // props.history.push("/admin_user/login");
          }
        })
        .catch((error: any) => toastr.error(error));
    }
  }

  function handleCaseForNextButton(currentStep: number) {
    switch (currentStep) {
      case 0: {
        if (mobileNumber === "") {
          setIsError(!isError);
        } else {
          sendOTP();
        }
        break;
      }

      case 1: {
        if (otp === "") {
          toastr.warning("Please enter OTP before proceeding");
        } else {
          verifyOTP();
        }
        break;
      }

      case 2: {
        if (currentState.newPassword === "") {
          toastr.warning("Please enter your new password to proceed!");
        } else {
          changePassword();
        }
        break;
      }

      default: {
        break;
      }
    }
  }

  const handleNext = () => {
    handleCaseForNextButton(activeStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function renderNewPasswordInput() {
    return (
      <Grid item xs={12} style={{ marginBottom: "15px" }}>
        <TextField
          style={{
            position: "absolute",
            left: "50%",
            top: "40%",
            transform: "translate(-50%, -50%)",
          }}
          variant="outlined"
          required
          name="newPassword"
          label="New Password"
          type="password"
          id="newPassword"
          autoComplete="current-password"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            event.preventDefault();
            let state = currentState;
            state.newPassword = event.target.value;
            setCurrentState(state);
          }}
        />
      </Grid>
    );
  }

  function renderCurrentPasswordInput() {
    return (
      <Grid item xs={12} style={{ marginBottom: "75px" }}>
        <FormControl
          className="full-width border-radius"
          style={{
            position: "absolute",
            left: "50%",
            top: "20%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <TextField
            variant="outlined"
            required
            name="oldPassword"
            label="Old Password"
            type={showOldPassword ? "password" : "text"}
            id="oldPassword"
            autoComplete="current-password"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.preventDefault();
              let state = currentState;
              state.currentPassword = event.target.value;
              setCurrentState(state);
            }}
          />
          <InputAdornment position="end" className="showpassword">
            <IconButton
              aria-label="toggle password visibility"
              style={{
                top: "6px",
                position: "absolute",
                right: "18px",
              }}
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                event.preventDefault();
                setShowOldPassword(!showOldPassword);
              }}
              edge="end"
            >
              {showOldPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        </FormControl>
      </Grid>
    );
  }

  function renderStepperContent() {
    switch (activeStep) {
      case 0: {
        return (
          <>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} style={{ marginBottom: "75px" }}>
                <TextField
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "30%",
                    transform: "translate(-50%, -50%)",
                  }}
                  error={isError}
                  helperText={isError ? "Please enter mobile number" : ""}
                  type="text"
                  name="Mobile Number"
                  variant="outlined"
                  label="Mobile Number"
                  value={mobileNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    event.preventDefault();
                    setMobileNumber(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </>
        );
      }

      case 1: {
        return (
          <Grid style={{ marginBottom: "75px" }}>
            <Grid
              style={{
                position: "absolute",
                left: "50%",
                top: "36%",
                transform: "translate(-50%, -50%)",
              }}
              item
              xs={5}
            >
              <OtpInput
                value={otp}
                isInputNum={true}
                onChange={(enteredValue: string) => setOTP(enteredValue)}
                inputStyle={{ padding: "20px", width: "100%" }}
                numInputs={6}
                separator={<span>-</span>}
              />
            </Grid>
          </Grid>
        );
      }

      case 2: {
        return (
          <div className={classes.form}>
            <Grid container spacing={2}>
              {renderCurrentPasswordInput()}
              {renderNewPasswordInput()}
            </Grid>
          </div>
        );
      }

      default: {
        return "";
      }
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      {renderStepperContent()}

      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}

export default withRouter(ChangePasswordStepper);
