import React, { useEffect, useContext, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { makeStyles } from "@material-ui/core/styles";
import { appContext } from "../App";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { TextField, Grid } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteModal from "./DeleteModal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  table: {
    minWidth: 450,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  textFieldClass: {
    width: "20%",
    height: "20%",
  },
}));

const DataTable = (props) => {
  const classes = useStyles();
  const { headCells, records } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const initialState = {
    date: new Date().toISOString(),
    rate: "",
    rate1: "",
  };
  const [currentState, setCurrentState] = React.useState(initialState);
  const [toBeDeleted, setToBeDeleted] = React.useState(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const EnhancedTableHead = (props) => {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    const customColumnStyle = { width: "10%" };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"left"}
              sortDirection={orderBy === headCell.id ? order : false}
              style={customColumnStyle}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChange = (event) => {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const convertToDate = (date) => {
    if (date === null) {
      return "";
    } else {
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = date.getFullYear();
      return dd + "/" + mm + "/" + yyyy;
    }
  };

  const handleDateChange = (date) => {
    let state = currentState;
    state.date = convertToDate(date);
    setCurrentState(state);
  };

  const renderInputOrReadOnly = (index, row, key, placeholder) => {
    if (records.length - 1 !== index) {
      return <TableCell align="left">{row[key]}</TableCell>;
    }
    if (key === "date") {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              format="dd/MM/yyyy"
              value={row[key] === "" ? new Date().toISOString() : row[key]}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      );
    } else {
      return (
        <Grid item xs={12}>
          <TableCell align="left">
            <TextField
              id="standard-basic"
              label="Standard"
              name={key}
              placeholder={placeholder}
              label={key}
              defaultValue={row[key]}
              onChange={(event) => handleChange(event)}
            />
          </TableCell>
        </Grid>
      );
    }
  };

  const handleAddNewRow = (event) => {
    if (
      currentState.date !== "" &&
      currentState.rate !== "" &&
      currentState.rate1 !== ""
    ) {
      let state = currentState;
      setCurrentState(initialState);
      props.OnAddNewRow(event, state);
    }
  };

  const handleDeleteIconOnClick = (event, row) => {
    event.preventDefault();
    setToBeDeleted(row);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = (event) => {
    event.preventDefault();
    setShowDeleteModal(false);
  };

  const handleDeleteOnClick = (event) => {
    event.preventDefault();
    let deletable = toBeDeleted;
    setToBeDeleted(null);
    setShowDeleteModal(false);
    setCurrentState(initialState);
    props.removeUnsavedCoupon(event, deletable);
  };

  const renderTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"small"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={records.length}
          />
          <TableBody>
            {stableSort(records, getComparator(order, orderBy)).map(
              (row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                  >
                    <TableCell id={labelId} scope="row" padding="none">
                      {renderInputOrReadOnly(index, row, "date", "dd/mm/yyyy")}
                    </TableCell>
                    <TableCell id={labelId} scope="row" padding="none">
                      {renderInputOrReadOnly(index, row, "rate", "123..")}
                    </TableCell>
                    <TableCell id={labelId} scope="row" padding="none">
                      {renderInputOrReadOnly(index, row, "rate1", "123..")}
                    </TableCell>

                    <TableCell
                      id={labelId}
                      scope="row"
                      padding="none"
                      style={{ textAlign: "center" }}
                    >
                      {row.rate === "" && row.rate1 === "" ? (
                        <AddCircleIcon
                          onClick={(event) => handleAddNewRow(event)}
                          color="primary"
                          fontSize="large"
                          style={{
                            float: "right",
                            "margin-top": "29px",
                            "margin-right": "30px",
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <DeleteIcon
                          onClick={(event) =>
                            handleDeleteIconOnClick(event, row)
                          }
                          color="primary"
                          fontSize="medium"
                          style={{
                            float: "right",
                            "margin-top": "29px",
                            "margin-right": "30px",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} style={{ padding: "15px" }}>
        <div style={{ fontSize: "20px", float: "left", padding: "0 0 10px" }}>
          Coupon *
        </div>
        {renderTable()}
      </Paper>
      <DeleteModal
        primaryText="Delete Coupon?"
        secondaryText="Are you sure you want to delete this Coupon ?"
        isOpen={showDeleteModal}
        handleClose={closeDeleteModal}
        confirmDelete={(event) => handleDeleteOnClick(event)}
      />
    </div>
  );
};

export default DataTable;
