import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Avatar from "@mui/material/Avatar";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {
  makeGETRequest,
  makeGETRequestOnActivityLogsService,
  makeGETRequestOnUserService,
} from "../Api/api";
import { CompressOutlined } from "@mui/icons-material";
import toastr from "toastr";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import IdleTimoutConfig from "../Helpers/IdleTimeoutConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "90vw",
    position: "relative",
    margin: "20px",
    color: "textSecondary",
  },
  root2: {
    flexGrow: 1,
    width: "90vw",
    position: "relative",
    color: "textSecondary",
  },
  inline: {
    display: "inline",
  },
  rounded: {
    borderRadius: "10px",
    width: "90vw",
    margin: "50px",
  },
}));

export default function ActivityLogs(props) {
  const classes = useStyles();
  const [activityLogs, setActivityLogs] = React.useState([]);
  const [adminActivityLogs, setAdminActivityLogs] = React.useState([]);
  const [areActivityLogsLoaded, setAreActivityLogsLoaded] =
    React.useState(false);

  React.useEffect(() => {
    makeGETRequestOnActivityLogsService("/activity_logs/list/")
      .then((response) => {
        if (response.status === 200) {
          setActivityLogs(response.data);
          setAreActivityLogsLoaded(true);
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  function activityLogsCard(activityLog) {
    return (
      <Grid className="QuotesDataGrid">
        <List className={classes.root2}>
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <Avatar> {activityLog.message[0].toUpperCase()}</Avatar>
            </ListItemIcon>
            <ListItemText
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {activityLog.message}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>
      </Grid>
    );
  }

  function downloadAsJSON(text, name) {
    const a = document.createElement("a");
    const type = name.split(".").pop();
    a.href = URL.createObjectURL(
      new Blob([text], { type: `text/${type === "txt" ? "plain" : type}` })
    );
    a.download = name;
    a.click();
  }

  async function exportAllActivityLogs(event) {
    event.preventDefault();
    try {
      let activityLogsResponse = await makeGETRequestOnUserService(
        "/activity_logs/export_all/"
      );
      let adminActivityLogsResponse = await makeGETRequest(
        "admin_activity_logs/export_all/"
      );
      if (
        adminActivityLogsResponse.status === 200 &&
        activityLogsResponse.status === 200
      ) {
        const concatenated = activityLogsResponse.data.concat(
          adminActivityLogsResponse.data
        );
        downloadAsJSON(
          JSON.stringify(concatenated, null, 2),
          "activity_logs.json"
        );
      }
    } catch (error) {
      toastr.error(error);
    }
  }

  if (!areActivityLogsLoaded) {
    return (
      <Box
        sx={{ width: 900 }}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </Box>
    );
  } else {
    return (
      <>
        <Button
          className="btnClass"
          variant="outlined"
          onClick={exportAllActivityLogs}
          color="primary"
          style={{
            float: "right",
            cursor: "pointer",
            right: "70px",
            top: "10px",
          }}
        >
          Export all
        </Button>
        <Grid className="QuotesDataGrid">
          <Paper elevation={10} square={false} className={classes.rounded}>
            <div style={{ fontSize: "36px", float: "left", margin: "50px" }}>
              Activity Logs
            </div>
            <br />
            {activityLogs
              .concat(adminActivityLogs)
              .sort(function (x, y) {
                return y.insertedAt - x.insertedAt;
              })
              .map((activityLog) => {
                return activityLogsCard(activityLog);
              })}
          </Paper>
          {/* <IdleTimoutConfig /> */}
        </Grid>
      </>
    );
  }
}
