import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Moment } from "moment";
import IBondDetails from "../interfaces/IBondDetails";
import IQuoteData from "../interfaces/IQuoteData";
import ICalculatedValues from "../interfaces/ICalculatedValues";

export interface ICashflow {
  date: string;
  amount: number | string;
  type: "Interest" | "Maturity";
}

interface ICalc {
  bondInfo: IBondDetails | null;
  searchResult: IQuoteData[] | null;
  searchInput: string;
  loading: boolean;
  breakup: boolean;
  zoom: boolean;
  units: number;
  date: Moment | null;
  Price: number | null;
  Yield: number | null;
  calculatedValues: ICalculatedValues | null;
  isMMY: boolean;
  cashflow: ICashflow[] | null;
  getBondInfo: (payload: IBondDetails | null) => void;
  getSearchInput: (payload: string) => void;
  getSearchResult: (payload: IQuoteData[] | null) => void;
  loader: (payload: boolean) => void;
  getBreakup: (payload: boolean) => void;
  getCalculatedValues: (payload: ICalculatedValues | null) => void;
  getUnits: (payload: number) => void;
  incrementUnit: () => void;
  decrementUnit: () => void;
  getDate: (payload: Moment | null) => void;
  getPrice: (payload: number | null) => void;
  getYield: (payload: number | null) => void;
  getMMY: (payload: boolean) => void;
  getZoom: (payload: boolean) => void;
  getCashfow: (payload: ICashflow[] | null) => void;
}

const useCalc = create<ICalc>()(
  devtools((set) => ({
    bondInfo: null,
    searchResult: null,
    searchInput: "",
    loading: false,
    breakup: false,
    zoom: false,
    units: 1,
    date: null,
    Price: null,
    Yield: null,
    calculatedValues: null,
    isMMY: false,
    cashflow: null,
    getBondInfo: (payload) => set({ bondInfo: payload }),
    getSearchInput: (payload) => set({ searchInput: payload }),
    getSearchResult: (payload) => set({ searchResult: payload }),
    loader: (payload) => set({ loading: payload }),
    getBreakup: (payload) => set({ breakup: payload }),
    getCalculatedValues: (payload) => set({ calculatedValues: payload }),
    getUnits: (payload) => set({ units: payload }),
    incrementUnit: () => set((state) => ({ units: state.units + 1 })),
    decrementUnit: () =>
      set((state) => ({ units: state.units === 1 ? 1 : state.units - 1 })),
    getDate: (payload) => set({ date: payload }),
    getPrice: (payload) => set({ Price: payload }),
    getYield: (payload) => set({ Yield: payload }),
    getMMY: (payload) => set({ isMMY: payload }),
    getZoom: (payload) => set({ zoom: payload }),
    getCashfow: (payload) => set({ cashflow: payload }),
  }))
);

export default useCalc;
