import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function GenericTable(props: any): React.ReactElement {
  function getFormattedRowContent(
    propertyName: string,
    row: any
  ): React.ReactElement | void {
    if (
      props.columnNames
        .map((columnName: string) => columnName.toLowerCase())
        .includes(propertyName)
    ) {
      return (
        <StyledTableCell component="th" scope="row">
          {row[propertyName]}
        </StyledTableCell>
      );
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {props.columnNames.map((columnName: string) => (
              <StyledTableCell>{columnName} </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row: any) => (
            <StyledTableRow key={row.name}>
              {Object.keys(row)
                .sort()
                .map((propertyName: string) =>
                  getFormattedRowContent(propertyName, row)
                )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
