import React from "react";
import {
  makeGETRequest,
  makePOSTRequest,
  makeDELETERequest,
  makePUTRequest,
} from "../../Api/api";
import { IPOQuote } from "./Interfaces";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import GenericDialog from "../../components/Dialog/GenericDialog";
import CreateIPOQuote from "../../components/IPOQuote/CreateIPOQuote";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const toastr = require("toastr");

interface Quote {
  uin: string;
  nature_of_instrument?: string;
  series_frontend: string;
  series_backend: string;
  category: string;
  category_subcode: string;
  payment_term: string;
  yield: string;
  tenure: string;
  uuid?: "";
  ipo_security_master_id?: "";
}

export default function IPOQuotes(props: any): React.ReactElement {
  const dataGridCells = [
    { field: "serial", headerName: "Serial", width: 350, editable: false },
    { field: "uin", headerName: "UIN", width: 350, editable: false },
    {
      field: "series_frontend",
      headerName: "Series Frontend",
      width: 350,
      editable: false,
    },
    {
      field: "series_backend",
      headerName: "Series Backend",
      width: 350,
      editable: false,
    },
    { field: "category", headerName: "Category", width: 350, editable: false },
    {
      field: "category_subcode",
      headerName: "Category Subcode",
      width: 350,
      editable: false,
    },
    {
      field: "payment_term",
      headerName: "Payment Term",
      width: 350,
      editable: false,
    },
    { field: "quote_yield", headerName: "Yield", width: 350, editable: false },
    {
      field: "tenure",
      headerName: "Tenure (in months)",
      width: 350,
      editable: false,
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      width: 250,
      disableClickEventBubbling: true,
      renderCell: (row: any) => {
        return (
          <EditIcon
            color="primary"
            fontSize="medium"
            onClick={(event: any) => handleIPOQuoteUpdate(event, row)}
            style={{
              float: "left",
              cursor: "pointer",
            }}
          />
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 250,
      disableClickEventBubbling: true,
      renderCell: (row: any) => {
        return (
          <DeleteIcon
            color="primary"
            onClick={(event: any) => handleIPOQuoteDelete(event, row)}
            fontSize="medium"
            style={{
              float: "left",
              cursor: "pointer",
            }}
          />
        );
      },
    },
  ];
  const [ipoQuotes, setIpoQuotes] = React.useState<IPOQuote[]>([]);
  const [showAddIPOQuoteModal, setShowAddIPOQuoteModal] =
    React.useState<boolean>(false);
  const quoteInitialState = {
    uin: "",
    nature_of_instrument: "",
    series_frontend: "",
    series_backend: "",
    category: "",
    category_subcode: "",
    payment_term: "",
    yield: "",
    tenure: "",
  };
  const [quote, setQuote] = React.useState<Quote>(quoteInitialState);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    React.useState<boolean>(false);
  const [toBeDeleted, setToBeDeleted] = React.useState<string>("");
  const [toBeUpdated, setToBeUpdated] = React.useState<any>(quoteInitialState);
  const [editMode, setEditMode] = React.useState<boolean>(false);

  function deleteIPOQuote(event: any) {
    event.preventDefault();
    makeDELETERequest(`/ipo_quote/delete/${toBeDeleted}/`)
      .then((_response: any) => {
        setIpoQuotes(
          ipoQuotes.filter(
            (ipoQuote: IPOQuote) => ipoQuote.uuid !== toBeDeleted
          )
        );
        setShowDeleteConfirmationModal(!showDeleteConfirmationModal);
        toastr.success("Deleted IPO Quote successfully!");
      })
      .catch((error) => toastr.error(error));
  }

  function handleIPOQuoteUpdate(event: any, row: any): void {
    setToBeUpdated(row.row);
    setEditMode(true);
    setShowAddIPOQuoteModal(!showAddIPOQuoteModal);
  }

  function handleIPOQuoteDelete(_event: any, row: any) {
    setShowDeleteConfirmationModal(!showDeleteConfirmationModal);
    setToBeDeleted(row.row.uuid);
  }

  function handleChange(event: any) {
    if (editMode) {
      setToBeUpdated((previousValues: any) => ({
        ...previousValues,
        [event.target.name]: event.target.value,
      }));
    } else {
      setQuote((previousValues) => ({
        ...previousValues,
        [event.target.name]: event.target.value,
      }));
    }
  }

  function formattify(ipoQuotes: IPOQuote[]): any[] {
    let formatted: any[] = [];
    ipoQuotes.map((ipoQuote: IPOQuote, index: number) => {
      let currentQuote = ipoQuote;
      currentQuote.serial = index + 1;
      formatted.push(currentQuote);
    });
    return formatted;
  }

  React.useEffect(() => {
    makeGETRequest("/ipo_quote/list/")
      .then((response: any) => {
        setIpoQuotes(formattify(response.data));
      })
      .catch((error: any) => toastr.error(error));
  }, []);

  function createIPOQuote(): void {
    makePOSTRequest("ipo_quote/create/", quote)
      .then((response) => {
        setShowAddIPOQuoteModal(!showAddIPOQuoteModal);
        setQuote(quoteInitialState);
        toastr.success("Created IPO Quote successfully!");
      })
      .catch((error: any) => toastr.error(error));
  }

  function updateIPOQuote(): void {
    let currentState: any = toBeUpdated;

    makePUTRequest(`/ipo_quote/update/${currentState.uuid}`, currentState)
      .then((response: any) => {
        setShowAddIPOQuoteModal(!showAddIPOQuoteModal);
        setToBeUpdated(quoteInitialState);
        toastr.success("Updated IPO quote successfully!");
      })
      .catch((error: any) => toastr.error(error));
  }

  function handleSubmitForIPOQuote(event: any) {
    event.preventDefault();
    if (editMode) {
      updateIPOQuote();
    } else {
      createIPOQuote();
    }
  }

  return (
    <Grid className="securityTypeDataGird">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>IPO Quote</div>
            </Grid>
            <Grid item xs="auto">
              <AddCircleIcon
                color="primary"
                fontSize="large"
                onClick={(event) => {
                  event.preventDefault();
                  setShowAddIPOQuoteModal(!showAddIPOQuoteModal);
                }}
                style={{
                  float: "right",
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <div style={{ height: "100%", width: "100%" }}>
          <DataGrid
            className="securityTypeDataGrid abc"
            components={{ Toolbar: GridToolbar }}
            rows={ipoQuotes}
            columns={dataGridCells}
            getRowId={(row) => row.uuid}
            autoHeight
          />
        </div>
      </Paper>
      {showAddIPOQuoteModal ? (
        <GenericDialog
          show={showAddIPOQuoteModal}
          heading="Create IPO Quote"
          inputProps={
            <CreateIPOQuote
              handleChange={handleChange}
              quote={quote}
              setQuote={setQuote}
              editable={toBeUpdated}
              editMode={editMode}
            />
          }
          onClose={(event: React.MouseEvent) => {
            event.preventDefault();
            setShowAddIPOQuoteModal(!showAddIPOQuoteModal);
            setQuote(quoteInitialState);
          }}
          handleSubmit={handleSubmitForIPOQuote}
        />
      ) : (
        ""
      )}
      {showDeleteConfirmationModal ? (
        <GenericDialog
          show={showDeleteConfirmationModal}
          heading="Delete IPO Quote"
          inputProps="Are you sure you want to delete this IPO Quote?"
          onClose={(event: React.MouseEvent) => {
            event.preventDefault();
            setShowDeleteConfirmationModal(!showDeleteConfirmationModal);
            setToBeDeleted("");
          }}
          handleSubmit={deleteIPOQuote}
        />
      ) : (
        ""
      )}
    </Grid>
  );
}
