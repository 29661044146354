import React from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "184px",
  },
}));

function removeKeysWithEmptyValues(obj) {
  return Object.entries(obj).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  );
}

function convertToDate(date) {
  if (date) {
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = date.getFullYear();
    return dd + "/" + mm + "/" + yyyy;
  } else return "";
}

export default function AddMarketerModal(props) {
  const initialState = {
    firstName: props.toBeEdited ? props.toBeEdited.firstName : "",
    lastName: props.toBeEdited ? props.toBeEdited.lastName : "",
    mobileNumber: props.toBeEdited ? props.toBeEdited.mobileNumber : "",
    emailId: props.toBeEdited ? props.toBeEdited.emailId : "",
    startDate: props.toBeEdited ? props.toBeEdited.startDate : "",
    password: "",
    validTill: props.toBeEdited ? props.toBeEdited.validTill : "",
    companyName: props.toBeEdited ? props.toBeEdited.companyName : "",
    revenueShare: props.toBeEdited ? props.toBeEdited.revenueShare : "",
    createdBy: "",
  };
  const [marketer, setMarketer] = React.useState(initialState);
  const [startDate, setStartDate] = React.useState(
    props.toBeEdited ? new Date(props.toBeEdited.startDate) : new Date()
  );
  const [validTill, setValidTill] = React.useState(
    props.toBeEdited ? new Date(props.toBeEdited.validTill) : new Date()
  );

  const handleChange = (event) => {
    event.persist();
    setMarketer((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleValidTillChange = (date) => {
    setValidTill(date);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (props.toBeEdited) {
      let state = marketer;
      state = removeKeysWithEmptyValues(state);
      state.uuid = props.toBeEdited.uuid;
      state.startDate = convertToDate(startDate);
      state.validTill = convertToDate(validTill);
      props.handleUpdateMarketer(event, state);
    } else {
      let state = marketer;
      setMarketer(initialState);
      state.startDate = convertToDate(startDate);
      state.validTill = convertToDate(validTill);
      props.handleCreateMarketer(event, state);
    }
  };

  return (
    <Dialog
      open={props.openModal}
      onClose={props.onCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props.toBeEdited ? "Update Marketer" : "Add Marketer"}
      </DialogTitle>
      <DialogContent style={{ width: "500px" }}>
        <DialogContentText id="alert-dialog-description">
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={6}>
              <TextField
                type="text"
                name="firstName"
                variant="outlined"
                fullWidth
                label="First Name"
                defaultValue={marketer.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="text"
                name="lastName"
                variant="outlined"
                fullWidth
                label="Last Name"
                defaultValue={marketer.lastName}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email Id"
              margin="normal"
              variant="outlined"
              name="emailId"
              fullWidth
              onChange={handleChange}
              value={marketer.emailId}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              margin="normal"
              variant="outlined"
              name="password"
              fullWidth
              onChange={handleChange}
              value={marketer.password}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Mobile Number"
              margin="normal"
              name="mobileNumber"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              value={marketer.mobileNumber}
            />
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item justify="space-around" xs={8}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Start Date"
                    format="dd/MM/yyyy"
                    value={startDate}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item justify="space-around" xs={8}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Valid Till"
                    format="dd/MM/yyyy"
                    value={validTill}
                    onChange={handleValidTillChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Company Name"
              margin="normal"
              variant="outlined"
              fullWidth
              name="companyName"
              onChange={handleChange}
              value={marketer.companyName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Revenue Share"
              margin="normal"
              variant="outlined"
              fullWidth
              name="revenueShare"
              onChange={handleChange}
              value={marketer.revenueShare}
            />
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCloseModal}>Cancel</Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
