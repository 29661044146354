import React, { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { shallow } from "zustand/shallow";
import useCalc from "../../store/calc";
import toastr from "toastr";
import { makeGETRequest } from "../../Api/api";
import IQuoteData from "../../interfaces/IQuoteData";
import styles from "../../Helpers/styles";
import { handleSecurityName } from "../../Helpers/functions";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { styled } from "@mui/material/styles";

const StyledSwitch = styled(Box)`
  background: #f2f3f4 0% 0% no-repeat padding-box;
  border-radius: 100px;
  padding: 6px;
  width: 100%;
  position: relative;
  input {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0;
  }
  label {
    display: flex;
    cursor: pointer;
    width: 100%;
    text-align: center;
    align-items: center;
    position: relative;
  }
  label span {
    width: 50%;
    padding: 8px 20px;
    font-weight: 500;
    font-size: inherit;
    white-space: nowrap;
    color: #2a324c;
    opacity: 0.5;
    position: relative;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
    user-select: none;
    z-index: 1;
  }
  input:not(:checked) + label span:first-of-type {
    opacity: 1;
    color: #fff;
  }
  input:checked + label span:last-child {
    color: #fff;
    opacity: 1;
  }
  label::before {
    content: "";
    position: absolute;
    background-color: #ec4f56;
    width: 50%;
    height: 100%;
    border-radius: 100px;
    transition: all 0.3s ease-in-out;
    z-index: 0;
  }
  input:checked + label::before {
    transform: translateX(100%);
  }
`;

const SearchResult = ({ viewMatch }: { viewMatch: boolean }) => {
  const [
    searchInput,
    searchResult,
    getBondInfo,
    getSearchResult,
    getSearchInput,
    loader,
  ] = useCalc(
    (state) => [
      state.searchInput,
      state.searchResult,
      state.getBondInfo,
      state.getSearchResult,
      state.getSearchInput,
      state.loader,
    ],
    shallow
  );

  const [view, setView] = useState<"trend" | "past">("trend");

  const handleBondClick = async (isin: string) => {
    try {
      loader(true);
      const response = await makeGETRequest(
        `admin/security_master/get/${isin}/`
      );
      if (response && response.status === 200) {
        getBondInfo(response.data);
        loader(false);
        getSearchInput("");
        getSearchResult(null);
      }
    } catch (error: any) {
      toastr.error(error.message);
      loader(false);
    }
  };

  return (
    <Box sx={{ height: "100%" }}>
      {searchInput && searchResult && searchResult.length ? (
        <Box
          sx={{
            overflowY: "scroll",
            ...(viewMatch
              ? {
                  height: "90%",
                }
              : {
                  mt: 1,
                  height: "430px",
                }),
            "&::-webkit-scrollbar": { display: "none", width: 0 },
          }}
        >
          {searchResult.map((result: IQuoteData, index: number) => (
            <Box
              key={index}
              sx={{
                p: 1,
                bgcolor: "#f2f3f4",
                borderRadius: "12px",
                ...styles.flexBetweenCenter,
                mb: 1,
                cursor: "pointer",
              }}
              onClick={() => handleBondClick(result.isin)}
            >
              <Box>
                <Typography
                  variant="h6"
                  noWrap
                  sx={{ color: "#2A324C", fontSize: 15, width: "300px" }}
                >
                  <Box component="span">
                    {handleSecurityName(result.security_name).securityCoupon}
                  </Box>
                  <Box component="span" sx={{ fontWeight: 700, ml: 0.5 }}>
                    {handleSecurityName(result.security_name).securityName}
                  </Box>
                </Typography>
                <Box
                  sx={{
                    ...styles.flexStartCenter,
                    color: "#9397A4",
                    "& > h6": { fontSize: 13 },
                  }}
                >
                  <Typography variant="h6">{result.isin}</Typography>
                  <Box component="span" sx={{ mx: 1 }}>
                    |
                  </Box>
                  <Typography variant="h6">
                    {result.date_type.replace(
                      /^[a-z]|[A-Z]/g,
                      (c, i) => (i ? " " : "") + c.toUpperCase()
                    )}{" "}
                    {moment(result.maturity_or_call_date, "DD/MM/YYYY").format(
                      "DD MMM, YYYY"
                    )}
                  </Typography>
                </Box>
              </Box>
              <IconButton>
                <ArrowRightAltIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      ) : searchInput && searchResult && searchResult.length === 0 ? (
        <Box
          sx={{
            p: 1,
            bgcolor: "#f2f3f4",
            borderRadius: "12px",
            ...styles.flexBetweenCenter,
            mb: 1,
          }}
        >
          <Typography sx={{ p: 1 }}>
            Sorry! 😢 We don&apos;t seem to have what you need.
          </Typography>
        </Box>
      ) : (
        <Box sx={{ height: "85%" }}>
          <StyledSwitch>
            <input
              type="checkbox"
              id="calcToggleId"
              onChange={() => {
                view === "trend" ? setView("past") : setView("trend");
              }}
            />
            <label htmlFor="calcToggleId">
              <span>Trending Bonds</span>
              <span>Past Searches</span>
            </label>
          </StyledSwitch>
          <Box
            sx={{
              mt: 2,
              overflowY: "auto",
              ...(viewMatch
                ? {
                    height: "100%",
                  }
                : {
                    height: "380px",
                  }),
              "&::-webkit-scrollbar": { display: "none", width: 0 },
            }}
          ></Box>
        </Box>
      )}
    </Box>
  );
};

export default SearchResult;
