import React from "react";
import { makeGETRequestOnUserService } from "../../Api/api";
import { QueryLog } from "./QueryLogInterface";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { addSerialNumber } from "../../Helpers/SecurityMasterHelpers";
const toastr = require("toastr");
const moment = require("moment");

const gridCells = [
  { field: "serial", headerName: "Serial", width: 200, editable: false },
  {
    field: "inserted_at",
    headerName: "Searched At",
    width: 400,
    editable: false,
  },
  { field: "query", headerName: "Search String", width: 600, editable: false },
];

function transformInsertedAtDate(records: QueryLog[]): QueryLog[] {
  let formatted = [];
  for (let iter = 0; iter < records.length; iter++) {
    let updated = { ...records[iter] };
    updated.inserted_at = moment(records[iter].inserted_at).format(
      "MMMM Do YYYY, h:mm:ss a"
    );
    formatted.push(updated);
  }
  return formatted;
}

export default function SearchLogs(props: any): React.ReactElement {
  const [queryLogs, setQueryLogs] = React.useState<QueryLog[]>([]);

  React.useEffect(() => {
    makeGETRequestOnUserService(`/user_search_logs/${[props.currentUser.uuid]}`)
      .then((response) => {
        if (response.data.length === 0) {
          toastr.warning("No Query Logs for this user!");
        } else {
          setQueryLogs(transformInsertedAtDate(response.data));
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  const currentlySelected = (params: any) => {};

  if (queryLogs.length === 0) {
    return (
      <Box
        sx={{ width: 900 }}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
        <Skeleton animation={false} />
      </Box>
    );
  } else {
    return (
      <Grid className="securityMasterDataGird">
        <Paper>
          <div
            style={{
              height: "100vh",
              width: "100%",
            }}
          >
            <DataGrid
              pagination
              className="securityMasterDataGrid hgn"
              components={{ Toolbar: GridToolbar }}
              rows={addSerialNumber(queryLogs)}
              onCellClick={currentlySelected}
              columns={gridCells}
              getRowId={(row) => row.serial}
            />
          </div>
        </Paper>
      </Grid>
    );
  }
}
