import React, { useEffect } from "react";
import toastr from "toastr";
import { TextField, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useForceUpdate from "use-force-update";
import Checkbox from "@mui/material/Checkbox";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "184px",
  },
}));

const AddAdminUserModal = (props) => {
  const classes = useStyles();
  const [currentState, setCurrentState] = React.useState(props.initialState);
  const [currentStateEdit, setCurrentStateEdit] = React.useState(
    props.toBeEdited
  );
  const forceUpdate = useForceUpdate();

  const resetStateAndClose = (event) => {
    event.preventDefault();
    setCurrentState(props.initialState);
    props.onClose(event);
  };

  useEffect(() => {
    if (props.editMode) {
      setCurrentStateEdit(props.toBeEdited);
    }
  }, [props.editMode]);

  const getFailureCond = () => {
    if (props.editMode) {
      return (
        currentStateEdit.mobileNumber === "" ||
        currentStateEdit.emailId === "" ||
        currentStateEdit.firstName === "" ||
        currentStateEdit.lastName === ""
      );
    } else {
      return (
        currentState.firstName === "" ||
        currentState.lastName === "" ||
        currentState.mobileNumber === "" ||
        currentState.emailId === "" ||
        currentState.password === ""
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (getFailureCond()) {
      toastr.error(
        "One or more fields are empty. Please fill all the fields to proceed!"
      );
    } else if (props.editMode) {
      let state = currentStateEdit;
      setCurrentStateEdit(props.initialState);
      props.onUpdate(event, state);
    } else {
      let state = currentState;
      setCurrentState(props.initialState);
      props.onSubmit(event, state);
    }
  };

  const handleChange = (event) => {
    event.persist();
    if (props.editMode) {
      setCurrentStateEdit((previousValues) => ({
        ...previousValues,
        [event.target.name]: event.target.value,
      }));
    } else {
      setCurrentState((previousValues) => ({
        ...previousValues,
        [event.target.name]: event.target.value,
      }));
    }
  };

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={resetStateAndClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create Admin User</DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            <Grid item xs={12}>
              <TextField
                type="text"
                name="firstName"
                variant="outlined"
                fullWidth
                label="First Name"
                defaultValue={
                  props.editMode
                    ? currentStateEdit.firstName
                    : currentState.firstName
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <TextField
                type="text"
                name="lastName"
                variant="outlined"
                fullWidth
                label="Last Name"
                defaultValue={
                  props.editMode
                    ? currentStateEdit.lastName
                    : currentState.lastName
                }
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <TextField
                type="text"
                name="mobileNumber"
                variant="outlined"
                fullWidth
                label="Mobile Number"
                disabled={props.editMode}
                defaultValue={
                  props.editMode
                    ? currentStateEdit.mobileNumber
                    : currentState.mobileNumber
                }
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <TextField
                type="text"
                name="emailId"
                variant="outlined"
                fullWidth
                label="Email ID"
                defaultValue={
                  props.editMode
                    ? currentStateEdit.emailId
                    : currentState.emailId
                }
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      props.editMode
                        ? currentStateEdit.can_see_all
                        : currentState.can_see_all
                    }
                    onChange={(event) => {
                      if (props.editMode) {
                        setCurrentStateEdit((previousValues) => ({
                          ...previousValues,
                          can_see_all: event.target.checked,
                        }));
                      } else {
                        setCurrentState((previousValues) => ({
                          ...previousValues,
                          can_see_all: event.target.checked,
                        }));
                      }
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Can See All"
              />
            </Grid>
            {props.editMode ? (
              ""
            ) : (
              <Grid item xs={12} style={{ "margin-top": "10px" }}>
                <TextField
                  type="text"
                  name="password"
                  variant="outlined"
                  fullWidth
                  label="Password"
                  defaultValue={currentState.password}
                  onChange={handleChange}
                />
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetStateAndClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddAdminUserModal;
