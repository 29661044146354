import OrderTable from "../components/Orders/OrderTable";
import OrderDataGrid from "../components/Orders/OrderDataGrid";
import IdleTimoutConfig from "../Helpers/IdleTimeoutConfig";

export default function Orders() {
  return (
    <>
      <OrderDataGrid />
      {/* <IdleTimoutConfig /> */}
    </>
  );
}
