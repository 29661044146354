import React from "react";
import { Grid } from "@material-ui/core";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Paper from "@material-ui/core/Paper";
import { callLogsDataGridCells } from "./utilities";

export default function RFQAPICallLogs(props: any) {
  return (
    <Grid className="securityTypeDataGird">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>RFQ Orders</div>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ height: "100%", width: "100%" }}>
          <DataGrid
            pagination
            className="securityMasterDataGrid"
            components={{ Toolbar: GridToolbar }}
            rows={props.logs}
            columns={callLogsDataGridCells}
            onCellClick={() => {}}
            getRowId={(row) => row.id}
            autoHeight
          />
        </div>
      </Paper>
    </Grid>
  );
}
