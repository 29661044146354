import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

export const eligibleForDateChangeSecurityMastersDataCells = [
  { field: "serial", headerName: "Serial", width: 250, editable: false },
  { field: "isin", headerName: "ISIN", width: 250, editable: false },
  {
    field: "dates",
    headerName: "Dates",
    width: 400,
    editable: false,
  },
  {
    field: "changeIndividual",
    headerName: "Change Individual Security Master",
    width: 250,
    editable: false,
    renderCell: (row) => {
      return (
        <ChangeCircleIcon
          color="primary"
          fontSize="medium"
          style={{
            float: "left",
            "margin-top": "10px",
            "margin-left": "14px",
            cursor: "pointer",
          }}
        />
      );
    },
  },
];

export const cashFlowDataGridCells = [
  {
    field: "adjusted_value_date",
    headerName: "Adjusted Value Date",
    width: 200,
    editable: false,
  },
  { field: "amount", headerName: "Amount", width: 200, editable: false },
  { field: "interest", headerName: "Interest", width: 200, editable: false },
  { field: "days", headerName: "Days", width: 200, editable: false },
  { field: "year", headerName: "Year", width: 200, editable: false },
  { field: "maturity", headerName: "Maturity", width: 200, editable: false },

  {
    field: "Edit",
    headerName: "Edit",
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,

    renderCell: () => {
      return (
        <EditIcon
          color="primary"
          fontSize="medium"
          style={{
            float: "left",
            marginLeft: "10px",
            marginTop: "14px",
            cursor: "pointer",
          }}
        />
      );
    },
  },
  {
    field: "Delete",
    headerName: "Delete",
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,

    renderCell: () => {
      return (
        <DeleteIcon
          color="primary"
          fontSize="medium"
          style={{
            float: "left",
            marginLeft: "10px",
            marginTop: "14px",
            cursor: "pointer",
          }}
        />
      );
    },
  },
];

function combineAllDates(object) {
  let commaSeperatedString = "";
  if (object.callOptions) {
    commaSeperatedString += object.callOptions
      .map((callOption) => callOption.date)
      .join(", ");
  }
  if (object.maturityOptions) {
    commaSeperatedString += object.maturityOptions
      .map((maturityOption) => maturityOption.date)
      .join(", ");
  }
  if (object.putOptions) {
    commaSeperatedString += object.putOptions
      .map((putOption) => putOption.date)
      .join(", ");
  }
  return commaSeperatedString;
}

export function addSerialAndParseDates(records) {
  let formatted = [];
  for (var iter = 0; iter < records.length; iter++) {
    let updatedObject = { ...records[iter], serial: iter + 1 };
    updatedObject.dates = combineAllDates(updatedObject);
    formatted.push(updatedObject);
  }
  return formatted;
}

export function formattifyForDateChange(record) {
  return {
    isin: record.isin,
    callOptions: record.callOptions
      ? record.callOptions.map((callOption) => ({
          uuid: callOption.uuid,
          date: callOption.date,
        }))
      : undefined,
    maturityOptions: record.maturityOptions
      ? record.maturityOptions.map((maturityOption) => ({
          uuid: maturityOption.uuid,
          date: maturityOption.date,
        }))
      : undefined,
    putOptions: record.putOptions
      ? record.putOptions.map((putOption) => ({
          uuid: putOption.uuid,
          date: putOption.date,
        }))
      : undefined,
  };
}

export function formattifyForUpdateAllDates(records) {
  return records.map((record) => record.isin);
}

export function addSerialNumber(records) {
  let formatted = [];
  for (var iter = 0; iter < records.length; iter++) {
    formatted.push({
      ...records[iter],
      serial: iter + 1,
    });
  }
  return formatted;
}
