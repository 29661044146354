import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import toastr from "toastr";
import { TextField, Button, Grid, Typography, Select } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { appContext } from "../App";
import {
  getAllIssuerMasters,
  getAllSecurityTypes,
  getAllRatingsAndOrganizationNames,
  deleteSecurityRatingOrganizationRelationship,
  deleteSecurityCouponRelationship,
  deleteSecurityCallOptionRelationship,
  deleteSecurityPutOptionRelationship,
  deleteSecurityMaturityRelationship,
  makePOSTRequest,
} from "../Api/api";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PutOptionDataTable from "./PutOptionDataTable";
import MaturityOptionDataTable from "./MaturityOptionDataTable";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import EditRatingOrganizationMasterTable from "./EditRatingOrganizationMasterTable";
import CouponEditTable from "./CouponEditTable";
import CallOptionEditTable from "./CallOptionEditTable";
import DebentureDetailsDialog from "./DebentureDetailsDialog";
import RegistrarDetailsDialog from "./RegistrarDetailsDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },
  dataTable: {
    flexGrow: 1,
  },
  paper: {
    width: "96%",
    margin: "35px auto",
    height: " 0px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {
    width: "400px",
  },
  remarks: {
    width: "300px",
  },

  textFieldClass: {
    width: "200px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditSecurityDialog = (props) => {
  const classes = useStyles();
  const { show, onCloseDialog, securityMaster } = props;

  const getCurrentDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = dd + "/" + mm + "/" + yyyy;
    return today;
  };
  const [faceValueOption, setFaceValueOption] = React.useState(
    securityMaster.faceValue.split(" ")[1]
  );
  const [faceValue, setFaceValue] = React.useState(
    securityMaster.faceValue.split(" ")[0]
  );
  const [pricePerBond, setPricePerBond] = React.useState(
    securityMaster.pricePerBond.split(" ")[0]
  );
  const [pricePerBondUnit, setPricePerBondUnit] = React.useState(
    securityMaster.pricePerBond.split(" ")[1]
  );
  const initialState = {
    isin: securityMaster.isin,
    divisibilityFactor: securityMaster.divisibilityFactor,
    nameOfInstrument: securityMaster.nameOfInstrument,
    securityType: securityMaster.securityType.type,
    securityTypeCode: securityMaster.securityTypeCode,
    securityCode: securityMaster.securityCode,
    issuerOfSecurity: securityMaster.issuerOfSecurity.issuerName,
    securityName: securityMaster.securityName,
    faceValue: faceValue,
    faceValueOption: faceValueOption,
    pricePerBond: pricePerBond,
    pricePerBondUnit: pricePerBondUnit,
    issueDate: securityMaster.issueDate,
    frequencyOfInterest: securityMaster.frequencyOfInterest,
    frequencyOfInterestOption: securityMaster.frequencyOfInterestOptions,
    lastIPDate: securityMaster.lastIPDate,
    lastIPDateOption: securityMaster.lastIPDateOptions,
    interestDays: securityMaster.interestDays,
    couponOn: securityMaster.couponOn,
    exchange: securityMaster.exchange,
    remarks: securityMaster.remarks,
    organizationName: "",
    rating: "",
    ratingDate: "",
    recordDate: securityMaster.recordDate,
    ipPayment: securityMaster.ipPayment,
    taxFree: securityMaster.taxFree,
    debentureTrusteeDetails: securityMaster.debentureTrusteeDetails,
    couponBasis: securityMaster.couponBasis,
    stepUpDown: securityMaster.stepUpDown,
    taxCategory: securityMaster.taxCategory,
    sector: securityMaster.sector,
    guaranteeType: securityMaster.guaranteeType,
    bankTier: securityMaster.bankTier,
    nriEligibility: securityMaster.nriEligibility,
    issuerType: securityMaster.issueType,
    securedOrUnsecured: securityMaster.securedOrUnsecured,
    issueSize: securityMaster.issueSize,
    modeOfIssue: securityMaster.modeOfIssue,
    guaranteeDetails: securityMaster.guaranteeDetails,
    activeOn: securityMaster.activeOn,
    retainCallDates: securityMaster.retainCallDates,
    retainMaturityDates: securityMaster.retainMaturityDates,
    nextIPDate: securityMaster.nextIPDate,
  };
  const amountOptions = ["LACS", "RUPEES", "CRORES"];
  const [currentState, setCurrentState] = React.useState(initialState);
  const { authUser, setAuthUser } = useContext(appContext);
  const [securityTypes, setSecurityTypes] = React.useState([]);
  const [securityType, setSecurityType] = React.useState(
    securityMaster.securityType
  );
  const [issuerOfSecurities, setIssuerOfSecurities] = React.useState([]);
  const [nextIPDate, setNextIPDate] = React.useState(currentState.nextIPDate);
  const [organizationNames, setOrganizationNames] = React.useState([]);
  const [ratingOrganizationMappings, setRatingOrganizationMappings] =
    React.useState([]);
  const [calculateInterestOnHolidays, setCalculateInterestOnHolidays] =
    React.useState(true);
  const [calculateInterestOnMaturity, setCalculateInterestOnMaturity] =
    React.useState(true);
  const [issueDateInclusive, setIssueDateInclusive] = React.useState(false);
  const [maturityDateInclusive, setMaturityDateInclusive] =
    React.useState(false);
  const [frequencyOfInterestOption, setFrequencyOfInterestOption] =
    React.useState("Normal");
  const [nriEligibility, setNriEligibility] = React.useState(
    securityMaster.nriEligibility
  );
  const [lastIPDateOption, setLastIPDateOption] = React.useState(
    initialState.lastIPDateOption || securityMaster.lastIPDateOptions
  );
  const [addedCoupons, setAddedCoupons] = React.useState([
    ...securityMaster.coupons,
    { uuid: "", date: "", rate: "", rate1: "" },
  ]);
  const [addedCallOptions, setAddedCallOptions] = React.useState([
    ...securityMaster.callOptions,
    { uuid: "", date: "", amount: "" },
  ]);
  const [addedPutOptions, setAddedPutOptions] = React.useState([
    ...securityMaster.putOptions,
    { uuid: "", date: "", amount: "" },
  ]);
  const [maturityOptions, setMaturityOptions] = React.useState([
    ...securityMaster.maturities,
    { uuid: "", date: "", amount: "" },
  ]);
  const [addedRatingOrgMasters, setAddedRatingOrgMasters] = React.useState([
    ...securityMaster.ratingOrganizationMasters,
    {
      uuid: "",
      organization: "",
      rating: "",
    },
  ]);
  const [retainCallDates, setRetainCallDates] = React.useState(
    securityMaster.retainCallDates || false
  );
  const [retainMaturityDates, setRetainMaturityDates] = React.useState(
    securityMaster.retainMaturityDates || false
  );
  const [lastIpDate, setLastIpDate] = React.useState(currentState.lastIPDate);
  const [issueDate, setIssueDate] = React.useState(currentState.issueDate);
  const [issuerType, setIssuerType] = React.useState("");
  const ratingOrganizationTableHeaders = [
    {
      id: "organizationName",
      numeric: true,
      disablePadding: false,
      label: "Organization Name",
    },
    { id: "rating", numeric: true, disablePadding: false, label: "Rating" },
    {
      id: "ratingDate",
      numeric: true,
      disablePadding: false,
      label: "Rating Date",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      label: "Action",
    },
  ];
  const couponHeaders = [
    { id: "date", numeric: true, disablePadding: false, label: "Date" },
    { id: "rate", numeric: true, disablePadding: false, label: "Rate" },
    { id: "rate1", numeric: true, disablePadding: false, label: "Rate1" },
    { id: "action", numeric: false, disablePadding: true, label: "Action" },
  ];
  const callOptionHeaders = [
    { id: "date", numeric: true, disablePadding: false, label: "Date" },
    { id: "amount", numeric: true, disablePadding: false, label: "Amount" },
    { id: "action", numeric: true, disablePadding: false, label: "Action" },
  ];
  const [showDebentureDetailsDialog, setShowDebentureDetailsDialog] =
    React.useState(false);
  const [showRegistrarDetailsDialog, setShowRegistrarDetailsDialog] =
    React.useState(false);
  const [debentureLogo, setDebentureLogo] = React.useState(null);
  const [registrarLogo, setRegistrarLogo] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const debentureTrusteeDetailsInitialState = {
    nameOfTrustee: "",
    address: "",
    phone: "",
    email: "",
    website: "",
  };
  const registrarDetailsInitialState = {
    nameOfRegistrar: "",
    address: "",
    phone: "",
    email: "",
    website: "",
  };
  const [debentureTrusteeDetails, setDebentureTrusteeDetails] = React.useState(
    securityMaster.debentureTrusteeDetails === null ||
      securityMaster.debentureTrusteeDetails === undefined
      ? debentureTrusteeDetailsInitialState
      : securityMaster.debentureTrusteeDetails
  );
  const [registrarDetails, setRegistrarDetails] = React.useState(
    securityMaster.registrarDetails === null ||
      securityMaster.registrarDetails === undefined
      ? registrarDetailsInitialState
      : securityMaster.registrarDetails
  );

  const removeEmptyValueDateAndAmount = (records) => {
    let updated = [];
    records
      .filter((record) => record.date !== "" && record.amount !== "")
      .map((record) => {
        let updatedRecord = record;
        if (updatedRecord.date.includes("-")) {
          let splitted = updatedRecord.date.split("-");
          updatedRecord.date =
            splitted[2] + "/" + splitted[1] + "/" + splitted[0];
          updated.push(updatedRecord);
        } else updated.push(record);
      });
    return updated;
  };

  const removeEmptyValuesRatingOrgMaster = (addedRatingOrgMasters) => {
    return addedRatingOrgMasters.filter(
      (addedRatingOrgMaster) =>
        addedRatingOrgMaster.organizationName !== "" &&
        addedRatingOrgMaster.rating !== "" &&
        addedRatingOrgMaster.ratingDate !== ""
    );
  };

  const removeEmptyValuesCoupon = (records) => {
    return records.filter(
      (record) =>
        record.date !== "" && record.rate !== "" && record.rate1 !== ""
    );
  };

  const convertToDate = (date) => {
    if (typeof date === "string") {
      if (date.includes("-")) {
        let splitted = date.split("-");
        return splitted[2] + "/" + splitted[1] + "/" + splitted[0];
      } else {
        return date;
      }
    } else {
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = date.getFullYear();
      return dd + "/" + mm + "/" + yyyy;
    }
  };

  const getRenamedProperties = (ratingOrganizationMasters) => {
    let withRenamedProperties = [];
    ratingOrganizationMasters.map((ratingOrganizationMaster) => {
      withRenamedProperties.push({
        organizationNameUUID: ratingOrganizationMaster.organization.uuid,
        ratingUUID: ratingOrganizationMaster.rating.uuid,
        ratingDate: convertToDate(ratingOrganizationMaster.ratingDate),
      });
    });
    return withRenamedProperties;
  };

  const handleSecuritySubmit = (event) => {
    let state = currentState;
    state.callOptions = removeEmptyValueDateAndAmount(addedCallOptions);
    state.putOptions = removeEmptyValueDateAndAmount(addedPutOptions);
    state.ratingOrganizationMasters = removeEmptyValuesRatingOrgMaster(
      addedRatingOrgMasters
    );
    state.ratingOrganizationMasters = getRenamedProperties(
      state.ratingOrganizationMasters
    );
    state.coupons = removeEmptyValuesCoupon(addedCoupons);
    if (state.nameOfInstrument === "Non Perpetual") {
      state.maturityOptions = removeEmptyValueDateAndAmount(maturityOptions);
    }
    state.lastIPDate = convertToDate(lastIpDate);
    state.lastIPDateOption = lastIPDateOption;
    state.issueDate = convertToDate(issueDate);
    state.nriEligibility = nriEligibility;
    state.pricePerBond = state.pricePerBond + " " + state.pricePerBondUnit;
    state.faceValue = state.faceValue + " " + state.faceValueOption;
    state.securityType = securityType.uuid;
    state.frequencyOfInterestOption = frequencyOfInterestOption;
    state.calculateInterestOnMaturity = calculateInterestOnMaturity;
    state.calculateInterestOnHolidays = calculateInterestOnHolidays;
    if (nextIPDate) {
      state.nextIPDate = convertToDate(nextIPDate);
    }
    state.issueDateInclusive = calculateInterestOnHolidays;
    state.maturityDateInclusive = maturityDateInclusive;
    state.nriEligibility = nriEligibility;
    state.debentureTrusteeDetails = debentureTrusteeDetails;
    state.registrarDetails = registrarDetails;
    state.retainCallDates = retainCallDates;
    state.retainMaturityDates = retainMaturityDates;
    if (!nextIPDate && "nextIPDate" in state) {
      delete state.nextIPDate;
    }
    state.issuerOfSecurity =
      state?.issuerOfSecurityUUID || securityMaster.issuerOfSecurity.uuid;
    props.onSubmit(event, state);
  };

  useEffect(() => {
    if (authUser && authUser.uuid) {
      getAllSecurityTypes()
        .then((response) => {
          if (response && response.status === 200) {
            setSecurityTypes(response.data);
          }
        })
        .catch((error) => toastr.error("Security Types => " + error));
    }
  }, []);

  useEffect(() => {
    if (authUser && authUser.uuid) {
      getAllIssuerMasters()
        .then((response) => {
          if (response && response.status === 200) {
            setIssuerOfSecurities(response.data);
          }
        })
        .catch((error) => toastr.error("Issuer Masters => " + error));
    }
  }, []);

  useEffect(() => {
    if (authUser && authUser.uuid) {
      getAllRatingsAndOrganizationNames()
        .then((response) => {
          if (response && response.status === 200) {
            let organizationNames = getAllOrganizationNames(response.data);
            setOrganizationNames(organizationNames);
            setRatingOrganizationMappings(response.data);
          }
        })
        .catch((error) =>
          toastr.error("Ratings and Organization Names  => " + error)
        );
    }
  }, []);

  useEffect(() => {
    setIssuerType(securityMaster.issuerOfSecurity.issuerType.type);
  }, [securityMaster.issuerOfSecurity.issuerType.type]);

  const isValuePresentInAccumulator = (accumulator, organizationUUID) => {
    return accumulator.some(
      (currentValue) => currentValue.uuid === organizationUUID
    );
  };

  const getAllOrganizationNames = (ratingOrganizationNames) => {
    let duplicatesRemoved = [];
    ratingOrganizationNames
      .map((ratingOrganizationName) => ratingOrganizationName.organization)
      .map((organization) => {
        if (isValuePresentInAccumulator(duplicatesRemoved, organization.uuid)) {
        } else {
          duplicatesRemoved.push(organization);
        }
      });
    return duplicatesRemoved;
  };

  const handleChange = (event) => {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const renderSecurityTypeCodeInput = () => {
    return (
      <Grid item xs={12}>
        <TextField
          type="text"
          name="securityTypeCode"
          variant="outlined"
          fullWidth
          required
          label="Security Type Code"
          defaultValue={currentState.securityTypeCode}
          onChange={(event) => handleChange(event)}
        />
      </Grid>
    );
  };

  const renderNameOfInstrumentDropdown = () => {
    let nameOfInstrumentOptions = ["Perpetual", "Non Perpetual"];
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Name of Instrument
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="nameOfInstrument"
            value={currentState.nameOfInstrument}
            onChange={(event) => handleChange(event)}
            label="Name of Instrument"
          >
            {nameOfInstrumentOptions &&
              nameOfInstrumentOptions.length > 0 &&
              nameOfInstrumentOptions.map((nameOfInstrument, index) => {
                return (
                  <MenuItem value={nameOfInstrument} key={index}>
                    {nameOfInstrument}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const handleSecurityTypeChange = (event) => {
    event.preventDefault();
    let filtered = securityTypes.filter(
      (securityType) => securityType.type === event.target.value
    )[0];
    setSecurityType(filtered);
  };

  const renderSecurityTypeDropdown = () => {
    let formattedSecurityTypes = securityTypes.map(
      (securityType) => securityType.type
    );
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Security Type *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="securityType"
            value={securityType.type}
            onChange={(event) => handleSecurityTypeChange(event)}
            label="Security Type *"
          >
            {formattedSecurityTypes &&
              formattedSecurityTypes.length > 0 &&
              formattedSecurityTypes.map((type, index) => {
                return (
                  <MenuItem value={type} key={index}>
                    {type}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderFirstRowOfInputs = () => {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          {renderNameOfInstrumentDropdown()}
        </Grid>
        <Grid item xs={4}>
          {renderSecurityTypeDropdown()}
        </Grid>
        <Grid item xs={4}>
          {renderSecurityTypeCodeInput()}
        </Grid>
      </React.Fragment>
    );
  };

  const renderSecurityCode = () => {
    return (
      <Grid item xs={12}>
        <TextField
          type="text"
          name="securityCode"
          variant="outlined"
          fullWidth
          required
          label="Security Code"
          defaultValue={currentState.securityCode}
          onChange={(event) => handleChange(event)}
        />
      </Grid>
    );
  };

  const getFilteredIssuerMasterByIssuerName = (enteredValue) => {
    return issuerOfSecurities.filter(
      (issuerOfSecurity) => issuerOfSecurity.issuerName === enteredValue
    )[0];
  };

  const handleIssuerNameChange = (event, enteredValue) => {
    event.preventDefault();
    let filteredIssuerMaster =
      getFilteredIssuerMasterByIssuerName(enteredValue);
    let state = currentState;
    state.issuerOfSecurity = filteredIssuerMaster.issuerName;
    state.issuerOfSecurityUUID = filteredIssuerMaster.uuid;
    setIssuerType(filteredIssuerMaster.issuerType);
  };

  const renderReadOnlyIssuerType = () => {
    return (
      <Grid item xs={12}>
        <TextField
          type="text"
          name="issuerType"
          variant="outlined"
          fullWidth
          disabled
          label="Issuer Type"
          value={issuerType}
        />
      </Grid>
    );
  };

  const renderIssuerOfSecurity = () => {
    let formattedIssuersOfSecurity = issuerOfSecurities.map(
      (issuerOfSecurity) => issuerOfSecurity.issuerName
    );
    return (
      <Grid item xs={12}>
        <Autocomplete
          id="free-solo-demo"
          freeSolo={true}
          options={formattedIssuersOfSecurity}
          onChange={(event, enteredValue) =>
            handleIssuerNameChange(event, enteredValue)
          }
          autoHighlight={true}
          value={currentState.issuerOfSecurity}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Issuer Of Security"
              margin="normal"
              variant="outlined"
            />
          )}
        />
      </Grid>
    );
  };

  const renderSecondRowOfInputs = () => {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          {renderSecurityCode()}
        </Grid>
        <Grid item xs={4}>
          {renderIssuerOfSecurity()}
        </Grid>

        <Grid item xs={4}>
          {renderReadOnlyIssuerType()}
        </Grid>
      </React.Fragment>
    );
  };

  const renderFaceValueOptionsDropdown = () => {
    let faceValueOptions = ["RUPEES", "LACS", "CRORES"];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">Unit</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="faceValueUnit"
            displayEmpty
            value={faceValueOption}
            onChange={(event) => handleChange(event)}
            label="Unit"
          >
            {faceValueOptions &&
              faceValueOptions.length > 0 &&
              faceValueOptions.map((amountOption, index) => {
                return (
                  <MenuItem value={amountOption} key={index}>
                    {amountOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderFaceValue = () => {
    return (
      <Grid item xs={12}>
        <TextField
          type="text"
          name="faceValue"
          variant="outlined"
          fullWidth
          label="Face Value"
          required
          defaultValue={currentState.faceValue}
          onChange={(event) => handleChange(event)}
        />
      </Grid>
    );
  };

  const renderFaceValueAndOptions = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <React.Fragment>
          <Grid item xs={6}>
            {renderFaceValue()}
          </Grid>
          <Grid item xs={6}>
            {renderFaceValueOptionsDropdown()}
          </Grid>
        </React.Fragment>
      </Grid>
    );
  };

  const renderNameOfSecurity = () => {
    return (
      <Grid item xs={12}>
        <TextField
          type="text"
          name="securityName"
          variant="outlined"
          fullWidth
          required
          label="Name of Security"
          defaultValue={currentState.securityName}
          onChange={(event) => handleChange(event)}
        />
      </Grid>
    );
  };

  const renderPricePerBondOptionsDropdown = () => {
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">Unit</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="pricePerBondUnit"
            value={currentState.pricePerBondUnit}
            onChange={(event) => handleChange(event)}
            label="Unit"
          >
            {amountOptions &&
              amountOptions.length > 0 &&
              amountOptions.map((amountOption, index) => {
                return (
                  <MenuItem value={amountOption} key={index}>
                    {amountOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderPricePerBond = () => {
    return (
      <Grid item xs={12}>
        <TextField
          type="text"
          name="pricePerBond"
          variant="outlined"
          fullWidth
          required
          label="Price per Bond"
          defaultValue={currentState.pricePerBond}
          onChange={(event) => handleChange(event)}
        />
      </Grid>
    );
  };

  const renderPricePerBondOptions = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <React.Fragment>
          <Grid item xs={6}>
            {renderPricePerBond()}
          </Grid>
          <Grid item xs={6}>
            {renderPricePerBondOptionsDropdown()}
          </Grid>
        </React.Fragment>
      </Grid>
    );
  };

  const renderThirdRowOfInputs = () => {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          {renderNameOfSecurity()}
        </Grid>
        <Grid item xs={4}>
          {renderFaceValueAndOptions()}
        </Grid>
        <Grid item xs={4}>
          {renderPricePerBondOptions()}
        </Grid>
      </React.Fragment>
    );
  };

  const renderFrequencyOfInterest = () => {
    let frequencyOfInterestOptions = [
      "Yearly",
      "Quarterly",
      "Monthly",
      "Half Yearly",
      "None",
    ];
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Frequency of Interest *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="frequencyOfInterest"
            value={currentState.frequencyOfInterest}
            onChange={(event) => handleChange(event)}
            label="Frequency Of Interest *"
          >
            {frequencyOfInterestOptions &&
              frequencyOfInterestOptions.length > 0 &&
              frequencyOfInterestOptions.map(
                (frequencyOfInterestOption, index) => {
                  return (
                    <MenuItem value={frequencyOfInterestOption} key={index}>
                      {frequencyOfInterestOption}
                    </MenuItem>
                  );
                }
              )}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const handleFrequencyOfInterestChange = (event) => {
    setFrequencyOfInterestOption(event.target.value);
  };

  const handleIssueDateChange = (date) => {
    setIssueDate(date);
  };

  const handleLastIPDateChange = (date) => {
    setLastIpDate(date);
  };

  const renderFrequencyOptionsRadioButtons = () => {
    return (
      <>
        <FormLabel component="legend">Options</FormLabel>
        <FormControlLabel
          value="Normal"
          label="Normal"
          control={
            <Radio
              checked={frequencyOfInterestOption === "Normal"}
              onChange={handleFrequencyOfInterestChange}
              value="Normal"
              color="primary"
              name="radio-button-demo"
            />
          }
        />

        <FormControlLabel
          value="Compound"
          label="Compound"
          control={
            <Radio
              checked={frequencyOfInterestOption === "Compound"}
              onChange={handleFrequencyOfInterestChange}
              value="Compound"
              color="primary"
              name="radio-button-demo"
            />
          }
        />
      </>
    );
  };

  const renderFrequencyOfInterestOptions = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <React.Fragment>
          <Grid item xs={8}>
            {renderFrequencyOfInterest()}
          </Grid>
          <Grid item xs={4}>
            {renderFrequencyOptionsRadioButtons()}
          </Grid>
        </React.Fragment>
      </Grid>
    );
  };

  const renderIssueDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid item justify="space-around" xs={12}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Issue Date *"
            format="dd/MM/yyyy"
            value={currentState.issueDate}
            onChange={handleIssueDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    );
  };

  const renderLastIPDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Last IP Date *"
            format="dd/MM/yyyy"
            value={lastIpDate}
            onChange={handleLastIPDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    );
  };

  const handleLastIPDateOptionChange = (event) => {
    setLastIPDateOption(event.target.value);
  };

  const renderLastIPDateOption = () => {
    return (
      <>
        <FormLabel component="legend">Options</FormLabel>
        <FormControlLabel
          value="Max"
          label="Max"
          control={
            <Radio
              checked={lastIPDateOption === "Max"}
              onChange={handleLastIPDateOptionChange}
              value="Max"
              color="primary"
              name="radio-button-demo"
            />
          }
        ></FormControlLabel>
        <FormControlLabel
          value="Actual"
          label="Actual"
          control={
            <Radio
              checked={lastIPDateOption === "Actual"}
              onChange={handleLastIPDateOptionChange}
              value="Actual"
              color="primary"
              name="radio-button-demo"
            />
          }
        ></FormControlLabel>
      </>
    );
  };

  const renderlastIPDateOptions = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <React.Fragment>
          <Grid item xs={5}>
            {renderLastIPDate()}
          </Grid>
          <Grid item xs={6}>
            {renderLastIPDateOption()}
          </Grid>
        </React.Fragment>
      </Grid>
    );
  };

  const renderFourthRowOfInputs = () => {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          {renderIssueDate()}
        </Grid>
        <Grid item xs={4}>
          {renderFrequencyOfInterestOptions()}
        </Grid>
        <Grid item xs={4}>
          {renderlastIPDateOptions()}
        </Grid>
      </React.Fragment>
    );
  };

  const renderInterestDays = () => {
    let interestDays = ["Actual/Actual", "Actual/365", "Equal Days"];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Interest Days
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="interestDays"
            value={currentState.interestDays}
            onChange={(event) => handleChange(event)}
            label="Interest Days"
          >
            {interestDays &&
              interestDays.length > 0 &&
              interestDays.map((interestDay, index) => {
                return (
                  <MenuItem value={interestDay} key={index}>
                    {interestDay}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderTaxFreeDropdown = () => {
    let taxFreeOptions = ["Retail", "Corporate"];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Tax Free (Step Up/ Step Down) *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="taxFree"
            value={currentState.taxFree}
            onChange={(event) => handleChange(event)}
            label="Tax Free"
          >
            {taxFreeOptions &&
              taxFreeOptions.length > 0 &&
              taxFreeOptions.map((taxFreeOption, index) => {
                return (
                  <MenuItem value={taxFreeOption} key={index}>
                    {taxFreeOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderIpPaymentOptions = () => {
    let ipPaymentOptions = ["Next Working Day", "Previous Working Day", "None"];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Ip Payment *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="ipPayment"
            value={currentState.ipPayment}
            onChange={(event) => handleChange(event)}
            label="IP Payment *"
          >
            {ipPaymentOptions &&
              ipPaymentOptions.length > 0 &&
              ipPaymentOptions.map((ipPayment, index) => {
                return (
                  <MenuItem value={ipPayment} key={index}>
                    {ipPayment}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderRecordDate = () => {
    return (
      <Grid item xs={4}>
        <TextField
          type="text"
          name="recordDate"
          variant="outlined"
          fullWidth
          required
          label="Record Date"
          defaultValue={currentState.recordDate}
          onChange={(event) => handleChange(event)}
        />
      </Grid>
    );
  };

  const renderISIN = () => {
    return (
      <Grid item xs={12}>
        <TextField
          type="text"
          required
          name="isin"
          variant="outlined"
          fullWidth
          label="ISIN"
          defaultValue={currentState.isin}
          onChange={(event) => handleChange(event)}
        />
      </Grid>
    );
  };

  const getConvertedToBool = (val) => {
    if (val === "No") return false;
    else return true;
  };

  const handleNRIEligibilityChange = (event) => {
    let convertedToBool = getConvertedToBool(event.target.value);
    setNriEligibility(convertedToBool);
  };

  const renderNRIEligibility = () => {
    return (
      <>
        <FormLabel component="legend">NRI Eligibility</FormLabel>
        <FormControlLabel
          value="Yes"
          label="Yes"
          control={
            <Radio
              checked={nriEligibility}
              onChange={handleNRIEligibilityChange}
              value="Yes"
              color="primary"
              name="radio-button-demo"
            />
          }
        ></FormControlLabel>
        <FormControlLabel
          value="No"
          label="No"
          control={
            <Radio
              checked={!nriEligibility}
              onChange={handleNRIEligibilityChange}
              value="No"
              color="primary"
              name="radio-button-demo"
            />
          }
        ></FormControlLabel>
      </>
    );
  };

  const renderFifthRowOfInputs = () => {
    return (
      <React.Fragment>
        <Grid item xs={4}>
          {renderInterestDays()}
        </Grid>
        <Grid item xs={4}>
          {renderRecordDate()}
        </Grid>
        <Grid item xs={4}>
          {renderIpPaymentOptions()}
        </Grid>
        <Grid item xs={4}>
          {renderISIN()}
        </Grid>

        <Grid item xs={4}>
          {renderNRIEligibility()}
        </Grid>

        <Grid item xs={4}>
          {renderTaxFreeDropdown()}
        </Grid>
      </React.Fragment>
    );
  };

  const renderCouponOnDropdown = () => {
    let couponOnOptions = ["Rate", "Amount"];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Coupon On *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="couponOn"
            value={currentState.couponOn}
            onChange={(event) => handleChange(event)}
            label="Coupon On *"
          >
            {couponOnOptions &&
              couponOnOptions.length > 0 &&
              couponOnOptions.map((couponOnOption, index) => {
                return (
                  <MenuItem value={couponOnOption} key={index}>
                    {couponOnOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderActiveOnDropdown = () => {
    let activeOnOptions = ["CDSL", "NSDL", "Both"];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Active On *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="activeOn"
            value={currentState.activeOn}
            onChange={(event) => handleChange(event)}
            label="Active On *"
          >
            {activeOnOptions &&
              activeOnOptions.length > 0 &&
              activeOnOptions.map((activeOnOption, index) => {
                return (
                  <MenuItem value={activeOnOption} key={index}>
                    {activeOnOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderExchangeDropdown = () => {
    let exchangeOptions = ["NSE", "BSE", "Both", "Unlisted"];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Exchange *
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="exchange"
            value={currentState.exchange}
            onChange={(event) => handleChange(event)}
            label="Exchange *"
          >
            {exchangeOptions &&
              exchangeOptions.length > 0 &&
              exchangeOptions.map((exchangeOption, index) => {
                return (
                  <MenuItem value={exchangeOption} key={index}>
                    {exchangeOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderRemarks = () => {
    return (
      <Grid item xs={3}>
        <TextField
          id="outlined-multiline-flexible"
          label="Remarks"
          multiline
          name="remarks"
          rowsMax={10}
          className={classes.remarks}
          value={currentState.remarks}
          onChange={(event) => handleChange(event)}
          variant="outlined"
        />
      </Grid>
    );
  };

  const renderSixthRowOfInputs = () => {
    return (
      <React.Fragment>
        <Grid item xs={3}>
          {renderCouponOnDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderExchangeDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderActiveOnDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderRemarks()}
        </Grid>
      </React.Fragment>
    );
  };

  const renderMaturityDateInclusive = () => {
    return (
      <>
        <FormLabel component="legend">Maturity Date Inclusive</FormLabel>
        <FormControlLabel
          value="Yes"
          label="Yes"
          control={
            <Radio
              checked={maturityDateInclusive}
              onChange={handleMaturityDateInclusive}
              value="Yes"
              color="primary"
              name="radio-button-demo"
            />
          }
        />

        <FormControlLabel
          value="No"
          label="No"
          control={
            <Radio
              checked={!maturityDateInclusive}
              onChange={handleMaturityDateInclusive}
              value="No"
              color="primary"
              name="radio-button-demo"
            />
          }
        />
      </>
    );
  };

  const renderIssueDateInclusive = () => {
    return (
      <>
        <FormLabel component="legend">Issue Date Inclusive</FormLabel>
        <FormControlLabel
          value="Yes"
          label="Yes"
          control={
            <Radio
              checked={issueDateInclusive}
              onChange={handleIssueDateInclusive}
              value="Yes"
              color="primary"
              name="radio-button-demo"
            />
          }
        />

        <FormControlLabel
          value="No"
          label="No"
          control={
            <Radio
              checked={!issueDateInclusive}
              onChange={handleIssueDateInclusive}
              value="No"
              color="primary"
              name="radio-button-demo"
            />
          }
        />
      </>
    );
  };

  const renderCalculateInterestOnMaturity = (event) => {
    return (
      <>
        <FormLabel component="legend">Calculate Interest On Maturity</FormLabel>
        <FormControlLabel
          value="Yes"
          label="Yes"
          control={
            <Radio
              checked={calculateInterestOnMaturity}
              onChange={handleInterestOnMaturity}
              value="Yes"
              color="primary"
              name="radio-button-demo"
            />
          }
        ></FormControlLabel>
        <FormControlLabel
          value="No"
          label="No"
          control={
            <Radio
              checked={!calculateInterestOnMaturity}
              onChange={handleInterestOnMaturity}
              value="No"
              color="primary"
              name="radio-button-demo"
            />
          }
        ></FormControlLabel>
      </>
    );
  };

  const handleCalculateInterestOnHolidays = (event) => {
    let convertedToBool = getConvertedToBool(event.target.value);
    setCalculateInterestOnHolidays(convertedToBool);
  };

  const renderCalculateInterestOnHolidays = () => {
    return (
      <>
        <FormLabel component="legend">Calculate Interest On Holidays</FormLabel>
        <FormControlLabel
          value="Yes"
          label="Yes"
          control={
            <Radio
              checked={calculateInterestOnHolidays}
              onChange={handleCalculateInterestOnHolidays}
              value="Yes"
              color="primary"
              name="radio-button-demo"
            />
          }
        ></FormControlLabel>
        <FormControlLabel
          value="No"
          label="No"
          control={
            <Radio
              checked={!calculateInterestOnHolidays}
              onChange={handleCalculateInterestOnHolidays}
              value="No"
              color="primary"
              name="radio-button-demo"
            />
          }
        ></FormControlLabel>
      </>
    );
  };

  const handleMaturityDateInclusive = (event) => {
    let convertedToBool = getConvertedToBool(event.target.value);
    setMaturityDateInclusive(convertedToBool);
  };

  const handleIssueDateInclusive = (event) => {
    let convertedToBool = getConvertedToBool(event.target.value);
    setIssueDateInclusive(convertedToBool);
  };

  const handleInterestOnMaturity = (event) => {
    let convertedToBool = getConvertedToBool(event.target.value);
    setCalculateInterestOnMaturity(convertedToBool);
  };

  const renderSeventhRowOfInputs = () => {
    return (
      <React.Fragment>
        <Grid item xs={3}>
          {renderCalculateInterestOnHolidays()}
        </Grid>
        <Grid item xs={3}>
          {renderCalculateInterestOnMaturity()}
        </Grid>

        <Grid item xs={3}>
          {renderIssueDateInclusive()}
        </Grid>

        <Grid item xs={3}>
          {renderMaturityDateInclusive()}
        </Grid>
      </React.Fragment>
    );
  };

  const getFilteredOrganizationName = (organizationNameUUID) => {
    return organizationNames.filter(
      (organizationName) => organizationName.uuid === organizationNameUUID
    )[0];
  };

  const getFilteredRating = (ratingUUID) => {
    let ratings = ratingOrganizationMappings.map(
      (ratingOrganizationMapping) => ratingOrganizationMapping.rating
    );
    return ratings.filter((rating) => rating.uuid === ratingUUID)[0];
  };

  const addNewRatingOrgTable = (event, addedRatingOrganizationMapping) => {
    let ratingOrgMasters = addedRatingOrgMasters;
    let filteredOrganization = getFilteredOrganizationName(
      addedRatingOrganizationMapping.organizationNameUUID
    );
    let filteredRating = getFilteredRating(
      addedRatingOrganizationMapping.ratingUUID
    );
    ratingOrgMasters.unshift({
      uuid: "",
      rating: filteredRating,
      organization: filteredOrganization,
      ratingDate: addedRatingOrganizationMapping.ratingDate,
    });

    setAddedRatingOrgMasters(ratingOrgMasters);
  };

  const deleteSecurityRatingOrgRelationship = (event, uuid) => {
    event.preventDefault();
    if (uuid === "") {
      let [head, ...tail] = addedRatingOrgMasters;
      setAddedRatingOrgMasters(tail);
      toastr.success("Successfully deleted Rating Organization Master");
    } else {
      deleteSecurityRatingOrganizationRelationship({
        changedBy: JSON.parse(localStorage.getItem("loginUser"))?.name,
        uuid: uuid,
      })
        .then((response) => {
          if (
            response &&
            (response.status === 204 || response.status === 200)
          ) {
            toastr.success("Successfully deleted Rating Organization Master");
            let filtered = addedRatingOrgMasters.filter(
              (addedRatingOrgMaster) => addedRatingOrgMaster.uuid !== uuid
            );
            setAddedRatingOrgMasters(filtered);
          }
        })
        .catch((error) => toastr.error(error));
    }
  };

  const renderAdditionalInformation = () => {
    if (
      securityType.type === "State Development Loans" ||
      securityType.type === "Government Securities"
    ) {
      return "";
    } else {
      return (
        <EditRatingOrganizationMasterTable
          headCells={ratingOrganizationTableHeaders}
          ratingOrganizationMappings={ratingOrganizationMappings}
          records={addedRatingOrgMasters}
          onAddNewRow={addNewRatingOrgTable}
          organizationNames={organizationNames}
          onDeleteRatingOrgMasterRelationship={
            deleteSecurityRatingOrgRelationship
          }
        />
      );
    }
  };

  const addNewCoupon = (event, newlyAdded) => {
    event.preventDefault();
    let coupons = addedCoupons;
    coupons.unshift({
      date: newlyAdded.date,
      rate: newlyAdded.rate,
      rate1: newlyAdded.rate1,
      uuid: "",
      securityCouponRelationshipUUID: "",
    });
    setAddedCoupons(coupons);
  };

  const handleDeleteOnClick = (event, toBeDeleted) => {
    event.preventDefault();
    if (toBeDeleted.uuid === "") {
      let [head, ...tail] = addedCoupons;
      setAddedCoupons(tail);
      toastr.success("Deleted Coupon successfully !");
    } else {
      deleteSecurityCouponRelationship({
        relationshipUuid: toBeDeleted.securityCouponRelationshipUUID,
        uuid: authUser.uuid,
        isSuperuser: authUser?.isSuperuser || false,
        changedBy: JSON.parse(localStorage.getItem("loginUser"))?.name,
      })
        .then((response) => {
          if (
            response &&
            (response.status === 200 || response.status === 204)
          ) {
            toastr.success("Deleted Coupon successfully !");
            let filtered = addedCoupons.filter(
              (coupon) => coupon.uuid !== toBeDeleted.uuid
            );
            setAddedCoupons(filtered);
          }
        })
        .catch((error) => toastr.error(error));
    }
  };

  const addNewCallOptionRow = (event, newlyAdded) => {
    event.preventDefault();
    let callOptions = addedCallOptions;
    callOptions.unshift(newlyAdded);
    setAddedCallOptions(callOptions);
  };

  const handleOnDeleteCallOption = (event, toBeDeleted) => {
    event.preventDefault();
    if (
      toBeDeleted.uuid === "" &&
      toBeDeleted.date === "" &&
      toBeDeleted.amount === ""
    ) {
      let [head, ...tail] = addedCallOptions;
      setAddedCallOptions(tail);
      toastr.success("Deleted Call Option !");
    } else {
      deleteSecurityCallOptionRelationship({
        relationshipUuid: toBeDeleted.securityCallOptionRelationshipUUID,
        uuid: authUser.uuid,
        isSuperuser: authUser?.isSuperuser || false,
        changedBy: JSON.parse(localStorage.getItem("loginUser"))?.name,
      })
        .then((resp) => {
          if (resp && (resp.status === 200 || resp.status === 204)) {
            toastr.success("Deleted Call Option !");
            let filtered = addedCallOptions.filter(
              (callOption) => callOption.uuid !== toBeDeleted.uuid
            );
            setAddedCallOptions(filtered);
          }
        })
        .catch((error) => toastr.error(error));
    }
  };

  const changeDateFormat = (date) => {
    if (date.includes("-")) {
      let s = date.split("-");
      return s[2] + "/" + s[1] + "/" + s[0];
    } else return date;
  };

  const addNewPutOption = (event, newlyAdded) => {
    event.preventDefault();
    let addedVal = newlyAdded;
    addedVal.date = changeDateFormat(newlyAdded.date);
    let putOptions = addedPutOptions;
    putOptions.unshift(addedVal);
    setAddedPutOptions(putOptions);
  };

  const handleOnDeletePutOption = (event, toBeDeleted) => {
    event.preventDefault();
    if (
      toBeDeleted.uuid === "" &&
      toBeDeleted.date === "" &&
      toBeDeleted.amount === ""
    ) {
      let [head, ...tail] = addedPutOptions;
      setAddedPutOptions(tail);
      toastr.success("Successfully Deleted Put Option !");
    } else {
      deleteSecurityPutOptionRelationship({
        uuid: toBeDeleted.securityPutOptionRelationshipUUID,
        changedBy: JSON.parse(localStorage.getItem("loginUser"))?.name,
      })
        .then((resp) => {
          if (resp && (resp.status === 200 || resp.status === 204)) {
            toastr.success("Successfully Deleted Put Option !");
            let filtered = addedPutOptions.filter(
              (putOption) => putOption.uuid !== toBeDeleted.uuid
            );
            setAddedPutOptions(filtered);
          }
        })
        .catch((error) => toastr.error(error));
    }
  };

  const addNewMaturityOption = (event, newlyAdded) => {
    event.preventDefault();
    let maturities = maturityOptions;
    maturities.unshift(newlyAdded);
    setMaturityOptions(maturities);
  };

  const handleMaturityOptionDelete = (event, toBeDeleted) => {
    event.preventDefault();
    if (
      toBeDeleted.uuid === "" &&
      toBeDeleted.date === "" &&
      toBeDeleted.amount === ""
    ) {
      let [head, ...tail] = maturityOptions;
      setMaturityOptions(tail);
      toastr.success("Deleted Maturity Option Successfully !");
    } else {
      deleteSecurityMaturityRelationship({
        relationshipUuid: toBeDeleted.securityMaturityRelationshipUUID,
        uuid: authUser.uuid,
        isSuperuser: authUser?.isSuperuser || false,
        changedBy: JSON.parse(localStorage.getItem("loginUser"))?.name,
      })
        .then((resp) => {
          if (resp && (resp.status === 200 || resp.status === 204)) {
            toastr.success("Deleted Maturity Option Successfully !");
            let filtered = maturityOptions.filter(
              (maturity) => maturity.uuid !== toBeDeleted.uuid
            );
            setMaturityOptions(filtered);
          }
        })
        .catch((error) => toastr.error(error));
    }
  };

  const handleUnsavedMaturityDelete = (event, toBeDeleted) => {
    event.preventDefault();
    let filteredMaturities = maturityOptions.filter(
      (maturityOption) => maturityOption.date !== toBeDeleted.date
    );
    setMaturityOptions(filteredMaturities);
  };

  const renderMaturityOption = () => {
    return (
      <MaturityOptionDataTable
        headCells={callOptionHeaders}
        records={maturityOptions}
        onAddNewRow={addNewMaturityOption}
        onDeleteClick={handleMaturityOptionDelete}
        removeUnsavedMaturity={handleUnsavedMaturityDelete}
        onToggleSwitch={(event) => setRetainMaturityDates(!retainMaturityDates)}
        retainMaturityDates={retainMaturityDates}
      />
    );
  };

  const handleUnsavedPutOptionDelete = (event, toBeDeleted) => {
    event.preventDefault();
    let filteredPutOptions = addedPutOptions.filter(
      (putOption) => putOption.date !== toBeDeleted.date
    );
    setAddedPutOptions(filteredPutOptions);
  };

  const renderPutOption = () => {
    return (
      <PutOptionDataTable
        headCells={callOptionHeaders}
        records={addedPutOptions}
        onAddNewRow={addNewPutOption}
        onDeleteClick={handleOnDeletePutOption}
        removeUnsavedPutOption={handleUnsavedPutOptionDelete}
      />
    );
  };

  const removeUnsavedCallOption = (event, toBeDeleted) => {
    let filteredCallOptions = addedCallOptions.filter(
      (callOption) => callOption.date !== toBeDeleted.date
    );
    setAddedCallOptions(filteredCallOptions);
  };

  const renderCallOption = () => {
    return (
      <CallOptionEditTable
        headCells={callOptionHeaders}
        records={addedCallOptions}
        onAddNewRow={addNewCallOptionRow}
        onDeleteClick={handleOnDeleteCallOption}
        onDeleteCallOption={removeUnsavedCallOption}
        onToggleSwitch={(event) => setRetainCallDates(!retainCallDates)}
        retainCallDate={retainCallDates}
      />
    );
  };

  const renderCoupon = () => {
    return (
      <CouponEditTable
        headCells={couponHeaders}
        records={addedCoupons}
        OnAddNewRow={addNewCoupon}
        onDeleteClick={handleDeleteOnClick}
      />
    );
  };

  const handleAddDebentureTrusteeDetails = (event) => {
    event.preventDefault();
    setShowDebentureDetailsDialog(true);
  };

  const handleRegistrarDetails = (event) => {
    event.preventDefault();
    setShowRegistrarDetailsDialog(true);
  };

  const renderAdditionalOptions = () => {
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={6} style={{ marginBottom: "2.5rem" }}>
                {renderCoupon()}
              </Grid>
              <Grid item xs={6} style={{ marginBottom: "2.5rem" }}>
                {renderCallOption()}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6} style={{ marginBottom: "2.5rem" }}>
                {renderPutOption()}
              </Grid>

              <Grid item xs={6} style={{ marginBottom: "2.5rem" }}>
                {currentState.nameOfInstrument === "Non Perpetual"
                  ? renderMaturityOption()
                  : ""}
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6} style={{ marginBottom: "2.5rem" }}>
                  <Button
                    className="btnClass"
                    variant="outlined"
                    onClick={(event) => handleAddDebentureTrusteeDetails(event)}
                    color="primary"
                  >
                    Add Debenture Trustee Details
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ marginBottom: "2.5rem" }}>
                  <Button
                    className="btnClass"
                    variant="outlined"
                    onClick={(event) => handleRegistrarDetails(event)}
                    color="primary"
                  >
                    Add Registrar Details
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                style={{
                  marginBottom: "2.5rem",
                  background: "#7659EE1A",
                  borderRadius: 12,
                  padding: 10,
                }}
                justifyContent="center"
              >
                <Grid item xs={12} sm={"auto"}>
                  <label>Select Debenture</label>
                  <Avatar
                    style={{ height: "100px", width: "100px" }}
                    variant="circle"
                    src={
                      debentureLogo
                        ? debentureLogo
                        : `data:image/png;base64, ${securityMaster.debenture_trustee_image}`
                    }
                  />
                  <input
                    type="file"
                    name="file"
                    onChange={(event) =>
                      setDebentureLogo(event.target.files[0])
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={"auto"}>
                  <label>Select Registrar</label>
                  <Avatar
                    style={{ height: "100px", width: "100px" }}
                    variant="circle"
                    src={
                      registrarLogo
                        ? registrarLogo
                        : `data:image/png;base64, ${securityMaster.registrar_trustee_image}`
                    }
                  />
                  <input
                    type="file"
                    name="file"
                    onChange={(event) =>
                      setRegistrarLogo(event.target.files[0])
                    }
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      let formData = new FormData();
                      formData.append("isin", securityMaster.isin);
                      formData.append("debenture_trustee_image", debentureLogo);
                      formData.append("registrar_details_image", registrarLogo);
                      setLoading(true);
                      makePOSTRequest("/admin/debt_registar_logo/", formData)
                        .then(() =>
                          toastr.success("Successfully Uploaded Logos")
                        )
                        .catch((error) => toastr.error(error));
                      setLoading(false);
                    }}
                  >
                    {loading ? <CircularProgress color="inherit" /> : "Submit"}
                  </Button>
                  <Typography
                    color="error"
                    style={{ fontSize: 11, textAlign: "center" }}
                  >
                    **Please click this button to submit the Debenture and
                    Registrar Logos seperatly. It won't get saved on the above
                    save click.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </div>
    );
  };

  const handleCloseDebentureDetailsDialog = (event) => {
    event.preventDefault();
    setShowDebentureDetailsDialog(false);
  };

  const handleDebentureDetailsSubmit = (event) => {
    event.preventDefault();
    setShowDebentureDetailsDialog(false);
  };

  const handleDebentureDetailsChange = (event) => {
    event.persist();
    setDebentureTrusteeDetails((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const renderDebentureDetailsDialog = () => {
    return (
      <DebentureDetailsDialog
        show={showDebentureDetailsDialog}
        onCloseDialog={handleCloseDebentureDetailsDialog}
        onSubmit={handleDebentureDetailsSubmit}
        onChange={handleDebentureDetailsChange}
        currentState={debentureTrusteeDetails}
      />
    );
  };

  const handleCloseRegistrarDetailsDialog = (event) => {
    event.preventDefault();
    setShowRegistrarDetailsDialog(false);
  };

  const handleRegistrarDetailsChange = (event) => {
    event.preventDefault();
    setRegistrarDetails((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const renderRegistrarDetailsDialog = () => {
    return (
      <RegistrarDetailsDialog
        show={showRegistrarDetailsDialog}
        onCloseDialog={handleCloseRegistrarDetailsDialog}
        onSubmit={handleCloseRegistrarDetailsDialog}
        onChange={handleRegistrarDetailsChange}
        currentState={registrarDetails}
      />
    );
  };

  const renderStepUpStepDown = () => {
    return (
      <Grid item xs={3}>
        <TextField
          id="outlined-multiline-flexible"
          name="stepUpDown"
          fullWidth
          label="Step Up/Step Down"
          className={classes.textFieldClass}
          value={currentState.stepUpDown}
          onChange={(event) => handleChange(event)}
          variant="outlined"
        />
      </Grid>
    );
  };

  const renderIssueSizeInput = () => {
    return (
      <Grid item xs={3}>
        <TextField
          id="outlined-multiline-flexible"
          label="Issue Size"
          fullWidth
          name="issueSize"
          className={classes.textFieldClass}
          value={currentState.issueSize}
          onChange={(event) => handleChange(event)}
          variant="outlined"
        />
      </Grid>
    );
  };

  const renderCouponBasis = () => {
    return (
      <Grid item xs={3}>
        <TextField
          id="outlined-multiline-flexible"
          label="Coupon Basis"
          fullWidth
          name="couponBasis"
          className={classes.textFieldClass}
          value={currentState.couponBasis}
          onChange={(event) => handleChange(event)}
          variant="outlined"
        />
      </Grid>
    );
  };

  const renderDivisibilityFactorDropdown = () => {
    let availableFactors = [360, 365, 366, 0];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Divisibility Factor
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="divisibilityFactor"
            value={currentState.divisibilityFactor}
            onChange={(event) => handleChange(event)}
            label="Divisibility Factor"
          >
            {availableFactors &&
              availableFactors.length > 0 &&
              availableFactors.map((availableFactor, index) => {
                return (
                  <MenuItem value={availableFactor} key={index}>
                    {availableFactor}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderModeOfIssueDropdown = () => {
    let modeOfIssueOptions = ["IPO", "Private Placement"];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Mode Of Issue
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="modeOfIssue"
            value={currentState.modeOfIssue}
            onChange={(event) => handleChange(event)}
            label="Mode Of Issue"
          >
            {modeOfIssueOptions &&
              modeOfIssueOptions.length > 0 &&
              modeOfIssueOptions.map((modeOfIssueOption, index) => {
                return (
                  <MenuItem value={modeOfIssueOption} key={index}>
                    {modeOfIssueOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderSecuredOrUnSecuredDropdown = () => {
    let options = [
      "Senior Secured",
      "Senior Unsecured",
      "Unsecured Subordinated Tier 1",
      "Unsecured Subordinated Tier 2",
      "Unsecured Subordinated Tier 3",
    ];
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Secured Or Unsecured
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="securedOrUnsecured"
            value={currentState.securedOrUnsecured}
            onChange={(event) => handleChange(event)}
            label="Secured Or Unsecured"
          >
            {options &&
              options.length > 0 &&
              options.map((option, index) => {
                return (
                  <MenuItem value={option} key={index}>
                    {option}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderBankTierDropdown = () => {
    if (currentState.sector === "Bank") {
      let bankTierOptions = ["Tier 1", "Tier 2", "Tier 3"];
      return (
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "100%" }}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Bank Tier
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="bankTier"
              value={currentState.bankTier}
              onChange={(event) => handleChange(event)}
              label="Bank Tier"
            >
              {bankTierOptions &&
                bankTierOptions.length > 0 &&
                bankTierOptions.map((bankTierOption, index) => {
                  return (
                    <MenuItem value={bankTierOption} key={index}>
                      {bankTierOption}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      );
    } else return "";
  };

  const renderGuaranteeDetails = () => {
    return (
      <Grid item xs={12}>
        <TextField
          type="text"
          name="guaranteeDetails"
          variant="outlined"
          className={classes.textField}
          label="Guarantee Details"
          defaultValue={currentState.guaranteeDetails}
          onChange={(event) => handleChange(event)}
        />
      </Grid>
    );
  };

  const renderTypeOfGuaranteeDropdown = () => {
    let typeOfGuaranteeOptions = [
      "Guaranteed",
      "Non-Guaranteed",
      "Partially Guaranteed",
    ];

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Type Of Guarantee
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="guaranteeType"
            value={currentState.guaranteeType}
            onChange={(event) => handleChange(event)}
            label="Type of Guarantee"
          >
            {typeOfGuaranteeOptions &&
              typeOfGuaranteeOptions.length > 0 &&
              typeOfGuaranteeOptions.map((typeOfGuaranteeOption, index) => {
                return (
                  <MenuItem value={typeOfGuaranteeOption} key={index}>
                    {typeOfGuaranteeOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderSectorDropdown = () => {
    let sectorDropdownOptions = [
      "Bank",
      "NBFC",
      "Transport",
      "Power",
      "Infrastructure",
      "Insurance",
      "Manufacturing",
      "Petrochemicals",
      "Municipal Bond",
      "FMCG",
      "Entertainment",
      "Telecommunication",
    ];
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">Sector</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="sector"
            value={currentState.sector}
            onChange={(event) => handleChange(event)}
            label="Sector"
          >
            {sectorDropdownOptions &&
              sectorDropdownOptions.length > 0 &&
              sectorDropdownOptions.map((sectorDropdownOption, index) => {
                return (
                  <MenuItem value={sectorDropdownOption} key={index}>
                    {sectorDropdownOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };
  const renderTaxCategoryDropdown = () => {
    let taxCategoryDropDownOptions = ["Taxable", "Tax Free"];
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Tax Category
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="taxCategory"
            value={currentState.taxCategory}
            onChange={(event) => handleChange(event)}
            label="Tax Category"
          >
            {taxCategoryDropDownOptions &&
              taxCategoryDropDownOptions.length > 0 &&
              taxCategoryDropDownOptions.map(
                (taxCategoryDropDownOption, index) => {
                  return (
                    <MenuItem value={taxCategoryDropDownOption} key={index}>
                      {taxCategoryDropDownOption}
                    </MenuItem>
                  );
                }
              )}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderOptionalInformation = () => {
    return (
      <React.Fragment>
        <Grid item xs={3}>
          {renderCouponBasis()}
        </Grid>
        <Grid item xs={3}>
          {renderStepUpStepDown()}
        </Grid>

        <Grid item xs={3}>
          {renderTaxCategoryDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderSectorDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderTypeOfGuaranteeDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderGuaranteeDetails()}
        </Grid>

        <Grid item xs={3}>
          {renderBankTierDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderSecuredOrUnSecuredDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderIssueSizeInput()}
        </Grid>

        <Grid item xs={3}>
          {renderModeOfIssueDropdown()}
        </Grid>

        <Grid item xs={3}>
          {renderDivisibilityFactorDropdown()}
        </Grid>

        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Next IP Date"
                format="dd/MM/yyyy"
                value={nextIPDate}
                onChange={(date) => {
                  setNextIPDate(date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </React.Fragment>
    );
  };

  const renderDialogBody = () => {
    return (
      <div className="mainFormClass">
        <Grid container spacing={5}>
          <Grid container item xs={12} spacing={3}>
            {renderFirstRowOfInputs()}
          </Grid>

          <Grid container item xs={12} spacing={3}>
            {renderSecondRowOfInputs()}
          </Grid>

          <Grid container item xs={12} spacing={3}>
            {renderThirdRowOfInputs()}
          </Grid>

          <Grid container item xs={12} spacing={3}>
            {renderFourthRowOfInputs()}
          </Grid>

          <Grid container item xs={12} spacing={3}>
            {renderFifthRowOfInputs()}
          </Grid>

          <Grid container item xs={12} spacing={3}>
            {renderSixthRowOfInputs()}
          </Grid>

          <Grid container item xs={12} spacing={3}>
            {renderOptionalInformation()}
          </Grid>
        </Grid>
        <br />
        <br />
        <Divider />
        <div style={{ fontSize: "30px", float: "left" }}>Interest Details</div>
        <br />

        <div className={classes.root}>
          <Grid container spacing={5}>
            <Grid container item xs={12} spacing={3}>
              {renderSeventhRowOfInputs()}
            </Grid>
          </Grid>
        </div>

        <br />
        <br />
        <Divider />
        <div style={{ fontSize: "30px", float: "left" }}>
          Additional Information
        </div>
        <br />
        <div className={classes.root}>
          <Grid container spacing={5}>
            <Grid container item xs={12} spacing={3}>
              {renderAdditionalInformation()}
            </Grid>
          </Grid>
        </div>

        <br />
        <br />
        <Divider />
        {renderAdditionalOptions()}
        {renderDebentureDetailsDialog()}
        {renderRegistrarDetailsDialog()}
      </div>
    );
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.show}
        onClose={(event) => props.onCloseDialog(event)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(event) => props.onCloseDialog(event)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Close
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={(event) => handleSecuritySubmit(event)}
            >
              Save
            </Button>
          </Toolbar>
        </AppBar>
        {renderDialogBody()}
      </Dialog>
    </div>
  );
};

export default EditSecurityDialog;
