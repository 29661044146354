import React, { useEffect, useContext, useState } from "react";
import toastr from "toastr";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { makeStyles } from "@material-ui/core/styles";
import { appContext } from "../App";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { TextField, Grid } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteModal from "./DeleteModal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    padding: "15px",
  },
  table: {
    minWidth: 1250,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  textFieldClass: {
    width: "20%",
    height: "20%",
  },
  textField: {
    width: "300px",
  },
}));

const EditRatingOrganizationMasterTable = (props) => {
  const classes = useStyles();
  const { headCells, ratingOrganizationMappings, organizationNames, records } =
    props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [mainLoadingIs, setmainLoadingIs] = React.useState(false);
  const [organizationName, setOrganizationName] = React.useState(null);
  const [rating, setRating] = React.useState(null);
  const [ratingDate, setRatingDate] = React.useState(new Date());
  const [toBeDeleted, setToBeDeleted] = React.useState(null);
  const [deleteModalState, setDeleteModalState] = React.useState(false);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const EnhancedTableHead = (props) => {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    const customColumnStyle = { width: "10%" };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"left"}
              sortDirection={orderBy === headCell.id ? order : false}
              style={customColumnStyle}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleOrganizationNameChange = (event) => {
    event.preventDefault();
    setOrganizationName(event.target.value);
  };

  const handleRatingDateChange = (date) => {
    setRatingDate(date);
  };

  const getMappedRatings = () => {
    if (organizationName === null) {
      return [];
    } else {
      return ratingOrganizationMappings
        .filter(
          (ratingOrganizationMapping) =>
            ratingOrganizationMapping.organization.uuid === organizationName
        )
        .map((ratingOrganizationMapping) => ratingOrganizationMapping.rating);
    }
  };

  const handleRatingChange = (event) => {
    event.preventDefault();
    setRating(event.target.value);
  };

  const renderRatingDropdown = (row, index) => {
    let ratings = getMappedRatings();

    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "400px" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">Rating</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name=""
            value={row.rating.uuid}
            onChange={(event) => handleRatingChange(event)}
            label="Rating"
          >
            {ratings &&
              ratings.length > 0 &&
              ratings.map((rating, index) => {
                return (
                  <MenuItem value={rating.uuid} key={index}>
                    {rating.rating}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderOrganizationNameDropdown = (row, index) => {
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "400px" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Organization Name
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="organizationName"
            value={row.organization.uuid}
            onChange={(event) => handleOrganizationNameChange(event)}
            label="Organization Name"
          >
            {organizationNames &&
              organizationNames.length > 0 &&
              organizationNames.map((organizationName, index) => {
                return (
                  <MenuItem value={organizationName.uuid} key={index}>
                    {organizationName.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderReadOnlyRating = (row, index) => {
    return (
      <TextField
        id="outlined-read-only-input"
        style={{ width: "400px" }}
        label=""
        value={row.rating.rating}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
    );
  };

  const renderReadOnlyOrganizationName = (row, index) => {
    return (
      <TextField
        id="outlined-read-only-input"
        style={{ width: "400px" }}
        label=""
        value={row.organization.name}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
    );
  };

  const renderRatingDate = (row, index) => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid item justify="space-around" xs={12}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label=""
            format="dd/MM/yyyy"
            value={ratingDate}
            onChange={handleRatingDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    );
  };

  const renderReadOnlyRatingDate = (row, index) => {
    return (
      <TextField
        id="outlined-read-only-input"
        style={{ width: "400px" }}
        label=""
        value={row.ratingDate}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
    );
  };

  const handleDeleteIconOnClick = (event, row) => {
    event.preventDefault();
    setToBeDeleted(row);
    setDeleteModalState(true);
  };

  const renderDeleteIcon = (row, labelId) => {
    return (
      <DeleteIcon
        onClick={(event) => handleDeleteIconOnClick(event, row)}
        color="primary"
        fontSize="medium"
        style={{
          float: "right",
          "margin-top": "29px",
          "margin-right": "30px",
          cursor: "pointer",
        }}
      />
    );
  };

  const addNewRow = (event) => {
    event.preventDefault();
    setOrganizationName("");
    setRating("");
    setRatingDate(new Date());
    props.onAddNewRow(event, {
      organizationNameUUID: organizationName,
      ratingUUID: rating,
      ratingDate: ratingDate,
    });
  };

  const renderAddIcon = () => {
    return (
      <AddCircleIcon
        onClick={(event) => addNewRow(event)}
        color="primary"
        fontSize="medium"
        style={{
          float: "right",
          "margin-top": "29px",
          "margin-right": "30px",
          cursor: "pointer",
        }}
      />
    );
  };

  const renderTable = () => {
    return (
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"small"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={ratingOrganizationMappings.length}
          />
          <TableBody>
            {stableSort(records, getComparator(order, orderBy)).map(
              (row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                let successCondition =
                  row.uuid === "" &&
                  row.rating === "" &&
                  row.organization === "";

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                  >
                    <TableCell id={labelId} scope="row" padding="none">
                      {!successCondition
                        ? renderReadOnlyOrganizationName(row, index)
                        : renderOrganizationNameDropdown(row, index)}
                    </TableCell>
                    <TableCell id={labelId} scope="row" padding="none">
                      {!successCondition
                        ? renderReadOnlyRating(row, index)
                        : renderRatingDropdown(row, index)}
                    </TableCell>

                    <TableCell id={labelId} scope="row" padding="none">
                      {!successCondition
                        ? renderReadOnlyRatingDate(row, index)
                        : renderRatingDate(row, index)}
                    </TableCell>

                    <TableCell id={labelId} scope="row" padding="none">
                      {!successCondition
                        ? renderDeleteIcon(row, labelId)
                        : renderAddIcon()}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const closeDeleteModal = (event) => {
    event.preventDefault();
    setDeleteModalState(false);
  };

  const handleDeleteOnClick = (event) => {
    event.preventDefault();
    let uuid = toBeDeleted.uuid;
    setToBeDeleted(null);
    setDeleteModalState(false);
    props.onDeleteRatingOrgMasterRelationship(event, uuid);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div style={{ fontSize: "20px", float: "left" }}>
          Rating Organization Master *
        </div>
        {renderTable()}
      </Paper>
      <DeleteModal
        primaryText="Delete Rating Organization Master Relationship"
        secondaryText="Are you sure you want to delete this Rating Organization Master Relationship ?"
        isOpen={deleteModalState}
        handleClose={closeDeleteModal}
        confirmDelete={(event) => handleDeleteOnClick(event)}
      />
    </div>
  );
};

export default EditRatingOrganizationMasterTable;
