import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Button, TextField } from "@material-ui/core";
import GenericDialog from "../Dialog/GenericDialog";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DeleteIcon from "@material-ui/icons/Delete";
import { IPORatingOrganizationMaster } from "../../pages/IPO/Interfaces";
import { makeDELETERequest, makePOSTRequest } from "../../Api/api";
import { getAllRatingsAndOrganizationNames } from "../../Api/api";
import { Organization, Rating } from "./interfaces";
import Autocomplete from "@material-ui/lab/Autocomplete";

const toastr = require("toastr");
const moment = require("moment");

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function IPORatingOrganizationMasterTable(
  props: any
): React.ReactElement {
  const [
    showAddRatingOrganizationMasterModal,
    setShowRatingOrganizationMasterModal,
  ] = React.useState<boolean>(false);
  const [currentRatingOrganizationMaster, setCurrentRatingOrganizationMaster] =
    React.useState<IPORatingOrganizationMaster | null>(null);
  const [organizations, setOrganizations] = React.useState<Organization[]>([]);
  const [ratings, setRatings] = React.useState<Rating[]>([]);
  const [selectedOrg, setSelectedOrg] = React.useState<Organization | null>(
    null
  );
  const [selectedRating, setSelectedRating] = React.useState<Rating | null>(
    null
  );

  function handleAddRatingOrganizationMaster(event: any): void {
    event.preventDefault();
    setShowRatingOrganizationMasterModal(!showAddRatingOrganizationMasterModal);
  }

  React.useEffect(() => {
    getAllRatingsAndOrganizationNames()
      .then((response) => {
        if (response && response.status === 200) {
          setOrganizations(
            response.data.map((record: any) => record.organization)
          );
          setRatings(response.data.map((record: any) => record.rating));
        }
      })
      .catch((error) =>
        toastr.error("Ratings and Organization Names  => " + error)
      );
  }, []);

  function generateInputProps(): React.ReactElement {
    return (
      <>
        <Grid item xs={12}>
          <Autocomplete
            id="organizations"
            freeSolo={true}
            value={selectedOrg}
            options={organizations}
            getOptionLabel={(option) => option.name}
            onChange={(e, selectedValue: Organization | null) => {
              setSelectedOrg(selectedValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Organization Name"
                margin="normal"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <Autocomplete
            id="ratings"
            freeSolo={true}
            value={selectedRating}
            options={ratings}
            getOptionLabel={(option) => option.rating}
            onChange={(e, selectedValue: Rating | null) => {
              setSelectedRating(selectedValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Rating"
                margin="normal"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item justify="space-around" xs={8}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Rating Date"
                format="dd/MM/yyyy"
                value={currentRatingOrganizationMaster?.ratingDate}
                onChange={(date: any) => {
                  setCurrentRatingOrganizationMaster((previousValues: any) => ({
                    ...previousValues,
                    date: moment(date).format("DD/MM/YYYY"),
                  }));
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </>
    );
  }

  function convertDateToYYYYMMDD(dateString: any) {
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return dateString;
    }

    const parts = dateString.split("/");
    const day = parts[0].padStart(2, "0");
    const month = parts[1].padStart(2, "0");
    const year = parts[2];
    return `${year}-${month}-${day}`;
  }

  function createIPORatingOrganizationMaster(event: React.MouseEvent): void {
    makePOSTRequest("/ipo/rating_organization_master/create/", {
      organization_name: selectedOrg?.name,
      rating: selectedRating?.rating,
      rating_date: convertDateToYYYYMMDD(currentRatingOrganizationMaster?.date),
      ipo_security_master_id: props.ipoSecurityMasterId,
    })
      .then((response: any) => {
        let currentList = props.rows;
        currentList.push(response.data);
        toastr.success("Created IPO Rating Organization Master successfully!");
        setShowRatingOrganizationMasterModal(
          !showAddRatingOrganizationMasterModal
        );
        setSelectedOrg(null);
        setSelectedRating(null);
        props.addToState(currentList);
      })
      .catch((error: any) => toastr.error(error));
  }

  function handleDeleteIconClick(row: any): void {
    makeDELETERequest(`/ipo/rating_organization_master/delete/${row.uuid}/`)
      .then((response: any) => {
        toastr.success("Rating Organization Master deleted successfully!");
        props.removeFromState(row?.uuid);
      })
      .catch((error: any) => toastr.error(error));
  }

  return (
    <>
      <Grid className="holidayMastersDataGrid">
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>
                Rating Organization Master
              </div>
            </Grid>
            <Grid item xs="auto">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleAddRatingOrganizationMaster}
              >
                Add Rating Organization Master
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Organization Name </StyledTableCell>
              <StyledTableCell align="right">Rating</StyledTableCell>
              <StyledTableCell align="right">Rating Date</StyledTableCell>
              <StyledTableCell align="right">Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row: any) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.organizationName}
                </StyledTableCell>
                <StyledTableCell align="right">{row.rating}</StyledTableCell>
                <StyledTableCell align="right">
                  {moment(row.date).format("MMMM Do YYYY")}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {
                    <DeleteIcon
                      onClick={(
                        event: React.MouseEvent<SVGSVGElement, MouseEvent>
                      ) => {
                        event.preventDefault();
                        handleDeleteIconClick(row);
                      }}
                      color="primary"
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  }
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showAddRatingOrganizationMasterModal ? (
        <GenericDialog
          show={showAddRatingOrganizationMasterModal}
          heading="Create IPO Rating Organization Master"
          inputProps={generateInputProps()}
          onClose={(event: React.MouseEvent) => {
            event.preventDefault();
            setShowRatingOrganizationMasterModal(
              !showAddRatingOrganizationMasterModal
            );
          }}
          handleSubmit={createIPORatingOrganizationMaster}
          submitButtonText="Create"
          cancelButtonText="Discard"
        />
      ) : (
        ""
      )}
    </>
  );
}
