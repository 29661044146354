import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value: number): string {
  return value.toString();
}

export default function GenericSlider(props: any): React.ReactElement {
  return (
    <Box sx={{ width: 300 }}>
      <Slider
        aria-label="GenericSlider"
        defaultValue={props.defaultValue}
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        step={1}
        marks={props.marks}
        min={1}
        max={5}
        onChange={(
          event: Event,
          value: number | Array<number>,
          activeThumb: number
        ) => {
          if (event) props.onChange(value);
        }}
      />
    </Box>
  );
}
