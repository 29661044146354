import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import PropTypes from "prop-types";
import toastr from "toastr";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { appContext } from "../App";
import AddIssuerTypeModal from "./AddIssuerTypeModal";
import { deleteIssuerTypeByUUID } from "../Api/api";
import DeleteModal from "./DeleteModal";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "id", numeric: true, disablePadding: false, label: "Serial No" },
  {
    id: "issuerType",
    numeric: false,
    disablePadding: true,
    label: "Issuer Type",
  },
  { id: "action", numeric: false, disablePadding: true, label: "Action" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "96%",
    margin: "35px auto",
    height: " 0px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddIssuerTypeDialog = (props) => {
  const classes = useStyles();
  const { show, onCloseModal, issuerTypes } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [showIssuerTypeModal, setShowIssuerTypeModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [toBeDeleted, setToBeDeleted] = React.useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleDeleteIconOnClick = (event, issuerType) => {
    setToBeDeleted(issuerType);
    setShowDeleteModal(true);
  };

  const renderDeleteIcon = (row, labelId) => {
    return (
      <TableCell id={labelId} scope="row" padding="none">
        <DeleteIcon
          onClick={(event) => handleDeleteIconOnClick(event, row)}
          color="primary"
          style={{
            cursor: "pointer",
          }}
        />
      </TableCell>
    );
  };

  const renderIssuerTypesTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={issuerTypes.length}
          />
          <TableBody>
            {stableSort(issuerTypes, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                  >
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell id={labelId} scope="row" padding="none">
                      {row.type}
                    </TableCell>
                    {renderDeleteIcon(row, labelId)}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const closeAddIssuerTypeModal = () => {
    setShowIssuerTypeModal(false);
  };

  const renderAddIssuerTypeModal = () => {
    return (
      <AddIssuerTypeModal
        openModal={showIssuerTypeModal}
        onCloseModal={closeAddIssuerTypeModal}
        issuerTypes={issuerTypes}
      />
    );
  };

  const handleAddIssuerTypeOnClick = (event) => {
    event.preventDefault();
    setShowIssuerTypeModal(true);
  };

  const renderFullScreenDialogBody = () => {
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div style={{ fontSize: "40px", float: "left" }}>
            Issuer Types Name
          </div>
          <AddCircleIcon
            onClick={(event) => handleAddIssuerTypeOnClick(event)}
            color="primary"
            fontSize="large"
            style={{
              float: "right",
              "margin-top": "29px",
              "margin-right": "30px",
              cursor: "pointer",
            }}
          />
          {renderIssuerTypesTable()}
          {renderAddIssuerTypeModal()}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={issuerTypes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setToBeDeleted(null);
  };

  const deleteIssuerType = () => {
    deleteIssuerTypeByUUID(toBeDeleted.uuid)
      .then((response) => {
        if (response && response.status === 204) {
          setToBeDeleted(null);
          setShowDeleteModal(false);
          toastr.success("Successfully deleted issuer type !");
        }
      })
      .catch((error) => toastr.error(error));
  };

  const renderDeleteModal = () => {
    return (
      <DeleteModal
        primaryText="Delete Issuer Master"
        secondaryText="Are you sure you want to delete this Issuer Master ?"
        isOpen={showDeleteModal}
        handleClose={closeDeleteModal}
        confirmDelete={deleteIssuerType}
      />
    );
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={show}
        onClose={onCloseModal}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onCloseModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Close
            </Typography>
          </Toolbar>
        </AppBar>
        {renderFullScreenDialogBody()}
        {renderDeleteModal()}
      </Dialog>
    </div>
  );
};

export default AddIssuerTypeDialog;
