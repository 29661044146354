import React, { useState, useEffect, useContext } from "react";
import toastr from "toastr";
import { TextField, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "184px",
  },
}));

export default function EditOrderModal(props) {
  const classes = useStyles();
  const { order } = props;
  const initialState = {
    noOfUnits: order.noOfUnits,
    settlementType: order.settlementType,
    purchasePrice: order.purchasePrice,
    spread: order.spread,
  };
  const [currentState, setCurrentState] = React.useState(initialState);

  const submitHandler = (event) => {
    if (
      currentState.noOfUnits === "" ||
      currentState.settlementType === "" ||
      currentState.purchasePrice === "" ||
      currentState.spread === ""
    ) {
      toastr.error("All fields are mandatory");
    } else {
      let state = currentState;
      setCurrentState({
        noOfUnits: "",
        settlementType: "",
        purchasePrice: "",
        spread: "",
      });
      props.editOrderDetails(event, state);
    }
  };

  const resetStateAndClose = (event) => {
    setCurrentState({
      noOfUnits: "",
      settlementType: "",
      purchasePrice: "",
      spread: "",
    });
    props.closeModal(event);
  };

  const handleChange = (event) => {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const renderSettlementAmount = () => {
    let settlementAmountOptions = ["T+0", "T+1"];
    return (
      <Grid item xs={12} style={{ "margin-top": "10px" }}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Purchase Date T+0/T+1
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="settlementType"
            value={currentState.settlementType}
            onChange={handleChange}
            label="T+0/T+1"
          >
            {settlementAmountOptions &&
              settlementAmountOptions.length > 0 &&
              settlementAmountOptions.map((settlementAmountOption, index) => {
                return (
                  <MenuItem value={settlementAmountOption} key={index}>
                    {settlementAmountOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={resetStateAndClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit Order</DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            <Grid item xs={12}>
              <TextField
                type="text"
                name="noOfUnits"
                variant="outlined"
                fullWidth
                label="No of Unit"
                defaultValue={currentState.noOfUnits}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <TextField
                type="text"
                name="purchasePrice"
                variant="outlined"
                fullWidth
                label="Purchase Price"
                defaultValue={currentState.purchasePrice}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <TextField
                type="text"
                name="spread"
                variant="outlined"
                fullWidth
                label="Spread"
                defaultValue={currentState.spread}
                onChange={handleChange}
              />
            </Grid>

            {renderSettlementAmount()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetStateAndClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={submitHandler}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
