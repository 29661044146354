import React from "react";
import { Grid, Button, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeGETRequestOnUserService } from "../../Api/api";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { Select } from "@material-ui/core";
import toastr from "toastr";
import HtmlToolTip from "../ToolTip/HtmlToolTip";
import ChipsArrayComponent from "../Chip/Chip";
import CircularProgress from "@mui/material/CircularProgress";
export default function SendWhatsappMessageDialog(props) {
  const [message, setMessage] = React.useState("");
  const [uploadedMedia, setUploadedMedia] = React.useState(null);
  const [categoryName, setCategoryName] = React.useState("");
  const [templates, setTemplates] = React.useState([]);
  const [currentTemplate, setCurrentTemplate] = React.useState(null);
  const [chipData, setChipData] = React.useState([]);

  React.useEffect(() => {
    makeGETRequestOnUserService("/whatsapp_campaign/templates/list/")
      .then((response) => {
        if (response?.status === 200) {
          setTemplates(response.data);
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  function handleOnConfirm(event) {
    event.preventDefault();
    let currentMessage = message;
    let currentUploadedMedia = uploadedMedia;
    let enteredCategoryName = categoryName;
    let selectedTemplateUUID = currentTemplate.uuid;
    let variableValues = chipData;
    setChipData([]);
    setMessage("");
    setUploadedMedia(null);
    setCategoryName("");
    setCurrentTemplate(null);
    props.handleSubmit(
      event,
      currentMessage,
      currentUploadedMedia,
      enteredCategoryName,
      selectedTemplateUUID,
      variableValues
    );
  }

  function handleOnEnter(text) {}

  function addValueToChip(event, newValue) {
    if (chipData.length < currentTemplate?.variableCount) {
      setChipData([...chipData, { key: chipData.length, label: newValue }]);
    } else {
      toastr.warning(
        `You can only add atmost ${currentTemplate?.variableCount} dynamic values.`
      );
    }
  }

  function deleteChip(chipToDelete) {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      className="profileEditDialogBox"
    >
      <DialogTitle id="form-dialog-title">Send Message</DialogTitle>
      <DialogContent>
        <HtmlToolTip
          title={
            <React.Fragment>
              <em>
                <b>Message Text </b> : {currentTemplate?.templateText || "--"}
              </em>{" "}
            </React.Fragment>
          }
        >
          <Grid className="selectRelatShip">
            {templates.length > 0 && (
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Templates
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="templateName"
                  value={currentTemplate?.templateName}
                  onChange={(event) => {
                    let filteredTemplate = templates.filter(
                      (template) => template.templateName === event.target.value
                    )[0];
                    setCurrentTemplate(filteredTemplate);
                  }}
                  label="Template Name"
                >
                  {templates &&
                    templates.length > 0 &&
                    templates.map((template, index) => {
                      return (
                        <MenuItem
                          value={template.templateName}
                          key={template.uuid}
                        >
                          {template.templateName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            )}
          </Grid>
        </HtmlToolTip>

        {currentTemplate ? (
          <ChipsArrayComponent
            chipData={chipData}
            handleDelete={deleteChip}
            addValueToChip={addValueToChip}
          />
        ) : (
          ""
        )}
        <br />
        <br />

        <Grid className="selectRelatShip">
          <input
            type="file"
            name="file"
            onChange={(event) => {
              event.preventDefault();
              setUploadedMedia(event.target.files[0]);
            }}
          />
        </Grid>

        {props.showCategoryNameInput && (
          <Grid className="">
            <TextField
              style={{ left: "13px" }}
              id="standard-basic"
              label="Category Name "
              variant="standard"
              defaultValue={categoryName}
              onChange={(event) => {
                event.preventDefault();
                setCategoryName(event.target.value);
              }}
              size="small"
            />
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className="requestUpdateBtn"
          color="primary"
          onClick={handleOnConfirm}
        >
          {props.showModalLoader ? <CircularProgress /> : "Send"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
