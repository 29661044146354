import React, { useState, useContext, useEffect } from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "400px",
  },
}));

export default function EditPortfolio(props) {
  const classes = useStyles();
  const [currentValue, setCurrentValue] = React.useState(props.currentValue);

  const handleCurrentValueChange = (event) => {
    event.preventDefault();
    setCurrentValue(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    let updatedState = currentValue;
    setCurrentValue("");
    props.updateCurrentValue(event, updatedState);
  };

  return (
    <Dialog
      open={props.openModal}
      onClose={props.onCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Update Portfolio</DialogTitle>
      <DialogContent style={{ width: "500px" }}>
        <DialogContentText id="alert-dialog-description">
          <TextField
            id="outlined-multiline-flexible"
            label="Current Value"
            multiline
            rowsMax={4}
            className={classes.textField}
            value={currentValue}
            onChange={handleCurrentValueChange}
            variant="outlined"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCloseModal}>Cancel</Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
