import React from "react";
import GenericDialog from "../Dialog/GenericDialog";
import { TextField, Grid } from "@material-ui/core";
import { makePOSTRequest } from "../../Api/api";

const toastr = require("toastr");
export default function IPOChangePassword(props: any) {
  const [password, setPassword] = React.useState<string>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");

  return (
    <GenericDialog
      show={props.show}
      heading="Change IPO Password"
      inputProps={
        <Grid container item xs={12} spacing={1}>
          <React.Fragment>
            <Grid item xs={6}>
              <TextField
                type="text"
                name="password"
                variant="outlined"
                fullWidth
                label="New Password"
                defaultValue={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="password"
                name="confirmPassword"
                variant="outlined"
                fullWidth
                label="Confirm Password"
                defaultValue={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
            </Grid>
          </React.Fragment>
        </Grid>
      }
      onClose={props.handleClose}
      handleSubmit={(event: any) => {
        event.preventDefault();
        if (password !== confirmPassword) {
          toastr.error(
            "Password and Confirm Password fields needs to have the same value."
          );
          return;
        } else {
          makePOSTRequest("/ipo/changepwd_ipo/", {
            newpwd: password,
            confirmpwd: confirmPassword,
          })
            .then((resp: any) => {
              if (resp.status < 300) {
                if ("errorcode" in resp.data && resp.data.errorcode === "0") {
                  toastr.success("Password Changed Successfully!");
                } else {
                  toastr.error(resp.data.message);
                }
              } else {
                toastr.error("Unable to change password. Please try again!");
              }
            })
            .catch((error: any) => toastr.error(error));
        }
      }}
    />
  );
}
