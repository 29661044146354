import React, { useEffect } from "react";
import {
  makeDELETERequest,
  makeGETRequest,
  makePOSTRequest,
  makePUTRequest,
} from "../../Api/api";
import { TextField, Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import AudioPlayer from "react-modular-audio-player";
import audioPlayIcon from "../../images/audioPlayIcon.svg";
import prevImg from "../../images/prev.svg";
import nextImg from "../../images/next.svg";
import toastr from "toastr";
import { testAudio } from "./testAudio";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BulkUploadModal from "../../components/BulkUploadModal";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteModal from "../../components/DeleteModal";
import EditIcon from "@material-ui/icons/Edit";
import EditFileModal from "./EditFileModal";
import IdleTimoutConfig from "../../Helpers/IdleTimeoutConfig";

function getFormattedAudioFiles(resp) {
  let formatted = [];
  resp.map((r) => {
    formatted.push({
      src: "data:audio/mp3;base64," + r.file,
      artist: "SMEST",
      author: r.guestName,
      title: r.title,
      podcastTitle: r.title,
      guestName: r.guestName,
      uuid: r.uuid,
    });
  });
  return formatted;
}

function getFilteredAudioFiles(audioFiles, toBeDeleted) {
  return audioFiles.filter((audioFile) => audioFile.uuid !== toBeDeleted);
}

export default function PodcastMaster() {
  const [podcasts, setPodcasts] = React.useState([]);
  const [audioFiles, setAudioFiles] = React.useState([testAudio]);
  const [showFileUploadModal, setShowFileUploadModal] = React.useState(false);
  const [toBeDeleted, setToBeDeleted] = React.useState(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [guestName, setGuestName] = React.useState("");
  const [toBeEdited, setToBeEdited] = React.useState(null);
  const [showEditFileModal, setShowEditFileModal] = React.useState(false);

  useEffect(() => {
    let requestURL = "/admin/podcast_master/all/";
    makeGETRequest(requestURL)
      .then((response) => {
        if (response && response.data) {
          setPodcasts(response.data);
          setAudioFiles(getFormattedAudioFiles(response.data));
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  function handleAddIconOnClick(event) {
    event.preventDefault();
    setShowFileUploadModal(true);
  }

  function handleBulkUploadOnClose(event) {
    event.preventDefault();
    setGuestName("");
    setTitle("");
    setShowFileUploadModal(false);
  }

  function handleSubmitForUpload(event, file) {
    event.preventDefault();
    let requestURL = "/admin/podcast_master/add/";
    let formData = new FormData();
    formData.append("file", file);
    formData.append("title", title);
    formData.append("guestName", guestName);

    makePOSTRequest(requestURL, formData)
      .then((response) => {
        if (response && response.status === 201) {
          toastr.success("Uploaded podcast successfully !");
          setShowFileUploadModal(false);
          setGuestName("");
          setTitle("");
          setAudioFiles(getFormattedAudioFiles(response.data));
        }
      })
      .catch((error) => toastr.error(error));
  }

  function handleOnEditClick(event, file) {
    event.preventDefault();
    setToBeEdited(file);
    setTitle(file.podcastTitle);
    setGuestName(file.guestName);
    setShowEditFileModal(true);
  }

  function handleOnDeleteClick(event, file) {
    event.preventDefault();
    setToBeDeleted(file.uuid);
    setShowDeleteModal(true);
  }

  function deletePodcastMaster(event) {
    event.preventDefault();
    let requestURL = `/admin/podcast_master/delete/${toBeDeleted}/`;
    makeDELETERequest(requestURL)
      .then((response) => {
        if (response) {
          toastr.success("Deleted Podcast !");
          setShowDeleteModal(false);
          setAudioFiles(getFilteredAudioFiles(audioFiles, toBeDeleted));
          setToBeDeleted(null);
        }
      })
      .catch((error) => toastr.error(error));
  }

  function closeDeleteModal(event) {
    event.preventDefault();
    setToBeDeleted(null);
    setShowDeleteModal(false);
  }

  function handleEditFileModalClose(event) {
    event.preventDefault();
    setToBeEdited(null);
    setShowEditFileModal(false);
  }

  function handleFileUpdate(event) {
    event.preventDefault();
    let requestURL = `/admin/podcast_master/update/${toBeEdited.uuid}/`;

    makePUTRequest(requestURL, {
      title: title,
      guestName: guestName,
    })
      .then((resp) => {
        if (resp && resp.status === 200) {
          setAudioFiles(getFormattedAudioFiles(resp.data));
          setPodcasts(resp.data);
          setToBeEdited(null);
          setTitle("");
          setGuestName("");
          setShowEditFileModal(false);
          toastr.success("Updated Podcast Master !");
        }
      })
      .catch((error) => toastr.error(error));
  }

  function returnAdditionalPropsForInput() {
    return (
      <>
        <TextField
          id="outlined-read-only-input"
          style={{ width: "400px", margin: "20px", justifyContent: "center" }}
          label="Title"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          variant="outlined"
        />
        <TextField
          id="outlined-read-only-input"
          style={{ width: "400px", margin: "20px", justifyContent: "center" }}
          label="Guest Name"
          value={guestName}
          onChange={(event) => setGuestName(event.target.value)}
          variant="outlined"
        />
      </>
    );
  }

  return (
    <Grid className="QuotesDataGrid">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid item xs>
            <div style={{ fontSize: "36px", float: "left" }}>Podcasts</div>
          </Grid>
          <Grid item xs="auto">
            <AddCircleIcon
              color="primary"
              fontSize="large"
              onClick={handleAddIconOnClick}
              style={{
                float: "right",
                "margin-top": "29px",
                "margin-right": "30px",
                cursor: "pointer",
              }}
            />
          </Grid>
          <Grid item md={12} sm={12}>
            <Grid container spacing={2}>
              {audioFiles.map((file) => (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <div className="controlAudioplayer">
                      <div className="contantAudioplayer">
                        <h6>{file.title}</h6>
                        <p>{file.author}</p>
                      </div>

                      <div className="audioPlayerViewBox">
                        <AudioPlayer
                          audioFiles={[file]}
                          playIcon={audioPlayIcon}
                          playHoverIcon={audioPlayIcon}
                          rewindIcon={prevImg}
                          rewindHoverIcon={prevImg}
                          forwardIcon={nextImg}
                          forwardHoverIcon={nextImg}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <DeleteIcon
                      onClick={(event) => handleOnDeleteClick(event, file)}
                      color="primary"
                      fontSize="medium"
                      style={{
                        float: "left",
                        "margin-top": "30px",
                        "margin-left": "14px",
                        cursor: "pointer",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <EditIcon
                      onClick={(event) => handleOnEditClick(event, file)}
                      color="primary"
                      fontSize="medium"
                      style={{
                        float: "left",
                        "margin-top": "30px",
                        "margin-left": "10px",
                        cursor: "pointer",
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {showFileUploadModal ? (
        <BulkUploadModal
          show={showFileUploadModal}
          onCloseModal={handleBulkUploadOnClose}
          submitHandler={handleSubmitForUpload}
          heading="Upload Podcast"
          inputProps={returnAdditionalPropsForInput()}
        />
      ) : (
        ""
      )}
      {showDeleteModal ? (
        <DeleteModal
          primaryText="Delete Podcast"
          secondaryText="Are you sure you want to delete this podcast ?"
          isOpen={showDeleteModal}
          handleClose={closeDeleteModal}
          confirmDelete={deletePodcastMaster}
        />
      ) : (
        ""
      )}
      {showEditFileModal ? (
        <EditFileModal
          open={showEditFileModal}
          handleClose={handleEditFileModalClose}
          inputProps={returnAdditionalPropsForInput()}
          handleUpdate={handleFileUpdate}
        />
      ) : (
        ""
      )}
      {/* <IdleTimoutConfig> </IdleTimoutConfig> */}
    </Grid>
  );
}
