import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Close from "@material-ui/icons/Close";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Fade,
  Backdrop,
  Modal,
  Avatar,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import toastr from "toastr";
import { appContext } from "../App";
import { makeStyles } from "@material-ui/core/styles";
import { createSecurityType } from "../Api/api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "400px",
  },
}));

const AddSecurityTypeModal = (props) => {
  const classes = useStyles();
  const { authUser, setAuthUser } = useContext(appContext);
  const [securityType, setSecurityType] = React.useState("");

  useEffect(() => {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();
    if (authUser && authUser.uuid) {
      createSecurityType({ type: securityType })
        .then((response) => {
          if (response && response.status === 201) {
            setSecurityType("");
            toastr.success("Successfully created Security Type");
            props.onCloseModal(event);
          }
        })
        .catch((error) => toastr.error(error));
    }
  };

  const handleTypeChange = (event) => {
    event.persist();
    setSecurityType(event.target.value);
  };

  const handleCloseModal = (event) => {
    event.preventDefault();
    setSecurityType("");
    props.onCloseModal(event);
  };

  return (
    <Dialog
      open={props.openModal}
      onClose={(event) => handleCloseModal(event)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Add Security Type</DialogTitle>
      <DialogContent style={{ width: "500px" }}>
        <DialogContentText id="alert-dialog-description">
          <TextField
            id="outlined-multiline-flexible"
            label="Security Type"
            multiline
            rowsMax={4}
            className={classes.textField}
            value={securityType}
            onChange={handleTypeChange}
            variant="outlined"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(event) => handleCloseModal(event)}>Cancel</Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSecurityTypeModal;
