import React from "react";
import { Grid, Button, TextField } from "@material-ui/core";
import GenericTable from "../GenericTable/GenericTable";
import DeleteIcon from "@material-ui/icons/Delete";
import { Pro } from "./Interfaces";
import GenericDialog from "../Dialog/GenericDialog";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export default function ProComponent(props: any): React.ReactElement {
  const [showAddProModal, setShowAddProModal] = React.useState<boolean>(false);
  const currentProInitialState = {
    heading: "",
    text: "",
  };
  const [currentPro, setCurrentPro] = React.useState<Pro>(
    currentProInitialState
  );

  function getFormattedRowsWithJSX(pros: Pro[]): any[] {
    let formatted: any[] = [];
    pros.map((pro: Pro, index: number) => {
      formatted.push({
        serial: index,
        heading: pro.heading,
        prodelete: (
          <DeleteIcon
            onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
              event.preventDefault();
              props.deletePro(pro.serial);
            }}
            color="primary"
            style={{
              cursor: "pointer",
            }}
          />
        ),
      });
    });
    return formatted;
  }

  return (
    <>
      <Grid className="holidayMastersDataGrid">
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>Pros</div>
            </Grid>
            <Grid item xs="auto">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  event.preventDefault();
                  setShowAddProModal(!showAddProModal);
                }}
              >
                Add Pro
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <GenericTable
        columnNames={["Heading", "ProDelete"]} // Make sure to arrange the column names in alphabetical order.
        rows={getFormattedRowsWithJSX(props.pros)}
      />

      {showAddProModal ? (
        <GenericDialog
          show={showAddProModal}
          heading="Add Pro"
          inputProps={
            <>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  name="pro"
                  variant="outlined"
                  fullWidth
                  label="Pro Heading"
                  defaultValue={currentPro.heading}
                  onChange={(event: any) => {
                    event.persist();
                    let currentState = currentPro;
                    currentState.heading = event.target.value;
                    setCurrentPro(currentState);
                  }}
                />
              </Grid>
              <br />
              <Grid item xs={12}>
                <TextareaAutosize
                  className={props.class}
                  id="filled-basic"
                  rowsMin={13}
                  onChange={(event: any) => {
                    event.persist();
                    let currentState = {
                      ...currentPro,
                      text: event.target.value,
                    };
                    setCurrentPro(currentState);
                  }}
                  value={currentPro.text}
                />
              </Grid>
            </>
          }
          onClose={(event: any) => {
            setShowAddProModal(!showAddProModal);
          }}
          handleSubmit={() => {
            props.addNewPro(currentPro);
            setCurrentPro(currentProInitialState);
            setShowAddProModal(!showAddProModal);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
