const dataGridCells = [
  { field: "id", headerName: "Serial", width: 350, editable: false },
  {
    field: "customerName",
    headerName: "Customer Name",
    width: 350,
    editable: false,
  },
  { field: "smestID", headerName: "Smest ID", width: 350, editable: false },
  { field: "orderNo", headerName: "Order Number", width: 350, editable: false },
  { field: "date", headerName: "Date", width: 350, editable: false },
  { field: "details", headerName: "Details", width: 350, editable: false },
  {
    field: "loyaltyPoints",
    headerName: "Loyalty Points",
    width: 350,
    editable: false,
  },
  {
    field: "transactionType",
    headerName: "Transaction Type",
    width: 350,
    editable: false,
  },
  {
    field: "nameOfReferredFriend",
    headerName: "Name Of Referred Friend",
    width: 350,
    editable: false,
  },
  {
    field: "securityName",
    headerName: "Security Name",
    width: 350,
    editable: false,
  },
];

export default dataGridCells;
