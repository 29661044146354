import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar, GridColDef } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { makeGETRequestOnUserService } from "../../Api/api";
const toastr = require("toastr");

interface ReferredUser {
  created_at: string;
  kyc_status: string;
  name: string;
  email: string;
  mobile: string;
  lead_owner: string;
  lead_status: string;
  smest_id: string;
}

const columns: GridColDef[] = [
  { field: "created_at", headerName: "Created At", width: 180 },
  { field: "kyc_status", headerName: "KYC Status", width: 150 },
  { field: "name", headerName: "Name", width: 200 },
  { field: "email", headerName: "Email", width: 250 },
  { field: "mobile", headerName: "Mobile", width: 150 },
  { field: "lead_owner", headerName: "Lead Owner", width: 200 },
  { field: "lead_status", headerName: "Lead Status", width: 200 },
  { field: "smest_id", headerName: "SMEST ID", width: 200 },
];

const ReferralsList: React.FC = (props: any) => {
  const [referredUsers, setReferredUsers] = useState<ReferredUser[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    makeGETRequestOnUserService(
      `/user/referrals/list/${props.currentUser.uuid}/`
    )
      .then((response) => {
        if (response.status === 200) {
          setReferredUsers(response.data);
          setLoading(false);
        } else {
          toastr.error(response?.data || "Something went wrong");
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  const addSerialNumber = (data: ReferredUser[]) => {
    return data.map((row, index) => ({ ...row, id: index + 1 }));
  };

  const handleCopyClick = () => {
    const baseUrl =
      process.env.REACT_APP_ENVIRONMENT === "prod"
        ? "https://smest.in"
        : "https://smest-new.vercel.app";
    const referralUrl = `${baseUrl}/auth/signup?referral_id=${props.currentUser.smestID}`;

    navigator.clipboard.writeText(referralUrl).then(
      () => toastr.success("SMEST ID copied to clipboard!"),
      (err) => toastr.error("Failed to copy!")
    );
  };

  return (
    <div style={{ height: 600, width: "100%" }}>
      <div style={{ marginBottom: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ContentCopyIcon />}
          onClick={handleCopyClick}
        >
          Copy SMEST ID
        </Button>
      </div>
      <DataGrid
        pagination
        className="referralsDataGrid"
        components={{ Toolbar: GridToolbar }}
        rows={addSerialNumber(referredUsers)}
        columns={columns}
        loading={loading}
      />
    </div>
  );
};

export default ReferralsList;
