import React, { useEffect } from "react";
import {
  makeDELETERequestOnUserServiceWithQueryParameter,
  makeGETRequestOnUserService,
  makePOSTRequestOnUserService,
  makePUTRequestOnUserService,
} from "../../Api/api";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import {
  descendingComparator,
  getComparator,
  stableSort,
  ChildAccountEnhancedTableHead,
} from "../../components/Users/TableUtils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddChildAccountDialog from "./AddChildAccountDialog";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteModal from "../DeleteModal";
import UserHelpers from "./UserHelpers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "96%",
    margin: "35px auto",
    height: " 0px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const FamilyMembersAccountTab = (props) => {
  const classes = useStyles();
  const { currentUser } = props;
  const [childAccounts, setChildAccounts] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [showAddChildAccount, setShowAddChildAccount] = React.useState(false);
  const [toBeEdited, setToBeEdited] = React.useState(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [toBeDeleted, setToBeDeleted] = React.useState(null);

  useEffect(() => {
    let requestURL = `/child_account/list/${currentUser.uuid}/`;
    makeGETRequestOnUserService(requestURL)
      .then((response) => {
        if (response && response.status === 200) {
          setChildAccounts(response.data);
        }
      })
      .catch((error) => toastr.error(error));
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOnClickChildAccountAdd = (event) => {
    event.preventDefault();
    setShowAddChildAccount(true);
  };

  const closeAddAccountModal = (event) => {
    event.preventDefault();
    setShowAddChildAccount(false);
    setToBeEdited(null);
  };

  const updateExistingChildAccount = (event, details) => {
    event.preventDefault();
    let requestURL = "/child_account/update/";
    details.childAccountUUID = toBeEdited.uuid;
    makePUTRequestOnUserService(requestURL, details)
      .then((response) => {
        if (response && response.status === 200) {
          setChildAccounts(response.data);
          toastr.success("Updated child account successfully !");
          setShowAddChildAccount(false);
          setToBeEdited(null);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const createChildAccount = (event, details) => {
    event.preventDefault();
    let requestURL = "/child_account/create/";
    details.parentAccount = currentUser.uuid;
    makePOSTRequestOnUserService(requestURL, details)
      .then((response) => {
        if (response && response.status === 201) {
          let accounts = childAccounts;
          accounts.push(response.data);
          setChildAccounts(accounts);
          setShowAddChildAccount(false);
          toastr.success("Created Child Account successfully !");
        }
      })
      .catch((error) => toastr.error(error));
  };

  const handleEditIconOnClick = (event, tobeEdited) => {
    event.preventDefault();
    setToBeEdited(tobeEdited);
    setShowAddChildAccount(true);
  };

  const handleDeleteIconOnClick = (event, deletable) => {
    event.preventDefault();
    setToBeDeleted(deletable);
    setShowDeleteModal(true);
  };

  const renderDeleteIcon = (row) => {
    return (
      <DeleteIcon
        onClick={(event) => handleDeleteIconOnClick(event, row)}
        color="primary"
        style={{
          float: "left",
          "margin-top": "10px",
          "margin-left": "14px",
          cursor: "pointer",
        }}
      />
    );
  };

  const renderEditIcon = (row) => {
    return (
      <EditIcon
        onClick={(event) => handleEditIconOnClick(event, row)}
        color="primary"
        fontSize="medium"
        style={{
          float: "left",
          "margin-top": "10px",
          "margin-left": "14px",
          cursor: "pointer",
        }}
      />
    );
  };

  const closeDeleteModal = (event) => {
    event.preventDefault();
    setShowDeleteModal(false);
    setToBeDeleted(null);
  };

  const deleteChildAccount = (event) => {
    event.preventDefault();
    let requestURL = `/child_account/delete/${toBeDeleted.uuid}/`;
    makeDELETERequestOnUserServiceWithQueryParameter(requestURL)
      .then((response) => {
        if (response && response.status === 200) {
          setChildAccounts(
            UserHelpers.removeDeletedChildAccount(
              childAccounts,
              toBeDeleted.uuid
            )
          );
          toastr.success("Deleted Child Account Successfully !");
          setShowDeleteModal(false);
          setToBeDeleted(null);
        }
      })
      .catch((error) => toastr.error(error));
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div style={{ fontSize: "36px", float: "left" }}>Family Members</div>
        <AddCircleIcon
          color="primary"
          fontSize="large"
          style={{
            float: "right",
            "margin-top": "29px",
            "margin-right": "30px",
            cursor: "pointer",
          }}
          onClick={handleOnClickChildAccountAdd}
        />
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <ChildAccountEnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={childAccounts.length}
            />
            <TableBody>
              {stableSort(childAccounts, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell align="left">{index + 1}</TableCell>

                      <TableCell id={labelId} scope="row" padding="none">
                        {row.accountType}
                      </TableCell>
                      <TableCell id={labelId} scope="row" padding="none">
                        {row.firstName}
                      </TableCell>
                      <TableCell id={labelId} scope="row" padding="none">
                        {row.lastName}
                      </TableCell>
                      <TableCell id={labelId} scope="row" padding="none">
                        {row.mobileNumber}
                      </TableCell>
                      <TableCell id={labelId} scope="row" padding="none">
                        {row.gender}
                      </TableCell>
                      <TableCell id={labelId} scope="row" padding="none">
                        {renderEditIcon(row)}
                      </TableCell>
                      <TableCell id={labelId} scope="row" padding="none">
                        {renderDeleteIcon(row)}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={childAccounts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <AddChildAccountDialog
        show={showAddChildAccount}
        closeModal={closeAddAccountModal}
        createChildAccount={createChildAccount}
        currentUser={currentUser}
        toBeEdited={toBeEdited}
        updateExistingChildAccount={updateExistingChildAccount}
      />
      <DeleteModal
        primaryText="Delete Child Account"
        secondaryText="Are you sure you want to delete this Child Account ?"
        isOpen={showDeleteModal}
        handleClose={closeDeleteModal}
        confirmDelete={deleteChildAccount}
      />
    </div>
  );
};
export default FamilyMembersAccountTab;
