import React from "react";
import { Grid } from "@material-ui/core";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { makeGETRequestOnUserService } from "../../Api/api";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import HtmlToolTip from "../../components/ToolTip/HtmlToolTip";
import IdleTimoutConfig from "../../Helpers/IdleTimeoutConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "90vw",
    position: "relative",
    margin: "20px",
    color: "textSecondary",
  },
  root2: {
    flexGrow: 1,
    width: "90vw",
    position: "relative",
    color: "textSecondary",
  },
  inline: {
    display: "inline",
  },
  rounded: {
    borderRadius: "10px",
    width: "90vw",
    margin: "50px",
  },
}));

export default function AllWhatsappMessages(props: any) {
  const toastr: any = require("toastr");
  const classes: any = useStyles();
  const [allMessages, setAllMessages] = React.useState<any[]>([]);
  const [hasLoaded, setHasLoaded] = React.useState(false);

  React.useEffect(() => {
    makeGETRequestOnUserService("/whatsapp_campaign/list/messages/")
      .then((response) => {
        if (response && response.status === 200) {
          setAllMessages(response.data);
          setHasLoaded(true);
        }
      })
      .catch((error: any) => {
        toastr.error(error);
      });
  }, []);

  if (hasLoaded) {
    return (
      <Grid className="QuotesDataGrid">
        <Grid className="referAndEaenSec">
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Grid className="referAndEaenBic pointsHistory">
                    <Grid container className="pointsHistoryHead">
                      <Grid item xs>
                        <Typography variant="h5" noWrap>
                          Sent Messages
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid className="">
                      <Grid className="gridComTabel tableHade">
                        <Grid container>
                          <Grid item xs md={1}>
                            <Typography variant="h5" noWrap>
                              Sr. No.
                            </Typography>
                          </Grid>
                          <Grid item xs md={5}>
                            <Typography variant="h5" noWrap>
                              Message
                            </Typography>
                          </Grid>
                          <Grid item xs={3} md={2}>
                            <Typography variant="h5" noWrap>
                              Sent By
                            </Typography>
                          </Grid>
                          <Grid item xs md={2}>
                            <Typography variant="h5" noWrap>
                              Sent to
                            </Typography>
                          </Grid>
                          <Grid item xs md={2}>
                            <Typography variant="h5" noWrap>
                              Sent On
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid className="recentTransactionsScroll referAndEaenTableData">
                        {allMessages.map((element, i) => (
                          <HtmlToolTip
                            title={
                              <React.Fragment>
                                <em>
                                  <b>Message </b> :{" "}
                                  {element.message ? element.message : "--"}
                                </em>{" "}
                                <br />
                                <em>
                                  {" "}
                                  <b> Sent By </b> :{" "}
                                  {element?.sentBy ? element.sentBy : "--"}
                                </em>
                                <br />
                                <em>
                                  {" "}
                                  <b> Mobile Number </b> :{" "}
                                  {element?.mobileNumber
                                    ? element.mobileNumber
                                    : "--"}
                                </em>
                                <br />
                                <em>
                                  {" "}
                                  <b> Sent on </b> :{" "}
                                  {element?.insertedAt
                                    ? moment(element.insertedAt).format(
                                        "DD MMM YY"
                                      )
                                    : "--"}
                                </em>
                              </React.Fragment>
                            }
                          >
                            <Grid className="gridComTabel tableData" key={i}>
                              <Grid container alignItems="center">
                                <Grid item xs md={1}>
                                  <Typography variant="h5" noWrap>
                                    {i + 1}
                                  </Typography>
                                </Grid>
                                <Grid item xs md={5}>
                                  <Typography variant="h5" noWrap>
                                    {element.message || "--"}
                                  </Typography>
                                </Grid>
                                <Grid item xs>
                                  <Typography
                                    className="boldText"
                                    variant="h5"
                                    noWrap
                                  >
                                    {element.sentBy || "--"}
                                  </Typography>
                                </Grid>
                                <Grid item xs>
                                  <Typography
                                    className="boldText"
                                    variant="h5"
                                    noWrap
                                  >
                                    {element.mobileNumber || "--"}
                                  </Typography>
                                </Grid>
                                <Grid item xs>
                                  <Typography
                                    variant="h5"
                                    noWrap
                                    className="unittext"
                                  >
                                    {element.insertedAt
                                      ? moment(element.insertedAt).format(
                                          "DD MMM YY"
                                        )
                                      : "--"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </HtmlToolTip>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <IdleTimoutConfig /> */}
      </Grid>
    );
  } else {
    return (
      <Box
        sx={{ width: 900 }}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </Box>
    );
  }
}
