import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import Close from "@material-ui/icons/Close";
import toastr from "toastr";
import {
  TextField,
  Button,
  Grid,
  Fade,
  Backdrop,
  Modal,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { appContext } from "../App";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import RatingOrganizationMaster from "../pages/RatingOrganizationMaster";
import { addRatingOrganizationMaster } from "../Api/api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "184px",
  },
}));

const AddRatingOrganizationMaster = (props) => {
  const classes = useStyles();
  const { authUser, setAuthUser } = useContext(appContext);
  const initialState = {
    rating: "",
    organizationName: "",
    organizationType: "",
  };
  const [currentState, setCurrentState] = useState(initialState);

  const handleChange = (event) => {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };
  }, []);

  const renderRatingsDropdown = () => {
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">Rating</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="rating"
            value={currentState.rating}
            onChange={(event) => handleChange(event)}
            label="Rating"
          >
            {props.availableRatings &&
              props.availableRatings.length > 0 &&
              props.availableRatings.map((availableRating, index) => {
                return (
                  <MenuItem value={availableRating} key={index}>
                    {availableRating.rating}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderOrganizationTypeDropDown = () => {
    return (
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Organization Name
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="organizationName"
            value={currentState.organizatinoName}
            onChange={(event) => handleChange(event)}
            label="Organization Name"
          >
            {props.organizationTypes &&
              props.organizationTypes.length > 0 &&
              props.organizationTypes.map((organizationType, index) => (
                <MenuItem value={organizationType} key={index}>
                  {organizationType}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (authUser && authUser.uuid) {
      addRatingOrganizationMaster({
        rating: currentState.rating.rating,
        organizationName: currentState.organizationName,
      })
        .then((response) => {
          if (response && response.status === 201) {
            props.closeModal();
            toastr.success("Successfully added Rating Organization Master!");
          }
        })
        .catch((error) => toastr.error(error));
    }
  };

  return (
    <div>
      <Dialog
        open={props.openModal}
        onClose={props.onCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Add Rating Organization Master
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            {renderOrganizationTypeDropDown()}
            <br />
            {renderRatingsDropdown()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.closeModal}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={submitHandler}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddRatingOrganizationMaster;
