import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import useCalc from "../../store/calc";
import styles from "../../Helpers/styles";
import { handleSecurityName, sortDate, toINR } from "../../Helpers/functions";

const BasicBondInfo = () => {
  const bondInfo = useCalc((state) => state.bondInfo);

  return (
    <>
      <Box
        sx={{
          ...styles.flexBetweenStart,
          mb: 1,
          cursor: "pointer",
        }}
      >
        <Box sx={styles.flexStartCenter}>
          <Box
            sx={{
              p: 1,
              mr: 1,
              ...styles.flexCenterCenter,
            }}
          >
            <Box
              component="img"
              src={bondInfo?.issuerOfSecurity.s3Url || "/favicon.png"}
              alt="Bond Logo"
              sx={{
                width: 70,
                height: 45,
                mixBlendMode: "multiply",
                objectFit: "contain",
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="h6"
              noWrap
              sx={{ color: "#2A324C", fontSize: 14, width: "250px" }}
            >
              <Box component="span">
                {handleSecurityName(bondInfo?.securityName!).securityCoupon}
              </Box>
              <Box component="span" sx={{ fontWeight: 700, ml: 0.5 }}>
                {handleSecurityName(bondInfo?.securityName!).securityName}
              </Box>
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "#9397A4", fontSize: 12, fontWeight: "bold" }}
            >
              {bondInfo?.isin}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.flexBetweenCenter,
          mt: 1,
          borderBottom: "1px solid #DFE0E4",
          pb: 1,
        }}
      >
        <Box>
          <Typography sx={{ color: "#2A324C", fontSize: 14, fontWeight: 700 }}>
            {bondInfo?.callOptions?.at(-1)?.date
              ? bondInfo?.callOptions?.sort((a, b) => sortDate(a, b)).at(-1)
                  ?.date
              : bondInfo?.maturities?.sort((a, b) => sortDate(a, b)).at(-1)
                  ?.date}
          </Typography>
          <Typography sx={{ color: `${alpha("#2A324C", 0.5)}`, fontSize: 11 }}>
            {bondInfo?.callOptions?.at(-1)?.date ? "Call Date" : "Maturity"}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ color: "#2A324C", fontSize: 14, fontWeight: 700 }}>
            {bondInfo?.coupons[0].rate}%
          </Typography>
          <Typography sx={{ color: `${alpha("#2A324C", 0.5)}`, fontSize: 11 }}>
            Coupon
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ color: "#2A324C", fontSize: 14, fontWeight: 700 }}>
            {toINR(bondInfo?.faceValue.split(" ")[0], 4)}
          </Typography>
          <Typography sx={{ color: `${alpha("#2A324C", 0.5)}`, fontSize: 11 }}>
            Face Value
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default BasicBondInfo;
