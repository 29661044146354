import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import toastr from "toastr";
import { TextField, Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { appContext } from "../App";
import {
  getArticlesForIssuerMaster,
  deleteIssuerMasterArticleByUUID,
  createIssuerMasterArticle,
  updateIssuerMasterArticle,
} from "../Api/api";
import ImageCard from "./ImageCard";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "184px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const IssuerMasterArticleDialog = (props) => {
  const classes = useStyles();
  const { authUser, setAuthUser } = useContext(appContext);
  const { show, onCloseDialog, issuerMasterUUID } = props;
  const [issuerMasterArticles, setIssuerMasterArticles] = React.useState([]);
  const [showAddNewArticleModal, setShowAddNewArticleModal] =
    React.useState(false);
  const initialState = {
    title: "",
    body: "",
  };
  const [currentState, setCurrentState] = React.useState(initialState);
  const [publishedAt, setPublishedAt] = React.useState(null);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState(null);
  const [issuerMasterArticleUUID, setIssuerMasterArticleUUID] =
    React.useState("");

  useEffect(() => {
    if (show) {
      getArticlesForIssuerMaster(issuerMasterUUID)
        .then((response) => {
          if (response && response.data) {
            setIssuerMasterArticles(response.data);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, [props.show]);

  const handleOnEditArticle = (event, issuerMasterArticle) => {
    event.preventDefault();
    setCurrentState({
      title: issuerMasterArticle.title,
      body: issuerMasterArticle.body,
    });
    setPublishedAt(new Date(issuerMasterArticle.publishedAt));
    setIsEditMode(true);
    setShowAddNewArticleModal(true);
    setIssuerMasterArticleUUID(issuerMasterArticle.uuid);
  };

  const handleOnDeleteArticle = (event, issuerMasterArticleUUID) => {
    event.preventDefault();
    deleteIssuerMasterArticleByUUID(issuerMasterArticleUUID)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 204)) {
          let filtered = issuerMasterArticles.filter(
            (issuerMasterArticle) =>
              issuerMasterArticle.uuid !== issuerMasterArticleUUID
          );
          setIssuerMasterArticles(filtered);
          toastr.success("Successfully deleted Issuer Master Article");
        }
      })
      .catch((error) => toastr.error(error));
  };

  const handleIssuerMasterArticleAdd = (event) => {
    event.preventDefault();
    setShowAddNewArticleModal(true);
  };

  const renderDialogBody = () => {
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <AddCircleIcon
            onClick={(event) => handleIssuerMasterArticleAdd(event)}
            color="primary"
            fontSize="large"
            style={{
              float: "right",
              margin: "55px 10px 15px",
              cursor: "pointer",
            }}
          />
          <div style={{ padding: "90px" }}>
            <Grid container item spacing={3}>
              <React.Fragment>
                {issuerMasterArticles.map((issuerMasterArticle) => {
                  return (
                    <Grid item xs={4}>
                      <ImageCard
                        issuerMasterArticle={issuerMasterArticle}
                        onDeleteArticle={handleOnDeleteArticle}
                        handleEditIconOnClick={handleOnEditArticle}
                        isEditMode={isEditMode}
                      />
                    </Grid>
                  );
                })}
              </React.Fragment>
            </Grid>
          </div>
        </Paper>
      </div>
    );
  };

  const closeAddArticleDialog = (event) => {
    event.preventDefault();
    setShowAddNewArticleModal(false);
    setCurrentState(initialState);
    setPublishedAt(null);
  };

  const handleArticleBodyChange = (event) => {
    event.preventDefault();
    let updatedState = currentState;
    updatedState.body = event.target.value;
    setCurrentState(updatedState);
  };

  const handleTitleChange = (event) => {
    event.preventDefault();
    let updatedState = currentState;
    updatedState.title = event.target.value;
    setCurrentState(updatedState);
  };

  const handlePublishedAtChange = (date) => {
    setPublishedAt(date);
  };

  const renderDatePicker = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="">
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Published At"
            format="dd/MM/yyyy"
            value={publishedAt}
            onChange={handlePublishedAtChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    );
  };

  const convertToDate = (date) => {
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = date.getFullYear();
    return dd + "/" + mm + "/" + yyyy;
  };

  const handleCreateOrEdit = (event) => {
    event.preventDefault();

    if (isEditMode) {
      let formData = new FormData();
      formData.append("title", currentState.title);
      formData.append("body", currentState.body);
      formData.append("publishedAt", convertToDate(publishedAt));
      formData.append("issuerMasterArticleUUID", issuerMasterArticleUUID);

      updateIssuerMasterArticle(formData).then((response) => {
        if (response && response.status === 200) {
          toastr.success("Successfully edited Issuer Master Article");
          setShowAddNewArticleModal(false);
          setCurrentState(initialState);
          setIsEditMode(false);
          setPublishedAt(null);
          setIssuerMasterArticles(response.data);
        }
      });
    } else {
      let formData = new FormData();
      formData.append("title", currentState.title);
      formData.append("body", currentState.body);
      formData.append("publishedAt", convertToDate(publishedAt));
      formData.append("issuerMasterUUID", issuerMasterUUID);
      formData.append("isSuperuser", authUser.isSuperuser);
      formData.append("uuid", authUser.uuid);

      createIssuerMasterArticle(formData)
        .then((response) => {
          if (response && response.status === 201) {
            toastr.success("Successfully created  Issuer Master Article");
            setShowAddNewArticleModal(false);
            setCurrentState(initialState);
            setPublishedAt(null);
            setIssuerMasterArticles(response.data);
          }
        })
        .catch((error) => toastr.error(error));
    }
  };

  const renderAddIssuerMasterArticleModal = () => {
    if (showAddNewArticleModal) {
      return (
        <React.Fragment>
          <Dialog
            fullWidth="md"
            maxWidth="md"
            open={showAddNewArticleModal}
            onClose={closeAddArticleDialog}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="max-width-dialog-title">
              {isEditMode
                ? "Edit Issuer Master Article"
                : "Add Issuer Master Article"}
            </DialogTitle>
            <DialogContent>
              <TextField
                type="text"
                name="securityTypeCode"
                variant="outlined"
                fullWidth
                label="Title"
                defaultValue={currentState.title}
                onChange={(event) => handleTitleChange(event)}
              />
              <br />
              <br />
              <TextareaAutosize
                style={{ width: "920px" }}
                aria-label="minimum height"
                rowsMin={12}
                placeholder="Body"
                defaultValue={currentState.body}
                onChange={(event) => handleArticleBodyChange(event)}
              />
              <br />
              <br />
              <Grid container item spacing={3}>
                <React.Fragment>
                  <Grid item xs={6}>
                    {renderDatePicker()}
                  </Grid>
                </React.Fragment>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeAddArticleDialog} color="primary">
                Close
              </Button>
              <Button onClick={handleCreateOrEdit} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
    } else return "";
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={show}
        onClose={(event) => onCloseDialog(event, issuerMasterArticles)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(event) => onCloseDialog(event, issuerMasterArticles)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Close
            </Typography>
          </Toolbar>
        </AppBar>
        {renderDialogBody()}
        {renderAddIssuerMasterArticleModal()}
      </Dialog>
    </div>
  );
};

export default IssuerMasterArticleDialog;
