import React, { useEffect, useContext } from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import toastr from "toastr";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "184px",
  },
}));

function convertToDate(date) {
  if (date) {
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = date.getFullYear();
    return dd + "/" + mm + "/" + yyyy;
  } else return "";
}

const AddPortfolioHoldingModal = (props) => {
  const classes = useStyles();
  const { currentHolding } = props;
  const initialState = {
    isin: currentHolding === null ? "" : currentHolding.isin,
    quantity: currentHolding === null ? "" : currentHolding.quantity,
    purchasePrice: currentHolding === null ? "" : currentHolding.purchasePrice,
  };
  const [currentState, setCurrentState] = React.useState(initialState);

  const [purchaseDate, setPurchaseDate] = React.useState(
    currentHolding === null
      ? new Date().toISOString()
      : new Date(
          currentHolding.dateOfPurchase || currentHolding.paymentDate
        ).toISOString()
  );

  const handleChange = (event) => {
    event.persist();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      currentState.isin === "" ||
      currentState.quantity === "" ||
      currentState.purchasePrice === ""
    ) {
      toastr.error("Please fill all the fields in order to proceed!");
    } else {
      let state = currentState;
      state.dateOfPurchase = convertToDate(purchaseDate);
      setCurrentState(initialState);
      props.submitHandler(event, state);
    }
  };

  const resetStateAndClose = (event) => {
    event.preventDefault();
    setCurrentState(initialState);
    props.closeModal(event);
  };

  const handlePurchaseDateChange = (date) => {
    setPurchaseDate(date);
  };

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={resetStateAndClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Portfolio Holding</DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            <Grid item xs={10}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item justify="space-around" xs={8}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Purchase Date"
                    dateFormat="dd/MM/yyyy"
                    value={purchaseDate}
                    onChange={handlePurchaseDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <TextField
                style={{ "margin-top": "10px" }}
                type="text"
                name="isin"
                variant="outlined"
                fullWidth
                label="ISIN"
                defaultValue={currentState.isin}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <TextField
                style={{ "margin-top": "10px" }}
                type="text"
                name="quantity"
                variant="outlined"
                fullWidth
                label="Quantity"
                defaultValue={currentState.quantity}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <TextField
                style={{ "margin-top": "10px" }}
                type="text"
                name="purchasePrice"
                variant="outlined"
                fullWidth
                label="Purchase Price"
                defaultValue={currentState.purchasePrice}
                onChange={handleChange}
              />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetStateAndClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddPortfolioHoldingModal;
