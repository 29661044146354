import React, { useCallback, useEffect, useMemo, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import Typography from "@mui/material/Typography";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FeedIcon from "@mui/icons-material/Feed";
import ShopTwoIcon from "@mui/icons-material/ShopTwo";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import copy from "copy-to-clipboard";
import toastr from "toastr";
import { axiosInstance } from "../Api/setAuthorizationToken";
import moment from "moment";

const PAYMENT_DETAILS = {
  icclRbi: `*For Payments Above INR 2 lacs*
Beneficiary Account No: 8715962
Beneficiary Name: Indian Clearing Corporation Limited
Beneficiary Bank: Reserve Bank of India
Branch: Fort, Mumbai
IFSC Code: ICLL0000001
Beneficiary Account Type: Current Account
Mode of Payment accepted: *Only RTGS*
`,
  icclHdfc: `*For Payments Below INR 2 lacs*
Beneficiary Account No: 57500001086245
Beneficiary Name: Indian Clearing Corporation Limited
Beneficiary Bank: HDFC Bank Limited
Branch: Fort, Mumbai
IFSC Code: HDFC0000060
Beneficiary Account Type: Current Account
Mode of Payment accepted: *RTGS, NEFT, IMPS*
`,
  nscclRbi: `*For Payments Above INR 2 lacs*
Beneficiary Account No: 8715106
Beneficiary Name: National Securities Clearing Corporation Limited
Beneficiary Bank: Reserve Bank of India
Branch: Mumbai
IFSC Code: XNSE0000001
Beneficiary Account Type: Current Account
Mode of Payment accepted: *Only RTGS*
`,
  nscclHdfc: `*For Payments Below INR 2 lacs*
Bank Account no.CBRIC1XXXXXXXXXX (where "XXXXXXXXXX" is the PAN of Buyer)
For example: If PAN number is ABCDE1234F then beneficiary Account number shall be "CBRIC1ABCDE1234F".
Beneficiary Name : NSE Clearing Limited
Beneficiary Bank: HDFC Bank Limited
Branch: Fort, Mumbai
IFSC Code : HDFC0000060
Beneficiary Account Type: Current Account
Mode of Payment accepted: *RTGS, NEFT, IMPS*
`,
  settlementInfoIcclNsdl: `Market Instruction/Market Type: ICDM (T+0), ICDM (T+1) and ICDM (T+2)
CM Name: ICCL
CM BP ID: IN619994
Settlement Number:
Client ID: 10000010
# CM BP ID is sufficient while filling payout Instruction Slip
`,
  settlementInfoIcclCdsl: `Market Instruction/Market Type: ICDM (T+0), ICDM (T+1) and ICDM (T+2)
CM Name: ICCL
CM ID: 999
Settlement Number:
Alternate BO ID: 1100002900000030
`,
};

const generateSpecialOfferEmailText = (
  userName: string,
  quotes: any,
  ipos: any
) => {
  return `
Dear Investor,
  
${userName} here from SMEST Capital! I'm thrilled to welcome you to smest.in. 
As your dedicated Relationship Manager, I'm here to assist you at every step of the way.
  
Signup Link: https://smest.in/auth/signup
  
⭐Don't miss out on live IPO's⭐
  
${ipos
  .map(
    (ipo: any) =>
      `🥳 ${ipo.ipo_security_master.issuer_of_security.issuerName.name} 
Yield Up to: ${ipo.ipo_security_master.max_interest_description}% 
Minimum Investment: INR 10,000
IPO closes on: ${moment(
        ipo.ipo_security_master.issue_close_date,
        "YYYY-MM-DD"
      ).format("DD MMM, YYYY")}
Apply now: https://smest.in/ipo-details/${ipo.ipo_security_master.issuer_of_security.issuerName.name?.replace(
        /[% ]/g,
        (m: any) => {
          const replaceChars: { [key: string]: string } = {
            "%": "",
            " ": "-",
          };
          return replaceChars[m];
        }
      )}/${ipo.ipo_security_master.uuid}`
  )
  .join("\n\n")}

🎉Check out our live Special Offers

${quotes
  .filter(
    (quote: any) =>
      quote.festival_name_for_quote !== "" &&
      quote.festival_name_for_quote !== "None"
  )
  .sort((a: any, b: any) =>
    Number(a.sell_yield) < Number(b.sell_yield) ? 1 : -1
  )
  .map(
    (quote: any) =>
      `${quote.security_name} 
Offer Yield: ${quote.sell_yield}% 
👉 More Info: https://smest.in/bond-details/${quote.security_name.replace(
        /[% ]/g,
        (m: any) => {
          const replaceChars: { [key: string]: string } = {
            "%": "",
            " ": "-",
          };
          return replaceChars[m];
        }
      )}/${quote.isin}`
  )
  .join("\n\n")}
  
With SMEST, you have the opportunity to invest your funds and unlock greater potential!
  
  • 0️⃣Pay zero brokerage fees!
  • 👍Benefit from competitive pricing!
  • 📃Receive detailed analysis reports of the issuer!
  • 🧮Use our easy-to-use Yield and Price Calculator!
  • 📖Access our ebook on the Indian Debt Market!

For your immediate reference, please find attached our Company Profile. 
We would be delighted to schedule a call or meeting at your earliest convenience to discuss these investment opportunities in further detail and to explore how SMEST can assist you in achieving your financial objectives. Please let us know a suitable time for you.
  
Thanking you,

Best Regards
    `;
};

const generateSpecialOfferWhatsappText = (
  userName: string,
  quotes: any,
  ipos: any
) => {
  return `
Hello Investor,
  
${userName} here from SMEST Capital! I'm thrilled to welcome you to smest.in. 
As your dedicated Relationship Manager, I'm here to assist you at every step of the way.
  
⭐Don't miss out on live IPO's on smest.in⭐
  
${ipos
  .map(
    (ipo: any) =>
      `🥳 *${ipo.ipo_security_master.issuer_of_security.issuerName.name}* 
*Yield Up to*: ${ipo.ipo_security_master.max_interest_description}% 
*Minimum Investment*: INR 10,000
*IPO closes on*: ${moment(
        ipo.ipo_security_master.issue_close_date,
        "YYYY-MM-DD"
      ).format("DD MMM, YYYY")}
*Apply now*: https://smest.in/ipo-details/${ipo.ipo_security_master.issuer_of_security.issuerName.name?.replace(
        /[% ]/g,
        (m: any) => {
          const replaceChars: { [key: string]: string } = {
            "%": "",
            " ": "-",
          };
          return replaceChars[m];
        }
      )}/${ipo.ipo_security_master.uuid}`
  )
  .join("\n\n")}

🎉Check out these Special Offers we are running right now:

${quotes
  .filter(
    (quote: any) =>
      quote.festival_name_for_quote !== "" &&
      quote.festival_name_for_quote !== "None"
  )
  .sort((a: any, b: any) =>
    Number(a.sell_yield) < Number(b.sell_yield) ? 1 : -1
  )
  .map(
    (quote: any) =>
      `*${quote.security_name}* 
*Offer Yield*: ${quote.sell_yield}% 
👉 More Info: https://smest.in/bond-details/${quote.security_name.replace(
        /[% ]/g,
        (m: any) => {
          const replaceChars: { [key: string]: string } = {
            "%": "",
            " ": "-",
          };
          return replaceChars[m];
        }
      )}/${quote.isin}`
  )
  .join("\n\n")}
  
Please feel free to reach out to me for any additional information.

Best Regards,
${userName}
    `;
};

const generateStepsToBuyText = (userName: string) => {
  return `
Dear Investor,
    
1. Log on to https://smest.in and create your free account!
2. Complete your KYC in a few minutes, you will require the below documents

   a. Proof Of Identity: PAN Card
   b. Proof Of Address: Aadhar Card / Passport Copy / Driving License
   c. Bank Proof: Cancelled Cheque (from where you will be transferring the funds to the ICCL for purchasing the Bonds)
   d. Demat Proof: CML/CMR Copy
   e. Wet Signature Photograph (sign on a plain white paper and take a picture of it)
   f. Passport Size Photograph

3. Explore Bonds on the Trade Floor (https://smest.in/trade-floor)
4. Select the Bond and Swipe Right to Purchase

For any assistance, please reach out to me.

Please Note:
*All trades are settled on ICCL.*
*Please Add the below-mentioned bank as a beneficiary to your KYC'd Bank account with us.*

Beneficiary Account No: 57500001086245
Beneficiary Name: Indian Clearing Corporation Limited
Beneficiary Bank: HDFC Bank Limited
Branch: Fort, Mumbai
IFSC Code: HDFC0000060
Beneficiary Account Type: Current Account
Mode of Payment accepted: *RTGS, NEFT, IMPS*


Best Regards,
${userName}
`;
};

const generateUnavailableClientText = (userName: string) => {
  return `
Dear Sir,

This is ${userName} from SMEST Capital. I am your relationship manager at SMEST, who will be assisting you for your investments in Corporate Bonds. 

I am thrilled that you have signed up on our portal. Please let me know a good time to connect with you.

Thanking You,

Best Regards
`;
};

const useFetchQuotesAndIpos = (shouldFetch: boolean) => {
  const [quotes, setQuotes] = useState([]);
  const [ipos, setIpos] = useState([]);

  const fetchData = useCallback(async () => {
    if (!shouldFetch) return;

    try {
      const [quotesResp, iposResp] = await Promise.all([
        axiosInstance.post("admin/quotes/filter/v2/"),
        axiosInstance.get("/ipo/security_master/list/"),
      ]);

      if (quotesResp.status === 200) setQuotes(quotesResp.data);
      if (iposResp.status === 200) setIpos(iposResp.data);
    } catch (error: any) {
      toastr.error(error.message);
    }
  }, [shouldFetch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { quotes, ipos };
};

const UtilityCopyButtons = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { quotes, ipos } = useFetchQuotesAndIpos(open);
  const userName = JSON.parse(localStorage.getItem("loginUser")!)?.name;

  const actions = useMemo(
    () => [
      {
        icon: <AssuredWorkloadIcon />,
        name: "ICCL RBI",
        text: PAYMENT_DETAILS.icclRbi,
      },
      {
        icon: <AssuredWorkloadIcon />,
        name: "ICCL HDFC",
        text: PAYMENT_DETAILS.icclHdfc,
      },
      {
        icon: <AssuredWorkloadIcon />,
        name: "NSCCL RBI",
        text: PAYMENT_DETAILS.nscclRbi,
      },
      {
        icon: <AssuredWorkloadIcon />,
        name: "NSCCL HDFC",
        text: PAYMENT_DETAILS.nscclHdfc,
      },
      {
        icon: <MarkEmailReadIcon />,
        name: "Special Offer eMail Body",
        text: generateSpecialOfferEmailText(userName, quotes, ipos),
      },
      {
        icon: <WhatsAppIcon />,
        name: "Welcome Whatsapp to Client",
        text: generateSpecialOfferWhatsappText(userName, quotes, ipos),
      },
      {
        icon: <FeedIcon />,
        name: "Settlement Information ICCL NSDL",
        text: PAYMENT_DETAILS.settlementInfoIcclNsdl,
      },
      {
        icon: <FeedIcon />,
        name: "Settlement Information ICCL CDSL",
        text: PAYMENT_DETAILS.settlementInfoIcclCdsl,
      },
      {
        icon: <ShopTwoIcon />,
        name: "Steps to Buy",
        text: generateStepsToBuyText(userName),
      },
      {
        icon: <NoAccountsIcon />,
        name: "Unavailable client",
        text: generateUnavailableClientText(userName),
      },
    ],
    [userName, quotes, ipos]
  );

  return (
    <>
      <Backdrop
        open={open}
        sx={{
          zIndex: 99999,
        }}
      />
      <SpeedDial
        ariaLabel="Utility Tools"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: 100000,
        }}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        icon={<SpeedDialIcon openIcon={<FileCopyIcon />} />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {action.name}
              </Typography>
            }
            tooltipOpen
            onClick={() => {
              copy(action.text);
            }}
          />
        ))}
      </SpeedDial>
    </>
  );
};

export default UtilityCopyButtons;
