import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function createData(
  serial: number,
  timestamp: string,
  changedBy: string,
  diff: any
) {
  return {
    serial,
    timestamp,
    changedBy,
    diff,
  };
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.serial}
        </TableCell>
        <TableCell align="right">{row.timestamp}</TableCell>
        <TableCell align="right">{row.changedBy}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Serial</TableCell>
                    <TableCell>Changed Column Name</TableCell>
                    <TableCell>Old Value</TableCell>
                    <TableCell align="right">New Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.diff.map((rowDiff: any) => (
                    <TableRow key={rowDiff.serial}>
                      <TableCell component="th" scope="row">
                        {rowDiff.serial}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {rowDiff.changedColumnName}
                      </TableCell>
                      <TableCell>{rowDiff.oldValue}</TableCell>
                      <TableCell align="right">{rowDiff.newValue}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function formattifyDiff(diff: any): any {
  let formatted: any = [];
  let counter = 1;
  for (var propertyName in diff) {
    formatted.push({
      serial: counter,
      changedColumnName: propertyName,
      oldValue: diff[propertyName]["old_value"],
      newValue: diff[propertyName]["new_value"],
    });
    counter += 1;
  }
  return formatted;
}

function getFormattedRecords(rows: any): any {
  let formatted: any = [];
  rows.history.map((r: any, index: number) => {
    formatted.push(
      createData(index, r.insertedAt, r.changedBy, formattifyDiff(r.diff))
    );
  });
  return formatted;
}

export default function SecurityMasterUpdateHistoryTable(tableProps: any) {
  let formatted: any = getFormattedRecords(tableProps.history);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Serial </TableCell>
            <TableCell align="right">Timestamp </TableCell>
            <TableCell align="right">Changed By</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formatted.map((record: any, index: number) => {
            return <Row key={index} row={record} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
