import React, { useState, useContext, useEffect } from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import toastr from "toastr";
import { appContext } from "../App";
import { makeStyles } from "@material-ui/core/styles";
import { createHolidayMaster } from "../Api/api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "400px",
  },
}));

const AddHolidayMaster = (props) => {
  const classes = useStyles();

  const { authUser, setAuthUser } = useContext(appContext);
  const [holidayMaster, setHolidayMaster] = useState("");
  const [remarks, setRemarks] = useState("");

  useEffect(() => {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };
  }, []);

  const submitHandler = () => {
    if (authUser && authUser.uuid && holidayMaster) {
      createHolidayMaster({ date: holidayMaster, remarks: remarks })
        .then((response) => {
          if (response && response.status === 201) {
            toastr.success("Added Holiday master successfully!");
            props.onCloseModal();
          }
        })
        .catch((error) => toastr.error(error));
    }
  };

  const getCurrentDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = dd + "/" + mm + "/" + yyyy;
    return today;
  };

  const handleDateChange = (event) => {
    event.persist();
    let enteredDate = event.target.value;
    let [year, month, day] = enteredDate.split("-");
    enteredDate = day + "/" + month + "/" + year;
    setHolidayMaster(enteredDate);
  };

  const handleRemarksChange = (event) => {
    event.persist();
    setRemarks(event.target.value);
  };

  return (
    <Dialog
      open={props.openModal}
      onClose={props.onCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Add Holiday Master</DialogTitle>
      <DialogContent style={{ width: "500px" }}>
        <DialogContentText id="alert-dialog-description">
          <Grid item xs={12}>
            <TextField
              id="date"
              label="Holiday date"
              type="date"
              defaultValue={getCurrentDate()}
              className={classes.textField}
              onChange={(event) => handleDateChange(event)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <br />
          <TextField
            id="outlined-multiline-flexible"
            label="Remarks"
            multiline
            rowsMax={4}
            className={classes.textField}
            value={remarks}
            onChange={handleRemarksChange}
            variant="outlined"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCloseModal}>Cancel</Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddHolidayMaster;
