import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import GenericDialog from "../Dialog/GenericDialog";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function AboutTheIssuer(props: any): React.ReactElement {
  const classes = useStyles();
  const [showChangeHeadingDialog, setShowChangeHeadingDialog] =
    React.useState<boolean>(false);
  const [updatedHeading, setUpdatedHeading] = React.useState<string>("");
  return (
    <Paper className={classes.paper}>
      <h3
        style={{ padding: "10px" }}
        onClick={(event) => {
          event.preventDefault();
          setShowChangeHeadingDialog(true);
        }}
      >
        {props.heading}
      </h3>
      <Grid item xs={6}>
        <TextareaAutosize
          className={props.class}
          id="filled-basic"
          rowsMin={13}
          onChange={props.setAboutTheIssuer}
          value={props.aboutTheIssuer}
        />
      </Grid>
      {showChangeHeadingDialog ? (
        <GenericDialog
          show={showChangeHeadingDialog}
          heading="Update Heading"
          inputProps={
            <TextField
              type="text"
              name="con"
              variant="outlined"
              fullWidth
              label="Update Heading"
              defaultValue={updatedHeading}
              onChange={(event: any) => {
                event.persist();
                setUpdatedHeading(event.target.value);
              }}
            />
          }
          onClose={(event: any) => {
            event.preventDefault();
            setShowChangeHeadingDialog(false);
          }}
          handleSubmit={(event: any) => {
            event.preventDefault();
            props.updateUseOfIssueHeading(updatedHeading);
            setShowChangeHeadingDialog(false);
          }}
        />
      ) : (
        ""
      )}
    </Paper>
  );
}
