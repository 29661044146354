import React from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeGETRequest, makePOSTRequest, makePUTRequest } from "../Api/api";
import useForceUpdate from "use-force-update";

const toastr = require("toastr");

export default function CreateCashflowRecord(props: any) {
  const cashflowRecordInitialState = {
    previous_ip_date: "",
    value_Date: "",
    adjusted_value_date: "",
    adjusted_prev_ip_date: "",
    amount: "",
    days: 0,
    year: 0,
    interest: "",
    maturity: "",
    security_master: "",
  };
  const [newCashflowRecord, setNewCashflowRecord] = React.useState<any>(
    cashflowRecordInitialState
  );
  const forceUpdate = useForceUpdate();

  let action = props.match.params.action;
  let isin = props.match.params.isin;
  let uuid = props.match.params.uuid;

  React.useEffect(() => {
    if (action === "update") {
      makeGETRequest(`/admin/security_master/cashflow_record/retrieve/${uuid}/`)
        .then((resp) => {
          setNewCashflowRecord(resp.data);
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  function handleChange(event: any) {
    const { name, value } = event.target;
    setNewCashflowRecord((previousValues: any) => ({
      ...previousValues,
      [name]: value,
    }));
  }
  return (
    <Grid
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        bottom: "-10%",
        width: "90%",
        transform: "translate(-50%, -50%)",
      }}
      className="securityMasterDataGird"
    >
      <Paper>
        <div
          style={{
            height: "100vh",
            width: "100%",
          }}
        >
          <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs>
                <div style={{ fontSize: "36px", float: "left" }}>
                  {action === "create" ? "Create" : "Update"} Cashflow Record
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={6}>
              <TextField
                style={{ marginTop: "10px" }}
                type="text"
                name="previous_ip_date"
                variant="outlined"
                fullWidth
                label="Previous IP Date"
                value={newCashflowRecord?.previous_ip_date}
                onChange={(event) => handleChange(event)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                style={{ marginTop: "10px" }}
                type="text"
                name="value_Date"
                variant="outlined"
                fullWidth
                label="Value Date"
                value={newCashflowRecord?.value_Date}
                onChange={(event) => handleChange(event)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                style={{ marginTop: "10px" }}
                type="text"
                name="adjusted_value_date"
                variant="outlined"
                fullWidth
                label="Adjusted Value Date"
                value={newCashflowRecord?.adjusted_value_date}
                onChange={(event) => handleChange(event)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                style={{ marginTop: "10px" }}
                type="text"
                name="adjusted_prev_ip_date"
                variant="outlined"
                fullWidth
                label="Adjusted Prev Value Date"
                value={newCashflowRecord?.adjusted_prev_ip_date}
                onChange={(event) => handleChange(event)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                style={{ marginTop: "10px" }}
                type="text"
                name="amount"
                variant="outlined"
                fullWidth
                label="Amount"
                value={newCashflowRecord?.amount}
                onChange={(event) => handleChange(event)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                style={{ marginTop: "10px" }}
                type="text"
                name="days"
                variant="outlined"
                fullWidth
                label="Days"
                value={newCashflowRecord?.days}
                onChange={(event) => handleChange(event)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                style={{ marginTop: "10px" }}
                type="text"
                name="year"
                variant="outlined"
                fullWidth
                label="Year"
                value={newCashflowRecord?.year}
                onChange={(event) => handleChange(event)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                style={{ marginTop: "10px" }}
                type="text"
                name="interest"
                variant="outlined"
                fullWidth
                label="Interest"
                value={newCashflowRecord?.interest}
                onChange={(event) => handleChange(event)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                style={{ marginTop: "10px" }}
                type="text"
                name="maturity"
                variant="outlined"
                fullWidth
                label="Maturity"
                value={newCashflowRecord?.maturity}
                onChange={(event) => handleChange(event)}
              />
            </Grid>
          </Grid>
          <Button
            variant="outlined"
            onClick={(event) => {
              let requestBody = { ...newCashflowRecord, security_master: isin };
              if (action === "create") {
                makePOSTRequest(
                  "/admin/security_master/cashflow_record/create/",
                  requestBody
                )
                  .then((resp) => {
                    if (resp.status === 201 || resp.status === 200) {
                      toastr.success("Created Cashflow Record successfully!");
                      setNewCashflowRecord(cashflowRecordInitialState);
                      forceUpdate();
                    }
                  })
                  .catch((error) => toastr.error(error));
              } else {
                makePUTRequest(
                  `/admin/security_master/cashflow_record/update/${newCashflowRecord.uuid}/`,
                  newCashflowRecord
                )
                  .then((resp: any) => {
                    if (resp.status === 200) {
                      toastr.success("Updated Cashflow Record successfully");
                    }
                  })
                  .catch((error: any) => toastr.error(error));
              }
            }}
            style={{
              float: "left",
              margin: "15px 10px 15px",
              cursor: "pointer",
            }}
          >
            Save
          </Button>
        </div>
      </Paper>
    </Grid>
  );
}
