import React, { useState } from "react";
import Grid from "@mui/material/Grid";

const ImageUpload = (props) => {
  const handleChange = (event) => {
    event.preventDefault();
    props.setFile(event);
  };
  return (
    <Grid item xs={12}>
      <input type="file" name="file" onChange={handleChange} />
    </Grid>
  );
};

export default ImageUpload;
