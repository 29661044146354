import Axios from "axios";
import axios from "axios";

Axios.defaults.baseURL = process.env.REACT_APP_ADMIN_SERVICE_BASE_URL;

Axios.defaults.headers.post["Content-Type"] = "application/json";

export const axiosInstance = Axios.create({});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem("AccessToken");
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if (error.response.status === 401 || error.response.status === 403) {
    //   localStorage.clear();
    //   window.location.href = "/";
    // axios
    //   .post(
    //     `${process.env.REACT_APP_ADMIN_SERVICE_BASE_URL}/admin/access_token/generate_new/`,
    //     {
    //       refresh_token: localStorage.getItem("RefreshToken"),
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((resp) => {
    //     if (resp.status === 200) {
    //       localStorage.setItem("AccessToken", resp.data.access_token);
    //       localStorage.setItem("IdToken", resp.data.id_token);
    //       window.location.reload();
    //     } else {
    // localStorage.clear();
    // window.location.href = "/";
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     localStorage.clear();
    //     window.location.href = "/";
    //   });
    // }
    return Promise.reject(error);
  }
);
