export function getUpdatedAmountByStampDuty(settlementAmount: number) {
  if (settlementAmount >= 500000) {
    let upperLimit = 500000,
      lowerLimit = 0,
      cond = true,
      stampDuty = 0;
    if (0 <= settlementAmount && settlementAmount <= 499999) return 0;
    else {
      lowerLimit = 500000;
      upperLimit += 1000000;
      while (cond) {
        if (settlementAmount >= 100000000) {
          stampDuty = 100;
          cond = false;
        } else {
          if (
            lowerLimit <= settlementAmount &&
            settlementAmount <= upperLimit
          ) {
            stampDuty += 1;
            cond = false;
          } else {
            upperLimit += 1000000;
            lowerLimit += 1000000;
            stampDuty += 1;
          }
        }
      }
      return stampDuty;
    }
  } else return 0;
}

export const sortDate = (a: any, b: any) => {
  const [dayA, monthA, yearA] = a.date.split("/").map(Number);
  const [dayB, monthB, yearB] = b.date.split("/").map(Number);
  const dateA = new Date(yearA, monthA - 1, dayA);
  const dateB = new Date(yearB, monthB - 1, dayB);
  return dateA.getTime() - dateB.getTime();
};

export const toINR = (value: any, fraction: number) => {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: fraction,
  });
  return formatter.format(value);
};

export function handleSecurityName(securityName: string) {
  return {
    securityCoupon: `${securityName.split("%", 2)[0]}%`,
    securityName: securityName.substring(securityName.indexOf("%") + 1).trim(),
  };
}
