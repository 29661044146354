import React from "react";
import { Grid, Typography } from "@material-ui/core";

export default function IPODetails(props: any) {
  let { ipo } = props;
  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">ASBANONASBA</Grid>
            <Typography variant="h6" noWrap>
              {ipo.asbanonasba || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">CATEGORY</Grid>
            <Typography variant="h6" noWrap>
              {ipo.category || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">CEILING PRICE</Grid>
            <Typography variant="h6" noWrap>
              {ipo.ceilingprice || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">CLOSED DATETIME</Grid>
            <Typography variant="h6" noWrap>
              {ipo.closedatetime || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">CUTOFF</Grid>
            <Typography variant="h6" noWrap>
              {ipo.cuttoff || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">DISCOUNT TYPE</Grid>
            <Typography variant="h6" noWrap>
              {ipo.discounttype || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">DISCOUNT VALUE</Grid>
            <Typography variant="h6" noWrap>
              {ipo.discountvalue || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">FLOOR PRICE</Grid>
            <Typography variant="h6" noWrap>
              {ipo.floorprice || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">ISIN</Grid>
            <Typography variant="h6" noWrap>
              {ipo.isin || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">ISSUE TYPE</Grid>
            <Typography variant="h6" noWrap>
              {ipo.issuetype || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">MAX BID QUANTITY</Grid>
            <Typography variant="h6" noWrap>
              {ipo.maxbidqty || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">MAX VALUE</Grid>
            <Typography variant="h6" noWrap>
              {ipo.maxvalue || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">MESSAGE</Grid>
            <Typography variant="h6" noWrap>
              {ipo.message || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">MIN BID QUANTITY</Grid>
            <Typography variant="h6" noWrap>
              {ipo.minbidqty || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">MIN VALUE</Grid>
            <Typography variant="h6" noWrap>
              {ipo.minvalue || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">NAME</Grid>
            <Typography variant="h6" noWrap>
              {ipo.name || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">OPEN DATETIME</Grid>
            <Typography variant="h6" noWrap>
              {ipo.opendatetime || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">SYMBOL</Grid>
            <Typography variant="h6" noWrap>
              {ipo.symbol || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">TICK PRICE</Grid>
            <Typography variant="h6" noWrap>
              {ipo.tickprice || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">
              T PLUS MODIFICATION FROM
            </Grid>
            <Typography variant="h6" noWrap>
              {ipo.tplusmodificationfrom || "--"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">T PLUS MODIFICATION TO</Grid>
            <Typography variant="h6" noWrap>
              {ipo.tplusmodificationto || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <Grid className="profileInfoDate">
            <Grid className="profileInfoDateTitle">TRADING LOT</Grid>
            <Typography variant="h6" noWrap>
              {ipo.tradinglot || "--"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
