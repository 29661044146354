import React from "react";
import { Grid, Button, TextField } from "@material-ui/core";
import GenericTable from "../GenericTable/GenericTable";
import DeleteIcon from "@material-ui/icons/Delete";
import { QuarterlyRevenue } from "./Interfaces";
import GenericDialog from "../Dialog/GenericDialog";

export default function QuarterlyRevenueComponent(
  props: any
): React.ReactElement {
  const [showAddRevenueModal, setShowAddRevenueModal] =
    React.useState<boolean>(false);
  const quarterlyRevenueInitialState = {
    halfYear: "",
    revenue: "",
    profitAfterTax: "",
  };
  const [currentQuarterlyRevenue, setCurrentQuarterlyRevenue] =
    React.useState<QuarterlyRevenue>(quarterlyRevenueInitialState);

  function getFormattedRowsWithJSX(quarterlyRevenues: QuarterlyRevenue[]): any {
    let formatted: any[] = [];
    if (quarterlyRevenues) {
      quarterlyRevenues.map((quarterlyRevenue: QuarterlyRevenue) => {
        formatted.push({
          halfyear: quarterlyRevenue.halfYear,
          revenue: quarterlyRevenue.revenue,
          profitaftertax: quarterlyRevenue.profitAfterTax,
          xdelete: (
            <DeleteIcon
              onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                event.preventDefault();
                props.removeQuarterlyRevenue(quarterlyRevenue.serial);
              }}
              color="primary"
              style={{
                cursor: "pointer",
              }}
            />
          ),
        });
      });
    }
    return formatted;
  }

  function handleChange(event: any): void {
    event.persist();
    setCurrentQuarterlyRevenue((previousValues: QuarterlyRevenue) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  }

  function generateInputProps(): React.ReactElement {
    return (
      <Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            name="halfYear"
            variant="outlined"
            fullWidth
            label="Half Year"
            defaultValue={currentQuarterlyRevenue.halfYear}
            onChange={handleChange}
          />
        </Grid>
        <br />

        <Grid item xs={12}>
          <TextField
            type="text"
            name="profitAfterTax"
            variant="outlined"
            fullWidth
            label="Profit After Tax"
            defaultValue={currentQuarterlyRevenue.profitAfterTax}
            onChange={handleChange}
          />
        </Grid>
        <br />

        <Grid item xs={12}>
          <TextField
            type="text"
            name="revenue"
            variant="outlined"
            fullWidth
            label="Revenue"
            defaultValue={currentQuarterlyRevenue.revenue}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid className="holidayMastersDataGrid">
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              {props.heading}
            </Grid>
            <Grid item xs="auto">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  event.preventDefault();
                  setShowAddRevenueModal(!showAddRevenueModal);
                }}
              >
                Add Quarterly Revenue
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <GenericTable
        columnNames={["HalfYear", "ProfitAfterTax", "Revenue", "XDelete"]} // Make sure to arrange the column names in alphabetical order.
        rows={getFormattedRowsWithJSX(props.quarterlyRevenues)}
      />
      {showAddRevenueModal ? (
        <GenericDialog
          show={showAddRevenueModal}
          heading="Add Quarterly Revenue"
          inputProps={generateInputProps()}
          onClose={(event: any) => {
            setShowAddRevenueModal(!showAddRevenueModal);
          }}
          handleSubmit={() => {
            props.addQuarterlyRevenue(currentQuarterlyRevenue);
            setCurrentQuarterlyRevenue(quarterlyRevenueInitialState);
            setShowAddRevenueModal(!showAddRevenueModal);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
