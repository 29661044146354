import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField, Button, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import useForceUpdate from "use-force-update";
import toastr from "toastr";

function getOnlyISINs(quoteISINMappings) {
  let formatted = [];
  quoteISINMappings.map((quoteISINMapping) => {
    formatted.push(quoteISINMapping.isin);
  });
  return formatted;
}

function getOnlySmestID(formattedMappings) {
  let formatted = [];
  formattedMappings &&
    formattedMappings.map((item) => {
      item && formatted.push(item.smestID);
    });

  return formatted;
}

function getFilteredSecurityName(isin, quoteISINMappings) {
  let filtered = quoteISINMappings.filter(
    (quoteISINMapping) => quoteISINMapping.isin === isin
  )[0];
  if (filtered === undefined || filtered === null) {
    return "";
  } else return filtered.securityName;
}

function getFilteredMaxNumberOfUnits(isin, quoteISINMappings) {
  let filtered = quoteISINMappings.filter(
    (quoteISINMapping) => quoteISINMapping.isin === isin
  )[0];

  return filtered.maximumQuantity / filtered.faceValue;
}

function getFilteredNumberOfUnits(isin, quoteISINMappings) {
  let filtered = quoteISINMappings.filter(
    (quoteISINMapping) => quoteISINMapping.isin === isin
  )[0];

  return filtered.minimumQuantity / filtered.faceValue;
}

function getFilteredQuoteUUID(isin, quoteISINMappings) {
  let filtered = quoteISINMappings.filter(
    (quoteISINMapping) => quoteISINMapping.isin === isin
  )[0];
  if (filtered === undefined || filtered === null) {
    return "";
  } else return filtered.uuid;
}

function getFIlteredCustomerIdBySMESTID(smestID, userNameAndUUIDMappings) {
  let filtered = userNameAndUUIDMappings.filter(
    (mapping) => mapping.smestID === smestID
  );
  return filtered[0].uuid;
}

function getFilteredCustomerName(mappings, uuid) {
  let filtered = mappings.filter((mapping) => mapping.smestID === uuid)[0];
  if (filtered === undefined || filtered === null) {
    return "";
  } else return filtered.name;
}

export default function CreateOrderModal(props) {
  const initialState = {
    isin: "",
    securityName: "",
    orderType: "",
    settlementType: "",
    customerName: "",
    customerUUID: "",
    noOfUnits: "",
    spread: "",
    quoteUUID: "",
    availableAmount: "",
    customerSellPrice: "",
  };
  const [order, setOrder] = React.useState(initialState);
  const [defaultNumberOfUnits, setDefaultNumberOfUnits] = React.useState(0);
  const [maxUnits, setMaxUnits] = React.useState(0);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const forceUpdate = useForceUpdate();

  const resetStateAndClose = (event) => {
    event.preventDefault();
    props.closeCreateOrderModal(event);
  };

  const handleNumberOfUnitsChange = (event) => {
    event.preventDefault();
    let converted = parseInt(event.target.value);
    if (
      converted % defaultNumberOfUnits === 0 &&
      converted <= maxUnits &&
      converted > 0
    ) {
      setIsDisabled(false);
      let state = order;
      state.noOfUnits = converted;
      setOrder(state);
    } else {
      setIsDisabled(true);
      toastr.error(
        `Number of units can only be in multiples of ${defaultNumberOfUnits}`
      );
    }
  };

  const handleChange = (event) => {
    event.persist();
    setOrder((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const renderSettlementTypeDropdown = () => {
    let SettlementTypeOptions = ["T+0", "T+1"];
    return (
      <Grid item xs={12} width="500px" style={{ "margin-top": "10px" }}>
        <InputLabel id="demo-simple-select-outlined-label">
          Settlement Type
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          name="settlementType"
          value={order.settlementType}
          onChange={handleChange}
          label="T+0/T+1"
          style={{ width: "450px" }}
          variant="outlined"
        >
          {SettlementTypeOptions &&
            SettlementTypeOptions.length > 0 &&
            SettlementTypeOptions.map((SettlementTypeOption, index) => {
              return (
                <MenuItem value={SettlementTypeOption} key={index}>
                  {SettlementTypeOption}
                </MenuItem>
              );
            })}
        </Select>
      </Grid>
    );
  };

  const renderOrderTypeDropdown = () => {
    let OrderTypeOptions = ["Buy", "Sell"];
    return (
      <Grid item xs={12} style={{ "margin-top": "10px" }}>
        <InputLabel id="demo-simple-select-outlined-label">
          Order Type
        </InputLabel>
        <Select
          style={{ width: "450px" }}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          name="orderType"
          value={order.orderType}
          onChange={handleChange}
          label="Buy/Sell"
          variant="outlined"
        >
          {OrderTypeOptions &&
            OrderTypeOptions.length > 0 &&
            OrderTypeOptions.map((OrderTypeOption, index) => {
              return (
                <MenuItem value={OrderTypeOption} key={index}>
                  {OrderTypeOption}
                </MenuItem>
              );
            })}
        </Select>
      </Grid>
    );
  };

  const handleSMESTIDChange = (event, enteredValue) => {
    if (enteredValue) {
      event.preventDefault();
      let currentState = order;
      let filteredCustomerID = getFIlteredCustomerIdBySMESTID(
        enteredValue,
        props.userNameAndUUIDMappings
      );

      let customerName = getFilteredCustomerName(
        props.userNameAndUUIDMappings,
        enteredValue
      );
      currentState.customerName = customerName;
      currentState.customerDetails = {
        firstLetter: "",
        name: customerName,
        smestID: enteredValue,
        uuid: filteredCustomerID,
      };
      currentState.customerUUID = filteredCustomerID;
      currentState.smestID = enteredValue;
      setOrder(currentState);
      forceUpdate();
    }
  };

  const handleISINChange = (event, enteredValue) => {
    event.preventDefault();
    let currentState = order;
    currentState.isin = enteredValue;
    currentState.securityName = getFilteredSecurityName(
      enteredValue,
      props.quoteISINMappings
    );
    currentState.noOfUnits = getFilteredNumberOfUnits(
      enteredValue,
      props.quoteISINMappings
    );
    setMaxUnits(
      getFilteredMaxNumberOfUnits(enteredValue, props.quoteISINMappings)
    );
    setDefaultNumberOfUnits(currentState.noOfUnits);
    currentState.quoteUUID = getFilteredQuoteUUID(
      enteredValue,
      props.quoteISINMappings
    );
    setOrder(currentState);
    forceUpdate();
  };

  const renderSecurityNameInput = () => {
    return (
      <Grid item xs={12} style={{ "margin-top": "10px" }}>
        <TextField
          type="text"
          name="securityName"
          variant="outlined"
          fullWidth
          disabled
          label="Security Name"
          value={order.securityName}
        />
      </Grid>
    );
  };

  const renderISINInputField = () => {
    return (
      <Grid item xs={12} style={{ "margin-top": "10px" }}>
        <Autocomplete
          id="free-solo-demo"
          freeSolo={true}
          options={getOnlyISINs(props.quoteISINMappings)}
          onChange={(event, enteredValue) =>
            handleISINChange(event, enteredValue)
          }
          autoHighlight={true}
          value={order.isin}
          renderInput={(params) => (
            <TextField
              {...params}
              label="ISIN"
              margin="normal"
              variant="outlined"
            />
          )}
        />
      </Grid>
    );
  };

  const renderNumberOfUnits = () => {
    if (order.noOfUnits !== "") {
      return (
        <Grid item xs={12} style={{ "margin-top": "10px" }}>
          <TextField
            type="text"
            name="noOfUnits"
            variant="outlined"
            fullWidth
            label="Number Of Units"
            defaultValue={order.noOfUnits}
            onChange={handleNumberOfUnitsChange}
          />
        </Grid>
      );
    }
  };

  const renderCustomerSellPrice = () => {
    return (
      <Grid item xs={12} style={{ "margin-top": "10px" }}>
        <TextField
          type="text"
          name="customerSellPrice"
          variant="outlined"
          fullWidth
          label="Customer Sell Price"
          defaultValue={order.customerSellPrice}
          onChange={handleChange}
        />
      </Grid>
    );
  };

  const renderSpread = () => {
    return (
      <Grid item xs={12} style={{ "margin-top": "10px" }}>
        <TextField
          type="text"
          name="spread"
          variant="outlined"
          fullWidth
          label="Spread"
          defaultValue={order.spread}
          onChange={handleChange}
        />
      </Grid>
    );
  };

  function renderSMESTID() {
    return (
      <Grid item xs={12} style={{ "margin-top": "10px" }}>
        <Autocomplete
          id="free-solo-demo"
          freeSolo={true}
          options={getOnlySmestID(props.userNameAndUUIDMappings)}
          onChange={(event, enteredValue) =>
            handleSMESTIDChange(event, enteredValue)
          }
          autoHighlight={true}
          value={order.smestID}
          renderInput={(params) => (
            <TextField
              {...params}
              label="SMEST ID"
              margin="normal"
              variant="outlined"
            />
          )}
        />
      </Grid>
    );
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let currentState = order;
    currentState.isin = currentState.isin.split("__")[0];
    setOrder(initialState);
    props.onSave(event, currentState);
  };

  const renderReadOnlyCustomerName = () => {
    return (
      <Grid item xs={12} style={{ "margin-top": "10px" }}>
        <TextField
          type="text"
          name="customerName"
          variant="outlined"
          fullWidth
          disabled
          label="Customer Name"
          value={order.customerName}
        />
      </Grid>
    );
  };

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={resetStateAndClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Place Order</DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            {renderISINInputField()}
            {renderSecurityNameInput()}
            {renderOrderTypeDropdown()}
            {renderSettlementTypeDropdown()}
            {renderReadOnlyCustomerName()}
            {/* {renderCustomerNameAutoComplete()} */}
            {renderSMESTID()}
            {renderNumberOfUnits()}
            {renderSpread()}
            {order.orderType === "Sell" ? renderCustomerSellPrice() : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetStateAndClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isDisabled}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
