import React from "react";
import { Grid, Button, TextField } from "@material-ui/core";
import GenericTable from "../GenericTable/GenericTable";
import DeleteIcon from "@material-ui/icons/Delete";
import { Con } from "./Interfaces";
import GenericDialog from "../Dialog/GenericDialog";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export default function ConComponent(props: any): React.ReactElement {
  const [showAddConModal, setShowAddConModal] = React.useState<boolean>(false);
  const currentConInitialState = {
    heading: "",
    text: "",
  };
  const [currentCon, setCurrentCon] = React.useState<Con>(
    currentConInitialState
  );

  function getFormattedRowsWithJSX(cons: Con[]): any[] {
    let formatted: any[] = [];
    cons.map((con: Con, index: number) => {
      formatted.push({
        serial: index,
        heading: con.heading,
        condelete: (
          <DeleteIcon
            onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
              event.preventDefault();
              props.deleteCon(con.serial);
            }}
            color="primary"
            style={{
              cursor: "pointer",
            }}
          />
        ),
      });
    });
    return formatted;
  }

  return (
    <>
      <Grid className="holidayMastersDataGrid">
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>Cons</div>
            </Grid>
            <Grid item xs="auto">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  event.preventDefault();
                  setShowAddConModal(!showAddConModal);
                }}
              >
                Add Con
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <GenericTable
        columnNames={["ConDelete", "Heading"]} // Make sure to arrange the column names in alphabetical order.
        rows={getFormattedRowsWithJSX(props.cons)}
      />

      {showAddConModal ? (
        <GenericDialog
          show={showAddConModal}
          heading="Add Con"
          inputProps={
            <>
              <TextField
                type="text"
                name="con"
                variant="outlined"
                fullWidth
                label="Con"
                defaultValue={currentCon.heading}
                onChange={(event: any) => {
                  event.persist();
                  let currentState = currentCon;
                  currentState.heading = event.target.value;
                  setCurrentCon(currentState);
                }}
              />
              <Grid item xs={12}>
                <TextareaAutosize
                  className={props.class}
                  id="filled-basic"
                  rowsMin={13}
                  onChange={(event: any) => {
                    event.persist();
                    let currentState = {
                      ...currentCon,
                      text: event.target.value,
                    };
                    setCurrentCon(currentState);
                  }}
                  value={currentCon.text}
                />
              </Grid>
            </>
          }
          onClose={(event: any) => {
            setShowAddConModal(!showAddConModal);
          }}
          handleSubmit={() => {
            props.addNewCon(currentCon);
            setCurrentCon(currentConInitialState);
            setShowAddConModal(!showAddConModal);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
